<div class="vendor-main-body"
	[ngClass]="{'vendor-main-body-certification': type.toLowerCase() === 'certification' && !isManuallyUploaded}">
	<div class="header-ven">
		<div class="cross-icon">
			<div class="cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="closePopUp()">
			</div>
		</div>
	</div>
	<div class="body-ven">
		<div class="custom-framework-heading lato-20-n-vw">
			Add New {{type}}
		</div>
		<div *ngIf="!isManuallyUploaded" class="custom-framework-body lato-16-n-vw">
			New {{type}} will appear in the vendor's collection section
		</div>

		<div class="artifact-parent">

			<div *ngIf="isManuallyUploaded"
				class="custom-framework-body lato-16-n-vw manual-artifact">
				Please note you are adding an artifact that will not be shared with the vendor.
			</div>

		</div>
		<div *ngIf="isManuallyUploaded" class="custom-framework-body lato-16-n-vw manual-artifact">
			The artifact will be visible for internal management in the vendor area below
			<div class="type-heading"> "{{selectedType}}"
				<span class="upload-icon manual-artifact-icon" cygovSvgIcon
					[svgName]="'info-board-icon'" tooltipClass="customized-artifact-tooltip"
					[ngbTooltip]="'If you would like to add the artifacts as visible to vendor , please use the add artifact button in the top right corner'"
					[placement]="'right'">
				</span>
			</div>
		</div>

		<!-- Naming Section of the custom framework -->
		<div class="custom-framework-name-wrapper-1"
			[ngClass]="{'custom-framework-name-wrapper-1-cert' : type === frameworkTypes.certificate && !isManuallyUploaded , 'margin-adjust':selectedFileName}">

			<div class="custom-framework-naming-section-wrapper"
				[ngClass]="{'custom-framework-naming-section-wrapper-cert' : type === frameworkTypes.certificate && !isManuallyUploaded}">
				<!-- Name Section -->
				<div class="name-section-wrapper">
					<label class="lato-16-n-vw" for="name">Name</label>
					<input class="name-text-input lato-14-n-vw" type="text" name="name" id="name"
						placeholder=" " [(ngModel)]="customFrameworkName" autocomplete="off" />
				</div>
				<!-- Separator
				<div class="horizontal-separator"></div> -->
				<!-- type Section-->

				<div *ngIf="showCategoryTooltip" class="tooltip-container" [@fadeInOutAnimation]>
					<ng-container *ngTemplateOutlet="infoDetails; ">
					</ng-container>
				</div>
				<div class="type-section-wrapper"
					[ngClass]="{'type-section-wrapper-expanded': isCategoryExpanded || isTypeExpanded}">
					<div class="type-select">
						<div class="category-type-dropdowns"
							*ngIf="type === frameworkTypes.requirement">
							<div class="type-name lato-16-n-vw">
								Category
								<span class="type-icon" cygovSvgIcon [svgName]="'info-board-icon'"
									(mouseenter)="showCategoryTooltip = true">
								</span>
							</div>
							<div class="drop-outer-container">
								<div class="expand-business-list"
									[ngClass]="{'expanded-business-drop-down': isCategoryExpanded }">
									<div class=" upper-portion"
										[ngClass]="{'expanded-dropdown': isCategoryExpanded }"
										(click)="expandCategoryLowerPortion()">
										<div class="left-text lato-16-l-vw">
											{{selectedTypeLabel(selectedType)}}
										</div>
										<div class="right-text">
											<div class="arrow-icon-right"
												[ngClass]="{'rotate-arrow-down':isCategoryExpanded}"
												cygovSvgIcon [svgName]="'arrow-right-type3'"></div>
										</div>
									</div>
									<div class="background-section" *ngIf="isCategoryExpanded">
										<div class="lower-portion" [@domainHeightAnimation]>
											<div class="lato-18-n-vw options-container">
												<div class="round-checkbox"
													*ngFor="let item of categoryTypesList; let i = index"
													(click)="categoryValueChange(item.value)">
													<input [id]="'label-step-icon' + i" type="radio"
														[name]="'step-icon-1' + i" #typeRadio1
														class="jarl-radio" [value]="item.value"
														[checked]="item.value.toLowerCase() === selectedType.toLowerCase()"
														(change)="categoryValueChange(item.value)" />
													<label [for]="'step-icon' + i"
														class="lato-16-l-vw capitalize label standard">{{
														item.name }}
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="category-type-dropdowns">
							<div class="type-name lato-16-n-vw">
								Type
							</div>
							<div class="drop-outer-container">
								<div class="expand-business-list"
									[ngClass]="{'expanded-business-drop-down': isTypeExpanded }">
									<div class="upper-portion"
										[ngClass]="{'expanded-dropdown': isTypeExpanded }"
										(click)="expandTypeLowerPortion()">
										<div class="left-text lato-16-l-vw category-type-with-ellipsis"
											(mouseenter)="checkIfEllipsis($event.target)"
											tooltipClass="customized-tooltip-dropper"
											[ngbTooltip]="selectedTypeOthersLabel(selectedTypeOthers)"
											[disableTooltip]="!showTextTooltip"
											[placement]="'top-left'">
											{{selectedTypeOthersLabel(selectedTypeOthers)}}
										</div>
										<div class="right-text">
											<div class="arrow-icon-right"
												[ngClass]="{'rotate-arrow-down':isTypeExpanded}"
												cygovSvgIcon [svgName]="'arrow-right-type3'"></div>
										</div>
									</div>
									<div class="background-section" *ngIf="isTypeExpanded">
										<div class="lower-portion" [@domainHeightAnimation]>
											<div class="square-checkbox">
												<span *ngIf="!isNewClicked"
													class="add-new-text lato-16-n-vw"
													(click)="isNewClicked = !isNewClicked">+ &nbsp;
													&nbsp; &nbsp; Add
													New</span>
												<div *ngIf="isNewClicked">
													<input type="text" class="add-new lato-16-l-vw"
														placeholder="Add New"
														[(ngModel)]="newlyAddedItem"
														(keyup.enter)="addNewLine($event)">
													<div class="cross-icon-frame"
														(click)="isNewClicked = !isNewClicked; ">
														<div class='cancel-cross'>x</div>
													</div>
												</div>
											</div>
											<div class="lato-18-n-vw options-container">
												<div class="round-checkbox"
													*ngFor="let item of displayedTypesList; let i = index"
													(click)="typeValueChange(item.value)">
													<input [id]="'label-step-icon' + i" type="radio"
														[name]="'step-icon-2' + i" #typeRadio2
														class="jarl-radio" [value]="item.value"
														[checked]="item.value.toLowerCase() === selectedTypeOthers.toLowerCase()"
														(change)="typeValueChange(item.value)" />
													<label [for]="'step-icon' + i"
														class="lato-16-l-vw capitalize label standard">
														<span class="category-items-with-ellipsis"
															(mouseenter)="checkIfEllipsis($event.target)"
															[ngbTooltip]="item.name"
															tooltipClass="customized-tooltip-dropper"
															[disableTooltip]="!showTextTooltip"
															container="body">{{
															item.name }}</span>
													</label>
													<div *ngIf="isDefaultSetting ? !item?.isDefault : !item?.isDefault && (newAddedItems?.includes(item.value) || customVendorReqs[selectedType.toUpperCase()]?.includes(item))"
														class="cross-icon-frame"
														(click)="removeItem(i)">
														<div class='cancel-cross'>x</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- Upload Template Section of the custom framework -->
		<div class="custom-framework-upload-file-section-wrapper"
			*ngIf="type !== frameworkTypes.certificate|| isManuallyUploaded">
			<!-- Label -->
			<div class="upload-label lato-16-n-vw">
				Upload Template
				<span class="upload-icon" cygovSvgIcon [svgName]="'info-board-icon'"
					tooltipClass="customized-tooltip-upload"
					[ngbTooltip]="'Include an example of an artifact for the vendors reference'"
					[placement]="'right'">
				</span>
			</div>
			<!-- Upload File -->
			<div class="upload-file-container">
				<div class="body">
					<div class="upload-icon-positioning">
						<div class="icon-upload-cloud" cygovSvgIcon [svgName]="'upload_collection'">
						</div>
					</div>

					<img *ngIf="!isInformatica" class="upload-cloud-img" src="assets/images/upload-cloud.png"
						alt="Cloud Upload" />
					<img *ngIf="isInformatica" class="upload-cloud-img" src="assets/images/cloud-new.png"
						alt="Cloud Upload" />
					<div id="caller" class="lato-16-n-vw center-align-text">
						Drag files here or
						<label class="browse-text" for="file">browse</label>
						<input type="file" [uploader]="uploader" multiple class="hide-input-file" id="file"
							accept=".gif, .jpg, .png, .ico, .doc, .docx, .csv, .pdf, .xls, .xlsx, .ppt, .txt"
							ng2FileSelect (onFileSelected)="uploadTemplateArtifacts($event)" />
					</div>
				</div>
			</div>
		</div>

		<!-- framework uploaded show -->
		<ng-container *ngIf="selectedFileName">
			<div class="line-separator"></div>
			<div class="flex-container-template-file-uploaded">
				<div class="circle"></div>
				<div class="text lato-16-n-vw"> {{selectedFileName}}</div>
				<div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
					(click)="removeFile()">
				</div>
			</div>
			<div class="line-separator"></div>
		</ng-container>

		<!-- Action Buttons -->
		<div class="custom-framework-action-buttons-wrapper"
			[ngClass]="{'top-margin': type === frameworkTypes.certificate && !isManuallyUploaded}">
			<cygov-button class="button-margin" [buttonText]="'COMPLETE'" [buttonWidth]="'5.1vw'"
				[buttonColor]="'#29a9eb'" [hoverColor]="'#0F92E3'"
				(click)="openConfirmationModal(NewTaskContent, NewTaskFooter)">
			</cygov-button>
			<cygov-button class="button-margin" [buttonText]="'CANCEL'"
				[buttonColor]="'transparent'" [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.1vw'"
				[textColor]="'#C5C9D1'" (click)="closePopUp()">
			</cygov-button>
		</div>
	</div>
</div>
<!----------------------- New Tasks pop up body ----------------------------->
<ng-template #NewTaskContent>
	<div class='modal-options-wrapper'>
		<div class="modal-option-wrapper-insider">
			<div class="lato-18-n-vw options-message">
				Please upload a default template<br>
			</div>
			<div class="lato-18-n-vw options-container">
				<div class="round-checkbox" *ngFor="let option of saveOptions; let i = index"
					(click)="onSelectType(option.value)">
					<input [id]="'label-step-icon' + i" type="radio" [name]="'step-icon' + i"
						#typeRadio class="jarl-radio" [value]="option.value"
						[checked]="option.value === selectedTemplateType"
						(change)="onSelectType(option.value)" />
					<label [for]="'step-icon' + i" class="lato-16-n-vw capitalize label standard">{{
						option.name }}
					</label>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!----------------------------- New Tasks pop up footer -------------------------------->
<ng-template #NewTaskFooter>
	<div class="lato-22-n-vw modal-options-footer">
		<cygov-button class="button-margin" [buttonText]="'UPLOAD'" [buttonWidth]="'5.1vw'"
			[buttonColor]="'#29a9eb'" [hoverColor]="'#0F92E3'" (click)="saveRequirement()">
		</cygov-button>
		<cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'transparent'"
			[buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.1vw'" [textColor]="'#C5C9D1'"
			(click)="closeSharedModal()">
		</cygov-button>
	</div>
</ng-template>

<ng-template #infoDetails let-dataValue>

	<div class="tooltip">
		<span class="close-icon" (click)="showCategoryTooltip = false">X</span>
		<div class="tooltip-content">
			Assignment of document category Will allocate document in artifact management control
		</div>

	</div>
</ng-template>