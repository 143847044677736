import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ExistingFilesComponent } from './existing-files.component';
import { DownloadExistingSharedModalComponent } from './download-existing-shared-modal/download-existing-shared-modal.component';
import { FileIconModule } from 'app/shared/file-icon/file-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';
import { SelectModule } from '../select/select.module';
import { UsernameIconModule } from '../username-icon/username-icon.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
  declarations: [ExistingFilesComponent, DownloadExistingSharedModalComponent],
  imports: [
    FileIconModule,
    CommonModule,
    SvgIconModule,
    NgbModule,
    CygovLoaderModule,
    SelectModule,
    UsernameIconModule,
    ProgressBarModule,
  ],
  exports: [ExistingFilesComponent, DownloadExistingSharedModalComponent],
})
export class ExistingFilesModule {}
