/* eslint-disable max-len */

export const environment = {
  production: true,
  debug: true,
  name: 'dev',
  captchKey: '6Lfqw88ZAAAAAO8aoC4V4xFLzsFTmxxvm9zKvX0H',
  disable3rdPartyModule: false,
  disableBoardModule: false,
  disableTrainingCtr: false,
  queryListLimit: 1000,
  region: 'us-east-1',
};
