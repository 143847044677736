<div *ngIf="deleteFlag && !integrationUser"
  [ngClass]="{'disabled-assign-user': greyOutColor, 'remove-user-cross-positioning': true}">
  <div class="remove-user-cross" [ngStyle]="{width:crossWidth+'vh',height:crossWidth+'vh'}"
    (click)="removeAssignedUser()">
    <div class="status-cross" cygovSvgIcon [svgName]="'x-icon'"></div>
  </div>
</div>
<div *ngIf="!integrationUser && showInitials" class="username-icon uppercase lato-{{textSize}}-n-vw"
  [ngClass]="{'dotted-manager': isManager , 'letter-spacing-class':letterSpacing, 'disabled-assign-user': greyOutColor, 'highlightUser': highlightUser ,'blue-border':currentLoggedInUser}"
  [ngStyle]="{ height: height + 'vmin', width: height + 'vmin', backgroundColor: bgColor }">
  <span
    [ngClass]="{'color-class': (!(!document && bgColor==='black') && !isSelected), 'selected-color-class': isSelected}"
    [title]="username">{{ initials }}</span>
</div>
<div *ngIf="integrationUser" class="integration-username-icon" [ngClass]=" {'dotted-manager': isManager , 'letter-spacing-class' :letterSpacing
  , 'int-container' : true}" [ngStyle]="{ height: intHeight + 'vh', width: intHeight + 'vh'}">
  <span
    [ngClass]="{'color-class': (!(!document && bgColor==='black') && !isSelected), 'selected-color-class': isSelected}"
    [title]="username">
    <div class="int-icon" cygovSvgIcon [svgName]="'integration-icon'"></div>
  </span>
</div>