export const  OnUpdateEntityByRootId =  `subscription OnUpdateEntityByRootId($id: String!) {
  onUpdateEntityByRootId(id: $id) {
    __typename
    id
    name
    users
    completionStatus
    accessGroups
    participantGroup
    rootEntityId
    childEntityId
    frameworkId
    frameworkName
    frameworkType
    parentId
    timeline {
      __typename
      initiationDate
      collectionDate
      nextReviewDate
      frequency
      days
      isAutoReminder
      isAutoReassessment
    }
    scores {
      __typename
      total
      target
      collection
      remediation
      reassessment
      totalQuestions
      completedQuestions
      totalGaps
      criticalGaps
      notApplicable
      top20 {
        __typename
        total
        target
        collection
        remediation
        reassessment
        totalQuestions
        completedQuestions
        totalGaps
        criticalGaps
        notApplicable
        top20 {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        maturity
        tierScores
        totalReadinessTasks
        totalComplianceTasks
        totalInternalTasks
      }
      maturity
      tierScores
      totalReadinessTasks
      totalComplianceTasks
      totalInternalTasks
    }
    entityType
    projectManager
    projectManagerName
    projectDeadline
    logo {
      __typename
      id
      name
      names
      entityId
      fileType
      date
      contentType
      length
      key
    }
    integrations {
      __typename
      name
      credentials
      status
      isEnable
      isAuth
      inheritFromParent
      webhookUpdatedAt
    }
    externalFeed {
      __typename
      name
      credentials
      status
      isEnable
      isAuth
      inheritFromParent
      webhookUpdatedAt
    }
    defaultSetting {
      __typename
      dueDateInDays
      frequency
      surveyLevel
      artifacts {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      standards {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      customRequirements {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      customCertifications {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      accessLevel
      privacyData
      impactsQualify {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      activeScan
      notifications
      selectedRisk
      isThirdParty
      isFourthParty
      domains
      otherData
      fourthPartyWeightage
    }
    createdBy
    createdAt
    modifiedBy
    modifiedAt
    industry
    domain
    activeAssessmentId
    tierSelected
    scName
    defaultQuestionSettings {
      __typename
      isApprovalRequired
      isArtifactsMandatory
      isCollaborative
    }
    KMS {
      __typename
      status
      version
      key
      alias
      dataKeyEncrypted
      newDataKeyEncrypted
      newKeyId
      rotations {
        __typename
        count
        status
        lastRotation
        firstRotationDate
      }
      userId
    }
    updatedAt
    vendorDetails {
      __typename
      id
      impact
      externalScan
      financialRisk
      criticalGaps
      standardsQualify {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      status
      probability
      website
      location
      size
      accessLevel
      collectionStatus
      timeline {
        __typename
        initiationDate
        collectionDate
        nextReviewDate
        frequency
        days
        isAutoReminder
        isAutoReassessment
      }
      approvalDate
      questionnaires {
        __typename
        tag
        status {
          __typename
          totalQuestions
          completedQuestions
        }
        criticalGaps
        allGaps
      }
      artifacts {
        __typename
        documents {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        scans {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        insurances {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        certifications {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
      }
      primaryPoc {
        __typename
        name
        id
        email
      }
      stage
      activeScan
      domain
      privacyData
      impactsQualify {
        __typename
        name
        isQualify
        templateURL {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        documentCategory
        documentType
        level
      }
      primaryLoss
      financialImpact {
        __typename
        type
        minimum
        mostLikely
        maximum
        confidence
        stepData
      }
      smartTags
      employees
      domains
      isThirdParty
      isFourthParty
      fourthPartyDomains
      level
      fourthPartyActiveScan
      intelligenceScanScore
      fourthPartyScanScore
      createdAt
      updatedAt
      intelligence {
        __typename
        id
        createdAt
        updatedAt
        breaches {
          __typename
          items {
            __typename
            id
            vendorId
            date
            year
            name
            description
            leaks
            data
            status
            email
            createdAt
            updatedAt
          }
          nextToken
        }
        externalThreats {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        others {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
    activeAssessment {
      __typename
      id
      standardList {
        __typename
        id
        type
        fileName
        filter {
          __typename
          impact
        }
      }
      timeline {
        __typename
        initiationDate
        collectionDate
        nextReviewDate
        frequency
        days
        isAutoReminder
        isAutoReassessment
      }
      rootId
      childId
      scores {
        __typename
        total
        target
        collection
        remediation
        reassessment
        totalQuestions
        completedQuestions
        totalGaps
        criticalGaps
        notApplicable
        top20 {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        maturity
        tierScores
        totalReadinessTasks
        totalComplianceTasks
        totalInternalTasks
      }
      left
      right
      managers
      exports {
        __typename
        jiraEntityIds
        serviceNowEntityIds
      }
      createdAt
      updatedAt
      standardFrameworkList {
        __typename
        items {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          selectedChapters
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCollaborative
          }
          documentCategory
          documentType
          suggestedArtifact
          level
          createdAt
          updatedAt
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
            standardFrameworkList {
              __typename
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
}`

export const onDeleteUser= `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    __typename
    id
    name
    email
    role
    roleId
    isViewOnly
    phone
    entityId
    entityIds
    isCognitoUser
    questionMap
    createdBy
    createdAt
    lastLogin
    invitationDate
    reminderDate
    modifiedBy
    modifiedAt
    domain
    temporaryHigherRank
    entityLayerIds
    clickThrough
    clickThroughDate
    projectManagerEmail
    isGlobalParticipant
    onBoardingStatus
    updatedAt
  }
}`;