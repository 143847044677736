import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cygov-thank-you-stage-one',
  templateUrl: './thank-you-stage-one.component.html',
  styleUrls: ['./thank-you-stage-one.component.scss'],
})
export class ThankYouStageOneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
