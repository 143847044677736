import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbCalendarComponent } from './ngb-calendar.component';
import { SelectModule } from '../select/select.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, SelectModule],
  declarations: [NgbCalendarComponent],
  exports: [NgbCalendarComponent],
  entryComponents: [NgbCalendarComponent],
})
export class NgbCalendarModule {}
