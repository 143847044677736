export enum UsersSettingConstant {
  firstParty = 'first-party',
  upperdeck = 'upperdeck',
  default = 'default',
  custom = 'custom',
  thirdParty = 'third-party',
  boardView = 'board',
  defaultSetting = 'default settings',
  overView = 'overview',
  wizard = 'wizard',
  vendors = 'vendors',
  customVendors = 'custom-vendors',
  name = 'name',
  ascending = 'asc',
  userSettings = 'User Settings',
  clients = 'clients',
  profit_center = 'Profit Center',
}

export enum UsersSettingScreens {
  users = 'users',
  permissions = 'permissions',
}
export enum FirstPartyTabs {
  COLLECTION = 'collection',
  UPPERDECK = 'upper deck',
  PILLARS = 'pillars',
  BREAK_DOWN = 'break down',
  MULTI_ENTITY = 'multi entity',
  REMEDIATION_CENTER = 'remediation center',
  USER_sETTINGS = 'user settings',
  RISK_REGISTER = 'risk register',
  INTEGRATIONS = 'integrations',
}
