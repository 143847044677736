import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'app/shared/file.service';
import { v4 as uuid } from 'uuid';
import { APIService } from 'app/API.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { UploadFileComponent } from 'app/shared/upload-file/upload-file.component';
import { ExistingFiles } from 'app/shared/existing-files/existing-files.component';
import { UploadFileService } from 'app/shared/upload-file/upload-file.service';
import validator from 'validator';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  modalRef: NgbModalRef;
  newTicket: any;
  confirmationModal = false;
  uploadFileModalReference = null;
  uploadedFiles: ExistingFiles[] = [];
  filePath = '';
  zipFile = null;
  PopUpModalOptions: NgbModalOptions = {
    backdrop: 'static',
    windowClass: 'support-modal',
    backdropClass: 'support-backdrop',
  };
  modalOptionsUploadScreen: NgbModalOptions = {
    windowClass: 'over-right-section-window',
    backdropClass: 'over-right-section-backdrop',
    centered: true,
  };
  invalidInput: boolean = false;

  constructor(
    private modalService: NgbModal,
    private fileService: FileService,
    private uploadService: UploadFileService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private customApi: CUSTOMAPIService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.newTicket = this.initTicket();
  }

  initTicket(): any {
    return {
      id: uuid(),
      name: '',
      email: '',
      description: '',
      filePath: null,
      domain: window.location.origin,
      createAt: JSON.stringify(Date.now()),
    };
  }

  openModal(content): void {
    this.modalRef = this.modalService.open(content, this.PopUpModalOptions);
  }

  /**
   * Function to remove files from list , files from zipped file and upload
   * the updated zip
   * @param deletedFiles the list consisting of file names to delete
   */
  removeFileFromZip(deletedFiles): void {
    // try {
    if (deletedFiles && deletedFiles.length) {
      deletedFiles.forEach(removedFile => {
        this.zipFile.remove(removedFile);
      });
      const filesRemaining = [];
      this.uploadedFiles.forEach(files => {
        if (!deletedFiles.includes(files.fileName)) {
          filesRemaining.push(files);
        }
      });
      this.uploadedFiles = JSON.parse(JSON.stringify(filesRemaining));
      this.toastr.success('Files Deleted and Updated Successfully');
    }
  }

  // This function execute one time before to send the Ticket sendTicket() is called/invoked
  async uploadFilesToS3(): Promise<void> {
    try {
      if (this.uploadedFiles.length) {
        const contentZip = await this.zipFile.generateAsync({ type: 'blob' });
        const fileInfo = {
          id: '',
          assessmentId: this.newTicket.id,
          body: contentZip,
          name: '',
          fileType: 'SUPPORT_FILES',
        };
        const upload = await this.fileService.uploadToS3(fileInfo);
        if (upload) {
          this.toastr.success('Files Uploaded Successfully');
        } else {
          this.toastr.error('Failed to Update Files');
        }
      }
    } catch (e) {
      this.toastr.error('Failed to Update Files');
    }
  }

  openUploadModal() {
    this.uploadFileModalReference = this.modalService.open(UploadFileComponent, this.modalOptionsUploadScreen);
    this.uploadFileModalReference.componentInstance.uploadPath = `SUPPORT_FILES/${this.newTicket.id}/`;
    this.uploadFileModalReference.componentInstance.isFakeUpload = true;
    this.uploadFileModalReference.componentInstance.requiredInfo = {
      fileType: 'SUPPORT_FILES',
      assessmentId: this.newTicket.id,
    };
    this.uploadFileModalReference.componentInstance.closeModalClicked.subscribe(response => {
      if (response) {
        this.uploadFileModalReference.close();
      }
    });
    this.uploadFileModalReference.componentInstance.totalUploadedFiles.subscribe(response => {
      if (response && response.length) {
        this.filePath = `SUPPORT_FILES/${this.newTicket.id}/${this.newTicket.id}.zip`;
        const zipEntry = response.pop();
        this.zipFile = zipEntry.file;
        this.uploadedFiles = JSON.parse(JSON.stringify(response));
      } else {
        this.uploadedFiles = [];
      }
    });
  }

  async sendTicket(): Promise<void> {
    try {
      if (this.validations()) {
        await this.uploadFilesToS3();
        this.newTicket.filePath =
          this.uploadedFiles && this.uploadedFiles.length
            ? `SUPPORT_FILES/${this.newTicket.id}/${this.newTicket.id}.zip`
            : null;

        const payload = this.customApi.encryptPayload(this.newTicket);
        await this.apiService.SentSupportTicket(payload);
        this.confirmationModal = true;
        this.toastr.success('Ticket sent successfully!');
      }
    } catch (e) {
      this.logger.error('sendTicket - Error:', e);
      this.toastr.error('Failed to send ticket!');
    }
  }
  validations() {
    let error = true;
    const regexForName = /^[A-Za-z ]+$/;
    if (this.newTicket.name === '') {
      error = false;
      this.toastr.info('Name cannot be empty');
    } else {
      if (!regexForName.exec(this.newTicket.name)) {
        error = false;
        this.toastr.info('Name cannot have numbers or special charcters');
      } else if (this.newTicket.name.length > 50) {
        error = false;
        this.toastr.info('Name cannot be longer than 50 charcters');
      }
    }
    if (this.newTicket.email === '') {
      error = false;
      this.toastr.info('Email cannot be empty');
    } else {
      if (!validator.isEmail(this.newTicket.email.trim())) {
        error = false;
        this.toastr.info('Not a valid email');
      }
    }
    return error;
  }
  onBlur(input: string): void {
    this.invalidInput = UtilsService.checkForHtml(input);
    if (this.invalidInput) {
      this.toastr.warning('Please enter valid text. HTML tags are not accepted!');
    }
  }

  closeModal() {
    setTimeout(() => {
      if (this.confirmationModal) {
        this.confirmationModal = false;
      }
    }, 400);
    this.modalRef.close();
    this.newTicket = this.initTicket();
    this.uploadedFiles = [];
    this.uploadService.supportZip = null;
  }
}
