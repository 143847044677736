export enum ExternalAuditStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum AssetCategoryEnum {
  PEOPLE = 'People',
  HARDWARE = 'Hardware',
  SOFTWARE = 'Software',
  COMMUNICATION = 'Communication',
  DEVICES = 'Devices',
  OTHER = 'Other',
}
export enum FinancialRiskTypeEnum {
  BASIC = 'BASIC',
  ADVANCE = 'ADVANCE',
}

export enum ConfidenceEnum {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export enum ConfidenceNumEnum {
  HIGH = 3.5,
  MEDIUM = 3,
  LOW = 2,
}

export enum ProjectTypeEnum {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
}

export enum CostRemediateImpactEnum {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  EXTREME = 'Extreme',
}
export enum ProbabilityTextEnum {
  UNLIKELY = 'Unlikely',
  POSSIBLE = 'Possible',
  LIKELY = 'Likely',
  HIGH_LIKELY = 'High Likely',
}

export enum ImpactTextEnum {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  CRITICAL = 'Critical',
}

export enum BoardTable {
  NEW_ROW = 'newRow',
  DELETE_ROW = 'deleteRow',
}

export enum WidgetEnum {
  RISK_SCORE = 'riskScore',
  COMPLIANCE_STATUS = 'compliance',
  OPERATIONAL = 'operational',
  MONITORING = 'monitoring',
  THREAT_LEVEL = 'threatLevel',
}

export enum AttackFunnelDataEnum {
  TOTAL_EVENTS = 'totalEvents',
  BLOCKED = 'blocked',
  INVESTIGATED = 'investigated',
  ESCALATED = 'escalated',
  LEAKS = 'leaks',
  ATTACK_FUNNEL = 'attackFunnel',
}

export enum PostureChartDataEnum {
  IDENTIFY = 'identify',
  PROTECT = 'protect',
  DETECT = 'detect',
  RESPOND = 'respond',
  RECOVER = 'recover',
}

export enum PostureFrameworkGroupEnum {
  TECHNOLOGY = 'technology',
  PHSYICAL_SECURITY = 'physical security',
  INTELLIGENCE = 'intelligence',
  ADMINISTRATIVE = 'administrative',
}

export enum ChooseReportEnum {
  FIRST = 'first',
  SECOND = 'second',
}

export enum ReportDownloadOptionsEnum {
  PPTX = 'pptx',
  PDF = 'pdf',
}
export enum EventDataProactiveMeasures {
  PROACTIVE_MEASURES = 'proactiveMeasures',
  PENTEST_FINDINGS_TOTAL = 'pentestFindingsTotal',
  PENTEST_FINDINGS_TARGET = 'pentestFindingsTarget',
  PHISHING_TESTS_TOTAL = 'phishingTestsTotal',
  PHISHING_TESTS_TOTAL_TARGET = 'phishingTestsTotalTarget',
  VULNERABILITIES_PATCHED_TOTAL = 'vulnerabilitiesPatchedTotal',
  VULNERABILITIES_PATCHED_TOTAL_TARGET = 'vulnerabilitiesPatchedTotalTarget',
  NUMERATOR = 'numerator',
  DENOMINATOR = 'denominator',
}
