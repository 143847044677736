<div class="slider-component lato-12-n-vw">
    <span *ngIf="showRanges" class="range-text">{{leftLabel && leftLabel.length ? leftLabel :
        minValue |
        largeNumberShort}}</span>
    <div class="slider-container"
        [ngClass]="{'disabled': isVersionClicked , 'hide-thumb':sliderValue<0}">
        <div *ngFor=" let pos of checkPointsPos">
            <span class="pin-icon"
                [ngStyle]="{left : pos.position-1+'px', top: '-16px'}">{{pos.score}}</span>
            <div cygovSvgIcon [svgName]="'pin'" class="pin-icon"
                [ngStyle]="{left : pos.position+'px'}">
            </div>
            <span class="pin-icon"
                [ngStyle]="{left : pos.position-10+'px', top: '5px'}">{{pos.label}}</span>
        </div>
        <div *ngIf="checkPoints" class="partial-values">
            <div class="value-label">
                <div class="thumb-value lato-12-n-vw" *ngIf="appendThumbVal">
                    50%
                </div>
                <div class="pointer-50"></div>
            </div>
            <div class="value-label extra-pading">
                <div class="thumb-value lato-12-n-vw" *ngIf="appendThumbVal ">
                    75%
                </div>
                <div class="pointer-75"></div>
            </div>
        </div>
        <div class="moveable-label lato-12-n-vw" [ngClass]="{'risk-moveable-label': isFromRisk}"
            *ngIf="moveableThumbLabel && this.progressValue>=0"
            [ngStyle]="{'margin-left' : isFromAIRisk ? (this.progressValue-this.progressValue/13)+'%' : this.progressValue-(this.progressValue/5.93)+'%', color: isVersionClicked ? '#ffffff' : '#29a9eb'}">
            {{sliderValue}}{{moveableSignValue}}
            <div class="stick" [ngClass]="{'risk-stick': isFromRisk}"></div>
        </div>
        <input type="range" [min]="minValue" [max]="maxValue" class="slider" [step]="incrementStep"
            [disabled]="isDisable"
            [ngClass]="{'blue-pointer': bluePointer, 'risk-slider': isFromRisk}" #customRange
            (click)="sliderClick()" (ngModelChange)="changeToPercent($event)"
            [(ngModel)]="sliderValue" [id]="idName">
        <div class=progress-area [ngClass]="{'risk-progress-area': isFromRisk}"
            [ngStyle]="{width : this.progressValue+'%', 'background': backgroundGradient}"></div>
    </div>
    <span *ngIf="showRanges" class="range-text">{{rightLabel && rightLabel.length ? rightLabel :
        maxValue |
        largeNumberShort}}</span>
</div>