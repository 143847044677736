<div class="select-framework">

    <div class="header">

        <div class="cross-icon" (click)="closeModal()">X</div>

        <div class="search-parent">
            <div class="lato-16-n-vw risk-assessment">Risk Assessment</div>


            <div class="search-wrapper">
                <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
                <input type="text" class="form-control search" aria-label="Search"
                    placeholder="Search" name="search" spellcheck="true"
                    (input)="filterFramework($event)" />
            </div>

        </div>

    </div>

    <div class="framework-section">
        <div class="framework-wrapper">
            <div class="firstTab-list-cont"
                [ngStyle]="{'height' : filteredFrameworks?.length < 24 ? 'unset' : '37vh'}">
                <div class="firstTab-name round-checkbox"
                    *ngFor="let item of filteredFrameworks; let i = index;">
                    <input type="radio" name="firstTab-item" [value]="item.name"
                        [checked]="item.selected" [id]="'firstTab-label-' + i"
                        class="firstTab-item-checkbox" (click)="selectFramework(item.key)" />
                    <label [for]="'firstTab-label-' + i" class="label netskope-overview">
                        <div class="text-area-ellipsis" [ngbTooltip]="item.name" #fwRadioLabel
                            [disableTooltip]="true" [tooltipClass]="'large-number-tooltip'">
                            {{ item.name }}
                        </div>
                    </label>
                </div>
            </div>

        </div>


    </div>
    <div class="horizontal-seperator"></div>


</div>