import {
  VendorStatusEnum,
  GetEntityQuery,
  EntityTypeEnum,
  ImpactEnum,
  StandardType,
  CreateFrameworkManagerInput,
  ModelEntityFilterInput,
} from './../API.service';
import { HeatMapCoordinate } from './../../models/heatmap.model';
import { StatusCount } from '../../models/status-count.model';
import { ExternalScanCount } from '../../models/external-scan-count.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { HeatMap } from 'models/heatmap.model';
import {
  RiskTextEnum,
  ImpactNumEnum,
  ImpactTextEnum,
  ProbabilityEnum,
  ProbabilityTextEnum,
  AssessmentTextEnum,
  ExtScanEnum,
  AssessmentEnum,
  ExternalScanTextEnum,
  RiskScoreEnum,
  RiskScoreTextEnum,
} from '../shared/enums/impact.enum';
import { ImpactCount } from 'models/impact-count.model';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';
import { TableHeader } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { FileService } from 'app/shared/file.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { UserService } from './../shared/user.service';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { UtilsService } from 'app/shared/utils.service';
import { VendorColumnMappersEnum, VendorThirdForthPartyFlag } from 'app/shared/enums/vendor-map.enum';
import { ImpactEnum as IE } from 'app/shared/enums/risk-map.enums';
import { RiskCount } from 'models/risk-count.model';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { ToastrService } from 'ngx-toastr';

import { CUSTOMAPIService } from 'app/custom-api.service';

const CYGOV_HEATMAP_SECTIONS = 'cygov_heatmap_sections_storage';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyService {
  isVendorListExpanded = false;
  storage: Storage;
  newVendorCreated = false;
  onNewVendorAdded = new Subject<boolean>();
  onLoaderAdded = new Subject<boolean>();
  triggerBulkPop: Subject<boolean> = new Subject<boolean>();
  public static isVendorCreated: boolean = false;
  vendorId: string = ''; // Adding this variable for a case when user navigates from remediation to collection in third party
  bulkVendorIds: any = {};
  isBulkInProgress: boolean = false;
  private onBulkCalled = new ReplaySubject<any>(1);
  bulkCalled$ = this.onBulkCalled.asObservable();
  previousAssessment: any;
  private clickAssessment = new BehaviorSubject<any>({});
  previousAssessment$ = this.clickAssessment.asObservable();

  constructor(
    private entityService: EntityService,
    private fileService: FileService,
    private userService: UserService,
    private customApi: CUSTOMAPIService,
    private clientLambdaService: ClientLambdaService,
    private toastr: ToastrService
  ) {
    this.storage = localStorage;
  }

  addPreviousAssessment(assessment: any) {
    this.previousAssessment = assessment;
    this.clickAssessment.next(assessment);
  }

  getPreviousAssessment() {
    return this.previousAssessment;
  }

  setBulkUploadStatus(data: any) {
    this.onBulkCalled.next(data);
  }

  public static changeVendorCreationStatus(created: boolean = false) {
    ThirdPartyService.isVendorCreated = created;
  }
  static getTotalVendors(vendors: GetEntityQuery[]): number {
    return vendors?.length;
  }

  static getRiskScoreAverage(vendors: GetEntityQuery[]): number {
    // if vendors array empty return 0 instead of NAN
    if (vendors && vendors.length === 0) {
      return 0;
    }

    return vendors?.reduce((average, vendor) => (average += vendor.scores.total), 0) / this.getTotalVendors(vendors);
  }

  static getTarget(): number {
    return 9.5;
  }

  static getImpactCounts(vendors: GetEntityQuery[]): ImpactCount {
    const impactCount = new ImpactCount();
    vendors?.forEach(vendor => {
      const impact = vendor?.vendorDetails?.impact;

      if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
        impactCount.low++;
      } else if (impact <= ImpactNumEnum.MEDIUM) {
        impactCount.medium++;
      } else if (impact <= ImpactNumEnum.HIGH) {
        impactCount.high++;
      } else {
        impactCount.critical++;
      }
    });

    return impactCount;
  }
  async sendEmail(vendor: any) {
    // sending email to vendor on toggling forth party toggle
    try {
      // getting manager
      const manager = await this.customApi.GetUser(vendor?.projectManager);
      // getting artifacts list that is added on vendor creation
      const artifactsList = [];
      vendor?.defaultSetting?.artifacts?.forEach(artifact => {
        if (artifact?.isQualify) {
          artifactsList.push(artifact.name);
        }
      });

      const json = {
        vendorName: vendor.name ? vendor.name : '',
        type: 'NOTIFY_MANAGER_VENDOR_NEW_FINDING',
        manager,
        artifactsList,
      };
      await this.clientLambdaService.invokeLambda('sendNotification', json);
      this.toastr.success('Email Sent Successfully!');
    } catch (e) {
      this.toastr.error('Failed to send Email!');
      console.log(e);
    }
  }

  static getRiskCounts(vendors: GetEntityQuery[]): RiskCount {
    const riskCount = new RiskCount();
    vendors?.forEach(vendor => {
      const probability = this.getProbabilityText(vendor.vendorDetails.probability, true);
      const impact = this.getImpactText(vendor.vendorDetails.impact);
      const risk = this.getRiskText(impact, probability);
      if (risk.toLowerCase() === RiskTextEnum.LOW) {
        riskCount.low++;
      } else if (risk.toLowerCase() === RiskTextEnum.MEDIUM) {
        riskCount.medium++;
      } else if (risk.toLowerCase() === RiskTextEnum.HIGH) {
        riskCount.high++;
      } else {
        riskCount.critical++;
      }
    });
    return riskCount;
  }

  getRiskText(impact: any, probability: number): string {
    return ThirdPartyService.getRiskText(
      ThirdPartyService.getImpactText(impact),
      ThirdPartyService.getProbabilityText(probability)
    );
  }

  static getImpactFromVendor(vendor: GetEntityQueryExtended): ImpactEnum {
    const riskFramework: any = vendor.activeAssessment.standardFrameworkList.items.find(
      standard => standard.type === StandardType.RISK_FRAMEWORK
    );
    return riskFramework.filter.impact;
  }

  static getExternalScanCounts(vendors: GetEntityQuery[], externalScanCount: ExternalScanCount): void {
    vendors.forEach(vendor => {
      if (vendor.vendorDetails.activeScan) {
        externalScanCount.failed++;
      } else {
        externalScanCount.cleared++;
      }
    });
  }

  static getStatusCounts(vendors: GetEntityQuery[]): StatusCount {
    const newStatusCount: StatusCount = new StatusCount();
    vendors.forEach(vendor => {
      switch (true) {
        case vendor.vendorDetails.status === VendorStatusEnum.IN_PROCESS:
          newStatusCount.inProcess++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.PENDING:
          newStatusCount.pending++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.APPROVED:
          newStatusCount.approved++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.DENIED:
          newStatusCount.denied++;
          break;
      }
    });
    return newStatusCount;
  }

  static getHeatMapData(vendors: GetEntityQuery[], heatMap: HeatMap[]): void {
    vendors.forEach(vendor => {
      const coordinate = new HeatMap();
      coordinate.probability = vendor.vendorDetails.probability;
      coordinate.impactValue = vendor.vendorDetails.impact;
      coordinate.impact = ThirdPartyService.getImpact(coordinate.impactValue, coordinate.probability);
      coordinate.vendorName = vendor.name;
      coordinate.vendorId = vendor.id;
      heatMap.push(coordinate);
    });
  }

  static getHeatMapCoordinates(
    heatMapData: HeatMap[],
    heatMapCoordinate: HeatMapCoordinate[],
    minX: number,
    minY: number,
    chartWidth: number,
    chartHeight: number
  ): void {
    heatMapData.forEach(heatMap => {
      if (heatMap.probability < 0.7) {
        heatMap.probability = 0.7;
      } else if (heatMap.probability > 99.3) {
        heatMap.probability = 99.3;
      }
      if (heatMap.impactValue < 1.3) {
        heatMap.impactValue = 1.3;
      } else if (heatMap.impactValue >= 98.7) {
        heatMap.impactValue = 98.7;
      }
      const coordinateX = parseFloat((minX + (chartWidth / 100) * heatMap.probability).toFixed(2));
      const coordinateY = parseFloat((minY + chartHeight - (chartHeight / 100) * heatMap.impactValue).toFixed(2));
      const color =
        heatMap.impact === ImpactTextEnum.CRITICAL
          ? '#F8241A'
          : heatMap.impact === ImpactTextEnum.HIGH
          ? '#F77057'
          : heatMap.impact === ImpactTextEnum.MEDIUM
          ? '#DFF726'
          : '#1BD981';
      heatMapCoordinate.push(
        new HeatMapCoordinate(
          coordinateX,
          coordinateY,
          color,
          heatMap.vendorId,
          heatMap.vendorName,
          heatMap?.probability
        )
      );
    });
  }

  static getImpact(impact: number, probability: number): string {
    if (
      (impact >= 0 && impact <= ImpactNumEnum.LOW && probability >= 0 && probability <= ImpactNumEnum.LOW) ||
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW)
    ) {
      return ImpactTextEnum.LOW;
    }
    if (
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW)
    ) {
      return ImpactTextEnum.MEDIUM;
    }
    if (
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.CRITICAL) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.CRITICAL) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.CRITICAL &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.CRITICAL &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM)
    ) {
      return ImpactTextEnum.HIGH;
    }
    if (
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.CRITICAL) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.CRITICAL &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.CRITICAL &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.CRITICAL)
    ) {
      return ImpactTextEnum.CRITICAL;
    }
    return ImpactTextEnum.CRITICAL;
  }

  static getRiskText(impactLabel: string, probabilityLabel: string): string {
    // Format = impactLabel#probabilityLabel
    const riskMapper = {
      'low#low': RiskTextEnum.LOW, // L	0-25
      'low#medium': RiskTextEnum.LOW, // L	25-50
      'low#high': RiskTextEnum.LOW, // L	50-75
      'low#critical': RiskTextEnum.MEDIUM, // L	75-100
      'medium#low': RiskTextEnum.LOW, // M	0-25
      'medium#medium': RiskTextEnum.MEDIUM, // M	25-50
      'medium#high': RiskTextEnum.MEDIUM, // M	50-75
      'medium#critical': RiskTextEnum.MEDIUM, // M	75-100
      'high#low': RiskTextEnum.MEDIUM, // H	0-25
      'high#medium': RiskTextEnum.MEDIUM, // H	25-50
      'high#high': RiskTextEnum.HIGH, // H	50-75
      'high#critical': RiskTextEnum.HIGH, // H	75-100
      'critical#low': RiskTextEnum.HIGH, // H	0-25
      'critical#medium': RiskTextEnum.HIGH, // H	25-50
      'critical#high': RiskTextEnum.CRITICAL, // H	50-75
      'critical#critical': RiskTextEnum.CRITICAL, // H	75-100
    };

    return riskMapper[impactLabel.toLowerCase() + '#' + probabilityLabel.toLowerCase()];
  }

  static getImpactText(impact: number): string {
    if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
      return ImpactTextEnum.LOW;
    } else if (impact <= ImpactNumEnum.MEDIUM) {
      return ImpactTextEnum.MEDIUM;
    } else if (impact <= ImpactNumEnum.HIGH) {
      return ImpactTextEnum.HIGH;
    } else {
      return ImpactTextEnum.CRITICAL;
    }
  }

  static getProbabilityText(probability: number, getCritical: boolean = false): string {
    if (probability >= 0 && probability <= ProbabilityEnum.LOW) {
      return ProbabilityTextEnum.LOW;
    } else if (probability >= ProbabilityEnum.LOW + 1 && probability <= ProbabilityEnum.MEDIUM) {
      return ProbabilityTextEnum.MEDIUM;
    } else if (probability >= ProbabilityEnum.MEDIUM + 1 && probability <= ProbabilityEnum.HIGH) {
      return ProbabilityTextEnum.HIGH;
    } else {
      return getCritical ? ProbabilityTextEnum.CRITICAL : ProbabilityTextEnum.CRITICAL;
    }
  }

  static getProbability(probability: number): string {
    if (probability >= 0 && probability <= ProbabilityEnum.LOW) {
      return ProbabilityTextEnum.LOW;
    } else if (probability <= ProbabilityEnum.MEDIUM) {
      return ProbabilityTextEnum.MEDIUM;
    } else if (probability <= ProbabilityEnum.HIGH) {
      return ProbabilityTextEnum.HIGH;
    } else {
      return ProbabilityTextEnum.CRITICAL;
    }
  }

  static getExternalScanText(extScan: number): string {
    if (extScan >= 0 && extScan <= ExtScanEnum.LOW) {
      return ExternalScanTextEnum.LOW;
    } else if (extScan <= ExtScanEnum.MEDIUM) {
      return ExternalScanTextEnum.MEDIUM;
    } else {
      return ExternalScanTextEnum.HIGH;
    }
  }

  static getAssessmentText(assessment: number): string {
    if (assessment >= 0 && assessment <= AssessmentEnum.LOW) {
      return AssessmentTextEnum.LOW;
    } else if (assessment <= AssessmentEnum.MEDIUM) {
      return AssessmentTextEnum.MEDIUM;
    } else {
      return AssessmentTextEnum.HIGH;
    }
  }

  static getImpactEnumFromImpactNum(impact: number): ImpactEnum {
    if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
      return ImpactEnum.LOW;
    } else if (impact <= ImpactNumEnum.MEDIUM) {
      return ImpactEnum.MEDIUM;
    } else if (impact <= ImpactNumEnum.HIGH) {
      return ImpactEnum.HIGH;
    } else {
      return ImpactEnum.CRITICAL;
    }
  }

  static sortArray(sortData: TableHeader, data: GetEntityQuery[]): any {
    const ascendingOrder = sortData.sortDirection === SortDirectionEnum.ASCENDING ? 1 : -1;
    const keys: string[] = sortData.value.split('.');

    switch (keys.length) {
      case 1:
        data.sort((a, b) => {
          return a[keys[0]] < b[keys[0]] ? ascendingOrder * -1 : b[keys[0]] < a[keys[0]] ? ascendingOrder : 0;
        });
        break;
      case 2:
        data.sort((a, b) => {
          return a[keys[0]][keys[1]] < b[keys[0]][keys[1]]
            ? ascendingOrder * -1
            : b[keys[0]][keys[1]] < a[keys[0]][keys[1]]
            ? ascendingOrder
            : 0;
        });
        break;
      case 3:
        data.sort((a, b) => {
          return a[keys[0]][keys[1]][keys[2]] < b[keys[0]][keys[1]][keys[2]]
            ? ascendingOrder * -1
            : b[keys[0]][keys[1]][keys[2]] < a[keys[0]][keys[1]][keys[2]]
            ? ascendingOrder
            : 0;
        });
        break;
      default:
    }
  }

  async assignNewFrameworkManager(manager: CreateFrameworkManagerInput): Promise<void> {
    await this.customApi.CreateFrameworkManager(manager);
  }

  toggleVendorList(isExpanded: boolean): void {
    this.isVendorListExpanded = isExpanded;
  }

  setNewVendorAdded(event: boolean): void {
    this.newVendorCreated = event;
  }

  getNewVendorAdded(): boolean {
    return this.newVendorCreated;
  }

  getHeatmapDefaultView(): number {
    const sections = Number(this.storage.getItem(CYGOV_HEATMAP_SECTIONS) || 0);
    return sections ? sections : HeatmapSectionEnum.None;
  }

  setHeatmapDefaultView(sections: HeatmapSectionEnum): void {
    this.storage.setItem(CYGOV_HEATMAP_SECTIONS, sections.toString());
  }

  async getExtendVendorsListByParentId(entityId, isExtended = true): Promise<GetEntityQueryExtended[]> {
    const currentUserRole = this.userService.getCurrentRole();
    let fil = {};
    if (
      currentUserRole &&
      currentUserRole.vendorIds &&
      currentUserRole.vendorIds.length &&
      currentUserRole.defaultOrEntityId.toLowerCase() !== UsersSettingConstant.default.toLowerCase() &&
      currentUserRole.isRootEntityAccess
    ) {
      if (!currentUserRole.vendorIds.includes('all')) {
        const filterVendorIds = currentUserRole.vendorIds.map(item => {
          return { id: { eq: item } };
        });
        fil = {
          or: filterVendorIds,
          entityType: { eq: EntityTypeEnum.VENDOR },
        };
      } else {
        fil = {
          entityType: { eq: EntityTypeEnum.VENDOR },
        };
      }
    } else if (currentUserRole.defaultOrEntityId.toLowerCase() === UsersSettingConstant.default.toLowerCase()) {
      fil = {
        entityType: { eq: EntityTypeEnum.VENDOR },
      };
    } else {
      return [];
    }
    const entityList: GetEntityQuery[] = await this.entityService.listEntitysByParentId(entityId, fil);
    if (!isExtended) {
      return entityList;
    }
    const promises = entityList.map(entity => this.setLogoUrl(entity));
    return await Promise.all(promises);
  }

  static getRiskScoreText(riskScore: number): string {
    if (riskScore >= 0 && riskScore <= RiskScoreEnum.LOW) {
      return RiskScoreTextEnum.LOW;
    } else if (riskScore <= RiskScoreEnum.MEDIUM) {
      return RiskScoreTextEnum.MEDIUM;
    } else {
      return RiskScoreTextEnum.HIGH;
    }
  }

  /**
   *
   * @param vendor - vendor needs to be updated
   * @returns - external scan calculation
   */
  externalScanOpenThreats(vendor: GetEntityQuery, rootEntity: any = {}): number {
    const fourthPartyWeightAge = rootEntity?.defaultSetting?.fourthPartyWeightage
      ? rootEntity.defaultSetting.fourthPartyWeightage
      : 50;
    return UtilsService.calculateWeightedAvg(
      {
        isActive: vendor.vendorDetails.activeScan,
        score: vendor.vendorDetails.intelligenceScanScore,
        weight: 100 - fourthPartyWeightAge,
      },
      {
        isActive: vendor.vendorDetails.fourthPartyActiveScan,
        score: vendor.vendorDetails.fourthPartyScanScore,
        weight: fourthPartyWeightAge,
      }
    );
  }

  async setLogoUrl(entity: GetEntityQuery): Promise<GetEntityQueryExtended> {
    let logoUrl = '';
    if (entity.logo) {
      logoUrl = (await this.fileService.downloadFromS3(entity.logo)) as string;
    }

    return {
      ...entity,
      logoUrl,
    };
  }
  // find any attribute which is empty or not equivalent to default values for some attribute
  // are corrupted vendor, use in bulk upload
  isCorruptedVendor(vendor): boolean {
    if (!vendor) {
      return false;
    }
    return (
      !vendor?.name?.length ||
      !UtilsService.dateIsValid(vendor[VendorColumnMappersEnum.date]) ||
      !this.checkIfDomainNeeded(vendor) ||
      !UtilsService.isValidEmail(vendor[VendorColumnMappersEnum.email]) ||
      !UtilsService.toLowerCaseAll(Object.values(VendorThirdForthPartyFlag)).includes(
        vendor[VendorColumnMappersEnum.isThirdParty]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(VendorThirdForthPartyFlag)).includes(
        vendor[VendorColumnMappersEnum.isFourthParty]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(IE)).includes(vendor[VendorColumnMappersEnum.impact]?.toLowerCase())
    );
  }
  /**
   * checks whether domain is required if third party flag is true or false
   */
  checkIfDomainNeeded(vendor): boolean {
    let isDomainNeeded = true;
    if (vendor.isThirdParty === VendorThirdForthPartyFlag.true) {
      isDomainNeeded = UtilsService.validateDomain(vendor[VendorColumnMappersEnum.domain]);
    }
    return isDomainNeeded;
  }
  async applyWeightAgeCalculationsForAllVendors(rootEntityId: string, fourthPartyWeightage: number): Promise<void> {
    try {
      const filter: ModelEntityFilterInput = {
        entityType: { eq: EntityTypeEnum.VENDOR },
      };
      const vendors = await this.entityService.listEntitysByParentId(rootEntityId, filter);
      if (vendors && vendors.length) {
        const promises = [];
        vendors.map(vendor => {
          if (vendor?.vendorDetails?.activeScan && vendor?.vendorDetails?.fourthPartyActiveScan) {
            const externalScore = UtilsService.calculateWeightedAvg(
              {
                isActive: true,
                score: vendor?.vendorDetails.intelligenceScanScore,
                weight: 100 - fourthPartyWeightage,
              },
              {
                isActive: true,
                score: vendor?.vendorDetails.fourthPartyScanScore,
                weight: fourthPartyWeightage,
              }
            );
            promises.push(
              this.customApi.UpdateVendorsDetail({
                id: vendor.id,
                externalScan: externalScore,
              })
            );
          }
        });
        await Promise.all(promises);
      }
    } catch (e) {}
  }
}
