import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionDirective } from './user-permission.directive';

@NgModule({
  declarations: [UserPermissionDirective],
  imports: [CommonModule],
  exports: [UserPermissionDirective],
})
export class UserPermissionModule {}
