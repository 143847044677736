export class ImpactCount {
  low: number;
  medium: number;
  high: number;
  critical: number;

  constructor() {
    this.low = this.medium = this.high = this.critical = 0;
  }
}
