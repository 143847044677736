import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils.service';
import * as moment from 'moment';

/**
### Official Bug in DatePicker
 *
 *  MinDate will not dynamically update when dynamic binding, you need to close and re-open the calendar
 *  by force in order to see new values.
 *  This component will not handle that, the component in which you will use this will open or close it
 *  to update using the calendar reference.
 *
#### Bug Issued Forum for DatePicker
 *
 *  https://github.com/valor-software/ngx-bootstrap/issues/5888
 *
 *
 * #### Calendar Mode
 *
 * - ToggleButton = True
 *
 *  This will show an input field and an icon to toggle the calendar
 *
 *
 * - ToggleButton = False
 *
 *  This will show the calendar (opened) but you cannot close it.
 */

@Component({
  selector: 'cygov-ngb-calendar',
  templateUrl: './ngb-calendar.component.html',
  styleUrls: ['./ngb-calendar.component.scss'],
})
export class NgbCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedDate: NgbDateStruct | null = null; // The current selected Date
  @Input() isCalendarDisabled = false; // Option to disable the calendar
  @Input() disableFullOpacity = false;
  @Input() dateToShow: NgbDateStruct | null = null; // To show date on top of calendar, only seen in non-toggle mode
  @Input() dateType: string; // An ID for the calendar
  @Input() displayName: string; // Name of calendar showing on top of calendar,only seen in non-toggle mode
  @Input() inputMaxDate: Date | any; // minimum selection date on calendar, not passing this value will not set the minDate
  @Input() inputMinDate: Date | any; // maximum selection date on calendar, not passing this value will not set the maxDate
  @Input() toggleButton = false; // Toggle the icon and mode.
  @Input() placeHolder = ''; // Placeholder to show on input field, only visible in Toggle Mode
  @Input() dontDisplayInputArea = false; // To display Input area of date picker
  @Input() openDefault = false;
  @Input() showDate = true;
  @Input() showCustomNavigation = false;
  @Input() disableCustomNavigation = false;
  @Input() navigation = 'arrows';
  @Input() isReadOnly: boolean = false;
  @Input() showDateLabel: boolean = true;
  @Input() disableDates = {};
  @Input() firstDayOfWeek = 1;
  @Input() isFromVendor = false;
  @Input() saveSelectedDate = false;
  @Input() riskLineChartData: any[] = [];
  @Input() isFromRiskRegister = false;

  @Output() monthChanged: EventEmitter<any> = new EventEmitter<any>();
  // Trigger to return the new selected date if any
  @Output() selectedDateChange: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
  // Pass the calendar ref to parent - optional
  @Output() calendarRef: EventEmitter<NgbInputDatepicker> = new EventEmitter<NgbInputDatepicker>();
  @ViewChild('dateInput') dateCalendar: NgbInputDatepicker; // A reference to the calendar
  @Input() emptyEndDate: boolean = false;

  loaded = false;
  // code for calendar customization (select month and year)
  yearsList: any = [];
  yearsRange: number = 10;
  monthsList: any = [];
  allMonthsList: any;
  currentSelectedYear: any;
  currentSelectedMonth: any;
  baseYear: string;

  constructor(private parserFormatter: NgbDateParserFormatter) {}

  getDateinDateFormat(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  calendarNavigateTrigger(event, date: NgbDateStruct): void {
    // update the month and year if the custom list in enabled
    if (event.next && this.showCustomNavigation) {
      // updating the month list and display month
      this.currentSelectedMonth = this.allMonthsList[event.next.month - 1];
      if (this.currentSelectedYear !== this.baseYear) {
        this.monthsList = this.allMonthsList;
      } else if (this.inputMinDate) {
        this.monthsList = this.allMonthsList.slice(this.inputMinDate.month - 1, 12);
      } else if (this.inputMaxDate) {
        this.monthsList = this.allMonthsList.slice(0, this.inputMaxDate.month);
      }
      // updating the year list and year display
      const calendarYear = event.next.year + '';
      if (this.currentSelectedYear !== calendarYear) {
        this.currentSelectedYear = calendarYear;
      }
    }

    // navigate to the next month
    if (!this.toggleButton && event.next && date) {
      date.month = event.next.month;
      date.year = event.next.year;
    }
    // Prevents issues when selecting a date from a month with more days than the next month's maximum.
    if ((this.isFromVendor || this.isFromRiskRegister) && !this.saveSelectedDate) {
      date.day = 1;
      this.selectedDateChange.emit(date);
    }

    this.monthChanged.emit({ month: event.next.month, year: event.next.year });
  }

  ngAfterViewInit(): void {
    // emit the calendar ref access internal functions
    setTimeout(() => {
      this.calendarRef.emit(this.dateCalendar);
      if (this.openDefault) {
        this.dateCalendar.open();
      }
      this.loaded = true;
    }, 100);
  }

  ngOnInit(): void {
    // MonthList is only used for customDropDown
    // if showCustomeNavigation is true then customDropDown is enabled.
    if (this.showCustomNavigation) {
      this.allMonthsList = moment.monthsShort().map(item => item.toUpperCase()); // Get the names of all months
      if (UtilsService.isDefined(this.inputMaxDate)) {
        if (UtilsService.isDefined(this.inputMinDate)) {
          // Both Min Max dates are given
          this.monthsList = this.allMonthsList.slice(this.inputMinDate.month, this.inputMaxDate.month);
          for (let i = this.inputMinDate.year; i <= this.inputMaxDate.year; i++) {
            this.yearsList.push(i.toString());
          }
        } else {
          this.monthsList = this.allMonthsList.slice(0, this.inputMaxDate.month);
          for (let i = 0; i < this.yearsRange; i++) {
            this.yearsList.push((this.inputMaxDate.year - i).toString());
          }
          if (this.monthsList && this.monthsList.length) {
            this.currentSelectedMonth = this.monthsList[this.monthsList.length - 1];
          }
        }
      } else if (UtilsService.isDefined(this.inputMinDate)) {
        this.monthsList = this.allMonthsList.slice(this.inputMinDate.month - 1, 12);
        for (let i = 0; i < this.yearsRange; i++) {
          this.yearsList.push((this.inputMinDate.year + i).toString());
        }
        this.currentSelectedMonth = this.monthsList && this.monthsList.length ? this.monthsList[0] : undefined;
      }
      this.currentSelectedYear = this.yearsList && this.yearsList.length ? this.yearsList[0] : undefined;
    }
  }

  dateUpdated(date): void {
    // emit the new date selected
    this.selectedDateChange.emit(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showCustomNavigation) {
      if (changes.inputMinDate && !changes.inputMinDate.firstChange) {
        // For End Date Calendar as the min date is always changing
        if (this.inputMaxDate?.year && changes.inputMinDate?.currentValue?.year) {
          this.yearsRange = this.inputMaxDate.year - changes.inputMinDate.currentValue.year + 1;
        }
        this.updateCalendarMonthYearList(true, changes.inputMinDate.currentValue);
      }
      if (changes.inputMaxDate && !changes.inputMaxDate.firstChange) {
        // For Start Date Calendar as the max date is always changing
        if (this.inputMinDate?.year && changes.inputMaxDate?.currentValue?.year) {
          this.yearsRange = changes.inputMaxDate.currentValue.year - this.inputMinDate.year + 1;
        }
        this.updateCalendarMonthYearList(false, changes.inputMaxDate.currentValue);
      }
      this.baseYear = this.selectedDate?.year?.toString();
    }
    if (this.loaded && this.selectedDate) {
      // Manually changing the date to refresh the calendar
      const updatedDate = UtilsService.getShortDate(new Date(this.parserFormatter.format(this.selectedDate)));
      this.dateCalendar.manualDateChange(updatedDate, true);
    }
  }

  /**
   * This updates the custom drop down list of calendar according to the input max or min Date
   * @param isEndCalendar Is it the End Calendar/Only Input Min Date given
   * @param newDateObj The new changed object which includes the Input Min/Max Date value
   */
  updateCalendarMonthYearList(isEndCalendar: boolean, newDateObj: any): void {
    let newYear = newDateObj.year;
    const newMonth = newDateObj.month;
    const newYearList = [];
    // update month list
    if (newYear + '' !== this.currentSelectedYear) {
      this.monthsList = this.allMonthsList;
    } else {
      this.monthsList = isEndCalendar
        ? this.allMonthsList.slice(newMonth - 1, 12)
        : this.allMonthsList.slice(0, newMonth);
    }
    // update year list
    if (!this.yearsList.includes(newYear)) {
      for (let i = 0; i < this.yearsRange; i++) {
        newYearList.push(newYear);
        newYear = isEndCalendar ? newYear + 1 : newYear - 1;
      }
      this.yearsList = JSON.parse(JSON.stringify(newYearList));
    }
  }

  customNavigateCalendar(event: any, isMonth = true) {
    if (isMonth) {
      const monthVal = moment().month(event).format('M');
      const monthInt = parseInt(monthVal, 10);
      this.selectedDate.month = monthInt;
    } else {
      const yearVal = moment().year(event).format('Y');
      const yearInt = parseInt(yearVal, 10);
      this.selectedDate.year = yearInt;
    }

    setTimeout(() => {
      this.dateCalendar.navigateTo(this.selectedDate);
    }, 50);
  }

  isDataFound(date): boolean {
    if (this.isFromRiskRegister && this.riskLineChartData?.length) {
      if (!date) {
        return false;
      }
      for (const data of this.riskLineChartData) {
        const createdAtDate = new Date(data.createdAt);
        if (
          createdAtDate.getFullYear() === date.year &&
          createdAtDate.getMonth() + 1 === date.month &&
          createdAtDate.getDate() === date.day
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
  getDivStyles(day) {
    day = day < 10 ? `0${day}` : day;
    const styles = {
      opacity: this.disableDates[day] ? '0.5' : '',
      'pointer-events': this.disableDates[day] ? 'none' : '',
      cursor: this.disableDates[day] ? 'not-allowed' : '',
    };
    return styles;
  }
}
