import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

import { DOWNLOAD_OVERALL_REPORT } from '../app.paths';
import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import { PromiseResult } from 'aws-sdk/lib/request';
// fix import - build-optimization-1
import { AWSError } from 'aws-sdk/lib/error';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from './utils.service';

export type ThemeValues = 'dark' | 'light';
export type ThemeSelector = {
  [propName in ThemeValues]: string;
};

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  async generateReport(type: string, body: any): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const payload = {
      type,
      body,
    };

    const credentials = await Auth.currentCredentials();

    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: this.authService.getRegion(),
    });

    return await lambda
      .invoke({
        FunctionName: `generateReport-${this.authService.getCurrentEnvironment()}`,
        Payload: JSON.stringify(payload),
      })
      .promise();
  }

  // Not implemented yet on Backend
  async generateOverallReport(stateId: string, theme: ThemeValues = 'dark'): Promise<Blob> {
    return this.http
      .post(DOWNLOAD_OVERALL_REPORT, { stateId, options: { theme } }, { observe: 'body', responseType: 'blob' })
      .toPromise();
  }

  async generatePDFReport(
    subEntityId: string,
    theme: ThemeSelector
  ): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const type: string = 'pdf';
    return await this.generateReport(type, { subEntityId, options: { theme } });
  }

  async generateCSVReport(
    subEntityId: string,
    frameworkName = null
  ): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const type: string = 'csv';
    return await this.generateReport(type, { subEntityId, options: { type }, frameworkName });
  }

  async generateAuditReport(subEntityId: string): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const type: string = this.router.url.includes('third') ? 'vendor' : 'mpl';
    if (!UtilsService.isDefined(subEntityId)) {
      subEntityId = UtilsService.getRouteParam(this.activeRoute.snapshot, 'subEntityId');
    }
    return await this.generateReport(type, { subEntityId, options: { type } });
  }

  async generateVendorReport(subEntityId: string): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const type: string = 'vendor';
    return await this.generateReport(type, { subEntityId, options: { type } });
  }

  async generateRiskReport(
    entityId: string,
    subEntityId: string,
    assessmentId: string,
    extensionType: string,
    risksData: any[],
    urls: string[]
  ): Promise<PromiseResult<Lambda.InvocationResponse, AWSError>> {
    const type: string = 'risk_map';

    // passing session information from localStorage to lambda func
    const session = Object.keys(localStorage).reduce(
      (obj, item) => ({ ...obj, [item]: localStorage.getItem(item) }),
      {}
    );
    return await this.generateReport(type, {
      entityId,
      subEntityId,
      assessmentId,
      type: extensionType,
      urls,
      session,
      risksWithControls: risksData,
      cyberArkDomain: UtilsService.isCyberArk,
    });
  }
}
