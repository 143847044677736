import { S3FileInput } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input, OnDestroy, OnChanges } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GetAnswerQuery } from 'app/API.service';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { Subscription } from 'rxjs';

export class ExistingFiles {
  fileName: string;
  uploadDate: number;
  processed: boolean;
  userName: string;
  file: S3FileInput;
  selected: boolean;

  constructor(name: string, date: number, proc: boolean, user: string, file: S3FileInput, sel: boolean) {
    this.fileName = name;
    this.uploadDate = date;
    this.processed = proc;
    this.userName = user;
    this.file = file;
    this.selected = sel;
  }
}

@Component({
  selector: 'cygov-existing-files',
  templateUrl: './existing-files.component.html',
  styleUrls: ['./existing-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExistingFilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() iconColor = '#29A9EB';
  @Input() filesList: ExistingFiles[] = [];
  @Input() offlineDownload: boolean = false;
  @Input() showDownload = true;
  @Input() showDelete = true;
  @Input() isSelectable = true;
  @Input() filePath = '';
  @Output()
  closeModalClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  updateDeletedFiles: EventEmitter<GetAnswerQuery[]> = new EventEmitter<GetAnswerQuery[]>();
  modalRef: NgbModalRef;

  loading = true;
  deleteModalFlag = false;
  downloadModalFlag = false;
  confirmationMessage = 'Do you really want to delete ?';
  filterOptions: string[] = ['one', 'two', 'three'];
  subscriptions: Subscription;
  queryText = '';
  filterBy = '';
  controlName = '';
  progressD = 0;
  totalSelected = 0;

  exFiles: ExistingFiles[] = [];
  filteredFiles: ExistingFiles[] = [];
  unZippedFiles: any = [];

  modalReference: NgbModalRef;
  ngbModalOptionsMoreUsers: NgbModalOptions = {
    windowClass: 'manage-users-modal',
    backdropClass: 'manage-users-modal-backdrop',
  };
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    windowClass: 'existing-file-shared-modal',
    backdropClass: 'existing-file-shared-modal-backdrop',
    centered: true,
  };
  modalOptionsUploadScreen: NgbModalOptions = {
    windowClass: 'upload-content-modal',
    backdropClass: 'upload-content-modal-backdrop',
    centered: true,
  };
  ngbModalOptionsConfirmation: NgbModalOptions = {
    size: 'sm',
    windowClass: 'confirmation-modal collection-modal-windowClass',
    backdropClass: 'collection-modal-backdropClass',
    centered: true,
  };

  constructor(
    private fileService: FileService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private modalService: NgbModal
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.filteredFiles = JSON.parse(JSON.stringify(this.filesList));
    // this.subscriptions.add(
    //   this.collectionService.getSelectedControl().subscribe(control => {
    //     this.controlName = control.name;
    //     this.loading = false;
    //   })
    // );
  }

  ngOnChanges(changes): void {
    if (changes && changes.filesList) {
      this.filteredFiles = JSON.parse(JSON.stringify(this.filesList));
    }
  }

  openExistingModal(modalRef): void {
    this.modalReference = this.modalService.open(modalRef, this.ngbModalOptions);
    this.totalSelected = 0;
    this.filteredFiles.map(file => {
      file.selected = false;
    });
  }

  async downloadFile(): Promise<void> {
    try {
      if (this.filteredFiles.length !== 0) {
        const entityId = this.filteredFiles[0].file.entityId; // fetching one's id coz same entity for all
        let count = 0;

        // const totalFiles: number = this.filteredFiles.length + 1;
        let totalFiles = 0;
        this.filteredFiles.forEach(file => {
          if (file.selected) {
            totalFiles += 1;
          }
        });
        const zipPercent: number = 100 - 100 / totalFiles;
        const blobs = [];
        let checkSelected = false;

        for (const file of this.filteredFiles) {
          if (file.selected) {
            this.toastr.info('Preparing file...');
            checkSelected = true;
            const url = await this.fileService.downloadFromS3(file.file);
            const response = await fetch(url);
            const reader = response.body.getReader();
            const contentLength: number = +response.headers.get('Content-Length');
            let receivedLength = 0;
            const chunks = [];

            let completion = false;

            while (!completion) {
              const { done, value } = await reader.read();
              if (done) {
                completion = true;
              } else {
                chunks.push(value);
                receivedLength += value.length;
                const total: number = (receivedLength / contentLength) * 100 + count * 100;
                this.progressD = total / totalFiles;
              }
              if (!this.downloadModalFlag) {
                this.progressD = 0;
                return;
              }
            }

            const blob = new Blob(chunks);
            blobs.push({ blob, name: file.fileName, userName: file.userName });
            count++;
          }
        }

        if (!this.downloadModalFlag) {
          this.progressD = 0;
          return;
        }
        if (checkSelected) {
          const zip = new JSZip();
          if (count === totalFiles) {
            if (blobs.length === 1) {
              const originalFile = await zip.loadAsync(blobs[0].blob);
              const fileName = Object.keys(originalFile.files)[0];
              originalFile.files[fileName].async('blob').then(data => {
                saveAs(data, `Artifacts-${entityId}.${fileName.split('.').slice(-1)}`);
              });
              return;
            }
            const promises = [];
            const fileNames = [];
            blobs.forEach(file => {
              promises.push(this.unZip(file));
            });
            await Promise.all(promises);
            this.unZippedFiles.forEach(item => {
              let name;
              if (!fileNames.includes(item.name)) {
                fileNames.push(item.name);
                name = item.name;
              } else {
                fileNames.push(item.name);
                const counter = fileNames.filter(filename => filename === item.name).length - 1;
                name = counter + '_' + item.name;
              }
              zip.file(name, item.file);
            });
            this.unZippedFiles.length = 0;
            fileNames.length = 0;
            const zipFile = await zip.generateAsync({ type: 'blob' }, metaData => {
              this.progressD = parseInt(metaData.percent.toFixed(0), 10) / totalFiles + zipPercent;
            });
            saveAs(zipFile, `Artifacts-${entityId}.zip`);
          }
        } else {
          this.toastr.info('No Artifacts Selected');
          this.downloadModalFlag = false;
        }
      }
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed downloading file');
    }
  }
  async unZip(file): Promise<void> {
    const currentZip = new JSZip();
    const originalFile = await currentZip.loadAsync(file.blob);
    const fileName = Object.keys(originalFile.files)[0];
    const data = await originalFile.files[fileName].async('blob');
    this.unZippedFiles.push({ name: file.name, file: data });
  }
  onKeyUp(): void {
    if (this.queryText.length) {
      const filterData = this.filesList.filter(item =>
        item.fileName.toLowerCase().includes(this.queryText.toLowerCase())
      );
      this.filteredFiles = filterData;
    } else {
      this.filteredFiles = this.filesList;
    }
  }

  filterChanged(event: any, type: string): void {
    if (type === 'search') {
      this.queryText = event.target.value;
    } else if (type === 'select') {
      this.filterBy = event.target.value;
    }
  }

  closeModal(): void {
    this.modalReference.close();
  }
  openDeleteModal(): void {
    this.deleteModalFlag = true;
  }
  closeDeleteModal(): void {
    this.deleteModalFlag = false;
  }
  openDownloadModal(): void {
    this.downloadModalFlag = true;
  }
  closeDownloadModal(closeDownloadloadModalFlag: boolean): void {
    if (closeDownloadloadModalFlag) {
      this.progressD = 0;
      this.downloadModalFlag = false;
    }
  }
  deleteAllFiles(): void {
    const filesToDelete = [];
    // this filter filter out all the files that are not selected
    this.filteredFiles.forEach(file => {
      if (file.selected) {
        // getting all selected artifacts
        filesToDelete.push(file.fileName);
      }
    });
    if (filesToDelete.length > 0) {
      this.updateDeletedFiles.emit(filesToDelete);
      // Update the Filtered Files after deletion
      const cacheFiltered = this.filteredFiles.filter(file => !file.selected);
      this.filteredFiles = JSON.parse(JSON.stringify(cacheFiltered));
      this.filesList = JSON.parse(JSON.stringify(cacheFiltered));
      this.totalSelected = 0;
    } else {
      this.toastr.info('No Artifacts Selected');
    }
    this.closeDeleteModal();
  }

  selectedRow(i: number): void {
    this.filteredFiles[i].selected = !this.filteredFiles[i].selected;
    this.totalSelected = this.filteredFiles[i].selected ? this.totalSelected + 1 : this.totalSelected - 1;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
