import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalTemplateComponent } from 'app/shared/modal-template/modal-template.component';
import { ClientLambdaService } from 'app/shared/client-lambda.service';

@Component({
  selector: 'cygov-new-framework-request-modal',
  templateUrl: './new-framework-request-modal.component.html',
  styleUrls: ['./new-framework-request-modal.component.scss'],
})
export class NewFrameworkRequestModalComponent implements OnInit {
  @Input() userName: string;
  @Input() userEmail: string;
  selectedFramework: any;
  constructor(
    public activeModal: NgbActiveModal,
    private domainFrameworkService: DomainFrameworkService,
    private thirdParty: ThirdPartyService,
    private modalService: NgbModal,
    private clientLambdaService: ClientLambdaService
  ) {}
  TAB_ENUMS = {
    RISK: 1,
    COMPLIANCE: 2,
    CUSTOM: 3,
  };
  active = this.TAB_ENUMS.RISK; //* Active Tab Risk
  riskFrameworks = [];
  complianceFrameworks = [];
  customFrameworks = [];
  closeModalInstance;
  textInput: string = '';
  isCheckboxChecked: boolean = false;
  ngbModalOptionsNewFrameworkRequest: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass:
      'modal-template-cont confirmation-filter-popup-window new-framework-request-acknowledgement-modal-window',
    backdropClass:
      'modal-template-cont-backdrop risk-popup-backdrop-modal new-framework-acknowledgement-request-modal-backdrop',
    backdrop: 'static',
    centered: true,
  };

  additionalGuideMessage = {
    1: {
      message: '',
      enabled: false,
    },
    2: {
      message: '',
      enabled: false,
    },
    3: {
      message: '',
      enabled: false,
    },
  };
  isFrameworkNameEllipsis: boolean = false;
  ngOnInit() {
    const frameworkSettings = this.domainFrameworkService.domainSettingsData;
    console.log('frameworkSettings', frameworkSettings);

    // risk
    this.riskFrameworks = JSON.parse(
      JSON.stringify(
        this.domainRestrictedFrameworks([
          ...frameworkSettings.RISK_FRAMEWORKS.filter(element => element.status === false && element.custom === false),
        ])
      )
    );

    // compliance
    this.complianceFrameworks = JSON.parse(
      JSON.stringify(
        this.domainRestrictedFrameworks([
          ...frameworkSettings.COMPLIANCE_FRAMEWORKS.filter(
            element => element.status === false && element.custom === false
          ),
        ])
      )
    ); // this.vendorList = this.domainRestrictedFrameworks([...frameworkSettings.VENDOR_FRAMEWORKS]);
    if (this.riskFrameworks.length === 0 && this.complianceFrameworks.length === 0) {
      this.active = this.TAB_ENUMS.CUSTOM; // Activate Custom tab if no Risk or Compliance
    } else if (this.riskFrameworks.length === 0) {
      this.active = this.TAB_ENUMS.COMPLIANCE; // Activate Compliance tab if no Risk
    }
  }
  selectFramework(framework: any, frameworks) {
    frameworks.forEach(compliance => {
      compliance.status = compliance === framework;
    });
    this.selectedFramework = framework;
    this.additionalGuideMessage[this.active].enabled = true;
  }

  async sendData() {
    const emailObject = {
      type: 'NOTIFY_CENTRALEYES_NEW_FRAMEWORK_REQUEST',
      framework: this.active === 3 ? 'CUSTOM' : this.selectedFramework.name,
      name: this.userName,
      email: this.userEmail,
      domain: DomainFrameworkService.getDomain(),
      message: this.additionalGuideMessage[this.active].message,
    };
    await this.clientLambdaService.invokeLambda('sendNotification', emailObject);
  }
  domainRestrictedFrameworks(riskFrameWorkArr) {
    return riskFrameWorkArr.filter(framework => {
      const frameworkName = framework.name
        ? framework.name.toLowerCase()
        : this.domainFrameworkService.RiskFrameworkEnum[framework.key];
      return !this.domainFrameworkService.restrictedFrameworks.includes(frameworkName);
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  closeModalTemplate() {
    this.closeModalInstance.close();
  }

  openNewFrameworkRequestAcknowledgementModal(
    header: TemplateRef<any>,
    content: TemplateRef<any>,
    footer: TemplateRef<any>
  ) {
    this.closeModal();
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsNewFrameworkRequest);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }
  isEllipsisActive(element: any): void {
    const isTooltipVisible = element.scrollWidth > element.clientWidth;
    this.isFrameworkNameEllipsis = isTooltipVisible;
  }
}
