export enum CommonEnum {
  ROUND_CHECKBOX_VIEWMODE = 'round-checkbox-viewmode',
  ROUND_CHECKBOX = 'round-checkbox',
  GENERAL = 'general',
  FIRSTPARTY = '1st Party',
  THIRDPARTY = '3rd Party',
}
export enum FiltersEnum {
  SEARCH = 'search',
}
export enum FiltersPopUpTagsEnum {
  ALL = 'All',
  ENABLED = 'Enabled',
}
export enum AdditionalRequirements {
  REQUIREMENTS = 'requirements',
  CERTIFICATIONS = 'certifications',
  DOMAINS = 'domains',
  ISTHIRDPARTY = 'isThirdParty',
  ISFOURTHPARTY = 'isFourthParty',
  CUSTOM_REQUIREMENTS = 'customRequirements',
  CUSTOM_CERTIFICATIONS = 'customCertifications',
}
