export const TierData = [
  {
    tierNumber: 4,
    level: 'Adaptive',
    capabilities: [
      'Adaptive Risk Management Practices',
      'Cultural, Risk-Informed Program',
      'Actively Shares Information',
    ],
    features: [
      {
        name: 'Risk Management',
        detailListing: [
          'Continuous improvement incorporating advanced technology and best practices',
          'Actively adapted based on past and present cybersecurity activities including lessons learned and predictive indicators',
          'Responds to sophisticated changing threat landscape in a timely and effective way',
        ],
      },
      {
        name: 'Integrated Risk Management',
        detailListing: [
          'Organization-wide approach to management of cybersecurity risks',
          'Risk-informed policies',
          'Processes and procedures in place to to address potential cybersecurity events',
          'Senior executives treat cyber risk the same as financial risk',
          'Cybersecurity risk management is part of organizational culture',
        ],
      },
      {
        name: 'External Participation',
        detailListing: [
          `Organization receives, generates, and reviews, information for
      continuous analysis of its risks in an evolving technological and threat landscape`,
          'Organization shares this information internally and externally',
          `Organization understands cyber risks in supply chain and uses real-time information 
      to understand and consistently act against those risks`,
          'Proactively communicates formally and informally to create and maintain strong supply chain relationships',
        ],
      },
    ],
  },
  {
    tierNumber: 3,
    level: 'Repeatable',
    capabilities: ['Formalized Risk Management', 'Organization-Wide Program', 'Receives External Partner Information'],
    features: [
      {
        name: 'Risk Management',
        detailListing: [
          'Formally approved and expressed as policy',
          `Cybersecurity practices are updated based on the application of risk management 
        process to changes in business requirements and a changing threat/technology landscape.`,
        ],
      },
      {
        name: 'Integrated Risk Management',
        detailListing: [
          'Organization-wide approach to management of cybersecurity risks',
          'Risk-informed policies',
          'Process are defined, implemented, and reviewed',
          'Cybersecurity and non-cybersecurity executives regularly communicate about cybersecurity risks',
        ],
      },
      {
        name: 'External Participation',
        detailListing: [
          'Organization regularly collaborates and receives information from outside entities',
          'Organization generates and shares information of their own with outside entities',
          `Organization is aware of cyber risks in their supply chain and formally acts against 
            those risks including written agreements communicating baseline requirements, governance 
            structures, and policy implementation/monitoring`,
        ],
      },
    ],
  },
  {
    tierNumber: 2,
    level: 'Risk Informed',
    capabilities: [
      'Some Risk Management Practices',
      'Increased Awareness; No Program',
      'Information External Participation',
    ],
    features: [
      {
        name: 'Risk Management',
        detailListing: [
          'Approved by management but NOT established as organizational-wide policy',
          `Prioritization of cybersecurity activities DOES consider organizational 
          risk objectives, business requirements, and the threat environment`,
        ],
      },
      {
        name: 'Integrated Risk Management',
        detailListing: [
          'Awareness of cybersecurity risk at organizational level',
          'Organization-wide approach to cybersecurity risk management has NOT been established',
          'Cybersecurity information is shared internally but on an irregular basis',
          'Cyber risk assessment of organizations assets occurs but is not a recurring (repeatedly done) process',
        ],
      },
      {
        name: 'External Participation',
        detailListing: [
          'Organization regularly collaborates and receives information from outside entities',
          'Organization generates and shares information of their own with outside entities',
          `Organization is aware of cyber risks in their supply chain but takes no formal or 
          consistent actions against those risks`,
        ],
      },
    ],
  },
  {
    tierNumber: 1,
    level: 'Partial',
    capabilities: ['Ad Hoc Risk Management', 'Limited Cybersecurity Risk Awareness', 'Low External Participation'],
    features: [
      {
        name: 'Risk Management',
        detailListing: [
          'Informal, only as the need arises, unprogressive, inconsistent',
          `Prioritization of cybersecurity activities DOES consider organizational 
          risk objectives, business requirements, and the threat environment`,
        ],
      },
      {
        name: 'Integrated Risk Management',
        detailListing: [
          'Limited awareness of cybersecurity risk at organizational level',
          'Risk management implemented irregularly on a case-by-case basis',
          'May or may not have processes to share cybersecurity information internally',
        ],
      },
      {
        name: 'External Participation',
        detailListing: [
          'Organization does NOT collaborate or exchange information with other entities',
          'Organization is unaware of the cyber risks in their supply chain',
        ],
      },
    ],
  },
];

export class BNBConstants {
  public static TITLES_WIZARD = {
    'OPEN PORTS': {
      header: [
        { name: 'ID', widthSize: '6vw', sort: 'asc', type: 'number' },
        { name: 'Port', widthSize: '10vw', sort: 'asc', type: 'number' },
        { name: 'Type', widthSize: '16vw', type: 'string' },
        { name: 'IP', widthSize: '10vw', sort: 'asc', type: 'string' },
      ],
      mapper: {
        ID: 'id',
        Port: 'port',
        Type: 'type',
        IP: 'ip',
      },
    },
    CVES: {
      header: [
        { name: 'ID', widthSize: '6vw', type: 'number', sort: 'asc' },
        { name: 'Risk Score', widthSize: '10vw', type: 'score', sort: 'asc' },
        { name: 'Port', widthSize: '8vw', type: 'number', sort: 'asc' },
        { name: 'Type', widthSize: '17vw', type: 'string', sort: 'asc' },
        { name: 'CVE', widthSize: '10vw', type: 'string', sort: 'asc' },
        { name: 'CVSS', widthSize: '10vw', type: 'number', sort: 'asc' },
        { name: 'IP', widthSize: '10vw', type: 'string', sort: 'asc' },
      ],
      mapper: {
        ID: 'id',
        'Risk Score': 'riskScore',
        Port: 'port',
        Type: 'type',
        CVE: 'cve',
        CVSS: 'cvScore',
        IP: 'ip',
      },
    },
    THREATS: {
      header: [
        { name: 'ID', widthSize: '2.8vw', type: 'number', sort: 'asc' },
        { name: 'IP', widthSize: '7vw', type: 'string', sort: 'asc' },
        { name: 'Port', widthSize: '4.1vw', type: 'number', sort: 'asc' },
        { name: 'Tags', widthSize: '5.1vw', type: 'string', sort: 'asc' },
        { name: 'Description', widthSize: '14.2vw', type: 'detailed', sort: 'asc' },
        { name: 'Risk', widthSize: '14.7vw', type: 'detailed', sort: 'asc' },
        { name: 'Risk Score', widthSize: '7.5vw', type: 'score', sort: 'asc' },
        { name: 'Recommendation', widthSize: '13.9vw', type: 'detailed', sort: 'asc' },
        { name: 'Created', widthSize: '5.6vw', type: 'date', sort: 'asc' },
        { name: 'Updated', widthSize: '5.6vw', type: 'date', sort: 'asc' },
      ],
      mapper: {
        ID: 'id',
        IP: 'ip',
        Port: 'port',
        Tags: 'tags',
        Description: 'description',
        Risk: 'risk',
        'Risk Score': 'riskScore',
        Recommendation: 'recommendation',
        Created: 'created',
        Updated: 'updated',
      },
    },
    // eslint-disable-next-line quote-props
    BREACHES: {
      header: [
        { name: 'ID', widthSize: '4.7vw', type: 'number', sort: 'asc' },
        { name: 'Name', widthSize: '5.7vw', type: 'string', sort: 'asc' },
        { name: 'Year', widthSize: '6.4vw', type: 'number', sort: 'asc' },
        { name: 'Details', widthSize: '22vw', type: 'detailed', sort: 'asc' },
        { name: 'Number of Records', widthSize: '11vw', type: 'number', sort: 'asc' },
        { name: 'Data', widthSize: '22vw', type: 'detailed' },
      ],
      mapper: {
        ID: 'id',
        Name: 'name',
        Year: 'year',
        Details: 'details',
        'Number of Records': 'numbRecords',
        Data: 'data',
      },
    },
    'EMAIL ADDRESSES': {
      header: [
        { name: 'Email', widthSize: '14vw', type: 'string', sort: 'asc' },
        { name: 'Data Breach', widthSize: '32vw', type: 'string' },
      ],
      mapper: {
        Email: 'email',
        'Data Breach': 'dataBreach',
      },
    },
  };

  // Table data types
  public static TableDataTypes = {
    STRING: 'string',
    NUMBER: 'number',
    SCORE: 'score',
    DETAILED: 'detailed',
    DATE: 'date',
  };

  public static TableNamesMapper = {
    'OPEN PORTS': 'openPorts',
    CVES: 'cves',
    THREATS: 'threats',
    BREACHES: 'breaches',
    'EMAIL ADDRESSES': 'emailAddresses',
  };
}

export const step8Data = [
  {
    title: 'Premium To Comp',
    type: '%',
    value: 0,
  },
  {
    title: 'Excess Limit',
    type: '$',
    value: 0,
  },
  {
    title: 'Excess Premium',
    type: '$',
    value: 0,
  },
  {
    title: 'Total PPM',
    type: '$',
    value: 0,
  },
  {
    title: 'Primary PPM',
    type: '$',
    value: 0,
  },
  {
    title: 'Excess PPM',
    type: '$',
    value: 0,
  },
  {
    title: 'Average ILF',
    type: '%',
    value: 0,
  },
];
