<div class="role-permission-popup">
  <div class="modal-header">
    <div class="cross-btn-cont" (click)="closePopUp()">
      <div cygovSvgIcon [svgName]="'x-icon-type2'" class='cross-btn'></div>
    </div>
  </div>

  <div class="role-permission-content-pop-up">
    <!-- Role Group Section -->
    <div class="role-group">
      <div class="profit-center-wrapper" *ngIf="isGlobalUserSetting">
        <div>
          <div class="global-setting-header pb-2"
            *ngIf="selectedRole?.defaultOrEntityId !== UsersSettingConstant.default && editMode">
            Profit Center</div>
          <h3 class="title-color" *ngIf="editMode && isGlobalUserSetting">{{rootEntity}}
          </h3>
        </div>
        <div>
          <div class="action-buttons" cygovUserPermission>
            <div *ngIf="selectedRole?.defaultOrEntityId!==UsersSettingConstant.default && editMode"
              class="action-icon" cygovSvgIcon ngbTooltip="Delete" container="body"
              [svgName]="'trash-icon'" (click)="deleteCustomRole()">
            </div>
            <cygov-button class="save-button" (buttonPressed)="saveRole()" *ngIf="!disableScreen"
              [buttonText]="'SAVE'" [buttonWidth]="'5.1vw'" [buttonColor]="'#29A9EB'"
              [disableClick]="disableSave"> </cygov-button>
          </div>
        </div>
      </div>
      <div *ngIf="!editMode && isGlobalUserSetting" class="entity-selectbox">
        <div class="global-setting-header">Profit Center</div>

        <div class="profit-center-wrapper">
          <div class="select-box" *ngIf="!editMode">
            <cygov-select [items]="entitiesList" (ngModelValueChange)="onSelectRootEntity($event)"
              bindLabel="name"
              [placeholder]="editMode ? rootEntity?.name : entitiesList ? 'Select Entities'  : 'Fetching data...' "
              [clearable]="false" [searchable]="false" [customArrow]="true">
            </cygov-select>
          </div>
        </div>
      </div>
      <div *ngIf="!isGlobalUserSetting" class="border-line"></div>
      <div class="top">
        <div class="left" *ngIf="selectedRole?.defaultOrEntityId!==UsersSettingConstant.default">
          <div class="header">Role Group Name</div>
          <input type="text" placeholder="New Role Name" [(ngModel)]="roleName"
            class="description" />
        </div>
        <div *ngIf="!isGlobalUserSetting" class="action-buttons" cygovUserPermission>

          <div *ngIf="selectedRole?.defaultOrEntityId!==UsersSettingConstant.default && editMode"
            class="action-icon" cygovSvgIcon ngbTooltip="Delete" container="body"
            [svgName]="'trash-icon'" (click)="deleteCustomRole()">
          </div>

          <div class="save-wrapper">
            <cygov-button class="save-button" (buttonPressed)="saveRole()" *ngIf="!disableScreen"
              [buttonText]="'SAVE'" [buttonWidth]="'5.1vw'" [buttonColor]="'#29A9EB'">
            </cygov-button>
          </div>
        </div>

      </div>
      <div class="border-line" *ngIf="!editMode"></div>
    </div>
    <!-- Entity Section -->
    <div class="entity-section"
      *ngIf="selectedRole?.defaultOrEntityId!==UsersSettingConstant.default">
      <div class="top" *ngIf="!editMode">
        <div class="header">Choose Entity</div>
        <div class="description">Please select at least one entity</div>
      </div>
      <div class="top" *ngIf="editMode">
        <div class="header">Choose Entity</div>
        <div class="description">Please select at least one entity</div>
      </div>
      <div class="entity-box" *ngIf="!isGlobalUserSetting">
        <div class="over-line" [ngClass]="{'spacing': !entityList.length}"></div>
        <ng-container *ngFor="let entity of entityList">
          <span (click)="checkRootEntityAccess() && onEntityClick(entity.id)"
            [ngClass]="{'entity-selected': selectedSubEntityIds.includes(entity.id), 'disable-entities': isRootEntityAccess}">{{entity.name|capitalizeFirst}}</span>
        </ng-container>
      </div>
      <div class="entity-box" *ngIf="isGlobalUserSetting">
        <div class="over-line" [ngClass]="{'spacing': !childEntitiesOfSelectedRootEntity?.length}">
        </div>
        <ng-container *ngFor="let entity of childEntitiesOfSelectedRootEntity">
          <span (click)="checkRootEntityAccess() && onEntityClick(entity.id)"
            [ngClass]="{'entity-selected': isRootEntityAccess ? true : selectedSubEntityIds.includes(entity.id), 'disable-entities': isRootEntityAccess}">{{entity.name|capitalizeFirst}}</span>
        </ng-container>
        <div class="over-line"></div>
      </div>
      <div class="border-line"></div>
    </div>
    <!-- Screen Permission section -->
    <div class="screen-permission">
      <div class="top">
        <div class="header">Screen Permission</div>
        <div class="description"
          *ngIf="selectedRole?.defaultOrEntityId!==UsersSettingConstant.default">
          Please select the following screens that their role group can be exposed to
        </div>
        <div class="description"
          *ngIf="selectedRole?.defaultOrEntityId===UsersSettingConstant.default">
          Screen Permission for {{selectedRole.name|capitalizeFirst}} user
        </div>
      </div>
      <div class="items-container">
        <ng-container *ngFor="let role of roleGroups">
          <div class="single-item-container"
            [ngStyle]="{'opacity':disableScreen || isPermissionToAccessScreens(role)?0.4:1}">
            <div *ngIf="disableScreen || isPermissionToAccessScreens(role)" class="disabled-screen">
            </div>
            <div class="top-element">
              <div class="title-group">
                <div class="check-box pointer" [ngClass]="{'selectedDefault': isDefaultRole()}"
                  [class.selected]="role.selected"
                  (click)="onScreenPermissionClicked(role, {}, true)">
                  <div *ngIf="role.selected" class="status-marker" cygovSvgIcon
                    [svgName]="'right-tick'"></div>
                </div>
                <div class="title"
                  [ngClass]="{'text-selected':role.selected, 'text-selected-default-role':isDefaultRole()}">
                  {{role.title}}</div>
              </div>
              <div class="arrow" [class.arrow-expand]="role.expanded"
                (click)="role.expanded = !role.expanded">
                <span cygovSvgIcon [svgName]="'arrow-right-type3'"></span>
              </div>
            </div>
            <div *ngIf="role.expanded" [@togglePanel]="role.expanded ? 'appear': 'void'"
              class="animated-level">
              <ng-container *ngFor="let level1Child of role?.level1Children">
                <div class="level-1">
                  <div class="top-line"></div>
                  <div class="level-1-element"
                    [class.no-child-padding]="!level1Child.level2Children?.length"
                    [class.with-child-padding]="level1Child.level2Children?.length">
                    <div class="element-group">
                      <div class="title-group">
                        <div class="check-box pointer"
                          [ngClass]="{'selectedDefault': isDefaultRole()}"
                          [class.selected]="level1Child.selected"
                          (click)="onScreenPermissionClicked(role, level1Child)">
                          <div *ngIf="level1Child.selected" class="status-marker" cygovSvgIcon
                            [svgName]="'right-tick'"></div>
                        </div>
                        <div class="title"
                          [ngClass]="{'text-selected':level1Child.selected, 'text-selected-default-role':isDefaultRole()}">
                          {{level1Child.title}}</div>
                      </div>
                      <div
                        [ngClass]="level1Child.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase() ? 'level-2 custom-vendors-list' : 'level-2'">
                        <ng-container *ngFor="let level2Child of level1Child.level2Children">
                          <div class="level-2-element">
                            <div class="title-group">
                              <div class="check-box pointer"
                                [ngClass]="{'selectedDefault': isDefaultRole()}"
                                [class.selected]="level2Child.selected"
                                (click)="onScreenPermissionClicked(level1Child, level2Child)">
                                <div *ngIf="level2Child.selected" class="status-marker" cygovSvgIcon
                                  [svgName]="'right-tick'"></div>
                              </div>
                              <div class="title"
                                [ngClass]="{'text-selected':level2Child.selected, 'text-selected-default-role':isDefaultRole()}">
                                {{level2Child.title}}
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

</div>