// eslint-disable-next-line no-shadow
export enum SortDirectionEnum {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  NONE = '',
}

// eslint-disable-next-line no-shadow
export enum SmartSortDirectionEnum {
  A_Z = 'A-Z',
  Z_A = 'Z-A',
  POTENTIAL_ASC = 'Potential Mapping Ascending',
  POTENTIAL_DESC = 'Potential Mapping Descending',
}
