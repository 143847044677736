import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UploadFileService } from '../../upload-file.service';
import { UploadStateEnum } from '../../upload-file.enum';

@Component({
  selector: 'cygov-upload-unsuccess',
  templateUrl: './upload-unsuccess.component.html',
  styleUrls: ['./upload-unsuccess.component.scss'],
})
export class UploadUnsuccessComponent implements OnInit {
  @Output() closeModalClicked: EventEmitter<any> = new EventEmitter();
  constructor(private uploadService: UploadFileService) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.closeModalClicked.emit(true);
  }

  /**
   * If the user wishes to upload another file,
   * this function will show the initial upload process screen
   */
  restartProcess(): void {
    this.uploadService.setUploadState(UploadStateEnum.UPLOAD_NOT_STARTED);
  }
}
