// eslint-disable-next-line no-shadow

/**  WARNING !!!
	 KINDLY DO NOT UPDATE THE ENUM SEQUENCE, AS THIS SEQUENCE HAS BEEN STRICTLY USED IN USERS SETTING PAGE.
 	 THIS WARNING IS FOR BOTH THE ENUMS  (DO NOT CHANGE THE SEQUENCE) */
export enum AppLevelRoleEnum {
  ADMIN = 'admin',
  MSSP = 'mssp',
  ENTITY_LEADER = 'entity leader',
  SUBENTITY_LEADER = 'sub-entity leader',
  VENDOR = 'vendor',
  PARTICIPANT = 'participant',
}

export enum AppLevelRoleEnumBnB {
  ADMIN = 'admin',
  USER_ADMINISTRATOR = 'user administrator',
  ENTERPRISE = 'enterprise',
  DIVISION_LEADER = 'division leader',
  REGION_LEADER = 'region leader',
  BUSINESS_ENTITY_ADMIN = 'business entity admin',
  SUBENTITY_LEADER = 'sub-entity leader',
  PARTICIPANT = 'participant',
}
export enum AppLevelRoleEnumThirdParty {
  ThirdPartyRole = 'third-party',
  CustomVendors = 'custom-vendors',
}
