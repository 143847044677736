export enum VendorColumnMappersEnum {
  name = 'name',
  domain = 'domain',
  email = 'email',
  impact = 'impact',
  date = 'date',
  primaryVendorContact = 'primaryVendorContact',
  isThirdParty = 'isThirdParty',
  isFourthParty = 'isFourthParty',
  projectManagerName = 'projectManagerName',
  businessUnits = 'businessUnits',
  category = 'category',
  dealValue = 'dealValue',
  products = 'products',
}

export enum VendorThirdForthPartyFlag {
  true = 'true',
  false = 'false',
}
