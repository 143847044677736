<div class="th-users d-flex">
  <div *ngFor="let item of headerState.Headers; let i = index" class={{item.class}}
    class="header-col" [ngClass]="{ 'header-padding' : !i}" (click)="onHeaderClicked(item)">
    <div class='header-name'
      [ngClass]="currentSortedColumn.toLowerCase() === item.name.toLowerCase() ?'header-name-active' : ''">
      <span class="current-sorted-column"
        [ngClass]="{ 'edit-header-name': isEditMode && isEditable(item.name) }">
        {{item.name}}</span>
      <div *ngIf="item.isSortApplicable" class="sort-buttons">
        <div class="icon-class-btn"
          [ngClass]="{ 'edit-sort-arrow': isEditMode && isEditable(item.name) }"
          [ngClass]="{'dsc':item.sortDirection===sortDirections.DESCENDING}" cygovSvgIcon
          [svgName]="'arrow-sort'">
        </div>
      </div>
    </div>

  </div>
</div>