export class ImpactNumEnum {
  static LOW = 25;
  static MEDIUM = 50;
  static HIGH = 75;
  static CRITICAL = 100;
}
export class ProbabilityEnum {
  static LOW = 25;
  static MEDIUM = 50;
  static HIGH = 75;
  static CRITICAL = 100;
}
export class ExtScanEnum {
  static LOW = 6;
  static MEDIUM = 8;
  static HIGH = 10;
}
export class AssessmentEnum {
  static LOW = 6;
  static MEDIUM = 8;
  static HIGH = 10;
}
export class ProbabilityTextEnum {
  static LOW = 'low';
  static MEDIUM = 'medium';
  static HIGH = 'high';
  static CRITICAL = 'critical';
}
export class ExternalScanTextEnum {
  static LOW = 'low-extScan';
  static MEDIUM = 'medium-extScan';
  static HIGH = 'high-extScan';
}
export class AssessmentTextEnum {
  static LOW = 'low-assessment';
  static MEDIUM = 'medium-assessment';
  static HIGH = 'high-assessment';
}

export class RiskTextEnum {
  static LOW = 'low';
  static MEDIUM = 'medium';
  static HIGH = 'high';
  static CRITICAL = 'critical';
}
export class ImpactTextEnum {
  static LOW = 'low';
  static MEDIUM = 'medium';
  static HIGH = 'high';
  static CRITICAL = 'critical';
}

export class InformaticaDepartmentEnum {
  static FINANCE = 'Finance';
  static HR = 'HR';
  static IPS = 'IPS';
  static IT = 'IT';
  static LEGAL = 'Legal';
  static MARKETING = 'Marketing';
  static PROCUREMENT = 'Procurement';
  static RND = 'R&D';
  static SALES = 'Sales';
  static SUPPORT = 'Support';
}

export class InformaticaCategoryEnum {
  static DATA_PROVIDER = 'Data provider';
  static EVENT = 'Event';
  static RESELLER = 'Reseller';
  static SAAS = 'SaaS';
  static SERVICE = 'Service';
  static SOFTWARE = 'Software';
  static TECH = 'Tech';
}

export class RiskScoreEnum {
  static LOW = 3.3;
  static MEDIUM = 6.5;
  static HIGH = 10;
}
export class RiskScoreTextEnum {
  static LOW = 'low';
  static MEDIUM = 'medium';
  static HIGH = 'high';
}
