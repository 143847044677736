import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Observable } from 'rxjs';
import { GetUserQuery, RoleEnum } from '../../API.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ParticipantRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService, private logger: NGXLogger) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivate: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivateChild: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  async checkUserRole(url?: string) {
    try {
      const user: GetUserQuery = await this.authService.getCurrentUser();
      if (
        user &&
        (user.role === RoleEnum.ADMIN ||
          user.role === RoleEnum.MSSP ||
          ((user.role === RoleEnum.LEADER || user.role === RoleEnum.VENDOR || user.role === RoleEnum.PARTICIPANT) &&
            this.authService.isEntityIdInUrl(url, user.entityId)))
      ) {
        return true;
      } else {
        this.logger.error('checkUserRole - Participant: Unauthorized');
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      this.logger.error('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
}
