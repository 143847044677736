import { Storage } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { FileTypeEnum, GetAssessmentStandardFrameworkQuery } from 'app/API.service';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import { APIService } from 'app/API.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FrameworkTreeService {
  cache = {};

  constructor(private fileService: FileService, private apiService: APIService, private route: ActivatedRoute) {}

  async getFrameworkFromS3(frameworkName: string): Promise<any> {
    try {
      const filePath = `FRAMEWORKS/${frameworkName}.csv`;
      return this.fileService.getTextFileFromS3(filePath, { download: true, level: 'public' });
    } catch (err) {
      console.log('ERR: getFrameworkFromS3 - :', err);
      return null;
    }
  }

  /**
   * TODO: Have to handle encryption checks
   * BECAUSE: unclear about its flow, since its path is currently
   * NOT in encrypted files, so its left unchanged for now.
   */
  getFrameworkTreeFromS3(frameworkName: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.FRAMEWORK_TREES}/${frameworkName}.json`;
      const options: any = { download: true, contentType: 'application/json', level: 'public' };
      return (this.cache[frameworkName] = Storage.get(filePath, options));
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  /**
   *
   * @param frameworkName
   * @returns framework tree file from S3
   */

  async getFrameworkTree(frameworkName: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.FRAMEWORK_TREES}/${frameworkName}.json`;
      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        Recursive: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      });
    } catch (err) {
      console.log('ERR: getAssessmentTreeFromS3 - :', err);
      return null;
    }
  }

  async getAssessmentTreeFromS3(assessmentId: string, framework: GetAssessmentStandardFrameworkQuery): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/${framework.key}.json`;

      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        Recursive: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      });
    } catch (err) {
      console.log('ERR: getAssessmentTreeFromS3 - :', err);
      return null;
    }
  }

  async getArchivedAssessmentTreeFromS3(
    assessmentId: string,
    framework: GetAssessmentStandardFrameworkQuery
  ): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/ARCHIVED/${framework.key}.json`;

      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        Recursive: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      });
    } catch (err) {
      console.log('ERR:: getArchivedAssessmentTreeFromS3 - ', err);
      return null;
    }
  }

  async getTasksListTreeFromS3(assessmentId: string, framework: GetAssessmentStandardFrameworkQuery): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/${framework.key}/TASKS/TASKS.json`;

      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        contentType: 'application/json',
        level: 'public',
      });
    } catch (err) {
      console.log('ERR:: getTasksListTreeFromS3 - ', err);
      return null;
    }
  }

  async getGroupAssessmentTreeFromS3(
    assessmentId: string,
    framework: GetAssessmentStandardFrameworkQuery
  ): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/${framework.key}/GROUPS/GROUPS.json`;

      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      });
    } catch (err) {
      console.log('ERR:: getGroupAssessmentTreeFromS3 - ', err);
      return null;
    }
  }
  async getDeniedDataFromS3(assessmentId: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/deniedList.json`;
      const list: any = await Storage.list(filePath);
      if (list.length) {
        return this.fileService.getJSONFileFromS3(filePath, {
          download: true,
          contentType: 'application/json',
          level: 'public',
          cacheControl: 'no-cache',
        });
      } else {
        return [];
      }
    } catch (err) {
      console.log('ERR:: getDeniedDataFromS3 - ', err);
      return [];
    }
  }

  async getArtifactDataFromS3(assessmentId: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/artifactList.json`;
      const list: any = await Storage.list(filePath);
      if (list.length) {
        return this.fileService.getJSONFileFromS3(filePath, {
          download: true,
          contentType: 'application/json',
          level: 'public',
          cacheControl: 'no-cache',
        });
      } else {
        return [];
      }
    } catch (err) {
      console.log('ERR:: getArtifactDataFromS3 - ', err);
      return [];
    }
  }

  // write:: have to update
  async setDeniedListToS3(assessmentId, s3File): Promise<any> {
    const config: any = { contentType: s3File.contentType };
    config.level = 'public';
    config.download = true;
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/deniedList.json`;
      const { key } = (await Storage.put(filePath, s3File.body, config)) as Record<string, string>;
      s3File.key = key;
      delete s3File.body;
      return s3File;
    } catch (err) {
      console.log('error -setReportInfoToS3: ', err);
      return null;
    }
  }

  // write:: have to update
  async setArtifactToS3(assessmentId, s3File): Promise<any> {
    const config: any = { contentType: s3File.contentType };
    config.level = 'public';
    config.download = true;
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/artifactList.json`;
      const { key } = (await Storage.put(filePath, s3File.body, config)) as Record<string, string>;
      s3File.key = key;
      delete s3File.body;
      return s3File;
    } catch (err) {
      console.log('error -setReportInfoToS3: ', err);
      return null;
    }
  }
  /**
   *
   * @param assessmentId
   * @param s3File
   * @param frameworkName
   * @returns UPDATE FRAMEWORK TREE IN S3
   */
  async setFrameworkTreeToS3(assessmentId, s3File, frameworkName = ''): Promise<any> {
    const config: any = { contentType: 'application/json' };
    config.level = 'public';
    config.download = true;
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/${frameworkName}.json`;
      const { key } = (await Storage.put(filePath, s3File, config)) as Record<string, string>;
      s3File.key = key;
      delete s3File?.body;
      return s3File;
    } catch (err) {
      console.log('error -setReportInfoToS3: ', err);
      return null;
    }
  }

  async getAssessmentRevisionTreeFromS3(id: string, frameworkName: string, date: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.REVISIONS}/${date}/${FileTypeEnum.ASSESSMENTS}/${id}/${frameworkName}.json`;
      const isFile = await this.fileService.checkIfPathExistsS3(filePath);
      if (isFile && isFile.length) {
        return this.fileService.getJSONFileFromS3(filePath, {
          download: true,
          contentType: 'application/json',
          level: 'public',
        });
      }
      return null;
    } catch (err) {
      console.log('Error fetching file: ', err);
      return null;
    }
  }

  async getAssessmentControlsFromS3(assessmentId: string): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/controls.json`;

      return this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        contentType: 'application/json',
        level: 'public',
      });
    } catch (err) {
      console.log('ERR: getAssessmentControlsFromS3 - ', err);
      return null;
    }
  }

  flatTree(frameworkTree) {
    // This will get the controls from frameworkTree by flatening the tree
    const leafNodes = [];
    function traverse(node) {
      if (!node.children || node.children.length === 0) {
        leafNodes.push(node);
      } else {
        for (const child of node.children) {
          traverse(child);
        }
      }
    }

    traverse(frameworkTree);
    return leafNodes;
  }

  async getControlsFromAssessment(assessmentId: string, framework): Promise<any> {
    try {
      const frameworkTree = await this.getAssessmentTreeFromS3(assessmentId, framework);
      const controls = this.flatTree(frameworkTree);
      return { controls };
    } catch (err) {
      return {};
    }
  }

  static getFrameworkKey(): string {
    let frameKey = 'NIST_CSF';
    switch (true) {
      case UtilsService.isBnB:
        frameKey = 'NIST_CSF_BB';
        break;
      case UtilsService.isBnBCyberSite:
        frameKey = 'PARAGON_FULL';
        break;
      case UtilsService.isOklahomaUniversity:
        frameKey = 'NIST_OU';
        break;
      case UtilsService.isITC:
        frameKey = 'ITC_CUSTOM';
        break;
    }

    return frameKey;
  }

  async getRiskControlsFromS3(fileName: string, frameworkKey: string = 'NIST_CSF'): Promise<any> {
    try {
      const filePath = `RISKS/${fileName}-${
        frameworkKey.includes('#') ? frameworkKey.split('#')[0] : frameworkKey
      }.json`;
      const res = await this.fileService.getJSONFileFromS3(filePath, {
        download: true,
        contentType: 'application/json',
        level: 'public',
      });
      return res;
    } catch (err) {
      console.warn('Info: getRiskControlsFromS3 - ', err);
      return null;
    }
  }
}
