import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientLambdaService {
  constructor() {}

  // shared lambda code to invoke lambda ( Note to make it reuseable instead of code copy paste)
  /**
   *
   * @param functionName name of lambda/function in backend to invoke
   * @param event  an object send as input to lambda
   * @returns response from lambda
   */
  async invokeLambda(functionName: string, event: any, invocationType = null, defaultTimeOut = 120000): Promise<any> {
    const credentials = await Auth.currentCredentials();
    // add region and credentials for access permission to execute lambda
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: environment.region,
      httpOptions: {
        timeout: defaultTimeOut, // Set timeout to 300000 milliseconds (5 minutes)
      },
    });
    // Information which will be send as parameters like event.json in lambda
    const params = invocationType
      ? {
          FunctionName: `${functionName}-${environment.name}`,
          Payload: JSON.stringify(event),
          InvocationType: invocationType,
        }
      : {
          FunctionName: `${functionName}-${environment.name}`,
          Payload: JSON.stringify(event),
        };
    // Invoking lambda
    try {
      const result = await lambda.invoke(params).promise();
      return result;
    } catch (e) {
      return null;
    }
  }
}
