// import { ParticipantRoleGuard } from 'app/shared/guards/participant-role.guard'; //!  build-optimization-2
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Route } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { TenantGuard } from 'app/shared/guards/tenant.guard';
import { DomainSpecificGuard } from 'app/shared/guards/domain-specific.guard';
import { AdminRoleGuard } from './shared/guards/admin-role.guard';
// import { EntityLeaderRoleGuard } from './shared/guards/entity-leader-role.guard'; //!  build-optimization-2
// import { SubEntityLeaderRoleGuard } from './shared/guards/sub-entity-leader-role.guard'; //!  build-optimization-2
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

// How to work properly with angular router:
// https://stackoverflow.com/questions/44864303/send-data-through-routing-paths-in-angular

const routes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    path: 'clients',
    loadChildren: () => import('app/clients/clients.module').then(m => m.ClientsModule),
  },
  {
    path: 'first-party',
    canActivate: [AuthGuard, RoleGuard, TenantGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/clients',
      },
      {
        path: ':entityId/findings/:serviceId',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/findings/findings.module').then(m => m.FindingsModule),
      },
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/upperdeck',
      },
      {
        path: ':entityId/upperdeck',
        // canActivate: [EntityLeaderRoleGuard],
        loadChildren: () => import('app/upperdeck/upperdeck.module').then(m => m.UpperdeckModule),
      },
      {
        path: ':entityId/multi-entity',
        // canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/multi-entity/multi-entity.module').then(m => m.MultiEntityModule),
      },
      {
        path: ':entityId/pillars',
        // canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/pillars/pillars.module').then(m => m.PillarsModule),
      },
      {
        path: ':entityId/break-down',
        // canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/break-down/break-down.module').then(m => m.BreakDownModule),
      },
      {
        path: ':entityId/remediation',
        // canActivate: [ParticipantRoleGuard],
        loadChildren: () => import('app/remediation/remediation.module').then(m => m.RemediationModule),
      },
      {
        path: ':entityId/user-settings',
        // canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/users-settings/users-settings.module').then(m => m.UsersSettingsModule),
      },
      // ! Tag:- build-optimization-2
      // ! Empty Modules were imported
      // {
      //   path: ':entityId/alerts',
      //   loadChildren: () => import('app/alerts/alerts.module').then(m => m.AlertsModule),
      // },
      // {
      //   path: ':entityId/attack-simulator',
      //   loadChildren: () => import('app/attack-simulator/attack-simulator.module').then(m => m.AttackSimulatorModule),
      // },
      // ! ---------------
      {
        path: ':entityId/questionnaire-management',
        // canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () =>
          import('app/questionnaire-management/questionnaire-management.module').then(
            m => m.QuestionnaireManagementModule
          ),
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: () => import('app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: ':entityId/collection',
        loadChildren: () => import('app/collection/collection.module').then(m => m.CollectionModule),
      },
      {
        path: ':entityId/risk-register',
        loadChildren: () => import('app/risk-register/risk-register.module').then(m => m.RiskRegisterModule),
      },
      {
        path: ':entityId/risk-map',
        loadChildren: () => import('app/risk-register/risk-register.module').then(m => m.RiskRegisterModule),
      },
      {
        path: ':entityId/integrations',
        loadChildren: () => import('app/findings/findings.module').then(m => m.FindingsModule),
      },
    ],
  },
  {
    path: 'third-party',
    canActivate: [AuthGuard, RoleGuard, TenantGuard],
    canActivateChild: [RoleGuard],
    loadChildren: () => import('app/third-party/third-party.module').then(m => m.ThirdPartyModule),
  },
  {
    path: 'vendor',
    canActivate: [AuthGuard, RoleGuard, TenantGuard],
    children: [
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/questionnaire',
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: () => import('app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: ':entityId/gaps',
        loadChildren: () => import('app/remediation/remediation.module').then(m => m.RemediationModule),
      },
    ],
  },
  {
    path: 'board',
    canActivate: [AuthGuard, RoleGuard, TenantGuard],
    canActivateChild: [RoleGuard],
    loadChildren: () => import('app/board/board.module').then(m => m.BoardModule),
  },
  {
    path: 'board-netskope',
    canActivate: [AuthGuard, RoleGuard, DomainSpecificGuard],
    loadChildren: () => import('app/board-netskope/board-netskope.module').then(m => m.BoardNetskopeModule),
  },
  {
    path: 'binary-edge',
    canActivate: [AuthGuard, TenantGuard],
    loadChildren: () => import('app/binary-edge/binary-edge.module').then(m => m.BinaryEdgeModule),
  },
  {
    path: 'training-center',
    canActivate: [AuthGuard, TenantGuard],
    loadChildren: () => import('app/training-center/training-center.module').then(m => m.TrainingCenterModule),
  },
  {
    path: 'admin',
    canActivate: [AdminRoleGuard],
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'generator',
    canActivate: [AdminRoleGuard],
    loadChildren: () => import('app/generator/generator.module').then(m => m.GeneratorModule),
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutesModule {}
