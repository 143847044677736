<cygov-loader *ngIf="loading"></cygov-loader>
<div class="modal-body" [ngClass]="{'disable-click' : loading}">
  <div class="modal-header center">
    <div class="capitalize">
      BEFORE WE GET START WITH THE DEMO, PLEASE FILL IN THE FIELDS BELOW
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <form (ngSubmit)="isValid() && addGenerator()" #generatorForm="ngForm" class="vendor-form">
    <div class="group-box ">
      <div class="group-header">
        <div class="capitalize">ENTITY</div>
      </div>
      <div class="row modal-container">
        <div class="item-container width-18">
          <cygov-select [isImageControl]="true" [items]="images"
            [placeholder]="'Select Root Entity Name'" [(ngModelValue)]="rootEntity.logoName">
          </cygov-select>
        </div>
        <div class="item-container width-18">
          <cygov-select [items]="industryOpt" placeholder="Select Industry"
            [(ngModelValue)]="rootEntity.industry">
          </cygov-select>
        </div>
        <div class="item-container due-date width-18 project-deadline">
          <span class="lato-16-n-vw">Project Deadline</span>
          <cygov-ngb-calendar [dateType]="'deadlineDate'" [(selectedDate)]="projectDeadline"
            (selectedDateChange)="setProjectDeadline()" [inputMinDate]="currentDate"
            [toggleButton]="true" [dontDisplayInputArea]="true">
          </cygov-ngb-calendar>
        </div>

        <div class="item-container">
          <span class="key color-blue">Project Manager Name</span>
          <input type="text" class="value vendor-form-input input-width" name="projectManagerName"
            id="projectManagerName" [(ngModel)]="rootEntity.projectManagerName" />
        </div>
      </div>
      <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <a ngbNavLink>Sub-Entities</a>
            <ng-template ngbNavContent>
              <!-- sub entity tab start -->
              <div>
                <div *ngIf="subEntityList.length > 0" class="group-header">
                  <div class="capitalize">SUB ENTITIES</div>
                  <span cygovSvgIcon [svgName]="'arrow-right-type3'" class="icon"></span>
                </div>
                <div style="display: flex;flex-direction: column;padding-left: 1vw"
                  class="fix-height">
                  <div *ngIf="subEntityList.length > 0" class="row modal-container ">
                    <div class="item-container w-100 entity-desc"
                      *ngFor="let item of subEntityList; let i = index">

                      <div (click)="toggleSubItem(i)" class="name-arrow-wrapper">
                        <span class="key">
                          {{ item.name }}
                        </span>
                        <div class="arrow-wrapper" [ngClass]="{ expandClass: !item.isCollapsed}">
                          <div class="arrow-icon" cygovSvgIcon [svgName]="'arrow-right-type3'">
                          </div>
                        </div>
                      </div>

                      <div class="desc-body" id="collapseExample"
                        [(ngbCollapse)]="item.isCollapsed">
                        <div class="row1">

                          <div class="lato-16-n-vw capitalize">
                            <span class="color-blue">Risk Framework :
                            </span>{{ item.riskFramework.value }}
                          </div>
                          <div class="lato-16-n-vw capitalize">
                            <span class="color-blue">Number of Users :
                            </span>{{ item.numberOfUsers }}
                          </div>
                          <div class="lato-16-n-vw capitalize">
                            <span class="color-blue">Users Answered :
                            </span>{{ item.userAnswers.YES ? 'Yes ' : null }}
                            {{ (item.userAnswersLabels | replace:' ':', ') | replace: '_':' ' }}
                          </div>
                        </div>
                        <div class=" row2 lato-16-n-vw capitalize">
                          <span class="color-blue">Compliance : </span>
                          <span *ngFor="let framework of item.frameworkList">{{ framework.name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="group-header">
                    <div class="capitalize">SUB ENTITY</div>
                  </div>
                  <div class="row modal-container">
                    <div class="row-container">
                      <div class="item-container width-18">
                        <span class="key color-blue">Company Name</span>
                        <input type="text" class="value vendor-form-input input-width"
                          name="companyName" id="companyName" spellcheck="true"
                          [(ngModel)]="subEntity.name" required />
                      </div>
                      <div class="item-container due-date">
                        <span>Due Date</span>

                        <cygov-ngb-calendar [dateType]="'subEntityDueDate'"
                          [(selectedDate)]="selectedSubEntityDueDate"
                          (selectedDateChange)="setSubEntityDueDate()" [inputMinDate]="currentDate"
                          [toggleButton]="true" [dontDisplayInputArea]="true">
                        </cygov-ngb-calendar>
                      </div>
                      <div class="item-container mt-15 width-18">
                        <cygov-select class="w-50" [items]="riskFrameWorkOpt" [labelKeys]="['key']"
                          [replacePipe]="true" [optionKeys]="['value']" [customTemplate]="true"
                          placeholder="Select Risk Framework"
                          [(ngModelValue)]="subEntity.riskFramework">
                        </cygov-select>
                      </div>
                    </div>
                    <div class="row-container">
                      <div class="item-container number-of-users">
                        <span class="key color-blue">Number Of Users</span>
                        <input type="number" class="value vendor-form-input " name="name" id="name"
                          spellcheck="true" [(ngModel)]="subEntity.numberOfUsers" required />
                      </div>
                      <div class="item-container user-answers">
                        <span class="key color-blue">Users Answers</span>
                        <div class="round-checkbox align-in-row">
                          <div class=" align-in-row" *ngFor="let item of possibleAnswers">
                            <cygov-checkbox [isChecked]="item.selected" [id]="item.name"
                              (changed)="item.selected = $event">
                            </cygov-checkbox>
                            <label class="capitalize value mr-8 mt-0"
                              for="{{item.name}}">{{item.name | replace: '_':' '}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="item-container certification w-100 align-in-row">
                      <span class="key color-blue full-width">Compliance : </span>
                      <div class=" align-in-row-component mb-1vh"
                        *ngFor="let standards of standardOptions; let i = index">
                        <div class="toggle-text">
                          <span class="value uppercase">{{ standards.name }}</span>
                        </div>
                        <div class="toggle-btn-container">
                          <cygov-toggle-button [isChecked]="standards.isQualify"
                            (changed)="standards.isQualify = $event" [id]="i.toString()">
                          </cygov-toggle-button>
                          <input type="hidden" [value]="standards.isQualify"
                            [(ngModel)]="standards.isQualify" [name]="standards.name"
                            [id]="standards.name" [checked]="standards.isQualify" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-group">
                  <button type="button" (click)="isValidSubEntity() ? addSubEntity() : false"
                    class="btn btn-primary add-sub-entity-btn">
                    <span class="add-button">+</span>
                    <span class="ml-1">Add New Sub Entity</span>
                  </button>
                </div>
              </div>
              <!-- sub entity tab end -->
            </ng-template>
          </li>

          <li ngbNavItem>
            <a ngbNavLink>Vendors</a>
            <ng-template ngbNavContent>
              <!-- Vendor tab start -->
              <div>
                <div style="display: flex;flex-direction: column;padding-left: 1vw"
                  class="fix-height">
                  <div *ngIf="vendorsList.length > 0" class="group-header">
                    <div class="capitalize">Vendors</div>
                    <span cygovSvgIcon [svgName]="'arrow-right-type3'" class="icon"></span>
                  </div>
                  <div *ngIf="vendorsList.length > 0" class="row modal-container ">
                    <div class="item-container w-100 entity-desc"
                      *ngFor="let item of vendorsList; let i = index">
                      <div (click)="toggleVendorItem(i)" class="name-arrow-wrapper">
                        <span class="key">
                          {{ item.name }}
                        </span>
                        <div class="arrow-wrapper" [ngClass]="{ expandClass: !item.isCollapsed}">
                          <div class="arrow-icon" cygovSvgIcon [svgName]="'arrow-right-type3'">
                          </div>
                        </div>
                      </div>
                      <div class="desc-body" id="collapseExample" [ngbCollapse]="item.isCollapsed">
                        <div class="row1">
                          <div class="lato-16-n-vw capitalize">
                            <span class="color-blue">Users Answered :
                            </span>{{ item.userAnswers.YES ? 'Yes ' : null }}
                            {{ (item.userAnswersLabels | replace:' ':', ') | replace: '_':' ' }}
                          </div>
                        </div>
                        <div class=" row2 lato-16-n-vw capitalize">
                          <span class="color-blue">Compliance : </span>
                          <span *ngFor="let framework of item.frameworkList">{{ framework.name }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="group-header">
                    <div class="capitalize">Vendor</div>
                  </div>
                  <div class="row modal-container">
                    <div class="row-container">
                      <div class="item-container width-18">
                        <cygov-select [isImageControl]="true" [items]="images"
                          [placeholder]="'Select Vendor Name'" [(ngModelValue)]="vendor.logoName">
                        </cygov-select>
                      </div>

                      <div class="item-container due-date">
                        <span>Due Date</span>

                        <cygov-ngb-calendar [dateType]="'vendorDueDate'"
                          [(selectedDate)]="selectedVendorDueDate"
                          (selectedDateChange)="setVendorDueDate()" [inputMinDate]="currentDate"
                          [toggleButton]="true" [dontDisplayInputArea]="true">
                        </cygov-ngb-calendar>
                      </div>

                      <div class="item-container active-scan align-in-row">
                        <div class="toggle-text">
                          <span class="value">Active Scan</span>
                        </div>
                        <div class="toggle-btn-container">
                          <cygov-toggle-button [isChecked]="vendor.activeScan"
                            (changed)="vendor.activeScan = $event" [id]="'activeScan-toggle'">
                          </cygov-toggle-button>
                          <input type="hidden" [value]="vendor.activeScan"
                            [(ngModel)]="vendor.activeScan" [name]="'activeScan'"
                            [id]="'activeScan-input'" [checked]="vendor.activeScan" />
                        </div>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container poc-container">

                        <div *ngIf="vendor.users && vendor.users.length > 0" class="added-poc">

                          <div *ngFor="let poc of vendor.users" class="poc">
                            <div class="lato-16-n-vw capitalize width-18">
                              <span class="color-blue">POC Name :
                              </span>{{poc.name}}
                            </div>
                            <div class="lato-16-n-vw capitalize width-18">
                              <span class="color-blue">POC Email :
                              </span>{{poc.email}}
                            </div>

                          </div>
                        </div>

                        <div class="new-poc">
                          <div class="width-18">
                            <span class="key color-blue">Primary POC</span>
                            <input type="text" class="value vendor-form-input input-width"
                              name="pocName" id="pocName" spellcheck="true"
                              [(ngModel)]="vendorUser.name" required />
                          </div>
                          <div class="width-18">
                            <span class="key color-blue">Email</span>
                            <input type="text" class="value vendor-form-input input-width"
                              name="pocEmail" id="pocEmail" spellcheck="true" type="email"
                              [(ngModel)]="vendorUser.email" required />
                          </div>

                          <div class="add-poc" (click)="addPOCRow()">
                            <span class="plus">+</span><span>Add POC</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container user-answers">
                        <span class="key color-blue">Users Answers</span>
                        <div class="round-checkbox align-in-row">
                          <div class=" align-in-row" *ngFor="let item of vendorPossibleAnswers">
                            <cygov-checkbox [isChecked]="item.selected" [id]="item.name"
                              (changed)="item.selected = $event">
                            </cygov-checkbox>
                            <label class="capitalize value mr-8 mt-0"
                              for="{{item.name}}">{{item.name | replace: '_':' '}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-container">
                      <div *ngIf="!isCRBAdjustments" class="item-container impact-container">
                        <span class="key color-blue">Impact</span>
                        <div class="impact">
                          <div class="impact-options">
                            <div class="round-checkbox" *ngFor="let opt of priorityOpt">
                              <input [id]="'label-' + opt" type="radio" [name]="'label-' + opt"
                                [value]="opt" [checked]="this.selectedSurvey === opt"
                                (change)="updateSelectedSurvey($event)" />
                              <label [for]="'label-' + opt"
                                class="lato-14-n-vw capitalize label target">{{opt.replace('_', '
                                ')}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item-container">
                        <div class="width-18">
                          <span class="key color-blue">Domain</span>
                          <input type="text" class="value vendor-form-input input-width"
                            name="domain" id="domain" spellcheck="true" [(ngModel)]="vendor.domain"
                            required />
                        </div>
                      </div>
                    </div>
                    <div class="row-container" *ngIf="isCRBAdjustments">
                      <div class="item-container operational-impacts">
                        <div class="key color-blue operational-impact-header">
                          <span class="key color-blue">Operational Impacts</span>
                          <span class="val key uppercase"
                            [ngClass]="getImpactText(selectedSurvey)">{{ selectedSurvey.replace('_',
                            ' ') }}</span>
                        </div>
                        <div class="impact-options">
                          <div class="toggle-container"
                            *ngFor="let impactOpt of impactOptions; let i = index">
                            <div class="toggle-text">
                              <span class="value">{{ impactOpt.name }}</span>
                            </div>
                            <div class="toggle-btn-container">
                              <cygov-toggle-button [isChecked]="impactOpt.isQualify"
                                (changed)="impactOpt.isQualify = $event; calcCrbVendorImpact()"
                                [id]="'impactOpt' + i">
                              </cygov-toggle-button>
                              <input type="hidden" [value]="impactOpt.isQualify"
                                [(ngModel)]="impactOpt.isQualify" [name]="impactOpt.name"
                                [id]="impactOpt.name" [checked]="impactOpt.isQualify" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ToDo need to confirm this -->
                    <!-- <div class="row-container" *ngIf="isCRBAdjustments">
                      <div class="item-container  privacy align-in-row">

                        <span class="key color-blue">Privacy Data</span>

                        <div class="privacy-toggle">
                          <cygov-toggle-button [isChecked]="vendor.privacyData"
                            (changed)="vendor.privacyData = $event"
                            id="vendorPrivacyDataToggle">
                          </cygov-toggle-button>
                          <input type="hidden" [value]="vendor.privacyData"
                            [(ngModel)]="vendor.privacyData" name="vendorPrivacyData"
                            id="vendorPrivacyData" [checked]="vendor.privacyData" />
                        </div>
                      </div>
                    </div> -->
                    <div class="row-container">
                      <div class="item-container artifact-container">
                        <span class="key color-blue">Artifacts</span>
                        <div class="artifacts">
                          <div class="artifact"
                            *ngFor="let artifact of mandatoryArtifacts; index as i">
                            <cygov-checkbox [isChecked]="artifact.isQualify"
                              [id]="artifact.name+'_'+i" (changed)="artifact.isQualify = $event">
                            </cygov-checkbox>
                            <input class='vendor-form-input' type="hidden"
                              [value]="artifact.isQualify" [(ngModel)]="artifact.isQualify"
                              [name]="artifact.name" [checked]="artifact.isQualify" />
                            <label class="capitalize value" for="{{ artifact.name+'_'+i }}">{{
                              artifact.name }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="item-container certification w-100 align-in-row">
                      <span class="key color-blue full-width">Compliance : </span>
                      <div class=" align-in-row-component mb-1vh"
                        *ngFor="let standards of vendorStandardOptions; let i = index">
                        <div class="toggle-text">
                          <span class="value uppercase">{{ standards.name }}</span>
                        </div>
                        <div class="toggle-btn-container">
                          <cygov-toggle-button [isChecked]="standards.isQualify"
                            (changed)="standards.isQualify = $event" [id]="i.toString()">
                          </cygov-toggle-button>
                          <input type="hidden" [value]="standards.isQualify"
                            [(ngModel)]="standards.isQualify" [name]="standards.name"
                            [id]="standards.name" [checked]="standards.isQualify" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-group">
                  <button type="button" (click)="isValidVendor() ? addVendor() : false"
                    class="btn btn-primary add-sub-entity-btn">
                    <span class="add-button">+</span>
                    <span class="ml-1">Add New Vendor</span>
                  </button>
                </div>
              </div>
              <!-- Vendor tab end -->

            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>

    </div>
    <div class="btn-group">
      <button type="submit" class="btn btn-primary uppercase btn-send save-btn lato-16-n-vw">
        Save
      </button>
    </div>
  </form>
</div>