import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { UploadStateEnum, UploadStateStyleEnum } from '../../upload-file.enum';
import { UploadFileService } from '../../upload-file.service';
import { BoardConstant } from 'app/board/board.constant';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { FileService } from 'app/shared/file.service';

@Component({
  selector: 'cygov-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent implements OnInit, OnDestroy {
  @Output() closeModalClicked: EventEmitter<any> = new EventEmitter();
  @Input() displayMessage: string = 'Uploading File/Files';
  @Input() requiredInfo: any;
  @Input() isVendorBulkUpload: boolean = false;
  @Input() loaderType: UploadStateStyleEnum;
  uploadCompleted = false;

  progress = 0;
  subscription: Subscription[] = [];
  interval;
  styleType = UploadStateStyleEnum;

  constructor(private uploadService: UploadFileService, private fileService: FileService) {}

  ngOnInit(): void {
    // a subscription to alert us if the raw data processing is complete or not
    this.subscription.push(
      this.uploadService.getProcessComplete().subscribe(process => {
        if (typeof process === BoardConstant.booleanKeyword) {
          this.uploadCompleted = process;
        } else {
          clearInterval(this.interval);
          this.resetData();
          setTimeout(() => {
            this.uploadService.setUploadState(UploadStateEnum.UPLOAD_UNSUCCESSFUL);
          }, 0);
        }
      }),
      this.uploadService.getFile().subscribe(file => {
        if (file) {
          const isItFake = this.uploadService.getUploadType();
          if (!isItFake) {
            this.uploadFile(file);
          }
        }
      })
    );
    this.displayMessage.toUpperCase();
    const isFake = this.uploadService.getUploadType();
    this.interval = setInterval(() => {
      // keep the progress bar on loading till either the process is complete or cancelled
      if (this.progress <= 114) {
        let currProg = this.progress + 1;
        currProg = currProg < 105 && this.uploadCompleted ? 105 : currProg;
        this.progress = currProg === 114 && !this.uploadCompleted && !isFake ? currProg - 1 : currProg;
      } else if (this.progress >= 114) {
        // when completed reset everything.
        if (this.requiredInfo && this.requiredInfo.fileType === 'RISKS_FILE') {
          this.uploadService.setUploadState('');
        } else {
          this.uploadService.setUploadState(UploadStateEnum.UPLOAD_SUCCESSFUL);
        }
        if (!this.uploadCompleted) {
          this.uploadService.setProcessComplete(true);
        }
        this.resetData();
        clearInterval(this.interval);
      }
    }, 50);
  }
  closeModal(): void {
    this.closeModalClicked.emit(true);
  }

  /**
   * Resetting data variables for another upload process.
   */
  resetData(): void {
    // this.uploadService.setProcessComplete(false);
    this.uploadCompleted = false;
    this.progress = 0;
  }

  async uploadFile(file) {
    this.displayMessage = file && file.name ? file.name : 'Uploading File/Files';
    const fileInfo = {
      id: uuid.v4(),
      assessmentId: this.requiredInfo.assessmentId,
      body: file,
      name: file.name,
      fileType: this.requiredInfo.fileType,
    };
    const upload = await this.fileService.uploadToS3(fileInfo);
    if (upload) {
      this.uploadService.setProcessComplete(true);
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach(listener => listener.unsubscribe());
    clearInterval(this.interval);
  }
}
