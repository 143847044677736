/**
 * This function will return the log message based on the key
 * @param key: string - tells which log should be returned
 * @param string1
 * @param string2
 * @param string3
 * @param number1
 * @returns log message
 */
export function getLogMessage(
  key: string,
  string1?: string,
  string2?: string,
  string3?: string,
  number1?: number
): string {
  const logsHash = {
    YES_ANSWER: `${string1} answered YES.`,
    NO_ANSWER: `${string1} answered NO.`,
    NA_ANSWER: `${string1} answered NA.`,
    REMOVE_ANSWER: `${string1} removed answer.`,
    PARTIAL_ANSWER: `${string1} answered PARTIAL ${number1} on this question.`,
    SAVE_COMMENT: `${string1} added a comment.`,
    EDIT_COMMENT: `${string1} edited ${number1 > 1 ? number1 + ' comments' : 'a comment'}.`,
    DELETE_COMMENT: `${string1} deleted ${number1 > 1 ? number1 + ' comments' : 'a comment'}.`,
    SAVE_AUDITOR_COMMENT: `${string1} added a comment as an auditor.`,
    EDIT_AUDITOR_COMMENT: `${string1} edited ${number1 > 1 ? number1 + ' comments' : 'a comment'} as an auditor.`,
    DELETE_AUDITOR_COMMENT: `${string1} deleted ${number1 > 1 ? number1 + ' comments' : 'a comment'} as an auditor.`,
    MANAGER_APPROVAL: `${string1} approved as a manager.`,
    MANAGER_DENIAL: `${string1} denied as a manager.`,
    MANAGER_EXCLUDE: `${string1} excluded this question as a manager.`,
    ARTIFACT_DOWNLOAD: `${string1} downloaded ${number1 > 1 ? number1 + ' artifacts' : 'an artifact'}.`,
    ARTIFACT_DELETE: `${string1} deleted ${number1 > 1 ? number1 + ' artifacts' : 'an artifact'}.`,
    ARTIFACT_UPLOAD: `${string1} uploaded ${number1 > 1 ? number1 + ' artifacts' : 'an artifact'}.`,
    STRONG_AUDIT: `${string1} marked this question Strong as an auditor.`,
    SATISFACTORY_AUDIT: `${string1} marked this question Satisfactory as an auditor.`,
    NEED_IMPROVEMENT_AUDIT: `${string1} required improvements on this question as an auditor.`,
    WEAK_AUDIT: `${string1} marked this question Weak as an auditor.`,
    UPDATE_AUDIT_STATUS: `${string1} updated this question from ${string2} to ${string3} as an auditor.`,
    QUESTION_TYPE: `${string1} changed question type to ${string2}.`,
    ARTIFACT_SETTINGS: `${string1} set artifact status to ${string2}.`,
    MANAGER_APPROVAL_SETTINGS: `${string1} set manager approval to ${string2}.`,
    UPDATE_VERSION_FREQUENCY: `${string1} updated version ${number1 > 1 ? 'frequencies' : 'frequency'}.`,
    ADD_TAG: `${string1} added ${number1 > 1 ? number1 + ' tags' : 'a tag'}.`,
    REMOVED_TAG: `${string1} removed ${number1 > 1 ? number1 + ' tags' : 'a tag'}.`,
    UPDATE_INTEGRATION_SETTING: `${string1} ${string2} integration for this question.`,
    UPDATE_SMART_MAPPING_SETTING: `${string1} set smart mapping ${string2} for this question.`,
    SELF_ASSIGNMENT: `${string1} self-assigned this question.`,
    ADDED_ASSIGNMENT: `${string1} assigned ${string2} on this question.`,
    REMOVED_ASSIGNMENT: `${string1} removed ${string2} from this question.`,
    CONFIRM_ASSESSMENT: `${string1} confirmed re-assessment on this question.`,
    UNCONFIRM_ASSESSMENT: `${string1} un-checked re-assessment on this question.`,
    REMOVE_STATUS: `${string1} removed Audit Status.`,
    UPDATE_INFO_SEC: `${string1} set Info Sec mapping ${string2} for this question.`,
    COMMENTS_SETTINGS: `${string1} set comments setting to ${string2}`,
    REMEDIATE_TASK: `This task was remediated by ${string1}`,
    SAVE_RISK_COMMENT: `${string1} added a comment.`,
    EDIT_RISK_COMMENT: `${string1} edited ${number1 > 1 ? number1 + ' comments' : 'a comment'}.`,
    DELETE_RISK_COMMENT: `${string1} deleted ${number1 > 1 ? number1 + ' comments' : 'a comment'}.`,
    ESCALATE_RISK: `${string1} escaleted this risk to ${string2} due to ${string3}`,
    DE_ESCALATE_RISK: `${string1} de-escaleted this risk`,
    ARCHIVE_RISK: `${string1} archived this risk`,
    REACTIVATE_RISK: `${string1} re-activated this risk`,
    STATUS_CHANGED_RISK: `${string1} changed the status to  ${string2}`,
    DISCRIPTION_CHANGED_RISK: `${string1} updated the description`,
    TAGS_ADDED_RISK: `${string1} added some tags to this risk`,
    ASSESTS_ADDED_RISK: `${string1} added some assets to this risk`,
    OWNER_ASSIGN_RISK: `${string1} assign owner to this risk`,
    OWNER_UN_ASSIGN_RISK: `${string1} delete owner from this risk`,
  };
  return logsHash[key] ? logsHash[key] : `${string1} answered ${key}`;
}
