import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, SvgIconModule],
  entryComponents: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
