import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'cygov-download-existing-shared-modal',
  templateUrl: './download-existing-shared-modal.component.html',
  styleUrls: ['./download-existing-shared-modal.component.scss'],
})
export class DownloadExistingSharedModalComponent implements OnInit, OnChanges {
  @Input() allFiles;
  @Input() assessmentValue = 0;
  @Input() totalCounter = 0;
  @Input() controlName: string;
  @Output()
  closeDownloadModalClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() startDownloading: EventEmitter<boolean> = new EventEmitter<boolean>();

  showConfirmDialogBox = true;
  confirmDownloadFlag = false;
  showSuccessMessage = false;
  allFileNames: Array<string> = new Array<string>();

  constructor() {}

  ngOnInit() {
    for (const file of this.allFiles) {
      if (file.selected) {
        this.allFileNames = this.allFileNames.concat(file.fileName.split(','));
      }
    }
  }

  ngOnChanges() {
    if (this.assessmentValue === 100) {
      this.downloadCompleted();
    }
  }

  closeDeleteModal() {
    this.showConfirmDialogBox = true;
    this.confirmDownloadFlag = false;
    this.showSuccessMessage = false;

    this.closeDownloadModalClicked.emit(true);
  }

  downloadFiles() {
    this.startDownloading.emit(true);
    this.showConfirmDialogBox = false;
    this.confirmDownloadFlag = true;
  }

  downloadCompleted() {
    setTimeout(() => {
      this.confirmDownloadFlag = false;
      this.showSuccessMessage = true;
    }, 700);
  }
}
