<div class="file-icon-button">
  <cygov-file-icon class="abs-centre" (click)="openExistingModal(existingFilesPopup)" [circleBgColor]="iconColor"
    ngbTooltip="Existing files" placement="top" [totalFiles]="filesList.length"></cygov-file-icon>
</div>

<ng-template #existingFilesPopup>
  <div *cygovLoader="false" class="existing-file-screen">
    <div class="entity-card">
      <div class="header">
        <div>
          <span (click)="closeModal()" class="lato-16-n-vw back-button">
            < Back </span>
        </div>
        <div class="header-tools">
          <span class="lato-18-n-vw existing-files">Existing Files</span>
          <div class="header-right-tools">
            <div class="search-and-filters">
              <div class="search-wrapper">
                <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
                <input type="text" class="form-control search" aria-label="Search"
                  placeholder="Search" name="search" (value)="(queryText)" (keyup)="onKeyUp()"
                  (input)="filterChanged($event, 'search')" spellcheck="true" />
              </div>
              <cygov-select class="filter-select" [items]="filterOptions" placeholder="All"
                (ngModelValueChange)="filterChanged($event, 'select')"></cygov-select>
            </div>
          </div>
        </div>
      </div>
  
      <div class="body">
        <table>
          <thead>
            <tr class="lato-16-b-vw">
              <th></th>
              <th>File Name</th>
              <th>Upload Date</th>
              <th>Processed</th>
              <th>User Name</th>
            </tr>
          </thead>
          <tbody>
            <ng-container class="table-body" *ngFor="let files of filteredFiles; let i = index;">
              <tr [ngClass]="{
                'table-body': files.selected === false,
                'table-body-selected-item': files.selected === true
              }">
                <td *ngIf="isSelectable" class="ticked-column">
                  <div [ngClass]="{
                      'status-circle-1': files.selected === false,
                      'status-circle-selected-item': files.selected === true
                    }" (click)="selectedRow(i)">
                    <ng-container *ngIf="files.selected">
                      <div class="status-marker" cygovSvgIcon [svgName]="'right-tick'"></div>
                    </ng-container>
                  </div>
                </td>
                <td class="lato-14-l-vw fileName">{{ files.fileName }}</td>
                <td class="lato-14-l-vw">{{ files.uploadDate | date: 'dd.MM.yy'}} </td>
                <td class="lato-14-l-vw">
                  <div class="status-circle">
                    <div class="status-marker" cygovSvgIcon [svgName]="'right-tick'"></div>
                  </div>
                </td>
                <td class="lato-14-l-vw">{{ files.userName }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
  
      <div class="footer" *ngIf="filteredFiles.length && isSelectable">
        <div class="lato-14-n-vw ver-sep-ter"></div>
        <div class="footer-buttons">
          <div *ngIf="showDownload" class="download-button-icon" (click)="openDownloadModal()" cygovSvgIcon
            [svgName]="'download-exfiles-icon'" placement="top" ngbTooltip="Download Files"></div>
          <div *ngIf="showDelete" class="delete-button-icon" (click)="openDeleteModal()" cygovSvgIcon
            [svgName]="'trash-icon'" placement="top" [ngbTooltip]="!deleteModalFlag?'Delete Files':''"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="delete-modal-screen" *ngIf="deleteModalFlag">
    <div class="modal-overlay">
      <div class="modal-body">
        <div class="modal-header">
          <div (click)="closeDeleteModal()" class="close-icon" cygovSvgIcon
            [svgName]="'x-icon-type2'">
          </div>
        </div>
        <div *ngIf="this.filteredFiles.length!==0 && totalSelected > 0;else showNoFilesMessage"
          class="modal-delete-content">
          <div class="icon" cygovSvgIcon [svgName]="'trash-icon-3'"></div>
          <span>
            Please note you are about to delete
            <span class="blue-txt">{{totalSelected}}</span>
            file<ng-container *ngIf="totalSelected>1">s</ng-container>
          </span>
  
          <div class="button-row">
            <button type="button"
              class="btn btn-primary uppercase add-entity-btn btnVendor lato-14-n-vw"
              (click)="deleteAllFiles()">
              Confirm
            </button>
            <button type="button"
              class="btn btn-primary uppercase add-entity-btn btnVendor transparent-background lato-14-n-vw"
              (click)="closeDeleteModal()">
              Cancel
            </button>
          </div>
        </div>
        <ng-template #showNoFilesMessage>
          <div class="no-files">
            <span class="lato-20-b-vw">No Files to delete</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="downloadModalFlag" class="pos-abs">
    <cygov-download-existing-shared-modal [allFiles]="filteredFiles" [totalCounter]="totalSelected" [assessmentValue]="progressD"
      [controlName]="controlName" (startDownloading)="downloadFile()"
      (closeDownloadModalClicked)="closeDownloadModal($event)">
    </cygov-download-existing-shared-modal>
  </div>
</ng-template>
