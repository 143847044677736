import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  APIService,
  CreateRoleMutation,
  GetRoleQuery,
  RoleEnum,
  GetEntityQuery,
  EntityTypeEnum,
} from 'app/API.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { UsersSettingConstant } from './users-settings.constant';
import { EntityService } from './../shared/entity.service';
import { CUSTOMAPIService } from 'app/custom-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersSettingsService {
  updatedUsersList: any[] = [];
  isEditMode = new BehaviorSubject<boolean>(false);
  isAllSelected = new BehaviorSubject<boolean>(false);
  currEntRoles = new Subject<GetRoleQuery[]>();
  currEntRolesList = [];
  currEntityId: string = '';
  prevEntityId: string = '';
  isAddNewUSerModalOpened = new BehaviorSubject<boolean>(false);
  UsersSettingConstant = UsersSettingConstant;
  roleRanks = {
    admin: 1,
    mssp: 1,
    enterprise: 1,
    'region leader': 1,
    'division leader': 1,
    'business entity admin': 2,
    'entity leader': 2,
    'sub-entity leader': 3,
    participant: 4,
  };
  // Here we have All Entities ( Root + child Entity) against current domain
  // map Global Entities list as {rootId:{rootEntity,childEntities}}
  globalEntities: any = {};

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private entityService: EntityService,
    private customApi: CUSTOMAPIService
  ) {
    this.currEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.prevEntityId = this.currEntityId;
  }

  setIsAddNewUSerModalOpened(value: boolean): void {
    this.isAddNewUSerModalOpened.next(value);
  }

  getIsAddNewUSerModalOpened(): Observable<boolean> {
    return this.isAddNewUSerModalOpened;
  }

  setIsEditMode(value: boolean): void {
    this.isEditMode.next(value);
  }

  getIsEditMode(): Observable<boolean> {
    return this.isEditMode;
  }

  setCurrEntity(entityId): void {
    this.currEntityId = entityId;
  }

  setIsAllSelected(value: boolean): void {
    this.isAllSelected.next(value);
  }

  getIsAllSelected(): Observable<boolean> {
    return this.isAllSelected;
  }

  addUserToUpdateList(user: any, isSelected: boolean): void {
    const index = this.updatedUsersList.findIndex(u => u.id === user.id);
    if (!(typeof user.role === 'string') && index > -1) {
      const role = this.updatedUsersList[index].role;
      const tmpRole = this.currEntRolesList.find(Role => Role.id === (typeof role === 'string' ? role : role?.id));
      user.role = tmpRole;
      user.roleId = this.updatedUsersList[index].roleId;
    }
    if (index > -1) {
      this.updatedUsersList[index] = { ...user, isSelected };
    } else {
      this.updatedUsersList.push({ ...user, isSelected });
    }
  }

  removeUserToUpdateList(user: any): void {
    const index = this.updatedUsersList.findIndex(u => u.id === user.id);
    if (index > -1) {
      this.updatedUsersList.splice(index, 1);
    }
  }

  getRoles(): GetRoleQuery[] {
    return this.currEntRolesList;
  }

  setRoles(roles): void {
    this.currEntRolesList = roles;
    this.currEntRoles.next(roles);
  }

  setRolesInCurrentEntity(roles): void {
    this.currEntRolesList = roles;
  }

  // All the roles against the current Entity
  getRolesInCurrentEntity(): GetRoleQuery[] {
    return this.currEntRolesList;
  }

  updateIsSelected(user: any): void {
    const index = this.updatedUsersList.findIndex(u => u.id === user.user.id);
    if (index > -1) {
      this.updatedUsersList[index].isSelected = user.isSelected;
    }
  }
  getUpdatedUsersList(): any[] {
    return this.updatedUsersList;
  }
  async createUpdateRole(role?: any, toCreate: boolean = true): Promise<CreateRoleMutation> {
    try {
      let createdOrUpdatedRole;
      if (!toCreate) {
        createdOrUpdatedRole = await this.customApi.UpdateRole(role);
        this.toastr.success('Role Updated Successfully.');
      } else if (toCreate && UtilsService.isDefined(role)) {
        createdOrUpdatedRole = await this.customApi.CreateRole(role);
        this.toastr.success('Role Created Successfully.');
      }
      if (createdOrUpdatedRole) {
        if (this.isGlobalUserSettings()) {
          this.updateCurrentRolesList(createdOrUpdatedRole);
        } else {
          const customRoles = await this.userService.getRolesByDefaultOrEntityId(this.currEntityId, false);
          const defaultRoles = await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
          this.setRoles([...customRoles, ...defaultRoles]);
        }
      }
      return createdOrUpdatedRole;
    } catch (error) {
      console.log('Error createRole ===>', error);
      this.toastr.error('Could not create Role.');
    }
  }
  async deleteRoles(roleId: string): Promise<any> {
    try {
      this.toastr.info('Deleting Role.');
      const response = await this.customApi.DeleteRole({ id: roleId });
      if (response) {
        this.toastr.success('Role deleted successfully.');
        this.currEntRolesList = this.currEntRolesList.filter(role => role?.id !== response?.id);
        this.currEntRoles.next(this.currEntRolesList);
      }
    } catch (error) {
      this.toastr.error('Could not delete role.');
    }
  }

  async filterRolesByUser(roles?: GetRoleQuery[], user?) {
    const isEntityChanged = this.prevEntityId !== this.currEntityId;
    if (isEntityChanged) {
      this.prevEntityId = this.currEntityId;
    }
    // fetch all roles
    if (isEntityChanged || !roles || !roles.length) {
      const customRoles = await this.userService.getRolesByDefaultOrEntityId(this.currEntityId, false);
      let defaultRoles = await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
      if (UtilsService.isBnB) {
        defaultRoles = defaultRoles.filter(
          role => role.name?.toLowerCase() !== AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase()
        );
      }
      this.setRoles([...customRoles, ...defaultRoles]);
    }

    // get users if not given through param
    roles = roles ? roles : this.getRoles();
    // get user if not given through param
    user = user ? user : await this.userService.getCurrentUser();
    const appLevelRolesIds = roles.map(role => {
      if (role?.defaultOrEntityId && role?.defaultOrEntityId?.toLowerCase() === 'default') {
        return role.id;
      }
    });
    const isCustomUser = !appLevelRolesIds.includes(user.roleId);
    let options: GetRoleQuery[] = [];
    const roleName = roles.find(role => role.id === user.roleId)?.name?.toLowerCase();
    if (!isCustomUser) {
      options = UtilsService.isBnB
        ? this.filterRolesByCurrentRoleBnB(roles, user)
        : this.filterRolesByCurrentRole(roles, user);
    } else {
      options.push(...roles.filter(role => !appLevelRolesIds.includes(role.id)));
    }
    return { userRole: roleName, privileges: options };
  }

  /**
   * helper function for filtering the roles list based on current role normal flow.
   * @param roles is the list of roles to be filtered
   * @param user is the currently logged in user
   * @returs the list of updated roles.
   */
  filterRolesByCurrentRole(roles: GetRoleQuery[], user): GetRoleQuery[] {
    const options = [];
    const roleName = roles.find(role => role.id === user.roleId)?.name?.toLowerCase();
    if (roleName === AppLevelRoleEnum.ADMIN) {
      options.push(...roles);
    } else if (roleName === AppLevelRoleEnum.MSSP) {
      options.push(...roles);
      const index = options.findIndex(option => option.name.toLowerCase() === AppLevelRoleEnum.ADMIN);
      options.splice(index, 1);
    } else if (roleName === AppLevelRoleEnum.ENTITY_LEADER) {
      options.push(
        ...roles.filter(
          role =>
            role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER ||
            role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER ||
            role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT ||
            role?.name?.toLowerCase() === AppLevelRoleEnum.VENDOR ||
            role?.defaultOrEntityId?.toLowerCase() !== 'default'
        )
      );
    } else if (roleName === AppLevelRoleEnum.SUBENTITY_LEADER) {
      options.push(
        ...roles.filter(
          role =>
            role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER ||
            role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT ||
            role?.defaultOrEntityId?.toLowerCase() !== 'default'
        )
      );
    } else if (roleName === AppLevelRoleEnum.PARTICIPANT) {
      options.push(...roles.filter(role => role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT));
    }
    return options;
  }

  /**
   * This function will add new role or update the existing one locally
   * @param item - new/updated role
   */
  updateCurrentRolesList(item: GetRoleQuery): void {
    const index = this.currEntRolesList.findIndex(role => role.id === item.id);
    if (index > -1) {
      this.currEntRolesList[index] = item;
    } else {
      this.currEntRolesList.push(item);
    }
    this.currEntRoles.next(this.currEntRolesList);
  }

  /**
   * helper function for filtering the roles list based on current role bnb flow.
   * @param roles is the list of roles to be filtered
   * @param user is the currently logged in user
   * @returs the list of updated roles.
   */
  filterRolesByCurrentRoleBnB(roles: GetRoleQuery[], user): GetRoleQuery[] {
    const options = [];
    const roleName = roles.find(role => role.id === user.roleId)?.name?.toLowerCase();
    if (roleName === AppLevelRoleEnum.ADMIN) {
      options.push(...roles);
    } else if (
      roleName === AppLevelRoleEnumBnB.ENTERPRISE ||
      roleName === AppLevelRoleEnumBnB.DIVISION_LEADER ||
      roleName === AppLevelRoleEnumBnB.REGION_LEADER
    ) {
      options.push(...roles);
      const index = options.findIndex(option => option.name.toLowerCase() === AppLevelRoleEnum.ADMIN);
      options.splice(index, 1);
    } else if (roleName === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN) {
      options.push(
        ...roles.filter(
          role =>
            role?.name?.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN ||
            role?.name?.toLowerCase() === AppLevelRoleEnumBnB.SUBENTITY_LEADER ||
            role?.name?.toLowerCase() === AppLevelRoleEnumBnB.PARTICIPANT ||
            role?.defaultOrEntityId?.toLowerCase() !== 'default'
        )
      );
    } else if (roleName === AppLevelRoleEnum.SUBENTITY_LEADER) {
      options.push(
        ...roles.filter(
          role =>
            role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER ||
            role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT ||
            role?.defaultOrEntityId?.toLowerCase() !== 'default'
        )
      );
    } else if (roleName === AppLevelRoleEnum.PARTICIPANT) {
      options.push(...roles.filter(role => role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT));
    }
    return options;
  }

  // temporary method will be removed after the connection.
  isBuiltInRole(role: string): boolean {
    const tmpArr = Object.values(RoleEnum);
    return tmpArr.some(ele => ele.toLowerCase() === role.toLowerCase());
  }

  async isRoleDeleteAble(roleId: string): Promise<boolean> {
    try {
      const result = await this.customApi.UserByRoleId(roleId);
      if (result && result.items) {
        return result.items.length <= 0;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  /**
   *  Compare current role with given role and if current role is greater then given
   */

  isCurrentRoleManageGivenRole(currentRole: GetRoleQuery, givenRole: GetRoleQuery) {
    if (currentRole && currentRole.id && givenRole && givenRole.id) {
      if (
        currentRole.id === givenRole.id &&
        currentRole.name &&
        (currentRole.name.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
          currentRole.name.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase() ||
          currentRole.name.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase())
      ) {
        // Users with same role can not manage to each other unless role is either ADMIN , MSSP or Entity Leader
        return true;
      }
      if (givenRole.name.toLowerCase() === AppLevelRoleEnum.VENDOR.toLowerCase()) {
        // Ven
        return false;
      }
      // if both roles are default roles.
      if (this.roleRanks[currentRole.name.toLowerCase()] && this.roleRanks[givenRole.name.toLowerCase()]) {
        return this.roleRanks[currentRole.name.toLowerCase()] < this.roleRanks[givenRole.name.toLowerCase()];
      }
      // if Both roles are Custom roles
      if (!this.roleRanks[currentRole.name.toLowerCase()] && !this.roleRanks[givenRole.name.toLowerCase()]) {
        // As in new requirements custom role with Root Entity access can manage same level roles users
        if (currentRole.isRootEntityAccess) {
          return true;
        }
        return false;
      }
      // if current role is custom role and given role is default role
      if (!this.roleRanks[currentRole.name.toLowerCase()] && this.roleRanks[givenRole.name.toLowerCase()]) {
        const customRoleRank = currentRole.isRootEntityAccess ? 2 : 3;
        // = sign is added as custom role with entity access can manage same level role user
        return customRoleRank <= this.roleRanks[givenRole.name.toLowerCase()];
      }
      // if current role is Default role and given role is custom role.
      if (this.roleRanks[currentRole.name.toLowerCase()] && !this.roleRanks[givenRole.name.toLowerCase()]) {
        const givenRoleRank = givenRole.isRootEntityAccess ? 2 : 3;
        // = sign is added as custom role with entity access can manage same level role user
        return this.roleRanks[currentRole.name.toLowerCase()] <= givenRoleRank;
      }
    }
    return false;
  }

  /**
   * set Entities against current Domain.
   * Set Entities so that we can use it. for global Users Setting. and avoid multiple API's calls.
   */
  setGlobalEntities(entities: GetEntityQuery[]): void {
    // map Global Entities list as [{rootEntity,childEntities}....]
    entities.map(rootEntity => {
      this.globalEntities[rootEntity.id] = { rootEntity, childEntities: null };
    });
  }

  async getGlobalRoles(entities: GetEntityQuery[]): Promise<GetRoleQuery[]> {
    try {
      const promises: any = [];
      entities.map(entity => {
        promises.push(this.userService.getRolesByDefaultOrEntityId(entity.id, false));
      });
      promises.push(this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true));
      return await Promise.all(promises);
    } catch (e) {
      console.log('Get Global Roles : ', e);
    }
  }

  getGlobalEntities(): any[] {
    return this.globalEntities ? this.globalEntities : [];
  }
  /**
   *parsing root entities from global entities
   * @returns list of root entities
   */
  getGlobalRootEntities(): any[] {
    const rootEntities = [];
    Object.keys(this.globalEntities).map(key => {
      if (this.globalEntities[key].rootEntity) {
        rootEntities.push(this.globalEntities[key].rootEntity);
      }
    });
    return rootEntities;
  }
  async getRootAndChildEntities(entityId: string, entityType: EntityTypeEnum): Promise<any> {
    try {
      if (entityType === EntityTypeEnum.ROOT_ENTITY) {
        if (this.globalEntities[entityId]) {
          if (!this.globalEntities[entityId].childEntities) {
            const childEntities = await this.entityService.listChildEntitysByParentId(entityId);
            this.globalEntities[entityId].childEntities = childEntities && childEntities.length ? childEntities : [];
          }
          return this.globalEntities[entityId];
        }
      } else if (entityType === EntityTypeEnum.CHILD_ENTITY) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (const key of Object.keys(this.globalEntities)) {
          if (
            this.globalEntities[key] &&
            this.globalEntities[key].childEntities &&
            this.globalEntities[key].childEntities.length
          ) {
            const index = this.globalEntities[key].childEntities.findIndex(entity => entity.id === entityId);
            if (index !== -1) {
              return this.globalEntities[key];
            }
          }
        }
        const childEntity = await this.customApi.GetEntity(entityId);
        if (childEntity && childEntity.parentId) {
          const childEntities = await this.entityService.listChildEntitysByParentId(childEntity.parentId);
          if (this.globalEntities[childEntity.parentId]) {
            this.globalEntities[childEntity.parentId].childEntities = childEntities;
          } else {
            const rootEntity = await this.customApi.GetEntity(childEntity.parentId);
            this.globalEntities[childEntity.parentId] = { rootEntity, childEntities };
          }

          return this.globalEntities[childEntity.parentId];
        }
      }
      return { rootEntity: null, childEntities: null };
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * helper function to check for global user settings
   */
  isGlobalUserSettings(): boolean {
    return this.router.url.includes('clients');
  }
}
