<div class="app-component" *ngIf="isAuthenticated; else routerOutlet">
  <cygov-header></cygov-header>
  <div class="main-layout">
    <cygov-sidenav class="side-nav-relative" *ngIf="showSidenav && !isOnboarding"></cygov-sidenav>
    <div class="main-content" [ngClass]="{ 'full-width': !showSidenav || isOnboarding }">
      <router-outlet></router-outlet>
      <cygov-footer *ngIf="!isOnboarding" [showLegend]="showLegend"></cygov-footer>
    </div>
  </div>
</div>

<ng-template #routerOutlet>
  <div class="app-component">
    <div class="main-layout">
      <div class="main-content full-width">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>