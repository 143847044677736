import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UploadFileService } from '../../upload-file.service';

@Component({
  selector: 'cygov-upload-success',
  templateUrl: './upload-success.component.html',
  styleUrls: ['./upload-success.component.scss'],
})
export class UploadSuccessComponent implements OnInit {
  @Output() closeModalClicked: EventEmitter<any> = new EventEmitter();

  constructor(private uploadService: UploadFileService) {}

  ngOnInit() {}

  closeModal(): void {
    const isFake = this.uploadService.getUploadType();
    if (isFake) {
      this.uploadService.getFile().subscribe(file => {
        if (file) {
          this.closeModalClicked.emit(file);
        }
      });
    } else {
      this.closeModalClicked.emit(true);
    }
  }
}
