const breadcrumbLinks = {
  overview: [
    {
      text: 'board',
      link: 'overview',
    },
  ],
  posture: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'posture',
      link: 'posture',
    },
  ],
  matrix: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: '4D risk matrix',
      link: 'matrix',
    },
  ],
  attacks: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'monitoring',
      link: 'monitoring',
    },
    {
      text: 'attacks',
      link: 'attacks',
    },
  ],
  budget: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'operational',
      link: 'operational',
    },
    {
      text: 'budget',
      link: 'budget',
    },
  ],
  'major-initiatives': [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'operational',
      link: 'operational',
    },
    {
      text: 'major initiatives',
      link: 'major-initiatives',
    },
  ],
  'risk-score': [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'risk score',
      link: 'risk-score',
    },
  ],
  'risk-posture': [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'risk score',
      link: 'risk-score',
    },
    {
      text: 'posture',
      link: 'posture',
    },
  ],
  'risk-matrix': [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'risk score',
      link: 'risk-score',
    },
    {
      text: '4D risk matrix',
      link: 'matrix',
    },
  ],
  compliance: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'compliance status',
      link: 'compliance',
    },
  ],
  'threat-level': [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'threat level',
      link: 'threat-level',
    },
  ],
  monitoring: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'monitoring',
      link: 'monitoring',
    },
  ],
  operational: [
    {
      text: 'board',
      link: 'overview',
    },
    {
      text: 'operational',
      link: 'operational',
    },
  ],
};

export default breadcrumbLinks;
