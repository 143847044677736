import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public message = 'Page Not Found!';

  constructor(private userService: UserService) {}

  ngOnInit() {}

  async navigateHome() {
    await this.userService.navigateByRole();
  }
}
