import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cygov-framework-modal',
  templateUrl: './framework-modal.component.html',
  styleUrls: ['./framework-modal.component.scss'],
})
export class FrameworkModalComponent implements OnInit {
  @Output() modalResult: EventEmitter<any> = new EventEmitter<any>();
  @Input() frameworkList = [];
  @Input() modalInstance: any = null;
  @Input() selectedFramework: string = '';
  filteredFrameworks = [];

  constructor() {}

  ngOnInit(): void {
    this.sortFrameworkList();
    if (!this.selectedFramework) {
      this.frameworkList.forEach((framework, index) => {
        if (!index) {
          framework.selected = true;
        } else {
          framework.selected = false;
        }
      });
    } else {
      this.frameworkList.forEach(framework => {
        if (this.selectedFramework === framework.key) {
          framework.selected = true;
        } else {
          framework.selected = false;
        }
      });
    }
    this.filteredFrameworks = [...this.frameworkList];
  }
  sortFrameworkList() {
    this.frameworkList.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  filterFramework(event: any) {
    const pressedKey = event.target.value;
    if (pressedKey) {
      this.filteredFrameworks = [...this.frameworkList];
      this.filteredFrameworks = this.filteredFrameworks.filter(framework => {
        return framework.name.toLowerCase().includes(pressedKey.toLowerCase());
      });
    } else {
      this.filteredFrameworks = this.frameworkList;
    }
  }

  closeModal() {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }
  selectFramework(framework) {
    this.selectedFramework = framework;
    this.modalResult.emit(framework);
  }
}
