export enum ProgressStatusEnum {
  ON_SCHEDULE = 'On Schedule',
  SLIGHTLY_BEHIND_SCHEDULE = 'Slightly Behind Schedule',
  BEHIND_SCHEDULE = 'Behind Schedule',
}
export enum RouteEnum {
  CAPABILITIES = 'capabilities',
  TOOLS = 'tools',
  COMPUTER_TICKETS = 'computer-tickets',
  VENDOR_RISK = 'vendor-risk',
}
export enum FilterEnum {
  HIGH = 'high',
  LOW = 'low',
}
export enum TabSections {
  NEW = 'new-close',
  OPEN = 'open-close',
  CLOSING = 'closing-pace-ratio',
  PRIVACY = 'privacy-check',
  DILIGENCE = 'diligence-party',
}
export enum RadioFilterMode {
  TOTAL = 0,
  CSRIT = 1,
  PSIRT = 2,
  ALL = 3,
  MANUAL_QUESTIONNAIRES = 1,
  THIRDPARTYTRUST = 2,
}
export enum TicketStatus {
  CLOSE = 'closed',
  OPEN = 'opened',
}

export enum NetskopeFileEnum {
  NETSKOPE_MATURITY_DATA = 'NETSKOPE_MATURITY_DATA',
  NETSKOPE_OVERVIEW_DATA = 'NETSKOPE_OVERVIEW_DATA',
}
