<div class="cygov-select"
  [ngClass]="{ disabled: disabled , 'hide-arrow':customArrow || hideArrow }">
  <div class="overlay-div" *ngIf="isDropDownOpen"></div>
  <div *ngIf="customArrow" class="arrow-wrapper drop-down-arrow" (click)="openSelect()"
    [ngClass]="{'dropdown-icon': isDropDownOpen}">
    <div class="arrow-icon" cygovSvgIcon [svgName]="'arrow-right-type3'"
      [ngClass]="{'expandClass':isDropDownOpen , 'arrow-icon': !largeArrow, 'large-arrow-icon': largeArrow}">
    </div>
  </div>
  <div [ngClass]="{'change-text-color': !standardSelect}" class='ng-select-cont'>
    <ng-select [dropdownPosition]="position" #selectTag
      *ngIf="!multiple && !singleSelection && !isImageControl" class="capitalize" autocomplete="off"
      [appendTo]="appendTo" [(ngModel)]="ngModelValue" [items]="items" [placeholder]="placeholder"
      [bindLabel]="bindLabel" [bindValue]="bindValue" [clearable]="clearable"
      [searchable]="searchable" [disabled]="disabled" [compareWith]="compareWith"
      (ngModelChange)="ngModelValueChange.emit(ngModelValue)" [searchFn]="searchFn"
      (open)="selectOpened.emit(selectTag) ; isDropDownOpen=true"
      (close)="isDropDownOpen=false; ngModelValue=fromRiskRegister?previousOption:ngModelValue"
      (click)="triggerOnSameOption ? isSameOptionSelected(ngModelValue) : null">

      <ng-template *ngIf="customTemplate  && highlightLabel" ng-label-tmp let-item="item">
        <b>{{item.label}}</b>
      </ng-template>
      <ng-template *ngIf="customTemplate  && highlightLabel" ng-option-tmp let-item="item">
        <div [ngClass]="{'disableOption': item.disableHighlight}">
          <b>{{item.label}}</b>
        </div>
      </ng-template>
      <ng-template *ngIf="customTemplate  && replacePipe" ng-label-tmp let-item="item">
        <b *ngFor="let key of labelKeys">{{ item[key] | replace:'_':' ' }}</b>
      </ng-template>
      <ng-template *ngIf="customTemplate  && !replacePipe" ng-label-tmp let-item="item">
        <b *ngFor="let key of labelKeys">{{ item[key] }}</b>
      </ng-template>
      <ng-template *ngIf="customTemplate" ng-option-tmp let-item="item" let-index="index">
        <b *ngFor="let key of optionKeys">{{ item[key] }}</b>
      </ng-template>
      <ng-template *ngIf="!customTemplate && replacePipe" ng-option-tmp let-item="item"
        let-index="index">
        <b>{{ item | replace: replaceSearchValue : replaceValue }} </b>
      </ng-template>
      <ng-template *ngIf="!customTemplate && replacePipe" ng-label-tmp let-item="item"
        let-index="index">
        <b>{{ item | replace: replaceSearchValue : replaceValue }} </b>
      </ng-template>
    </ng-select>

    <ng-select #selectTag *ngIf="(multiple || singleSelection) && !isImageControl"
      class="capitalize" [appendTo]="appendTo" [(ngModel)]="ngModelValue" [items]="items"
      [placeholder]="placeholder" [bindLabel]="bindLabel" [bindValue]="bindValue"
      [groupBy]="groupBy" [multiple]="multiple" [clearable]="clearable" [searchable]="searchable"
      [searchFn]="searchFn" [disabled]="disabled" [compareWith]="compareWith"
      (ngModelChange)="ngModelValueChange.emit(ngModelValue)" [closeOnSelect]="false"
      (open)="selectOpened.emit(selectTag) ; isDropDownOpen=true"
      (close)="isDropDownOpen=false; closeSelect()">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
        *ngIf="showCheckBox">
        <div class="row">
          <cygov-checkbox class="access-checkbox" [id]="'item-'+index" [isChecked]="item$.selected"
            [height]="12" [width]="12" (changed)="changeValue(item$)"></cygov-checkbox>
          <label class="check-box-label" for="inlineItem"> {{item[bindLabel]}}</label>
        </div>
      </ng-template>
    </ng-select>

    <ng-select #selectTag *ngIf="isImageControl" [items]="items" [bindLabel]="bindLabel"
      [appendTo]="appendTo" [bindValue]="bindValue" [placeholder]="placeholder"
      [clearable]="clearable" [(ngModel)]="ngModelValue" [compareWith]="compareWith"
      (ngModelChange)="ngModelValueChange.emit(ngModelValue)"
      (open)="selectOpened.emit(selectTag) ; isDropDownOpen=true" (close)="isDropDownOpen=false">
      <ng-template ng-label-tmp let-item="item">
        <div *ngIf="!isTextRequired; else imageOrText">
          <img height="25" width="90" [src]="item" />
        </div>
        <ng-template #imageOrText>
          <div *ngIf="!!item.image; else showText">
            <img height="25" width="90" [src]="item.image" />
          </div>
          <ng-template #showText>
            {{item.name}}
          </ng-template>
        </ng-template>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div *ngIf="!isTextRequired; else imageOrText">
          <img height="25" width="90" [src]="item" />
        </div>
        <ng-template #imageOrText>
          <div *ngIf="!!item.image; else showText">
            <img height="25" width="90" [src]="item.image" />
          </div>
          <ng-template #showText>
            {{item.name}}
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-select>
  </div>
</div>