import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserListingComponent } from './user-listing.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
import { UsernameIconModule } from '../username-icon/username-icon.module';
import { CygovButtonModule } from 'app/shared/cygov-button/cygov-button.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [UserListingComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    FormsModule,
    SvgIconModule,
    CheckboxModule,
    UsernameIconModule,
    CygovButtonModule,
  ],
  exports: [UserListingComponent],
})
export class UserListingModule {}
