<div class="upload-content"
    [ngClass]="{'bulk-vendor': uploadProcess === connectionResult.UPLOAD_STARTED && isVendorBulkUpload}"
    [ngSwitch]="uploadProcess">
    <div *ngIf="isHiTrust" class="cross-icon" (click)="closeModal()" cygovSvgIcon
        [svgName]="'x-icon-type2'">
    </div>
    <cygov-upload-template *ngSwitchCase="connectionResult.UPLOAD_NOT_STARTED"
        [uploadPath]="uploadPath" [requiredInfo]="requiredInfo"
        (closeModalClicked)="closeIt($event)" [uploadType]="uploadType"
        [restrictFileDrop]="restrictFileDrop"
        (returnUploadedFiles)="totalUploadedFiles.emit($event)" [multiSelect]="multiSelect">
    </cygov-upload-template>
    <cygov-upload-progress *ngSwitchCase="connectionResult.UPLOAD_STARTED"
        [requiredInfo]="requiredInfo" (closeModalClicked)="closeIt($event)"
        [isVendorBulkUpload]="isVendorBulkUpload" [displayMessage]="displayMessage"
        [loaderType]="loaderType"></cygov-upload-progress>
    <cygov-upload-success *ngSwitchCase="connectionResult.UPLOAD_SUCCESSFUL"
        (closeModalClicked)="closeIt($event)"></cygov-upload-success>
    <cygov-upload-unsuccess *ngSwitchCase="connectionResult.UPLOAD_UNSUCCESSFUL"
        (closeModalClicked)="closeIt($event)"></cygov-upload-unsuccess>
</div>