import { MidmarketWizardPopUpComponent } from './midmarket-wizard-pop-up.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListingModule } from 'app/shared/user-listing/user-listing.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { PipesModule } from '../pipes/pipes.module';
import { EntityProgressBarsModule } from '../entity-progress-bars/entity-progress-bars.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from '../cygov-loader/cygov-loader.module';
import { ToggleButtonModule } from '../toggle-button/toggle-button.module';
import { CygovButtonModule } from 'app/shared/cygov-button/cygov-button.module';
import { SelectModule } from 'app/shared/select/select.module';
import { NgbCalendarModule } from 'app/shared/ngb-calendar/ngb-calendar.module';
import { RouterModule } from '@angular/router';
import { ScoreColorModule } from '../score-color/score-color.module';
import { FormsModule } from '@angular/forms';
import { AddButtonModule } from 'app/shared/add-button/add-button.module';
import { MidMarketFileUploadModule } from '../mid-market-file-upload/mid-market-file-upload.module';

@NgModule({
  declarations: [MidmarketWizardPopUpComponent],
  imports: [
    ProgressBarModule,
    CommonModule,
    PipesModule,
    SvgIconModule,
    UserListingModule,
    CygovLoaderModule,
    EntityProgressBarsModule,
    NgbTooltipModule,
    ToggleButtonModule,
    CygovButtonModule,
    SelectModule,
    FormsModule,
    RouterModule,
    ScoreColorModule,
    AddButtonModule,
    NgbCalendarModule,
    MidMarketFileUploadModule,
  ],
  exports: [MidmarketWizardPopUpComponent],
  entryComponents: [MidmarketWizardPopUpComponent],
})
export class MidmarketWizardPopUpModule {}
