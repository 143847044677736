import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})
export class ModalTemplateComponent {
  title = 'Title';
  customizeHeader = false;
  headerContentRef: TemplateRef<any>;
  contentRef: TemplateRef<any>;
  footerContentRef: TemplateRef<any>;
  crossBtnVisibility = true;
  editModeBorder: boolean = false;
  @Output() modalResult: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  closeModalTemplate(): void {
    this.modalResult.emit();
    this.activeModal.close();
  }
}
