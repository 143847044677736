import { SelectModule } from './../select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddButtonComponent } from './add-button.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SvgIconModule, FormsModule, SelectModule],
  declarations: [AddButtonComponent],
  exports: [AddButtonComponent],
  entryComponents: [AddButtonComponent],
})
export class AddButtonModule {}
