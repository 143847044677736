import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRemoveValue',
})
export class FilterRemoveValuePipe implements PipeTransform {
  transform(items: any[], value: any, column = null): any[] {
    if (!items || !value) {
      return items;
    }
    // array of objects
    if (column) {
      if (!value[column]) {
        return items;
      }
      // remove the given value from list
      return items.filter(item => item[column] !== value[column]);
    } else {
      // array of string or integer etc
      return items.filter(item => item !== value);
    }
  }
}
