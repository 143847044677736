<div *ngIf="loading" class="loader-height">
    <div *cygovLoader="loading"></div>
</div>
<div class="modal-body" *ngIf="!loading">
    <!------------------------------ Header Section -------------------------------->
    <div class="modal-header">
        <div class="capitalize">SETTINGS</div>
    </div>
    <!------------------------------ Body Content Section -------------------------------->
    <div class="modal-main" *cygovLoader="loading">
        <!------------------------------ Assessment & Additional Requirement Section -------------------------------->
        <div class="assessment-section">
            <div class="assessment-header">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                    <li [ngbNavItem]="1" [ngClass]="{'active-link':activeTabId===1}">
                        <a ngbNavLink class="pointertab">Assessment</a>
                        <ng-template ngbNavContent>
                            <!------------------------------------------------------------------------------------>
                            <!-----------------------------------Assessment AREA ---------------------------------->
                            <!------------------------------------------------------------------------------------>
                            <div class="content-body">
                                <cygov-framework-frequency-section
                                    class="assessment-section informatica-assessment-section"
                                    [assessmentData]="assessmentData" [entity]="entity"
                                    [isFromDefaultSettings]="true"
                                    (assessmentDataChange)="assessmentDataChange($event)">

                                </cygov-framework-frequency-section>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" [ngClass]="{'active-link':activeTabId===2}">
                        <a ngbNavLink class="pointertab"> Additional
                            Requirements</a>
                        <ng-template ngbNavContent>
                            <!------------------------------------------------------------------------------------>
                            <!-------------------------------------Additional Requirements------------------------>
                            <!------------------------------------------------------------------------------------>
                            <div class="content-body">
                                <cygov-require-certif-section class="requirements-section"
                                    [additionalData]="additionalData" [isDefaultSetting]="true"
                                    (additionalDataChange)="updateCertifications($event)"
                                    [customDefaultTypes]="customDefaultTypes">
                                </cygov-require-certif-section>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <!-- <div [class]="'custom-active-border tab-' + activeTabId"></div> -->
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
        <!------------------------------ Separation Line -------------------------------->
        <div class="horizontal-underline"></div>
        <!------------------------------ Email Notification Section -------------------------------->
        <div class="automated-email-section">
            <!------------------------------ Notification Header Section -------------------------------->
            <div class="email-header">
                Automated Email Messages
            </div>
            <!------------------------------ Notification Body Section -------------------------------->
            <div class="email-messages-body">
                <div class="message-row" *ngFor="let notification of NotifyMessages,let i=index">
                    <!------------------------------ Notification Toggle button -------------------------------->
                    <div class="togglebtnContainer">
                        <cygov-toggle-button [isChecked]="notification.shouldNotify"
                            (changed)="notification.shouldNotify = $event" [id]="i.toString()">
                        </cygov-toggle-button>
                    </div>
                    <!------------------------------ Notification Text/description -------------------------------->
                    <div class="toggleText">
                        <div class="action-icon">
                            <span class="email-icon" cygovSvgIcon tooltipClass="customized-tooltip"
                                [ngbTooltip]="'Edit email template'" container="body"
                                [svgName]="'envelope-icon'"></span>
                        </div>
                        <span class="value">{{ notification.description }}</span>
                        <!------------------------------ Notification Frequency Section -------------------------------->
                        <div class="frequency">
                            <div class="checkbox-with-name"
                                *ngFor="let item of notification.fixedDays">
                                <div class="selection-box" (click)="item.selected=!item.selected">
                                    <div *ngIf="item.selected" cygovSvgIcon class="right-tick"
                                        [svgName]="'right-tick'">
                                    </div>
                                </div>
                                <span class="check-box-text">
                                    {{item.value }} {{item.value > 1 ? 'days' : 'day'}}
                                </span>
                            </div>
                            <div class="checkbox-with-number-input"
                                *ngFor="let item of notification.customDays;let j=index">
                                <div class="selection-box" (click)="item.selected=!item.selected">
                                    <div *ngIf="item.selected" cygovSvgIcon class="right-tick"
                                        [svgName]="'right-tick'">
                                    </div>
                                </div>
                                <cygov-large-number-input [fontSize]="1.75" class="input-style"
                                    (valueDigitChange)="item.value = $event" [ngModel]="item.value"
                                    [showDays]="true">
                                </cygov-large-number-input>
                                <div class="cross-icon-area"
                                    *ngIf="notification.customDays?.length===j+1"
                                    (click)="removeCustomDay(notification,item.id)">
                                    <div class="cross-icon">
                                        <div class="cross-alpha">
                                            X
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="notification.allowCustomDay && notification.fixedDays.length+notification.customDays?.length< 7"
                            (click)="addCustomNotifyDay(notification,i)"
                            class='add-icon txt-white abs-centre'>+</div>
                        <!------------------------------ Notification Slider -------------------------------->
                        <div *ngIf="notification?.riskSlider" class="risk-slider">
                            <cygov-slider [moveableThumbLabel]="true"
                                [(sliderValue)]="notification.riskScore" [leftLabel]="'1'"
                                [rightLabel]="'10'" [minValue]="1" [maxValue]="10"
                                [gradient]="riskGradient">
                            </cygov-slider>
                        </div>

                        <!-- Radio Buttons options  -->
                        <div *ngIf="notification?.isFindings" class="radio-buttons">
                            <div class="round-checkbox" *ngFor="let opt of notification.findings ">
                                <input [id]="'label-' + opt.label" type="radio"
                                    [name]="'label-' + opt.label" [value]="opt.label"
                                    [checked]="selectedOpt===opt.label" #surveyRadio
                                    (change)="selectedOpt=opt.label" class="jarl-radio" />
                                <label [for]="'label-' + opt.label"
                                    class="lato-14-n-vw label target">{{opt.label.replace('-','
                                    ')}}</label>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

        </div>
        <!------------------------------ Separation Line -------------------------------->
        <div class="horizontal-underline"></div>
        <!------------------------------ General Section -------------------------------->
        <div class="automated-email-section">
            <div class="email-header">
                General
            </div>
            <!-- <div class="horizontal-underline"></div> -->
            <div class="email-messages-body">
                <div class="message-row">
                    <div class="toggleText">
                        <span class="value">4th Party weighting on external risk score</span>
                        <div class="slider-input slider-area">
                            <cygov-slider [moveableThumbLabel]="true" [moveableSignValue]="'%'"
                                [(sliderValue)]="sliderScore" [leftLabel]="'10%'"
                                [rightLabel]="'50%'" [minValue]="10" [maxValue]="50"
                                [gradient]="gradient">
                            </cygov-slider>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!------------------------------ Footer Section to Save functionality -------------------------------->
        <div class="modal-footer">
            <div class="footer-action-btns">
                <cygov-button class="button-margin" [buttonText]="'SAVE'" [buttonWidth]="'5vw'"
                    [buttonHeight]="'3vh'" [textSize]="'18'" (click)="saveDefaultSetting()">
                </cygov-button>
            </div>
        </div>
    </div>

</div>