import { FirstPartyTabs } from './../users-settings.constant';
import { EntityService } from 'app/shared/entity.service';
import { Component, OnInit, Output, Input, TemplateRef, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalTemplateComponent } from 'app/shared/modal-template/modal-template.component';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { UtilsService } from 'app/shared/utils.service';
import { ToastrService } from 'ngx-toastr';
import { Animations } from './animations';
import { EntityTypeEnum, GetRoleQuery } from 'app/API.service';
import { UsersSettingConstant } from '../users-settings.constant';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum } from 'app/shared/enums/appLevelRoles.enum';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface MenuItemsList {
  id?: string;
  index?: number;
  level?: number;
  title?: string;
  value?: string;
  expanded?: boolean;
  selected?: boolean;
  level1Children?: MenuItemsList[];
  level2Children?: MenuItemsList[];
  level3Children?: MenuItemsList[];
}
@Component({
  selector: 'cygov-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss'],
  animations: [Animations.toogleDropdown],
})
export class RolePermissionComponent implements OnInit {
  closeModalInstance = null;
  ngbModalOptions: NgbModalOptions = {
    size: 'md',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont',
    backdrop: false,
  };
  @Output() closeModal = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Input() entityId: string = '';
  @Input() entityList = [];
  @Input() vendorsList = [];
  @Input() editMode: boolean = false;
  @Input() selectedRole: GetRoleQuery;
  @Input() disableScreen: boolean = false;
  @Input() isGlobalUserSetting: boolean;
  roleSaveSubject = new Subject();
  isBnB: boolean = UtilsService.isBnB;
  isBeecher: boolean = UtilsService.isBnBCyberSite;
  entitiesList: any;
  isSavedButtonClicked: boolean = true;
  childEntitiesOfSelectedRootEntity: any = [];
  customVendorsList: any = [];
  isRootEntityAccess: boolean = false;
  roleName: string = '';
  selectedSubEntityIds: Array<string> = [];
  cb1: boolean = false;
  cb2: boolean = false;
  cb3: boolean = true;
  permissions: any;
  roleGroups: MenuItemsList[] = [
    {
      id: '',
      index: 0,
      level: 0,
      title: '1st Party',
      value: 'first-party',
      expanded: true,
      selected: false,
      level1Children: [
        {
          index: 0,
          level: 1,
          title: UtilsService.isBnB ? 'Profit Center' : 'Upperdeck',
          value: 'upperdeck',
          selected: false,
        },
        {
          index: 1,
          level: 1,
          title: 'Collection',
          value: 'collection',
          selected: false,
        },
        {
          id: '',
          index: 2,
          level: 1,
          title: 'Multientity',
          value: 'multi-entity',
          selected: false,
          // follow the structure below if adding more nested routes
          // level2Children: [
          //   {
          //     id: '',
          //     level: 2,
          //     title: 'Asset view',
          //     selected: true,
          //   },
          // ],
        },
        {
          index: 3,
          level: 1,
          title: 'Pillars',
          value: 'pillars',
          selected: false,
        },
        {
          index: 4,
          level: 1,
          title: 'Controls',
          value: 'break-down',
          selected: false,
        },
        {
          index: 5,
          level: 1,
          title: 'Remediation',
          value: 'remediation',
          selected: false,
        },
        {
          index: 6,
          level: 1,
          title: 'User settings',
          value: 'user-settings',
          selected: false,
        },
        {
          index: 7,
          level: 1,
          title: 'Risk register',
          value: 'risk-register',
          selected: false,
        },
        {
          index: 8,
          level: 1,
          title: 'Integrations',
          value: 'integrations',
          selected: false,
        },
      ],
    },
    {
      id: '',
      index: 1,
      level: 0,
      title: '3rd Party',
      value: 'third-party',
      expanded: true,
      selected: false,
      level1Children: [
        {
          index: 0,
          level: 1,
          title: 'Vendors',
          selected: false,
          value: 'custom-vendors',
          expanded: true,
          level2Children: [],
        },
        {
          index: 1,
          level: 1,
          title: 'Overview',
          selected: false,
          value: 'overview',
        },
        {
          id: '',
          index: 2,
          level: 1,
          title: 'Vendors',
          selected: false,
          value: 'vendors',
        },
        {
          index: 3,
          level: 1,
          title: 'Heatmap',
          selected: false,
          value: 'heat-map',
        },
        {
          index: 4,
          level: 1,
          title: 'Default Settings',
          selected: false,
          value: 'default settings',
        },
      ],
    },
    UtilsService.isNetskope
      ? {
          id: '',
          index: 2,
          level: 0,
          title: 'Boardview',
          value: 'board-netskope',
          expanded: true,
          selected: false,
          level1Children: [
            {
              id: '',
              index: 0,
              level: 1,
              title: 'Overview',
              value: 'overview-netskope',
              selected: false,
            },
            {
              id: '',
              index: 1,
              level: 1,
              title: 'Executive Summary',
              value: 'executive-summary',
              selected: false,
            },
            {
              id: '',
              index: 2,
              level: 1,
              title: 'Security',
              value: 'security',
              selected: false,
            },
            {
              id: '',
              index: 3,
              level: 1,
              title: 'Projects',
              value: 'projects',
              selected: false,
            },
            {
              id: '',
              index: 4,
              level: 1,
              title: 'Maturity',
              value: 'maturity',
              selected: false,
            },
          ],
        }
      : {
          id: '',
          index: 2,
          level: 0,
          title: 'Boardview',
          value: 'board',
          expanded: true,
          selected: false,
          level1Children: [
            {
              id: '',
              index: 0,
              level: 1,
              title: 'Overview',
              value: 'overview',
              selected: false,
            },
            {
              id: '',
              index: 1,
              level: 1,
              title: 'Risk Score',
              value: 'risk-score',
              selected: false,
            },
            {
              id: '',
              index: 2,
              level: 1,
              title: 'Compliance',
              value: 'compliance',
              selected: false,
            },
            {
              id: '',
              index: 3,
              level: 1,
              title: 'Threat Level',
              value: 'threat-level',
              selected: false,
            },
            {
              id: '',
              index: 4,
              level: 1,
              title: 'Monitoring',
              value: 'monitoring',
              selected: false,
            },
            {
              id: '',
              index: 5,
              level: 1,
              title: 'Operational',
              value: 'operational',
              selected: false,
            },
            {
              id: '',
              index: 6,
              level: 1,
              title: 'Wizard',
              value: 'wizard',
              selected: false,
            },
            {
              id: '',
              index: 7,
              level: 1,
              title: 'Reports Archive',
              value: 'reports-archive',
              selected: false,
            },
          ],
        },
  ];
  screenPermissions: any = {};
  disableSave = false;
  UsersSettingConstant = UsersSettingConstant;
  rootEntity: any = [];
  globalRootEntityId: any;
  disableModal = false;

  constructor(
    private modalService: NgbModal,
    private usersSettingService: UsersSettingsService,
    private userService: UserService,
    private toastr: ToastrService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    if (!UtilsService.isDefined(this.isGlobalUserSetting)) {
      this.isGlobalUserSetting = this.usersSettingService.isGlobalUserSettings();
    }
    this.entitiesList = this.usersSettingService.getGlobalRootEntities();
    this.entityId = !this.entityId && this.isGlobalUserSetting ? this.selectedRole?.entityId : this.entityId;
    if (
      !UtilsService.isDefined(this.selectedRole) ||
      (this.selectedRole.name &&
        this.selectedRole.name.toLowerCase() !== AppLevelRoleEnum.ADMIN &&
        this.selectedRole.name.toLowerCase() !== AppLevelRoleEnum.MSSP &&
        this.selectedRole.name.toLowerCase() !== AppLevelRoleEnum.ENTITY_LEADER) ||
      this.selectedRole.defaultOrEntityId.toLowerCase() !== UsersSettingConstant.default.toLowerCase()
    ) {
      this.populateVendorsListData();
      this.customVendorsList = this.selectedRole && this.selectedRole.vendorIds ? this.selectedRole.vendorIds : [];
      if (!this.vendorsList || !this.vendorsList.length) {
        this.removeCustomVendorsList();
      }
    } else {
      // remove the custom vendors from the screen.
      this.removeCustomVendorsList();
    }
    this.populatePermissionsData();
    this.roleSaveSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.onRoleSave();
    });
  }

  populateVendorsListData(): void {
    const thirdPartyGroup = this.roleGroups.find(roleGroup => roleGroup.value === UsersSettingConstant.thirdParty);
    thirdPartyGroup.level1Children.forEach(group => {
      if (group.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()) {
        if (this.vendorsList && this.vendorsList.length) {
          this.vendorsList.forEach((vendor, index) => {
            group.level2Children.push({
              index,
              level: 2,
              title: vendor.scName,
              value: vendor.id,
              selected:
                this.selectedRole &&
                this.selectedRole.vendorIds &&
                (this.selectedRole.vendorIds.includes(vendor.id) || this.selectedRole.vendorIds.includes('all')),
            });
            group.selected =
              this.selectedRole && this.selectedRole.vendorIds && this.selectedRole.vendorIds.includes('all');
          });
        }
      }
    });
  }

  populatePermissionsData(): void {
    if (this.editMode && this.selectedRole) {
      this.roleName = this.selectedRole.name;
      this.onSelectRootEntity(this.selectedRole);
      this.permissions = JSON.parse(this.selectedRole.screenPermissions);
      const permissionsArr = Object.keys(this.permissions);
      this.isRootEntityAccess = this.selectedRole.isRootEntityAccess;
      permissionsArr.forEach(level => {
        if (level !== 'basic-routes') {
          const route = this.roleGroups.find(r => r.value === level);
          if (route && route.level1Children) {
            this.permissions[level].forEach(r => {
              const index = route.level1Children.findIndex(i => i.value.toLowerCase() === r.toLowerCase());
              if (index > -1) {
                route.level1Children[index].selected = true;
              }
            });
            route.selected = this.isAllChildrenSelected(route.level1Children);
          }
        }
      });
      this.selectedSubEntityIds = this.isRootEntityAccess
        ? this.entityList.map(entity => entity?.id)
        : this.selectedRole.entityIds;
    } else if (this.editMode && !UtilsService.isDefined(this.selectedRole)) {
      this.selectedRole.defaultOrEntityId = this.entityId;
    }
  }

  openPopUP(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>): void {
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptions);
    const compInstance = modalRef.componentInstance;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
    this.closeModalInstance = modalRef;
  }

  onEntityClick(id): void {
    if (!this.selectedSubEntityIds.includes(id)) {
      this.selectedSubEntityIds.push(id);
    } else {
      this.selectedSubEntityIds.splice(this.selectedSubEntityIds.indexOf(id), 1);
    }
  }

  onScreenPermissionClicked(role: any = {}, subPermission: any = {}, isParentClicked = false): void {
    if (!this.roleGroups) {
      return;
    }
    if (isParentClicked && role && role.value) {
      // if main route is clicked make all sub routes selected
      role.selected = !role.selected;
      role.level1Children.forEach(item => (item.selected = role.selected));
      if (role.value.toLowerCase() === UsersSettingConstant.firstParty.toLowerCase()) {
        this.isAllowRootEntityAccess(!!role.selected);
      }
      if (role.value.toLowerCase() === UsersSettingConstant.thirdParty.toLowerCase()) {
        if (role.level1Children && role.level1Children.length) {
          const index = role.level1Children.findIndex(
            item => item.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()
          );
          if (index > -1) {
            role.level1Children[index].level2Children = role.level1Children[index].level2Children.map(child => {
              child.selected = role.selected;
              if (role.selected) {
                // when main check box clicked then customVendorListUpdated
                this.customVendorsList.push(child.value);
              }
              return child;
            });
          }
        }
      }
    } else {
      // if child route is clicked

      if (subPermission?.value?.toLowerCase() === FirstPartyTabs.INTEGRATIONS && !role?.level1Children[1]?.selected) {
        this.toastr.warning('Collection needs to be selected for Integrations');
        return;
      }

      if (subPermission?.value.toLowerCase() === UsersSettingConstant.defaultSetting.toLowerCase()) {
        this.toastr.warning('Select overview/vendors to manipulate the default settings.');
      } else {
        if (subPermission?.value?.toLowerCase() === FirstPartyTabs.COLLECTION) {
          role.level1Children[8].selected = !subPermission?.selected;
        }
        if (
          subPermission?.value?.toLowerCase() === FirstPartyTabs.INTEGRATIONS &&
          subPermission?.selected &&
          role?.level1Children[1]?.selected
        ) {
          this.toastr.warning('Please Unselect Collection');
          return;
        }
        subPermission.selected = !subPermission.selected;
      }
      // checking the condition for default settings in third party
      if (
        (subPermission.value?.toLowerCase() === UsersSettingConstant.upperdeck.toLowerCase() ||
          subPermission.value?.toLowerCase() === UsersSettingConstant.profit_center.toLowerCase()) &&
        subPermission.selected
      ) {
        this.isAllowRootEntityAccess(true);
      } else if (
        (subPermission?.value?.toLowerCase() === UsersSettingConstant.upperdeck.toLowerCase() ||
          subPermission?.value?.toLowerCase() === UsersSettingConstant.profit_center.toLowerCase()) &&
        !subPermission.selected
      ) {
        this.isAllowRootEntityAccess(false);
      }
      // if custom vendor is selected then all the vendors list will be selected along with vendors screen.
      if (subPermission?.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()) {
        // selecting all the items in the vendors list.
        subPermission.level2Children.forEach(item => (item.selected = subPermission.selected));
        if (subPermission.selected) {
          this.customVendorsList = ['all'];
        } else {
          this.customVendorsList = [];
        }
      }
      // if vendor is selected from the vendor list then vendors screen will be selected.
      if (subPermission && subPermission.level === 2 && role.value === UsersSettingConstant.customVendors) {
        // this.vendorsList;
        if (subPermission.selected) {
          const vendor = this.vendorsList.find(ven => ven.id === subPermission.value);
          this.customVendorsList.push(vendor.id);
          if (this.customVendorsList.length === this.vendorsList.length) {
            this.customVendorsList = ['all'];
            // update the vendors of third-party
            const tpIndex = this.roleGroups.findIndex(item => item.value === UsersSettingConstant.thirdParty);
            if (tpIndex > -1) {
              if (this.roleGroups[tpIndex].level1Children && this.roleGroups[tpIndex].level1Children.length) {
                const vIndex = this.roleGroups[tpIndex].level1Children.findIndex(
                  item => item.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()
                );
                if (vIndex > -1) {
                  this.roleGroups[tpIndex].level1Children[vIndex].selected = true;
                }
              }
            }
          }
        } else {
          // handle the 'all' case in customVendorsList
          if (this.customVendorsList?.length > 0 && this.customVendorsList[0] === 'all') {
            const arr = [];
            this.roleGroups[1].level1Children[0].level2Children.map(child => {
              if (child.value !== subPermission.value) {
                arr.push(child.value);
              }
            });
            this.customVendorsList = arr;
          } else {
            const vIndex = this.customVendorsList.findIndex(v => v.id === subPermission.value);
            this.customVendorsList.splice(vIndex, 1);
          }
        }
      }

      if (
        role &&
        role.level1Children &&
        role.value === UsersSettingConstant.boardView &&
        subPermission &&
        (subPermission.value === UsersSettingConstant.overView || subPermission.value === UsersSettingConstant.wizard)
      ) {
        // handling the selection scenario
        role.level1Children.forEach(child => {
          if (child.value === UsersSettingConstant.overView || child.value === UsersSettingConstant.wizard) {
            child.selected = subPermission.selected;
          }
        });
      } else if (
        role &&
        role.level1Children &&
        role.value === UsersSettingConstant.thirdParty &&
        (subPermission.value === UsersSettingConstant.overView || subPermission.value === UsersSettingConstant.vendors)
      ) {
        // handling the selection scenario
        role.level1Children.forEach(child => {
          if (child.value === UsersSettingConstant.defaultSetting && subPermission.selected) {
            child.selected = subPermission.selected;
          } else if (
            child.value === UsersSettingConstant.defaultSetting &&
            !subPermission.selected &&
            !this.checkBoardPermissionForDefaultSettings(role.level1Children)
          ) {
            child.selected = subPermission.selected;
          }
        });
      }
      if (role && role.level1Children && subPermission) {
        if (subPermission.selected && this.isAllChildrenSelected(role.level1Children)) {
          role.selected = true;
        } else {
          // if last selected su route is un selected then make parent un selected as well
          this.screenPermissions = role.level1Children.filter(item => item.selected);
        }
      }
      if (!subPermission.selected) {
        role.selected = false;
      }
    }
  }

  /**
   *
   * @param list1 Helper function for checking the third party permissions
   * @returns
   */
  checkBoardPermissionForDefaultSettings(list: any[]): boolean {
    if (list) {
      return !!list.find(
        child =>
          child.selected &&
          (child.value === UsersSettingConstant.overView || child.value === UsersSettingConstant.vendors)
      );
    }
    return false;
  }

  /**
   *
   * @param list1 is list of children of 1st Party , 3rd Party or Board view
   * @returns true if all childs are selected else false
   */
  isAllChildrenSelected(list1: any[]): boolean {
    if (list1 && list1.length) {
      const tempList1 = list1.filter(item => item.selected);
      return tempList1.length === list1.length;
    }
    return false;
  }

  formScreenPermissions(): void {
    // reduce all permission into a valid object to store at backend
    this.screenPermissions = this.roleGroups.reduce((acc: any, curr: any) => {
      acc[curr.value] = [];
      if (curr.level1Children && curr.level1Children.length) {
        const routes = curr.level1Children.filter(item => item.selected).map(permission => permission.value);
        acc[curr.value].push(...routes);
      }
      if (acc[curr.value] && !acc[curr.value].length) {
        // if first-pary, third-party or board is empty, remove it from acc
        delete acc[curr.value];
      }
      return acc;
    }, {});
  }

  validateRoleData(): boolean {
    let isValid = true;
    const allRoles = this.userService.allRoles;
    const rolesNames = allRoles && allRoles.length ? allRoles.map(item => item.name.toLowerCase()) : [];
    if (!this.editMode && (!this.roleName || (this.roleName && !this.roleName.trim().length))) {
      this.toastr.info('Role name can not be empty');
      isValid = false;
    } else if (!this.editMode && rolesNames && rolesNames.length && rolesNames.includes(this.roleName.toLowerCase())) {
      this.toastr.info('Role name ' + this.roleName + ' already exist please try another one');
      isValid = false;
    }
    if (!this.selectedSubEntityIds.length) {
      this.toastr.info('At least one sub entity required');
      isValid = false;
    }
    if (!this.screenPermissions) {
      this.toastr.info('At least one screen permission required');
      isValid = false;
    }
    if (
      this.selectedRole &&
      this.selectedRole?.isRootEntityAccess &&
      this.selectedSubEntityIds.length === 1 &&
      this.selectedSubEntityIds[0] === this.entityId
    ) {
      this.toastr.info('At least one sub entity required');
      isValid = false;
    }
    if (
      this.screenPermissions &&
      this.screenPermissions.board &&
      this.screenPermissions.board.length &&
      (!this.screenPermissions.board.includes('overview') || !this.screenPermissions.board.includes('wizard'))
    ) {
      this.toastr.info('Board Overiew and Board Wizard must be selected');
      isValid = false;
    }
    return isValid;
  }
  removeCustomVendorsList(): void {
    // first find the third party.
    const thirdPartyObj = this.roleGroups.find(group => group.value === UsersSettingConstant.thirdParty);

    if (thirdPartyObj && thirdPartyObj.level1Children && thirdPartyObj.level1Children.length) {
      // finding the custom vendors list.
      const customVendorIndex = thirdPartyObj.level1Children.findIndex(
        child => child.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()
      );

      // deleting the object.
      if (customVendorIndex > -1) {
        thirdPartyObj.level1Children.splice(customVendorIndex, 1);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectOrUnselectLevel2Childs(flag: boolean): void {
    // first find the third party.
    const thirdPartyObj = this.roleGroups.find(group => group.value === UsersSettingConstant.thirdParty);

    if (thirdPartyObj && thirdPartyObj.level1Children && thirdPartyObj.level1Children.length) {
      // finding the custom vendors list.
      const customVendorIndex = thirdPartyObj.level1Children.findIndex(
        child => child.value === UsersSettingConstant.customVendors
      );

      // deleting the object.
      if (customVendorIndex > -1) {
        thirdPartyObj.level1Children.splice(customVendorIndex, 1);
      }
    }
  }

  async onRoleSave(): Promise<void> {
    // validation while adding custom roles
    if (!this.validateRoleData()) {
      return;
    }
    this.formScreenPermissions();
    if (this.selectedRole && this.validateRoleData()) {
      const response = await this.usersSettingService.createUpdateRole(
        {
          id: this.selectedRole.id,
          name: this.roleName,
          entityId: this.selectedRole.entityId,
          entityIds: this.isRootEntityAccess ? [this.entityId] : this.removeRootEntityId(),
          vendorIds: this.isRootEntityAccess ? this.customVendorsList : [], // TODO
          screenPermissions: JSON.stringify({
            ...this.screenPermissions,
            'basic-routes': ['training-center'],
            'training-center': ['management', 'first-party', 'third-party', 'board'],
          }),
          isRootEntityAccess: this.isRootEntityAccess,
        },
        false
      );
      if (response) {
        if (!this.isGlobalUserSetting) {
          const customRoles = await this.userService.getRolesByDefaultOrEntityId(this.entityId, false);
          const defaultRoles = await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
          this.usersSettingService.setRoles([...customRoles, ...defaultRoles]);
        }
        this.closePopUp();
      }
    } else {
      const response = await this.usersSettingService.createUpdateRole({
        name: this.roleName.toLowerCase(),
        entityId: this.isGlobalUserSetting ? this.globalRootEntityId : this.entityId,
        entityIds: this.isRootEntityAccess ? [this.entityId] : this.selectedSubEntityIds,
        vendorIds: this.isRootEntityAccess ? this.customVendorsList : [],
        screenPermissions: JSON.stringify({
          ...this.screenPermissions,
          // adding some basic routes, clients will be removed if user is not Admin or mssp
          'basic-routes': ['training-center'],
          'training-center': ['management', 'first-party', 'third-party', 'board'],
        }),
        defaultOrEntityId: this.isGlobalUserSetting ? this.globalRootEntityId : this.entityId,
        isRootEntityAccess: this.isRootEntityAccess,
      });
      this.removeCustomVendorsList();
      if (response) {
        if (!this.isGlobalUserSetting) {
          const customRoles = await this.userService.getRolesByDefaultOrEntityId(this.entityId, false);
          const defaultRoles = await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
          this.usersSettingService.setRolesInCurrentEntity([...customRoles, ...defaultRoles]);
          this.usersSettingService.currEntRoles.next([...customRoles, ...defaultRoles]);
        }
        this.closePopUp();
      }
    }
  }

  saveRole(): void {
    if (!this.disableScreen) {
      if (this.validateRoleData()) {
        this.formScreenPermissions();
        this.roleSaveSubject.next();
      }
    }
  }

  closePopUp(): void {
    this.closeModal.emit('true');
  }

  deleteCustomRole(): void {
    if (!this.disableModal) {
      this.disableModal = true;
      this.onDelete.emit(this.selectedRole);

      setTimeout(() => {
        this.disableModal = false;
      }, 3000);
    }
  }

  /**
   * remove root EntityId if its in selected EntityId
   * @returns return child selected EntityIds
   */
  removeRootEntityId(): string[] {
    this.selectedRole.entityIds.filter((entityId, index) => {
      if (entityId === this.entityId) {
        this.selectedRole.entityIds.splice(index, 1);
      }
    });
    return this.selectedRole.entityIds;
  }

  isAllowRootEntityAccess(flag: boolean): void {
    if (flag) {
      this.isRootEntityAccess = true;

      if (this.isGlobalUserSetting) {
        this.selectedSubEntityIds = this.childEntitiesOfSelectedRootEntity.map(entity => {
          return entity.id;
        });
      } else {
        this.selectedSubEntityIds = this.entityList.map(entity => {
          return entity.id;
        });
      }
    } else {
      this.isRootEntityAccess = false;
      this.selectedSubEntityIds = this.selectedRole && this.selectedRole.entityIds ? this.selectedRole.entityIds : [];
      const unSelectList = [
        UsersSettingConstant.thirdParty.toLowerCase(),
        UsersSettingConstant.boardView.toLowerCase(),
      ];
      if (this.roleGroups && this.roleGroups.length) {
        unSelectList.map(item => {
          const index1 = this.roleGroups.findIndex(subScreen => subScreen.value.toLowerCase() === item);
          if (index1 >= 0) {
            this.roleGroups[index1].selected = true;
            this.onScreenPermissionClicked(this.roleGroups[index1], {}, true);
          }
          // to unselect or select the grand child of third-party
          if (item === UsersSettingConstant.thirdParty.toLowerCase()) {
            if (this.roleGroups[index1].level1Children && this.roleGroups[index1].level1Children.length) {
              const customVendorIndex = this.roleGroups[index1].level1Children.findIndex(
                child => child.value.toLowerCase() === UsersSettingConstant.customVendors.toLowerCase()
              );
              if (customVendorIndex > -1) {
                this.roleGroups[index1].level1Children[customVendorIndex].level2Children = this.roleGroups[
                  index1
                ].level1Children[customVendorIndex].level2Children.map(vendor => {
                  vendor.selected = false;
                  return vendor;
                });
              }
            }
          }
        });
      }
    }
  }

  isPermissionToAccessScreens(role: any): boolean {
    return role && role.value.toLowerCase() !== UsersSettingConstant.firstParty && !this.isRootEntityAccess;
  }

  checkRootEntityAccess(): boolean {
    if (this.isRootEntityAccess) {
      this.toastr.info('Selected Role has Root Entity access so can not unselect its sub entities ');
      return false;
    }
    return true;
  }

  isDefaultRole(): boolean {
    return (
      this.selectedRole &&
      this.selectedRole?.defaultOrEntityId?.toLowerCase() === UsersSettingConstant.default.toLowerCase()
    );
  }

  async onSelectRootEntity(event: any): Promise<any> {
    if (event) {
      this.globalRootEntityId = this.editMode ? event.entityId : event?.id;
      this.childEntitiesOfSelectedRootEntity = await this.usersSettingService.getRootAndChildEntities(
        this.globalRootEntityId,
        EntityTypeEnum.ROOT_ENTITY
      );
      this.rootEntity = this.childEntitiesOfSelectedRootEntity?.rootEntity?.name?.toUpperCase();
      this.childEntitiesOfSelectedRootEntity = this.childEntitiesOfSelectedRootEntity?.childEntities;
    } else {
      this.childEntitiesOfSelectedRootEntity = [];
    }
  }
}
