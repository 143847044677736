import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersSettingsComponent } from './users-settings.component';

const routes: Routes = [
  {
    path: '',
    // canActivate: [EntityLeaderRoleGuard],
    component: UsersSettingsComponent,
  },
  {
    path: ':subEntityId',
    // canActivate: [SubEntityLeaderRoleGuard],
    component: UsersSettingsComponent,
  },
];

export const UsersSettingsRoutes: ModuleWithProviders<any> = RouterModule.forChild(routes);
