import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFrameworksComponent } from './select-frameworks.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SelectFrameworksComponent],
  imports: [CommonModule, NgbModule, SvgIconModule, NgbTooltipModule],
  exports: [SelectFrameworksComponent],
})
export class SelectFrameworksModule {}
