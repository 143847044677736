import { animate, state, style, transition, trigger } from '@angular/animations';

export const Animations = {
  fadeInOutAnimation: trigger('fadeInOutAnimation', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(400)]),
    transition(':leave', [style({ opacity: 1 }), animate(400, style({ opacity: 0 }))]),
  ]),
  fadeInOnly: trigger('fadeInOnly', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(400)]),
  ]),
  fadeInOutAnimationDelayed: trigger('fadeInOutAnimationDelayed', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(1000)]),
    transition(':leave', [style({ opacity: 1 }), animate(1000, style({ opacity: 0 }))]),
  ]),
  domainHeightAnimation: trigger('domainHeightAnimation', [
    state(
      'void',
      style({ height: 0, 'min-height': 0, 'max-height': 0, 'margin-bottom': 0, 'margin-top': 0, overflow: 'hidden' })
    ),
    state(
      '*',
      style({
        height: '*',
        'min-height': '*',
        'max-height': '*',
        'margin-bottom': '*',
        'margin-top': '*',
      })
    ),
    transition('void => *', [animate('700ms 0ms cubic-bezier(0,0,.01,1)')]),
  ]),
};
