import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { UtilsService } from 'app/shared/utils.service';
import { UserService } from '../user.service';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';
import { DomainFrameworkService } from '../domain-framework.service';

@Injectable({
  providedIn: 'root',
})
export class TenantGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private logger: NGXLogger,
    private userService: UserService,
    private domainFrameworkService: DomainFrameworkService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isUserAllowed();
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isUserAllowed();
  }
  async isUserAllowed(): Promise<boolean> {
    try {
      const completePath: string = location.href;
      const settings = this.domainFrameworkService.domainSetting;
      if (settings && UtilsService.isNetskope) {
        const { allowedRoutes } = settings;
        if (allowedRoutes && allowedRoutes.length && completePath && completePath.length) {
          // if route is present in allowed routes then allow access
          const isAllowed = allowedRoutes.find(route => completePath.toLowerCase().includes(route.toLowerCase()));
          if (isAllowed) {
            return true;
          } else {
            const user = this.userService.getCurrentUserSync();
            const userRole = this.userService.getCurrentRole();
            // check if user is not participant and vendor than redirect to clients
            // because participant and vendor does not have permission to visit client page
            if (
              userRole &&
              userRole.name &&
              userRole.name.toLowerCase() !== AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() &&
              userRole.name.toLowerCase() !== AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase() &&
              userRole.name.toLowerCase() !== AppLevelRoleEnum.PARTICIPANT.toLowerCase() &&
              userRole.name.toLowerCase() !== AppLevelRoleEnum.VENDOR.toLowerCase() &&
              !userRole.isRootEntityAccess
            ) {
              await this.router.navigate(['/clients']);
            } else {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    } catch (error) {
      this.logger.error('isAuthenticated - Error: ', error);
    }
    return Promise.reject(false);
  }
}
