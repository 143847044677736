import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityProgressBarsComponent } from './entity-progress-bars.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [EntityProgressBarsComponent],
  imports: [CommonModule, ProgressBarModule, SvgIconModule, PipesModule],
  exports: [EntityProgressBarsComponent],
})
export class EntityProgressBarsModule {}
