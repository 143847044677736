import { ParticipantRoleGuard } from 'app/shared/guards/participant-role.guard';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService } from 'app/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderModule } from './header/header.module';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { UtilsService } from './shared/utils.service';
import { FileService } from './shared/file.service';
import { DomainFrameworkService } from './shared/domain-framework.service';
import { SidenavModule } from './sidenav/sidenav.module';
import { FooterModule } from './footer/footer.module';
import { AppRoutesModule } from './app.routes';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SvgIconService } from './shared/svg-icon/svg-icon.service';
import { SvgIconModule } from './shared/svg-icon/svg-icon.module';
import { CygovLoaderComponent } from './shared/cygov-loader/cygov-loader.component';
import { CygovErrorHandler } from './shared/cygov-error-handler/cygov-error-handler.service';
import { APIService } from './API.service';
import { ToastrModule, GlobalConfig } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel, LoggerConfig } from 'ngx-logger';
import { environment } from 'environments/environment';
import { JiraConfirmationModalComponent } from './shared/jira-confirmation-modal/jira-confirmation-modal.component';
import { ScanIntegrationServiceModalComponent } from './shared/scan-integration-service-modal/scan-integration-service-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WelcomeGuideModalComponent } from 'app/shared/welcome-guide-modal/welcome-guide-modal.component';
import { RiskRegisterService } from './risk-register/risk-register.service';
import { HitrustConfirmationModalComponent } from './shared/hitrust-confirmation-modal/hitrust-confirmation-modal.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { UsersSettingsModule } from './users-settings/users-settings.module';
import { LargeNumberShort } from 'app/shared/pipes/large-number-short.pipe';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { AdminRoleGuard } from './shared/guards/admin-role.guard';
import { CollectionService } from './collection/collection.service';
import { FrameworkModalComponent } from './shared/framework-modal/framework-modal.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { CUSTOMAPIService } from './custom-api.service';
import { UserPermissionModule } from './shared/user-permission/user-permission.module';

export function CygovErrorHandlerProvider() {
  return { provide: ErrorHandler, useClass: CygovErrorHandler };
}

const toastConfig: Partial<GlobalConfig> = {
  timeOut: 5000,
  closeButton: true,
  positionClass: 'toast-top-right',
  enableHtml: true,
  progressBar: false,
  progressAnimation: 'increasing',
  newestOnTop: true,
  preventDuplicates: true,
  countDuplicates: true,
  resetTimeoutOnDuplicate: true,
};

const loggerConfig: LoggerConfig = {
  level: environment.debug ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.OFF,
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    JiraConfirmationModalComponent,
    ScanIntegrationServiceModalComponent,
    WelcomeGuideModalComponent,
    HitrustConfirmationModalComponent,
    NotAuthorizedComponent,
    FrameworkModalComponent,
  ],
  entryComponents: [
    AppComponent,
    CygovLoaderComponent,
    JiraConfirmationModalComponent,
    ScanIntegrationServiceModalComponent,
  ],
  providers: [
    AuthService,
    UserRoleGuard,
    AdminRoleGuard, // !  build-optimization-2
    ParticipantRoleGuard,
    UtilsService,
    FileService,
    CollectionService,
    DomainFrameworkService,
    SvgIconService,
    CygovErrorHandlerProvider(),
    APIService,
    RiskRegisterService,
    // SunburstService,  *moved to its related module - build-optimization-1*
    // TenableIntegrationService, *moved to its related module - build-optimization-1*
    // CrowdstrikeService,  *moved to its related module - build-optimization-1*
    LargeNumberShort,
    ClientLambdaService,
    CUSTOMAPIService,
  ],
  imports: [
    BrowserModule,
    AppRoutesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(toastConfig),
    // AdminModule,//!  build-optimization-2
    // ProgressBarModule,//!  build-optimization-2
    HeaderModule,
    SidenavModule,
    FooterModule,
    SvgIconModule,
    // CygovLoaderModule,
    LoggerModule.forRoot(loggerConfig),
    ReactiveFormsModule,
    FormsModule,
    // CircularIconModule, //!  build-optimization-2
    // WidgetBoxModule,//!  build-optimization-2
    UsersSettingsModule, // !  build-optimization-2
    // DomainFrameworksModalModule,//!  build-optimization-2
    OnboardingModule,
    UserPermissionModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
