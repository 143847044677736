// eslint-disable-next-line no-shadow
export enum Integration {
  JIRA = 'jira',
  SERVICE_NOW = 'service-now',
  TENABLE = 'tenable',
  RAPID7 = 'rapid7',
  CROWDSTRIKE = 'crowdstrike',
  AZURE = 'azuredefender',
  MICROSOFT = 'microsoftdefender',
}

export enum RESULTS {
  PASSED = 'passed',
  FAILED = 'failed',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum CROWDSTRIKE_SEVERITY {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
export enum GENERIC_SEVERITY {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum R7_SEVERITY {
  MODERATE = 'moderate',
  CRITICAL = 'critical',
}

export enum CROWDSTRIKE_RESULT {
  NORMAL = 'normal',
  TRUE_POSITIVE = 'true_positive',
}

export enum RAPID7_RESULTS {
  VULNERABLE = 'vulnerable',
}

export enum COLS {
  REF = 'ref',
  NAME = 'name',
  SEVERITY = 'severity',
  IP = 'ip',
  RESULT = 'result',
}

export enum LOGO {
  CROWDSTRIKE = 'CrowdStrike_logo',
  TENABLE = 'tenable_logo',
  RAPID7 = 'rapid7-logo',
  AZURE = 'azure-logo',
  MICROSOFT = 'microsoft-logo',
}

export enum SVG {
  INTEGRATION = 'new-integration-icon',
  SMART_MAPPING = 'smart-mapping',
}

export enum Azure {
  ASSESSMENT_TYPE = 'assessment type',
  AZURE = 'Azure',
  MICROSOFT = 'Microsoft',
  SERVICE = 'service',
  RANK = 'rank',
  MAX_SCORE = 'Max Score',
  ACTION_TYPE = 'action type',
  RESULT = 'result',
}
