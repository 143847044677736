<div class="impact-main-body">
    <!------------------------------ Modal Header -------------------------------->
    <div class="header-ven">
        <div class="title-cross">
            <div class="cross-icon">
                <div class="cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'"
                    (click)="closePopUp()"></div>
            </div>
        </div>
    </div>
    <!------------------------------ Modal Body-------------------------------->
    <div class="body-ven">
        <div class="body-content">


            <div class="body">
                <div class="header">
                    <span class="impact-heading">
                        Domains
                    </span>

                </div>
                <div class="impacts-container">
                    <div class="toggle-container"
                        *ngFor="let domain of domainOptions; let i = index">

                        <div class="toggle-btn-container">

                            <cygov-checkbox [width]="16" [height]="16"
                                [ngClass]="{'reduce-opacity' : domain?.defaultSelected}"
                                [isChecked]="domain?.isQualify" [id]="domain?.name"
                                (changed)="domain.isQualify = $event">
                            </cygov-checkbox>
                        </div>

                        <div class="toggle-text">
                            <span [ngClass]="{'reduce-opacity' : domain?.defaultSelected}"
                                class="value">{{ domain?.name }}</span>
                        </div>


                    </div>
                </div>
            </div>
            <!------------------------------Modal Body Content Footer -------------------------------->

        </div>

    </div>


</div>
<!------------------------------ Modal Footer -------------------------------->
<div class="footer-ven">
    <div class="footer-action-btns">
        <div class="save-button">
            <cygov-button class="button-margin" [buttonText]="'Save'" [buttonWidth]="'5vw'"
                [textSize]="'18'" [buttonHeight]="'3vh'" (buttonPressed)="saveImpact()">
            </cygov-button>
        </div>
        <div class="cancel-button">
            <cygov-button class="button-margin cancel-button" [buttonText]="'Cancel'"
                [buttonWidth]="'5vw'" [buttonColor]="'#12161A'" [textSize]="'18'"
                [buttonHeight]="'3vh'" (buttonPressed)="closePopUp()">
            </cygov-button>
        </div>
    </div>
</div>