import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() showLegend = false;
  isBnb: boolean = UtilsService.isBnB;
  lessThan = '<';

  constructor() {}

  ngOnInit() {}
}
