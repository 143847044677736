<div class="upload-content">
  <!--Close Modal-->
  <div class="header-close-big" *ngIf="this.startUpload">
    <div class="close-cross-icon" (click)="closeModal()" cygovSvgIcon [svgName]="'x-icon-type2'">
    </div>
  </div>
  <div class="upload-container">
    <!--Header Section-->
    <div class="existing-files-container" *ngIf="this.startUpload && existingFiles.length>0">
      <span class="existing-files-title">Existing Files</span>
      <div class="horizontal-separator"></div>
    </div>
    <!--Existing Files List-->
    <div class="existing-files" *ngIf="this.startUpload &&existingFiles.length>0">
      <ng-container *ngFor="let file of existingFiles">
        <div class="doc-avatar">
          <cygov-username-icon [document]="true" [docFormat]="file.name" [height]="'6'"
            [deleteFlag]="true" crossWidth="1.5" (removeUser)="deleteFile(file)">
          </cygov-username-icon>
          <span class="filename">{{file.name}}</span>
        </div>
        <div class="horizontal-separator"></div>
      </ng-container>
    </div>
    <div *ngIf="this.startUpload" class="positioning-center" ng2FileDrop [uploader]="uploader"
      (onFileDrop)="handleFileInput($event);closeModal()"
      [ngClass]="{'existing-drag-files':existingFiles.length>0?true:false,'drag-files-container':existingFiles.length>0?false:true}">
      <div class=" body">
        <!-- <div class="upload-icon-positioning">
          <div class="icon-upload-cloud" cygovSvgIcon [svgName]="'upload_collection'"
            [ngClass]="{'padding-class':existingFiles.length===0}"></div>
        </div> -->

        <img class="upload-cloud-img" src="assets/images/upload-cloud.png" alt="Cloud Upload"
          [ngClass]="{'margin-class':existingFiles.length===0}" />
        <div id="caller" class="lato-30-n-vw center-align-text">
          Drag files here or
          <label class="browse-text" for="file">browse</label>
          <input class="hide-input-file" type="file" id="file" ng2FileSelect [uploader]="uploader"
            accept=".gif, .jpg, .png, .doc, .docx, .csv, .pdf, .xls, .xlsx, .ppt, .txt"
            (onFileSelected)="handleFileInput($event);closeModal()" multiple />
        </div>
      </div>
    </div>
  </div>

  <!--Uploading File Pop-up-->
  <!-- <ng-template #uploadProcess>
    <div class="positioning-center-inprocess">
      <div class="pop-up-uploads">
        <div class="header-close">
          <div class="close-cross-icon" (click)="closeModal()" cygovSvgIcon
            [svgName]="'x-icon-type2'"></div>
        </div>
        <div class="body-uploads">
          <div *ngFor="let item of uploader.queue; let i = index" class="progress-upload-file">
            <div class="doc-avatar">
              <cygov-username-icon [document]="true" [docFormat]="item?.file?.name" [height]="'6'">
              </cygov-username-icon>
            </div>
            <div class="file-details">
              <div class="lato-14-n-vw">
                {{ item?.file?.name }}
              </div>
              <cygov-progress-bar class="progress-bar" *ngIf="progressBars[item?.file?.name] < 100"
                [assessmentLevel]="progressBars[item?.file?.name]"
                [postFill]="['#29A9EB', '#0B517C']" [height]="10"></cygov-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template> -->
</div>