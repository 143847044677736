<div class="footer">
  <div class="logo-wrapper">
    <div class="logo" cygovSvgIcon [svgName]="'powered-by'"></div>
  </div>

  <div class="color-legend" *ngIf="showLegend">
    <ng-container *ngIf="!isBnb ; else bnbLegend">
      <div class="circle score-high-bg"></div>
      <div class="lato-14-n-vw capitalize">reached target</div>
      <div class="circle score-medium-bg"></div>
      <div class="lato-14-n-vw capitalize">near target</div>
      <div class="circle score-low-bg"></div>
      <div class="lato-14-n-vw capitalize">below target</div>
    </ng-container>
    <ng-template #bnbLegend>
      <div class="bnb-adjustments">
        <div class="circle bnb-score-lowest-bg"></div>
        <div class="lato-14-n-vw capitalize"> 0{{lessThan}}2.0 </div>
        <div class="circle bnb-score-low-bg"></div>
        <div class="lato-14-n-vw capitalize"> 2.0{{lessThan}}3.0 </div>
        <div class="circle bnb-score-medium-bg"></div>
        <div class="lato-14-n-vw capitalize"> 3.0{{lessThan}}4.0 </div>
        <div class="circle bnb-score-high-bg"></div>
        <div class="lato-14-n-vw capitalize"> 4.0{{lessThan}}5.0 </div>
        <div class="circle bnb-score-highest-bg"></div>
        <div class="lato-14-n-vw capitalize"> =5</div>
      </div>
    </ng-template>
  </div>
</div>