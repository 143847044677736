<div class="main-body">
    <div class="header">
        <div class="cross-icon" cygovSvgIcon (click)="closeModal()" [svgName]="'x-icon-type2'">
        </div>
    </div>
    <div class="content">
        <div class="message lato-18-n-vw">
            <div class="color-blue">Please Note</div>
            <div>The current template contains <span class="color-red">invalid data</span></div>
        </div>
        <div class="button-upload">
            <div class="lato-14-b-vw standard-btn flex-c" (click)="restartProcess()">
                <span class="lato-14-n-vm">UPLOAD NEW TEMPLATE</span>
            </div>
        </div>
    </div>
</div>