export enum UploadStateEnum {
  UPLOAD_NOT_STARTED = 'upload-not-started',
  UPLOAD_STARTED = 'upload-started',
  UPLOAD_SUCCESSFUL = 'upload-successful',
  UPLOAD_UNSUCCESSFUL = 'upload-unsuccessful',
}

export enum UploadStateStyleEnum {
  STYLE1 = 'style-1',
  STYLE2 = 'style-2',
}
