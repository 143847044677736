export enum DeletePopup {
  CONFIRM = 'confirm',
}
// Will add other side nav menus later when needed
export enum SideNav {
  COLLECTION = 'collection',
  VENDORS = 'vendors',
  FIRST_PARTY = 'first-party',
  RISK_REGISTER = 'risk-register',
}

export enum ReassessmentEnum {
  NOT_INITIATED = 'NOT_INITIATED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum FRAMEWORK_TYPES {
  requirement = 'Requirement',
  certificate = 'Certification',
  document = 'Document',
  scan = 'Scan',
  other = 'Other',
  manual_artifact = 'Artifact',
}

export class ArrowsEnum {
  static RIGHT = 'Right';
  static LEFT = 'Left';
  static UP = 'Up';
  static DOWN = 'Down';
}

export class KMS_STATUS {
  static DONE = 'done';
  static IN_PROGRESS = 'in_progress';
  static FAILED = 'failed';
}

export enum CURR_VIEW {
  USERNAME = 'username',
  ACCESSKEY = 'accessKey',
}

export class Entity_Type {
  static CHILD_ENTITY = 'CHILD_ENTITY';
}

export enum DOWNLOAD_REPORT {
  CYBER360 = 'VENDOR_RISK_HOWDEN_CYBER_360',
  HOWDEN_FULL = 'VENDOR_RISK_HOWDEN_FULL',
  HOWDEN_RANSOMWARE = 'VENDOR_RISK_HOWDEN_RANSOMWARE',
  SSM_HEALTH = 'VENDOR_RISK_SSM_HEALTH',
}
export enum UPPERDECK_FACELIFT {
  RESIDUAL_RISK = 'residual-risks',
  ATTACK_MAP = 'attacks-map',
  RESIDUAL_RISK_ATTACK_SURFACE = 'residual-risk-attack-surface',
}
