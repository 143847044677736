export class CrbVendorConstant {
  public static artifacts = {
    CRB1: 'information security policy',
    CRB2: 'procedure for background screening',
    CRB3: 'bcp and drp policies',
    CRB4: 'data retention and destruction requirements',
    CRB5: 'cyber insurance policies',
    CRB6: 'ssae 18 reports for the past year',
    CRB7: 'ssae 18 evidence and reports of vendors',
    CRB8: 'technology risk assessments and risk management programs',
    CRB9: 'latest technology risk assessments reports',
    CRB10: 'penetration testing results',
    CRB11: 'bcp and drp testing schedules and results',
    CRB12: 'critical infrastructure physical security guidelines',
    CRB13: 'non-disclosure agreement',
  };

  public static compliances = {
    CRB14: 'ffiec',
    CRB15: 'pci dss',
    CRB16: 'gdpr',
    CRB17: 'iso 27001',
  };

  public static impacts = [
    'Lost sales and income',
    'Negative cash flows resulting delayed sales',
    'Increased expenses',
    'Regulatory fines',
    'Loss or leakage of sensitive data',
    'Contractual penalties or loss of contractual bonuses',
    'Customer dissatisfaction or attrition',
    'Delay executing business plan or strategic initiative',
  ];

  public static artifactsQualification = () => {
    return Object.values(CrbVendorConstant.artifacts).map(name => ({
      isQualify: false,
      name,
    }));
  };
}
