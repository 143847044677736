export enum BoardWizardStepsEnum {
  NEW_REPORT_DATA = 'New Report Dates',
  SELECT_WIDGETS = 'Select Widgets',
  ADD_DATA = 'Add Data',
  ASSET = 'Asset',
  EVENTS = 'Events',
  EXTERNAL_AUDIT = 'External Audit',
  BUDGET = 'Budget',
  FUTURE_PROJECTS_REGISTRY = 'Future Projects Registry',
  PUBLISH = 'Publish',
}

export enum StepsTemplateLength {
  ASSET = 13,
  EVENTS = 15,
  EXTERNAL_AUDIT = 6,
  BUDGET = 7,
  FUTURE_PROJECTS_REGISTRY = 6,
}

export enum StepActionEnum {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}
