import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';
export class HeatMap {
  impactValue: number;
  impact: string;
  probability: number;
  vendorName: string;
  vendorId: string;
  constructor() {}
}

export class EmitHeatMap {
  navigateToRiskManagement: boolean;
  minProbability: number;
  maxProbability: number;
  minImpact: number;
  maxImpact: number;
  sections: HeatmapSectionEnum;
  constructor(
    expandHeatMap: boolean,
    minProbability: number,
    maxProbability: number,
    minImpact: number,
    maxImpact: number,
    sections: HeatmapSectionEnum
  ) {
    this.navigateToRiskManagement = expandHeatMap;
    this.minProbability = minProbability;
    this.maxProbability = maxProbability;
    this.minImpact = minImpact;
    this.maxImpact = maxImpact;
    this.sections = sections;
  }
}

export class HeatMapCoordinate {
  x: number;
  y: number;
  color: string;
  vendorName: string;
  vendorId: string;
  probability: number;
  constructor(x: number, y: number, color: string, vendorId: string, vendorName: string, probability: number) {
    this.x = x;
    this.y = y;
    this.color = color;
    this.vendorName = vendorName;
    this.vendorId = vendorId;
    this.probability = probability;
  }
}
