export class EnbdVendorConstant {
  public static categories = [
    {
      id: 1,
      name: 'Mandatory for all identified suppliers',
      defaultDomains: [
        'Information Security Policy',
        'Organization of Information Security',
        'Human Resources Security',
        'Supplier and Third Party Risk Management',
        'Operations Security',
        'Communications Security',
        'Identity and Access Control',
        'Governance and Technology Risk Management',
        'Information Security Incident Management',
        'Information Security aspects of business continuity management',
        'Compliance',
        'Audit Assurance and Technology compliance',
      ],
      numberOfQuestions: 0,
      defaultSelected: true,
      isQualify: true,
    },
    {
      id: 2,
      name: 'Integration with ENBD systems (External API/ VPN/MPLS etc.)',
      defaultDomains: ['Cryptography and Key Management', 'Application and Interface Security'],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
    {
      id: 3,
      name: 'Data Sharing (Confidenti al/Restrict ed/ Internal)',
      defaultDomains: [
        'Asset Management',
        'Data Security and Information lifecycle Management',
        'Privacy Compliance',
        'Physical and Environmental Security',
        'Cryptography and Key Management',
      ],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
    {
      id: 4,
      name: 'Infra hosting provider',
      defaultDomains: [
        'Asset Management',
        'Data Security and Information lifecycle Management',
        'Privacy Compliance',
        'Physical and Environmental Security',
        'Data Security Center',
        'Cryptography and Key Management',
      ],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
    {
      id: 5,
      name: 'Application provider/Soft ware development services',
      defaultDomains: [
        'Security Requirements of Information Systems',
        'Secure Development practices',
        'Application and Interface Security',
      ],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
    {
      id: 6,
      name: 'Authorized offshore',
      defaultDomains: [
        'Data Security and Information lifecycle Management',
        'Privacy Compliance',
        'Physical and Environmental Security',
      ],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
    {
      id: 7,
      name: 'Software as a Service',
      defaultDomains: [
        'Data Security and Information lifecycle Management',
        'Privacy Compliance',
        'Physical and Environmental Security',
        'Physical and Environmental Security',
        'Data Security Center',
        'Security Requirements of Information Systems',
        'Secure Development practices',
        'Application and Interface Security',
        'Cloud Security and Forensics',
      ],
      numberOfQuestions: 0,
      defaultSelected: false,
      isQualify: false,
    },
  ];

  public static artifactsQualification = () => {
    return Object.values(EnbdVendorConstant.categories).map(name => ({
      isQualify: false,
      name,
    }));
  };
}
