export enum FiltersEnum {
  // FILTERS TO APPLY
  ASSIGNED_TO_ME = 'assigned to me',
  WAITING_FOR_REVIEWER = 'waiting for reviewer',
  DENIED = 'denied',
  APPROVED = 'approved',
  EXCLUDED = 'excluded',
  VERSIONS = 'versions',
  UNANSWERED = 'unanswered',
  NOT_APPLICABLE = 'not applicable',
  INFO_SEC = 'infosec',
  PARTIAL = 'partial',
  ARTIFACTS = 'artifacts',
  INTEGRATION_SOURCES = 'integration sources',
  SMART_MAPPING = 'smart mapping',
  DENIED_REVIEWER = 'denied by reviewer',

  // FILTERS TYPES

  SELECTED_TAGS = 'selectedTags',
  SELECTED_SEVERITY_LIST = 'selectedSeverityList',
  SELECTED_STATUS_LIST = 'selectedStatusList',
  SELECTED_OTHERS = 'selectedOthers',
  SELECTED_ANSWER_LIST = 'selectedAnswerList',
  SELECTED_USERS_LIST = 'selectedUsersList',

  // FOR ALL FILTERS
  ALL = 'all',
}
