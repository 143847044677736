<div class="modal-body" *ngIf="newUser">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <span class="lato-20-n-vw txt-white">Add User</span>
      <span class="lato-24-n-vw" *ngIf="framework && framework.name">
        | {{ framework.name }}
      </span>
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <div class='modal-content-body'>
    <div class="form-container" [ngClass]="{'form-conatiner-informatica' : isFromBulkVendor}">
      <div class="sub-title lato-18-n-vw" *ngIf="!isFromBulkVendor">
        Who would you like to send the questionnaire to?
      </div>
      <div class="user-input">
        <label class="lato-16-n-vw capitalize txt-label" for="name">Full Name</label>
        <input class="form-control" [(ngModel)]="newUser.name" id="name" spellcheck="true" />
      </div>
      <div class="user-input">
        <label class="lato-16-n-vw capitalize txt-label" for="Email">Email</label>
        <input class="form-control" [(ngModel)]="newUser.email" id="Email" spellcheck="true" />
      </div>

      <div class="user-input" *ngIf="currentUser && !notAllowedRoles.includes(currentUser.role)">
        <div class="input-box role">
          <label class="lato-16-n-vw capitalize txt-label" for="role">Role</label>
          <cygov-select id="role" [items]="rolesList" bindValue="id" bindLabel="name"
            [(ngModelValue)]="newUser.roleId" [clearable]="false" [customArrow]="true">
          </cygov-select>
        </div>
      </div>
      <div class="section-seprator-full-width" *ngIf="isFromBulkVendor"></div>
      <div class="label-heading lato-16-b-vw" *ngIf="isFromBulkVendor">Permmison</div>
      <div class="radio-group padding-top-bottom" *ngIf="isFromBulkVendor">
        <div class="type-radio round-checkbox radio-span-setting-options" style="display: flex;">
            <span class="radio-span-setting-options"
               >
                <input type="radio" class="question-type-checkbox"
                    name="question-type"
                    [checked]="true"
                    [id]="'question-type'" />
                <label 
                    class='label netskope-overview lato-14-n-vw'>
                    {{'Edit'}}</label>
            </span>
        </div>
        <div class="type-radio round-checkbox radio-span-setting-options" style="display: flex;">
          <span class="radio-span-setting-options"
             >
              <input type="radio" class="question-type-checkbox"
                  name="question-type"
                  [checked]="false"
                  [id]="'question-type'" />
              <label 
                  class='label netskope-overview lato-14-n-vw'>
                  {{'View Only'}}</label>
          </span>
      </div>
    </div>
    <div class="section-seprator-full-width" *ngIf="isFromBulkVendor"></div>
      <div class="send-invite-btn" *ngIf="!isFromBulkVendor">
        <button type="button" class="uppercase add-participant-btn" (click)="emitUser(newUser)">
          Send invitation
        </button>
      </div>
    </div>
  </div>
  <div class="line-seprator" *ngIf="!isFromBulkVendor"></div>
  <div class="modal-footer" [ngClass]="{'footer-margin' : isFromBulkVendor}">
    <div class="footer-text" *ngIf="!isFromBulkVendor">
      <div class="lato-14-b-vw what-happen">What happens next?</div>
      <div class="lato-14-n-vw address">
        The addressed user will get the invitation by email,
      </div>
      <div class="lato-14-n-vw securely">
        securely logs into the assessment website and answers the related questionnaire.
      </div>

    </div>
    <div class="d-flex" *ngIf="isFromBulkVendor">
      <cygov-button class="button-margin" [buttonText]="'ADD'" [buttonWidth]="'5vw'"
        (click)="emitUser(newUser)"
        [buttonColor]="'#29a9eb'" [hoverColor]="'#0F92E3'" >
      </cygov-button>

      <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
      (click)="activeModal.close()"
        [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5vw'" [textColor]="'#C5C9D1'"
        >
      </cygov-button>
    </div>
  </div>
</div>