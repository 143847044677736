<div class="modal-body" *ngIf="newUser">
  <div class="modal-header">
    <div class="lato-18-n-vw txt-white capitalize edit-icon">{{ user ? 'EDIT USER' : 'ADD NEW USER'
      }}</div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="closeModal()">
    </div>
  </div>
  <!-- Commented till next feature is enabled -->
  <!-- <div class="send-enrollment-section">
    <div class="selection-box abs-centre" [ngClass]="{'is-selected':sendEnrollment}"
      (click)="sendEnrollment=!sendEnrollment">
      <div *ngIf="sendEnrollment" cygovSvgIcon [svgName]="'right-tick'"></div>
    </div>
    <div class="lato-16-n-vw txt-white">Send an Enrollment Email</div>
  </div> -->
  <div class="modal-main">
    <div class="user-input-group border-input-group">
      <div class="input-box">
        <label class="lato-18-n-vw capitalize input-label" for="name">Name</label>
        <input type="text" class="input-field  lato-16-n-vw" aria-label="Name"
          [(ngModel)]="newUser.name" id="name" placeholder="Name" spellcheck="true" />
      </div>
    </div>
    <div class="user-input-group border-input-group">
      <div class="input-box">
        <label class="lato-18-n-vw capitalize input-label" for="Email">Email</label>
        <input class="input-field  lato-16-n-vw  " [class.disabled]="user" [disabled]="!!user"
          [(ngModel)]="newUser.email" id="Email" placeholder="Email" spellcheck="true" />
      </div>
    </div>
    <div class="user-input-group border-input-group" *ngIf="user && user.isCognitoUser">
      <div class="input-box">
        <label class="lato-16-n-vw capitalize input-label" for="Phone">Phone</label>
        <input class="input-field" [(ngModel)]="newUser.phone" id="Phone" placeholder="Phone"
          spellcheck="true" />
      </div>
    </div>
    <div class="user-input-group direction-col remove-border-bottom"
      *ngIf="!isGlobalUsersSetting && currentUser && !notAllowedRoles.includes(currentUser.role)">
      <div class="group-row">
        <div class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">Role</label>
          <cygov-select *ngIf="!isUserModal" id="role" class="role-drop-down" [items]="roleOptions"
            placeholder="Select Role" [(ngModelValue)]="newUser.role"
            (ngModelValueChange)="upgradeRole($event)" [clearable]="false" [customArrow]="true"
            [searchable]="false">
          </cygov-select>
          <cygov-select *ngIf="isUserModal" id="role" class="role-drop-down"
            [items]="userModalRoles" [placeholder]="'Select Role'" [(ngModelValue)]="newUser.role"
            (ngModelValueChange)="upgradeRole($event)" [clearable]="false" [customArrow]="true"
            [searchable]="false">
          </cygov-select>
        </div>

        <div
          *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnumBnB.DIVISION_LEADER || newUser.role?.toLowerCase() === appLevelRoleEnumBnB.REGION_LEADER || newUser.role?.toLowerCase() === appLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN"
          class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">{{preSelectedTitle}}</label>
          <cygov-select id="role" class="role-drop-down" [disabled]="true" [clearable]="false"
            [customArrow]="true" [searchable]="false" [ngModelValue]="preSelectedName">
          </cygov-select>
        </div>
        <!-- <div *ngIf="newUser.role === roleEnum.LEADER" class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">Leader Type</label>
          <cygov-select class="role-drop-down" [disabled]="user && disableInput"
            [items]="leaderTypes" placeholder="Select" [(ngModelValue)]="leaderType"
            [customArrow]="true" [clearable]="false" [searchable]="false">
          </cygov-select>
        </div> -->
        <div
          *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnum.SUBENTITY_LEADER"
          class="input-box role actual-role">
          <label *ngIf="!isUserModal" class="lato-16-n-vw capitalize input-label"
            for="role">Subentity</label>
          <!-- Commenting this code, might need in the future  -->
          <!-- <cygov-select *ngIf="!isUserModal" class="role-drop-down"
            [disabled]="user && disableInput" [items]="subEntityList" placeholder="Select"
            bindValue="id" bindLabel="name" [(ngModelValue)]="selectedSubEntityId"
            [customArrow]="true" [clearable]="false"
            [multiple]="!(newUser.role?.toLowerCase() === appLevelRoleEnum.PARTICIPANT)"
            [searchable]="false">
          </cygov-select> -->
          <cygov-custom-drop-down [itemList]="subEntityList" bindLabel="name" [showCheckbox]="true"
            [showCross]="true"
            (ngModelValueChange)="selectedSubEntities($event)"></cygov-custom-drop-down>
        </div>
        <div *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnum.PARTICIPANT && !isUserModal"
          class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label"
            for="role">Subentity</label>
          <cygov-custom-drop-down [itemList]="subEntityList" bindLabel="name" [showCheckbox]="true"
            [showCross]="true" [showSearch]="true"
            (ngModelValueChange)="selectedSubEntities($event)"></cygov-custom-drop-down>
        </div>
      </div>

    </div>

    <!-- Select boxes for Global User Settings -->

    <div class="user-input-group direction-col remove-border-bottom"
      *ngIf="isGlobalUsersSetting && currentUser && !notAllowedRoles.includes(currentUser.role)">
      <div class="group-row">
        <div class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">Role</label>
          <cygov-select id="role" class="role-drop-down" [items]="roleOptions"
            placeholder="Select Role" [(ngModelValue)]="newUser.role"
            (ngModelValueChange)="upgradeRole($event)" [clearable]="false" [customArrow]="true"
            [searchable]="false">
          </cygov-select>
        </div>

        <!-- Second Select Box -->
        <div *ngIf="secondSelectionList.includes(newUser.role?.toLowerCase()) && !isGlobalParticipant"
          class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label"
            for="role">{{secondSelectBoxTitle}}</label>
          <cygov-select class="role-drop-down" [disabled]="user && disableInput"
            [items]="listOfAllLayersAndEntities" placeholder="Select" bindValue="id"
            bindLabel="name" [(ngModelValue)]="entityId" [customArrow]="true" [clearable]="false"
            (ngModelValueChange)="listValueSelected($event,1)" [searchable]="false">
          </cygov-select>
        </div>
        <!-- Third Select Box -->
        <div
          *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnum.SUBENTITY_LEADER && entityId"
          class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">Sub-Entities</label>
          <cygov-select class="role-drop-down" [disabled]="user && disableInput"
            [items]="childEntitiesOfSelectedRootEntity" placeholder="Select" bindValue="id"
            bindLabel="name" [(ngModelValue)]="selectedSubEntityId" [customArrow]="true"
            [clearable]="false" [searchable]="false"
            [multiple]="!(newUser.role?.toLowerCase() === appLevelRoleEnum.PARTICIPANT)">
          </cygov-select>
        </div>
        <div *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnum.PARTICIPANT && entityId"
          class="input-box role actual-role">
          <label class="lato-16-n-vw capitalize input-label" for="role">Sub-Entities</label>
          <cygov-custom-drop-down [itemList]="childEntitiesOfSelectedRootEntity" bindLabel="name"
            [showCheckbox]="true" [showCross]="true" [showSearch]="true" [widthArea]="'9.3vw'"
            (ngModelValueChange)="selectedSubEntities($event)"></cygov-custom-drop-down>
        </div>
      </div>
      <div *ngIf="newUser.role?.toLowerCase() === appLevelRoleEnum.PARTICIPANT.toLowerCase() && isBnB"
        class="global-participant">
        <div class="check-box" (click)="isGlobalParticipant = !isGlobalParticipant">
          <div *ngIf="isGlobalParticipant" class="selected-tick" cygovSvgIcon [svgName]="'right-tick'">
          </div>
        </div>
        <span class="lato-14-n-vw">Global Participant</span>
      </div>

    </div>
    <div *ngIf="prompt" class="box-container border-input-group">
      <div class="happen-box">
        <label *ngIf="!isRoleLeader" class="lato-16-n-vw box-label" for="prompt">What happens
          next?</label>
        <div class="lato-16-n-vw box-text">
          {{ prompt }}
        </div>
      </div>
    </div>

    <!-- Permission section - Need to be implemented with New Feature -->

    <div *ngIf="newUser.role?.toLowerCase() !== appLevelRoleEnum.PARTICIPANT.toLowerCase()"
      class="user-input-group border-top-permissions remove-border-bottom">
      <div class="input-box">
        <label *ngIf="!isUserModal" class="lato-16-n-vw capitalize txt-blue">Permission</label>
        <label *ngIf="isUserModal" class="lato-16-n-vw capitalize txt-blue">Assignment</label>

        <div *ngIf="!isUserModal" class="permission-check-boxes">
          <div class="round-checkbox">
            <input id="edit" name="edit" type="radio" (click)="setPermission()"
              [checked]="!newUser.isViewOnly" [disabled]="false" [value]="!newUser.isViewOnly" />
            <label for="edit"
              class="lato-14-n-vw label display-mode display-mode-label">Edit</label>
          </div>
          <div class="round-checkbox">
            <input id="viewOnly" name="viewOnly" type="radio" (click)="setPermission()"
              [checked]="newUser.isViewOnly" [disabled]="false" [value]="newUser.isViewOnly" />
            <label for="viewOnly" class="lato-14-n-vw label display-mode display-mode-label">View
              Only</label>
          </div>
        </div>

        <div class="check-selection" *ngIf="isUserModal">
          <div class="selection-box" (click)="assignmentCheck = !assignmentCheck">
            <div *ngIf="assignmentCheck" cygovSvgIcon [svgName]="'right-tick'"></div>
          </div>
          <div class="lato-14-n-vw txt-white">
            Assign user to all question
          </div>

        </div>

      </div>
    </div>

    <!-- Click Through section - Shown only to Admins -->

    <div *ngIf="currentUserRole.name?.toLowerCase() === appLevelRoleEnum.ADMIN && !isThirdParty"
      class="user-input-group border-top-permissions remove-border-bottom">
      <div class="input-box">
        <label class="lato-16-n-vw capitalize txt-blue">Click Through</label>
        <div class="click-through">
          <cygov-checkbox [width]="12" [height]="12" [isChecked]="newUser.clickThrough"
            [id]="'clickThrough'" (changed)="newUser.clickThrough = $event">
          </cygov-checkbox>
          <label class="click-through-label lato-14-n-vw" for="clickThrough">Enable Click Through
            Terms
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <cygov-button (click)="emitUser(newUser)" [buttonText]="buttonText"
      [buttonWidth]="isUserModal ? '6vw':'9vw'" [buttonColor]="buttonColor"
      [hoverColor]="'#0f92e3'">
    </cygov-button>
  </div>

</div>