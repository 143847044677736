import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/auth/auth.service';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'cygov-walkthrough-videos',
  templateUrl: './walkthrough-videos.component.html',
  styleUrls: ['./walkthrough-videos.component.scss'],
})
export class WalkthroughVideosComponent implements OnInit, OnChanges {
  @Input() currentRoute: string;
  modalRef: NgbModalRef;
  videoPath: string;
  SOURCE_FOLDER: string = 'WALKTHROUGH_VIDEOS';
  isParticipant = false;
  videoRouteMap: any;

  constructor(private modalService: NgbModal, private fileService: FileService, private authService: AuthService) {}

  ngOnChanges(): void {
    this.ngOnInit();
  }

  async ngOnInit(): Promise<void> {
    this.isParticipant = this.authService.isParticipant();
    this.videoRouteMap = this.initVideoRouteMap();
    this.videoPath = await this.getVideoPath(this.videoNameByRoute(this.currentRoute));
  }

  initVideoRouteMap() {
    return {
      clients: 'Management Screen.mp4',
      ['first-party']: {
        upperdeck: 'Upperdeck.mp4',
        collection: UtilsService.isMidMarket
          ? 'BB Mid Market Intro.mp4'
          : this.isParticipant
          ? 'Participant Walkthrough.mp4'
          : 'Collection.mp4',
        ['multi-entity']: 'Multi Entity.mp4',
        pillars: 'Pillars Walkthrough.mp4',
        ['break-down']: 'Break Down Screen.mp4',
        remediation: this.isParticipant ? 'Participant Walkthrough.mp4' : 'Remediation.mp4',
        ['user-settings']: 'User Settings.mp4',
        ['risk-register']: 'Risk Register.mp4',
        integrations: 'Integrations.mp4',
      },
      ['third-party']: {
        overview: '3rd Party Overview.mp4',
        ['heat-map']: 'Heatmap.mp4',
        vendors: 'Vendors List.mp4',
        vendorId: 'Vendors Profile.mp4',
      },
      board: {
        overview: '',
        ['risk-score']: '',
        compliance: '',
        ['threat-level']: '',
        monitoring: '',
        operational: '',
        wizard: '',
        ['reports-archive']: '',
      },
      ['training-center']: '',
    };
  }

  getVideoPath(videoName: string): Promise<string> {
    if (!videoName) {
      return null;
    }

    return this.fileService.downloadFromS3({
      key: `${this.SOURCE_FOLDER}/${videoName}`,
      contentType: 'video/mp4',
    });
  }

  videoNameByRoute(currentRoute: string): string {
    const queryParamMap: any = new Proxy(new URLSearchParams(currentRoute.split('?')[1] || ''), {
      get: (searchParams, prop) => searchParams.get(prop as any),
    });
    const routeArr = currentRoute.split('?')[0].slice(1).split('/');
    let videoName = null;
    switch (true) {
      case routeArr[0].includes('clients'):
        videoName = this.videoRouteMap.clients;
        break;
      case routeArr[0].includes('first-party'):
        videoName =
          routeArr[2] && this.videoRouteMap['first-party'][routeArr[2]]
            ? this.videoRouteMap['first-party'][routeArr[2]]
            : null;
        break;
      case routeArr[0].includes('third-party'):
        if (routeArr[2] && routeArr[2] === 'vendors') {
          const isVendorDetails = !!queryParamMap.vendorId;
          videoName = isVendorDetails
            ? this.videoRouteMap['third-party'].vendorId
            : this.videoRouteMap['third-party'][routeArr[2]];
        } else {
          videoName =
            routeArr[2] && this.videoRouteMap['third-party'][routeArr[2]]
              ? this.videoRouteMap['third-party'][routeArr[2]]
              : null;
        }
        break;
      default:
      case routeArr[0].includes('board'):
        videoName = routeArr[2] && this.videoRouteMap.board[routeArr[2]] ? this.videoRouteMap.board[routeArr[2]] : null;
        break;
    }
    return videoName;
  }

  openModal(content): void {
    this.modalRef = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'video-modal',
      backdropClass: 'video-modal-backdrop',
    });
  }
}
