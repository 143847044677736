<div class="body-back"
    [ngStyle]="{height:buttonHeight,width:buttonWidth,'background-color':buttonColor , cursor:!disableClick ? 'pointer' : 'default' , border:buttonBorder ? '0.1px solid '+buttonBorder : '', 'border-radius': borderRadius ? borderRadius : '', opacity: disableClick ? '0.6': '1',pointerEvents : fullyDisabled ? 'none' : 'all'}"
    (click)="buttonClicked()" (mouseover)="!disableClick && hoverColorChange(true)"
    (mouseout)="hoverColorChange(false)">
    <div class="text-box" [ngStyle]="{margin: textMargin}">
        <div class="{{'text-style lato-'+textSize+'-'+boldType+'-vw'}}"
            [ngStyle]="{color:textColor}">{{buttonText}}</div>
        <div *ngIf="addIcon" class='icon'>
            <div class="arrow-icon" cygovSvgIcon [svgName]="addIcon"></div>
        </div>
    </div>
</div>