// eslint-disable-next-line no-shadow
export enum ManagerActionEnum {
  APPROVE = 'approve',
  DENIED = 'deny',
}

export enum ReviewerActionsEnum {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}
