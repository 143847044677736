import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cygov-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @ViewChild('selectTag', { static: false }) selectInput;
  @Input() items: any;
  @Input() placeholder: string;
  @Input() ngModelValue: any;
  @Input() multiple: boolean;
  @Input() isImageControl: boolean;
  @Input() bindLabel: string;
  @Input() bindValue: any;
  @Input() compareWith: any;
  @Input() groupBy: string;
  @Input() isTextRequired = false;
  @Input() clearable = true;
  @Input() standardSelect = true;
  @Input() searchable = true;
  @Input() disabled = false;
  @Input() customTemplate = false;
  @Input() highlightLabel = false;
  @Input() labelKeys: string[] = [];
  @Input() optionKeys: string[] = [];
  @Input() searchFn = null;
  @Input() replacePipe = false;
  @Input() replaceSearchValue = '';
  @Input() replaceValue = '';
  @Input() customArrow = false;
  @Input() hideArrow = false;
  @Input() appendTo = '';
  @Input() showCheckBox: boolean = false;
  @Input() sortByAlphabet: boolean = false;
  @Input() sortByField: string = ''; // field in the object by which you wanna sort
  @Input() singleSelection: boolean = false;
  @Input() triggerOnSameOption: boolean = false;
  @Input() position: 'top' | 'bottom' | 'auto' = 'auto';
  @Input() largeArrow: boolean = false;
  @Input() fromRiskRegister: boolean = false;

  @Output() ngModelValueChange = new EventEmitter<any>();
  @Output() selectOpened = new EventEmitter<any>();
  @Output() selectClosed = new EventEmitter<any>();
  @Output() sameOptionSelected = new EventEmitter<any>();

  isDropDownOpen = false;
  previousOption: string;
  isOpened = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.previousOption = changes?.ngModelValue?.currentValue;
  }

  isSameOptionSelected(data): void {
    if (data?.toLowerCase() === this.previousOption?.toLowerCase()) {
      this.sameOptionSelected.emit(data);
    }
  }
  ngOnInit(): void {
    if (this.sortByAlphabet) {
      this.items = this.sortItems(this.items, this.sortByField);
    }
  }

  sortItems(array: any, field: string = ''): any[] {
    array.sort((a: any, b: any) => {
      if (field) {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }

  openSelect(): void {
    this.selectInput.open();
    // Below condition is only for risk register component. When editing the status of the risk.
    // Need to show the change text in main field.
    if (this.fromRiskRegister) {
      this.isOpened = !this.isOpened;
      this.ngModelValue = 'Change';
    }
    this.selectOpened.emit(this.selectInput);
  }
  changeValue(item) {
    if (this.fromRiskRegister) {
      this.isOpened = false;
    }
    this.ngModelValueChange.emit(item);
    return item.selected;
  }

  closeSelect(): void {
    this.selectClosed.emit(true);
  }
}
