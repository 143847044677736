import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { SelectModule } from '../shared/select/select.module';
import { ProgressBarModule } from '../shared/progress-bar/progress-bar.module';
import { ScoreColorModule } from '../shared/score-color/score-color.module';
import { NgbModule, NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'; // !  build-optimization-2
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';
import { UsernameIconModule } from 'app/shared/username-icon/username-icon.module';
import { GeneratorModule } from 'app/generator/generator.module';
import { WalkthroughVideosComponent } from './walkthrough-videos/walkthrough-videos.component';
import { SupportComponent } from './support/support.component';
import { ExistingFilesModule } from 'app/shared/existing-files/existing-files.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SvgIconModule,
    ProgressBarModule,
    ScoreColorModule,
    // remove duplicate - build-optimization-1
    SelectModule,
    NgbDropdownModule,
    CygovLoaderModule,
    UsernameIconModule,
    GeneratorModule,
    ExistingFilesModule,
  ],
  declarations: [HeaderComponent, WalkthroughVideosComponent, SupportComponent],
  exports: [HeaderComponent],
  providers: [NgbDropdownConfig],
})
export class HeaderModule {}
