<!-- <div   class="user-row " (click)="toggleList(user)"> -->


<div [formGroup]="userForm" (focusout)="onupdate()" class="user-row"
	[ngClass]="{'expand-row':expanded}">
	<div class="name item-value header-padding">
		<span class="short-name" *ngIf="!isEditMode || !isEditable || isVendorUser">
			<cygov-username-icon height="3.5" username={{item.user.name}} [dotInitials]="true">
			</cygov-username-icon>
		</span>
		<span container="body" class="lato-14-n-vw name-title-span"
			*ngIf="!isEditMode || !isEditable || isVendorUser"
			(mouseenter)="checkIfEllipsis($event.target)" [ngbTooltip]="item.user.name"
			[disableTooltip]="!showColumnTooltip">{{ item.user.name}}</span>
		<div *ngIf="isEditMode && isEditable && !isVendorUser" class="edit-item-value">
			<input type="text" formControlName="name">
		</div>
	</div>
	<div container="" body class="role item-value" [ngClass]="{'disable-text':isEditMode}">
		<span container="body" *ngIf="!isEditMode || isVendorUser || !isEditable"
			class="lato-14-n-vw capitalize roleName" (mouseenter)="checkIfEllipsis($event.target)"
			[ngbTooltip]="item.user.role?.name" [disableTooltip]="!showColumnTooltip">
			{{ item.user.role?.name }}
		</span>
		<div class="edit-select" *ngIf="isEditMode && isEditable && !isVendorUser">
			<cygov-select container="body" class="role-select" [items]="tmpRoleList"
				[clearable]="false" [searchable]="false" bindLabel="name" bindValue="id"
				[standardSelect]="false" [customArrow]="true" [compareWith]="compairRole"
				[placeholder]="item?.user?.role?.name ? item?.user?.role?.name: 'Select Role'"
				[ngModelValue]="item?.user?.role" (ngModelValueChange)="selectRole($event)">
			</cygov-select>
		</div>
		<!-- <cygov-select *ngIf="isEditMode" [items]="[]" [clearable]="false" [customArrow]="true"
      (ngModelValueChange)="selectValueChanged('role', statusFilter)">
    </cygov-select> -->
	</div>

	<div class="permission item-value" [ngClass]="{'disable-text':isEditMode}">
		<span *ngIf="!isEditMode || viewOnlyNotAllowed || isVendorUser || !isEditable"
			class="lato-14-n-vw capitalize">{{permission}}</span>
		<div class="edit-select"
			*ngIf="isEditMode && isEditable && !viewOnlyNotAllowed  && !isVendorUser">
			<cygov-select [items]="permissionOptions" [clearable]="false" [searchable]="false"
				[standardSelect]="false" [customArrow]="true" [(ngModelValue)]="permission"
				(ngModelValueChange)="updatePermission($event, true)">
			</cygov-select>
		</div>
		<!-- <cygov-select *ngIf="isEditMode" [items]="[]" [clearable]="false" [customArrow]="true"
      (ngModelValueChange)="selectValueChanged('role', statusFilter)">
    </cygov-select> -->
	</div>
	<div class="access item-value"
		[ngClass]="{'disable-text':isEditMode,'access-scroll':!(isEditMode && isEditable && toShowAccess && !isVendorUser)}">

		<span *ngIf="!isEditMode || isVendorUser || !toShowAccess"
			class="lato-14-n-vw capitalize">{{accessColName}}</span>
		<div class="edit-select" *ngIf="isEditMode && isEditable && toShowAccess && !isVendorUser">
			<cygov-select *ngIf="!isParticipantUser"
				[items]="isEntityAdmin ? rootEntities : childEntities" [clearable]="false"
				bindLabel="name" bindValue="id" [multiple]="!isParticipant && !isEntityAdmin"
				[ngModelValue]="tmpSelectedEntities" [customArrow]="true" [showCheckBox]="true"
				(ngModelValueChange)="updateSubEntities($event)"
				[placeholder]="isEntityAdmin ? 'Select Root Entity' :'Select Sub Entities' ">
			</cygov-select>
			<cygov-custom-drop-down *ngIf="isParticipantUser"
				[itemList]="isGlobalUserSetting && isBnB ? globalChildEntities : childEntities"
				bindLabel="name" [widthArea]="'9.2vw'" [showCheckbox]="true"
				[preSelectedItems]="subEntityNames" [itemsColor]="'#121521'"
				(ngModelValueChange)="updateSubEntitiesParticipant($event)"
				[closed]="'#1A2336'"></cygov-custom-drop-down>
		</div>
		<!-- <span *ngIf="(!isEditMode || isVendorUser || !toShowAccess) && expanded"
          class="lato-14-n-vw capitalize">{{subEntityNames}} </span> -->
		<div class="sub-entities"
			*ngIf="((!isEditMode && toShowAccess ) || (!isEditMode && isVendorUser)) && (subEntityNames && subEntityNames.length && expanded)">
			<div class="cross-button" (click)="expanded = false">x</div>
			<ul class="sub-entity-list">
				<li *ngFor="let subEntity of subEntityNames" class="access-Item-list">{{ subEntity
					}}</li>
			</ul>
		</div>
		<div
			*ngIf="subEntityNames && subEntityNames.length && !this.expanded && !(isEditMode && isEditable) && toShowAccess">
			<span class="lato-14-n-vw field-value">{{ subEntityNames[0] }}</span>
		</div>
		<span
			*ngIf="subEntityNames && subEntityNames.length > 1 && !this.expanded && !(isEditMode && isEditable) && toShowAccess"
			(click)="toggleExpand($event)" class="lato-14-n-vw expand-toggle">
			...</span>
	</div>
	<!-- Assigned  column -->
	<div class="framework access-framework item-value header-padding"
		[ngClass]="{'show-star':!item.entities, 'framework-overflow':item.entities  }">
		<div *ngIf="!expanded" class="access-framework-item"
			[ngClass]="{'disable-text':isEditMode}">
			<!-- <div (click)="toggleExpand($event)">{{ item.access }}</div> -->
			<div class="lato-14-n-vw inner-container text-ellipsis"
				*ngIf="item.entities && item.entities.length && item.entities[0]?.assignments && item.entities[0]?.assignments.length > 0"
				title="{{ item?.entities[0]?.assignments && item?.entities[0]?.assignments?.length > 0 ?
					item?.entities[0]?.assignments[0]?.name.replaceAll('_',' ') : ''
					}}&nbsp;({{item?.entities[0]?.totalQuestions}})">
				{{ item?.entities[0]?.assignments && item?.entities[0]?.assignments?.length > 0 ?
				item?.entities[0]?.assignments[0]?.name.replaceAll('_',' ') : ''
				}}&nbsp;({{item?.entities[0]?.assignments[0]?.totalQuestions ?
				item?.entities[0]?.assignments[0]?.totalQuestions : 0}})
			</div>
			<!-- <div *ngIf="!item.entities" class="lato-14-n-vw"> - </div> -->
		</div>
		<div *ngIf="expanded">
			<div *ngFor="let entity of item.entities" class="access-framework-item"
				[ngClass]="{'shrink':!expanded ,'disable-text':isEditMode}">
				<!-- <span class="lato-15-l-vw" (click)="toggleExpand($event)">{{ entity.access }}</span> -->
				<span>
					<div class="inner-container text-ellipsis"
						*ngFor="let assignment of entity.assignments">
						<span class="lato-14-n-vw field-value"
							title="{{ assignment?.name?.replaceAll('_','')}}&nbsp;({{assignment.totalQuestions}})">{{
							assignment?.name?.replaceAll('_','
							')
							}}&nbsp;({{assignment.totalQuestions}})</span>
					</div>
				</span>
			</div>
		</div>
		<span
			*ngIf="item.entities && !expanded && item.entities.length && item.entities[0]?.assignments && item.entities[0].assignments.length > 1"
			(click)="toggleExpand($event)" class="lato-14-n-vw expand-toggle">
			{{'...' }}</span>
		<div *ngIf="!item.entities" class="lato-14-n-vw"> - </div>
	</div>
	<!-- Invitation date -->
	<div class="invitation-date item-value" [ngClass]="{'disable-text':isEditMode}">
		<span class="lato-14-n-vw">{{ item.user.invitationDate | date: 'dd.MM.yy' }}</span>
		<span class="lato-14-n-vw" *ngIf="!item.user.invitationDate"> - </span>
	</div>
	<div class="last-login item-value" [ngClass]="{'disable-text':isEditMode}">
		<span class="lato-14-n-vw">{{ lastUserActivity | date: 'dd.MM.yy' }}</span>
		<span class="lato-14-n-vw" *ngIf="!lastUserActivity"> - </span>
	</div>
	<div class="email item-value" [ngClass]="{'disable-text':isEditMode}">
		<span class="lato-14-n-vw field" (mouseenter)="checkIfEllipsis($event.target)"
			[ngbTooltip]="item.user.email" [disableTooltip]="!showColumnTooltip">{{ item.user.email
			}}</span>
	</div>
	<div class=" phone item-value justify-content-between" [ngClass]="{'disable-text':isEditMode}">
		<div class="phone-text">
			<span class="lato-14-n-vw" *ngIf="item.user.phone">{{
				hidePhoneNumber(item.user.phone)
				}}</span>
			<span class=" lato-14-n-vw" *ngIf="!item.user.phone"> - </span>
			<!-- <div *ngIf="isEditMode && isEditable && item.user.phone" class="edit-item-value">
        <input type="text" formControlName="phone">
      </div> -->
		</div>
		<!-- <span *ngIf="item.entities" class="more-toggle"
      [ngStyle]="{ 'letter-spacing': expanded ? '3px' : '2px', 'color': expanded ? 'white' : '#5e6374' }"
      (click)="toggleExpand($event)">
      ...
    </span> -->
		<div class="actions-col"
			*ngIf="(item.entities && item.entities[0].assignments && item.entities[0].assignments.length > 1) || (subEntityNames && subEntityNames.length>1)">
			<div class=" icon arrow" [ngClass]="{'right-arrow':!expanded}" cygovSvgIcon
				[svgName]="'arrow-down-type2-white'" (click)="toggleExpand($event)"></div>
		</div>
	</div>
</div>