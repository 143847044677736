<div class="sidenav-container " #sideNav [ngClass]="{'welcome-board': isWelcome}">
  <div *ngIf="isWelcome" class="guide">
    <div class="colored-circle" cygovSvgIcon [svgName]="'colored-circle1'" placement="left"></div>
    <div class="colored-arrow" cygovSvgIcon [svgName]="'colored-arrow'" placement="left">
    </div>
    <div class="lato-18-n-vw text">
      Full training center with videos and guides on the platform solutions and use cases
    </div>
  </div>
  <div class="menu-item"
    [ngClass]="{ 'entity-logo': (entity && entity.logo) || showBnbLogo || showMidMarketLogo }">
    <img class="entity-logo" *ngIf="(entity && entity.logoUrl) || showBnbLogo || showMidMarketLogo"
      [src]="showBnbLogo || showMidMarketLogo ? bnbLogoDir : entity.logoUrl"
      alt="{{ showBnbLogo || showMidMarketLogo ? bnbLogoAltName : entity.name }}" />
    <span class="lato-20-n-vw" *ngIf="entity && !entity.logoUrl">{{ entity.name }}</span>
  </div>

  <ng-container *ngFor="let item of menuItems">
    <button class="btn-type-link menu-item" [disabled]="item.disabled || isSideNavDisabled"
      [routerLink]="item.routerLink" [queryParams]="item.queryParams"
      (click)="item.click ? item.click() : closeModal();"
      [routerLinkActive]="item.click ? 'active activeWithEvents' : 'active'" [hidden]="item.hidden"
      [ngClass]="{'disabled': item.disabled, 'active-disable': (('/' + item.routerLink) !== currentRoutePath ) && !item?.subMenu?.length }">
      <span *ngIf="item.svgName && isParticipant" class="menu-item-ico" cygovSvgIcon
        [svgName]="item.svgName"></span>
      <span class="lato-18-n-vw capitalize menu-item-text">{{ item.name }}</span>
      <span *ngIf="item.subMenu" class="iconContainer" cygovSvgIcon
        [svgName]="'arrow-right-type3'"></span>
    </button>

    <div class="sub-menu">
      <ng-container *ngFor="let subItem of item.subMenu">
        <button class="btn-type-link sub-menu-item" [routerLink]="subItem.routerLink"
          [routerLinkActive]="subItem.active?'':'active'"
          (click)="subItem.click ? subItem.click() : closeModal(); moveToBoardPage(subItem)"
          [ngClass]="{'disabled':subItem.disabled, 'has-sub-menu-parent' : subItem.subItems , 'active':vendorMenuActive && subItem?.svgName?.toLowerCase() === 'vendors-icon' }"
          [hidden]="subItem.hidden || item.hidden"
          [disabled]="subItem.disabled || isSideNavDisabled">
          <span *ngIf="subItem.svgName" class="menu-item-ico" cygovSvgIcon
            [svgName]="subItem.name === 'upperdeck' && isBnB ? 'binoculars': subItem.svgName"></span>
          <span class="lato-16-n-vw capitalize menu-item-text marginal-top"
            [ngClass]="{'menu-item-text-netskope':isNetskope, 'has-sub-menu' : subItem.subItems}">{{
            subItem.name === 'upperdeck' &&
            isBnB ? 'Profit Center': subItem.name === 'collection' &&
            (isBnBCyberSite || isMidMarket) ? 'Application' : subItem.name }}</span>
          <!-- <span *ngIf="subItem.subItems" class="iconContainer-sub" cygovSvgIcon
            [svgName]="'arrow-right-type3'"></span> -->
          <span
            *ngIf="subItem.subItems && subItem.name === 'multi entity' || subItem.name === 'Reporting' || subItem.name === 'controls' || subItem.name === 'reporting' || subItem.name === 'risk register' "
            class="iconContainer-sub" cygovSvgIcon [svgName]="'arrow-right-type3'"></span>
        </button>
        <div [ngStyle]="{display: subItem.active || isFromThirdPartyCollection? 'block' : 'none'}"
          class="nested-menu nested-menu-overlay" *ngIf="subItem.subItems">
          <button *ngFor="let subMenuItem of subItem.subItems"
            class="btn-type-link sub-menu-item sub-menu-sub-item"
            [skipLocationChange]="skipLocation()" [routerLink]="subMenuItem.routerLink"
            [queryParams]="subMenuItem.queryParams" [state]="subMenuItem.state"
            [routerLinkActive]="subItem.active?'':'active'"
            [routerLinkActiveOptions]="{exact: true}" [disabled]="subMenuItem.disabled"
            [hidden]="subMenuItem.hidden"
            [ngClass]="{'sub-menu-sub-item-vendor' : subItem.name.toLowerCase() === sideNavEnum.VENDORS}">
            <span class="lato-16-n-vw capitalize menu-item-text marginal-top"
              [ngClass]="{'collection-menu' : subItem.name.toLowerCase() === sideNavEnum.COLLECTION}">{{subMenuItem.name}}</span>
          </button>
        </div>
      </ng-container>
    </div>

  </ng-container>
  <!-- Added following div to adjust the dropdown icon svg -->
  <div *ngIf="!disableDownArrow" class="drop-arrow"></div>
</div>

<div *ngIf="showScrollArrows">

  <div *ngIf="!isScroll" [ngClass]="disableUpArrow ? 'up-arrow-disabled' : 'up-arrow'"
    (click)="moveScroll(sideNav,arrowsEnum.UP)" cygovSvgIcon [svgName]="'arrow-up-type1'"></div>

  <div *ngIf="!isScroll" [ngClass]="disableDownArrow ? 'down-arrow-disabled' : 'down-arrow'"
    (click)="moveScroll(sideNav,arrowsEnum.DOWN)" cygovSvgIcon [svgName]="'arrow-down-type1'"></div>

</div>