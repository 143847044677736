import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRoleQuery, GetUserQuery, RoleEnum } from '../../API.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../user.service';

@Injectable()
export class AdminRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private logger: NGXLogger, private userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivate: canActivateChild was called');
    return this.checkUserRole();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivateChild: canActivateChild was called');
    return this.checkUserRole();
  }

  async checkUserRole() {
    try {
      const user: GetUserQuery = await this.userService.getCurrentUser();
      const userRole: GetRoleQuery = this.userService.getCurrentRole();
      if (
        user &&
        userRole &&
        (userRole.name.toLowerCase() === RoleEnum.ADMIN.toLowerCase() ||
          userRole.name.toLowerCase() === RoleEnum.MSSP.toLowerCase())
      ) {
        return true;
      } else {
        this.logger.error('checkUserRole - Admin: Unauthorized');
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      this.logger.error('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
}
