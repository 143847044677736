export enum UserPermissionEnum {
  EDIT = 'edit',
  VIEW_ONLY = 'view only',
}

export enum Roles {
  ADMIN = 'admin',
  MSSP = 'mssp',
  ENTITYLEADER = 'entity leader',
  SUBENTITYLEADER = 'subentity leader',
  PARTICPANT = 'participant',
}
export const rolePermissions = {
  [Roles.MSSP]: [Roles.PARTICPANT, Roles.ENTITYLEADER, Roles.SUBENTITYLEADER, Roles.MSSP, Roles.ADMIN],
  [Roles.ADMIN]: [Roles.PARTICPANT, Roles.ENTITYLEADER, Roles.SUBENTITYLEADER, Roles.MSSP, Roles.ADMIN],
  [Roles.ENTITYLEADER]: [Roles.PARTICPANT, Roles.ENTITYLEADER, Roles.SUBENTITYLEADER],
  [Roles.SUBENTITYLEADER]: [Roles.PARTICPANT, Roles.SUBENTITYLEADER],
  [Roles.PARTICPANT]: [Roles.PARTICPANT],
};
