<div class="modal-body">
    <div class="modal-header">
        <div class="user-icon-title-container">
            <span class="lato-18-n-vw message">
                {{ 'INSTANCE SETTINGS' }}
            </span>
        </div>
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
            (click)="modalResult.emit(true);" *ngIf="!isFrameworkGroupVisible">
        </div>
    </div>
    <div class='modal-content-body'>
        <div class="instance-settings-container">
            <div class="horizontal-seprator-centerlized"></div>
            <div class="client-side-key-management-container">
                <div class="heading-text text-white lato-16-n-vw">Client Side Key Management</div>
                <div class="active-inactive-buttons">
                    <div class="tab inActive-tab lato-16-n-vw"
                        [ngClass]="{'active': !this.kmsStatus}"
                        (click)="switchTabs(clientSideKeyManagementTabEnum.INACTIVE)">
                        Inactive
                    </div>
                    <div class="tab active-tab lato-16-n-vw" [ngClass]="{'active': this.kmsStatus}"
                        (click)="switchTabs(clientSideKeyManagementTabEnum.ACTIVE)">
                        Active
                    </div>
                </div>
            </div>
            <div class="horizontal-seprator-centerlized"></div>
            <cygov-domain-frameworks-listings [title]="'Choose framework'"
                [riskFrameWorkItems]="riskFrameWorkOpt" [complianceItems]="standardList"
                [vendorFrameworkItems]="vendorList"
                (showFrameworkGroupDetail)="handleFrameworkGroupDetail($event)"
                (clickOnSaveButton)="saveFrameworkSettings($event)"
                [isSelectAllVisible]="true"></cygov-domain-frameworks-listings>
        </div>
    </div>
    <div class="modal-content-footer" *ngIf="!isFrameworkGroupVisible">
        <div class="d-flex">
            <cygov-button class="button-margin" [buttonText]="'SAVE'" [buttonWidth]="'5vw'"
                [buttonColor]="'#29a9eb'" [hoverColor]="'#0F92E3'" (click)="saveButtonClicked()">
            </cygov-button>

            <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
                [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5vw'" [textColor]="'#C5C9D1'"
                (click)="modalResult.emit(true);">
            </cygov-button>
        </div>
    </div>
</div>


<div class="framework-settings-modal d-none">
    <div class='abs-centre frameworkLoading' *ngIf="isLoading">
        <div *cygovLoader="isLoading"></div>
    </div>
    <ng-container *ngIf="!isLoading">
        <div class="upper-part">
            <div class="lato-16-n-vw uppercase header-name heading">
                Domain Framework settings
            </div>
            <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                (click)="modalResult.emit(false);">
            </div>
        </div>
        <div class="middle-part">
            <div class="text-container certification w-100 align-in-row">

                <span class="key color-blue full-width mt-1">Risk Frameworks: </span>
                <div class="toggle-container align-in-row-component"
                    *ngFor="let riskFramework of riskFrameWorkOpt; let ir = index">
                    <div class="toggle-text">
                        <span class="value">{{ riskFramework.name }}</span>
                    </div>

                    <div class="toggle-btn-container">
                        <cygov-toggle-button [isChecked]="riskFramework.status"
                            (changed)="riskFramework.status = $event" [id]="'risk'+ir">
                        </cygov-toggle-button>
                    </div>
                </div>

                <span class="key color-blue full-width mt-3">Compliance : </span>
                <div class="toggle-container align-in-row-component"
                    *ngFor="let standards of standardList; let ic = index">
                    <div class="toggle-text">
                        <span class="value">{{ standards.name }}</span>
                    </div>

                    <div class="toggle-btn-container">
                        <cygov-toggle-button [isChecked]="standards.status"
                            (changed)="standards.status = $event" [id]="'compliance'+ic">
                        </cygov-toggle-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="end-part">
            <cygov-button class="save-settings-btn" [buttonText]="'SAVE'" [buttonWidth]="'5.2vw'"
                [buttonHeight]="'3.22vh'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'">
            </cygov-button>
        </div>
    </ng-container>
</div>