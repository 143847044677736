import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-invitation-sent-modal',
  templateUrl: './invitation-sent-modal.component.html',
  styleUrls: ['./invitation-sent-modal.component.scss'],
})
export class InvitationSentModalComponent implements OnInit {
  @Input() users: any[];
  @Output() modalResult = new EventEmitter<any>();
  exceptionReason: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.users = this.users || [];
  }
}
