<div class="cancel-cross-icon" *ngIf="showAddButton && !auditMode">
  <div class=" cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="cancelUser()"></div>
</div>
<div class="select-manager select-manager-section back-color-default lato-16-n-vw"
  *ngIf="!auditMode" [ngClass]="{'select-manager-midMarket' : isMidMarket}">
  <div *ngIf="!isENBD" class="manager-section-header">
    <span class="lato-16-n-vw title">{{listLabel}} <small
        *ngIf="isShowColon && isInformatica">:</small></span>
    <span *ngIf="showToolTip" tooltipClass="financial-tooltip"
      [ngbTooltip]="'Please note the broker must be an active user defined to a managment role in the platform.'"
      [placement]="'right'" class="broker-tooltip" cygovSvgIcon
      [svgName]="'info-board-icon'"></span>
    <span *ngIf="isRequiredLabel && isInformatica" class="required-asterisk">*</span>

    <!--Commenting this because of a major bug, once functionality implemented, will remove it asked by Ofir-->
    <!-- <div class="add-icon" (click)="addNewUserModal()" *ngIf="isInformatica">
      <span class="lato-18-b-vw">+</span>
    </div> -->

  </div>
  <div *ngIf="isENBD" class="horizontal-seperator"></div>
  <ng-container>
    <div *ngIf="isAddRiskOwner" class="horizontal-line"></div>
    <div *ngIf="isSearchShow && !isBulkVendor" class="search"
      [ngClass]="{'search-midMarket' : isMidMarket}">
      <div class="search-icon" cygovSvgIcon [svgName]="SearchIconSvgName"
        [ngClass]="{'search-icon-midMarket' : isMidMarket}"></div>
      <input type="text" autocomplete="off" class="form-control search-input lato-16-n-vw"
        aria-label="Search" placeholder="Search" spellcheck="true" name="search-manager"
        id="search-manager" [(ngModel)]="searchText" (keyup)="applyManagerSearch()"
        [ngClass]="{'search-input-midMarket' : isMidMarket}" />
    </div>

    <div *ngIf="isSearchShow && isBulkVendor" class="search-div">
      <div class="search-icon" cygovSvgIcon [svgName]="SearchIconSvgName"></div>
      <input type="text" class="form-control search-input lato-16-n-vw" aria-label="Search"
        placeholder="Search" spellcheck="true" name="search-manager" id="search-manager"
        [(ngModel)]="searchText" (keyup)="applyManagerSearch()" />
    </div>
    <div *ngIf="userListSeparator" class="user-list-separator-top"></div>
    <div class="users-list" id="users-list" [ngClass]="{'users-list-midMarket' : isMidMarket}">
      <div *ngFor="let user of filteredUserList, index as i" class="user-row"
        [ngClass]="user.checked?'user-row-highlight':''"
        [ngClass]="{'user-row-midMarket' : isMidMarket}">
        <cygov-checkbox [isChecked]="user.checked" [id]=" 'user-'+(i+1)"
          (changed)="changeSelectedManager(user)" [width]="1.8" [height]="1.8"
          [useViewPortDimensions]="true">
        </cygov-checkbox>
        <cygov-username-icon [showInitials]="showInitials"
          [username]="user.name"></cygov-username-icon>
        <div *ngIf="showUserName" class="lato-14-n-vw capitalize user-name" [ngbTooltip]="user.name"
          [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
          (mouseout)="isUserNameEllipsis = false" [disableTooltip]="!isUserNameEllipsis">
          {{showUserName ?
          user.name: ""}}
        </div>
        <div class="line-separator" *ngIf="isMidMarket"
          [ngClass]="{'line-seprator-midMarket': isMidMarket}"></div>
        <div *ngIf="showUserRole && !isShowRoleName" [ngbTooltip]="user?.role?.toLowerCase()"
          class="role"> IT | {{
          user.role.toLowerCase()}}
        </div>
        <div *ngIf="isShowRoleName" class="vertical-separator"></div>
        <div *ngIf="isShowRoleName" [ngbTooltip]="getRoleName(user?.roleId)" class="role"> {{
          getRoleName(user?.roleId)}}
        </div>

      </div>
    </div>
    <div *ngIf="userListSeparator" class="user-list-separator-bottom"></div>
    <div *ngIf="isAddRiskOwner" class="horizontal-line margin-up"></div>
    <div *ngIf="isENBD" class="end-line"></div>
    <cygov-button *ngIf="showAddButton" class="add-btn" [ngClass]="{'separator': isFromThirdParty}"
      [buttonText]=" 'ADD' " [buttonWidth]="'5vw'" [buttonHeight]="'3vh'"
      (click)="addSelectedUser()">
      <!-- (buttonPressed)="isValid()" > -->
    </cygov-button>
  </ng-container>
</div>

<div class="select-user back-color-default lato-16-n-vw" *ngIf="auditMode">
  <div class="user-section-header">
    <span class="lato-16-n-vw title">{{listLabel}}</span>
    <div (click)="addNewUser()" class="lato-16-n-vw add-button" cygovUserPermission>
      <span class="add-icon">+</span>
      <span class="button-text">Add User</span>
    </div>
  </div>
  <div class="new-users-list" id="users-list">
    <div *ngFor="let n of newUsersList" class="display-users">
      <input type="text" id="name" placeholder="User Name"
        class="lato-14-n-vw new-user-row user-name" [(ngModel)]="n.name" />
      <input type="text" id="email" placeholder="Email" class="lato-14-n-vw new-user-row user-email"
        [(ngModel)]="n.email" (change)="addUsers($event)" />
      <div class='delete-user-btn input-icon' (click)="disableField(n)">
      </div>
    </div>
    <div *ngFor="let user of addedUsers, index as i" class="display-users">
      <input type="text" id="name" class="lato-14-n-vw new-user-row user-name"
        [(ngModel)]="user.name" />
      <input type="text" id="email" placeholder="Email" class="lato-14-n-vw new-user-row user-email"
        [(ngModel)]="user.email" (change)="updateUsers(user, i)" />
      <div class='delete-user-btn input-icon' (click)="removeUserFromList(i)">


        <!-- <span class="lato-14-n-vw new-user-row user-name"> {{user.name}}</span>
        <span class="lato-14-n-vw new-user-row user-email"> {{user.email}}</span>
        <div class='delete-user-btn' cygovSvgIcon [svgName]="'x-icon'"
          (click)="removeUserFromList(i)"></div> -->
      </div>

    </div>
  </div>