<div class="main-body-req">
    <div class="upper-section">

        <!-------------------------------------REQUIREMENTS SELECTION AREA------------------------------------->
        <div class="left-section">
            <div class="title-name">
                {{frameworkTypes.requirement + 's'}}
                <!-- Hiding this button for now as functionality isn't implemented -->
                <cygov-add-button (click)="openAddVendorModal(frameworkTypes.requirement)"
                    [titleName]="'Add New'" [textSize]="'14'" [textColor]="'#fffff'"
                    [buttonWidth]="'4.2vw'">
                </cygov-add-button>
            </div>
            <div class="requirements-listings">
                <ng-container *ngFor="let req of totalArtifactList; let idx=index">
                    <div class="req-container lato-14-n-vw">


                        <div class="icon-wrapper">
                            <div *ngIf="req.isUserAdded" class="cross-icon"
                                (click)="removeUserAddedCert(req, 'requirements')">
                                <div>X</div>

                            </div>
                        </div>

                        <cygov-toggle-button [isChecked]="req.isQualify" class="toggling-button"
                            (changed)="req.isQualify = $event; updateRequirements(req)"
                            [id]="'req-toggle'+idx">
                        </cygov-toggle-button>
                        <span class="capitalize">{{req.name}}</span>
                        <!-- hiding icon as functionality isn't implemented yet -->
                        <div class="delete-file-icon" *ngIf="req.templateURL"
                            tooltipClass="customized-tooltip text-truncate"
                            [ngbTooltip]="req.templateURL.name">
                            <div class="cross-icon" (click)="deleteTemplate(req)">
                                <div>X</div>
                            </div>
                            <div class="file-icon" cygovSvgIcon [svgName]="'file-icon-simple'">
                            </div>
                        </div>

                        <!-- hiding icon as functionality isn't implemented yet -->
                        <div class="upload-file-icon" *ngIf="req.isQualify "
                            [ngClass]="{'add-extra-margin': !req.templateURL }">
                            <div class="bk-circle" tooltipClass="customized-tooltip"
                                [ngbTooltip]="'Upload Template'">
                                <label class="arrow-up-icon" cygovSvgIcon
                                    [svgName]="'arrow-up-type3'" for="{{'myFile' + idx}}">
                                </label>
                                <input type="file" [uploader]="uploader" [id]="'myFile' + idx"
                                    ng2FileSelect style="display:none"
                                    accept=".gif, .jpg, .png, .doc, .docx, .csv, .pdf, .xls, .xlsx, .ppt, .txt"
                                    (onFileSelected)="uploadTemplateArtifacts($event,req)" />
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="vert-seprator" *ngIf="isInformatica"></div>
        <!----------------------------------------CERTIFICATION SELECTION AREA------------------------------------------>
        <div class="right-section">
            <div class="title-name">
                {{frameworkTypes.certificate + 's'}}
                <!-- Hiding this button as the functionality isn't implemented yet -->
                <cygov-add-button (click)="openAddVendorModal(frameworkTypes.certificate)"
                    [titleName]="'Add New'" [textSize]="'14'" [textColor]="'#fffff'"
                    [buttonWidth]="'4.2vw'">
                </cygov-add-button>
            </div>
            <div class="certification-listings">
                <ng-container *ngFor="let cert of totalCertificateList;let idx=index">
                    <div class="cert-container lato-14-n-vw">

                        <div *ngIf="isInformatica" class="icon-wrapper">
                            <div *ngIf="cert.isUserAdded" class="cross-icon"
                                (click)="removeUserAddedCert(cert, 'certifications')">
                                <div>X</div>
                            </div>
                        </div>

                        <cygov-toggle-button [isChecked]="cert.isQualify" class="toggling-button"
                            (changed)="cert.isQualify = $event ; updateCertifications(cert)"
                            [id]="'cert-toggle'+idx">
                        </cygov-toggle-button>
                        <span class="capitalize">{{cert.name}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="horizs-seprator" *ngIf="isInformatica"></div>
    <div class="lower-section">

        <!----------------------------------------ACTIVE SCAN SELECTION AREA------------------------------------------>
        <div class="title-name">
            Active Scan
        </div>
        <div class="content-to-scroll">
            <div class="third-party-area lato-14-n-vw">
                <cygov-toggle-button [isChecked]="isThird" class="toggling-button"
                    (changed)="updateIsThirdParty($event)" [id]="'req-toggle-third'"
                    [isChecked]="additionalData.isThirdParty">
                </cygov-toggle-button>
                3rd Party

                <div class="temp-glitch-fix">

                    <cygov-add-button *ngIf="additionalData.isThirdParty" [titleName]="'Add Domain'"
                        [textSize]="'14'" [textColor]="'#fffff'"
                        (click)="additionalData.domains.unshift({ value: '' })"
                        [buttonWidth]="'5.13vw'">
                    </cygov-add-button>
                    <div class="domain-listings">
                        <ng-container
                            *ngFor="let domain of additionalData.domains; let idx = index">
                            <div class="overflow-container">
                                <div class="single-domain-input">
                                    <input class="input-domain-styling" type="text"
                                        [ngModel]="domain.value" placeholder="Fill Domain"
                                        (ngModelChange)="domain.value=$event;updateDomains(idx, domain.value)" />
                                    <div class="cancel-domain"
                                        (click)="additionalData.domains.splice(idx, 1)">
                                        x
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="fourth-party-area lato-14-n-vw">
                <cygov-toggle-button [isChecked]="isFourth" class="toggling-button"
                    (changed)="updateIsFourthParty($event)" [id]="'req-toggle-fourth'"
                    [isChecked]="additionalData.isFourthParty">
                </cygov-toggle-button>
                4th Party
            </div>
        </div>

    </div>
</div>