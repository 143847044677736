import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MidMarketFileUploadComponent } from './mid-market-file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { UsernameIconModule } from 'app/shared/username-icon/username-icon.module';
import { ProgressBarModule } from 'app/shared/progress-bar/progress-bar.module';

@NgModule({
  declarations: [MidMarketFileUploadComponent],
  imports: [CommonModule, FileUploadModule, SvgIconModule, UsernameIconModule, ProgressBarModule],
  exports: [MidMarketFileUploadComponent],
})
export class MidMarketFileUploadModule {}
