/* eslint-disable max-len */

export class SSOinfo {
  static CognitoHostedUiBaseUrl: string = 'centraleyes-ENV.auth.REGION.amazoncognito.com';
  static CognitoHostedUiFullUrl: string =
    'https://centraleyes-ENV.auth.REGION.amazoncognito.com/oauth2/authorize?identity_provider=PROVIDER&redirect_uri=REDIRECT_URI&response_type=CODE&client_id=CLIENT_ID';

  static SSOClientId: any = {
    'localhost:4200': '6i3ooua7bjr1ckrhtij0shkv3j',
    'dev.cygovdev.com': '3l3j6k253fg4uarbarkr3govl8',
    'stage.cygovrmp.com': '2ls50e3s6gbhjch2llmggn1b6g',
    'cyber-in-site.bbrown.com': '6as027b7j5v6u908e974tb9b7s',
  };

  static Oauth2AuthorizeURL: string = `https://${SSOinfo.CognitoHostedUiBaseUrl}/oauth2/authorize`;

  static authorizedScopes: string = 'phone email openid profile aws.cognito.signin.user.admin';
}
