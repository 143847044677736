<div class="walkthrough-videos">
  <div class="video-icon-wrapper" (click)="openModal(videoModal)">
    <!-- <div class="new-indication" cygovSvgIcon [svgName]="'new-indication-framework'"></div> -->
    <div class="video-icon" [ngClass]="{'disabled' : !videoPath}" cygovSvgIcon
      [svgName]="'video-icon'">
    </div>

  </div>

  <ng-template #videoModal>
    <div class="modal-body">
      <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="modalRef.close()">
      </div>
      <div class="video-wrapper section" *ngIf="videoPath">
        <video controls disablePictureInPicture>
          <source [src]="videoPath" type="video/mp4" />
        </video>
      </div>
    </div>
  </ng-template>
</div>