import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// AWS Auth Modules.
import { Amplify } from 'aws-amplify';
import { SSOinfo } from 'app/auth/auth.constants';
import awsmobile from './aws-exports';

const { host, origin } = window.location;
const envName = environment.name === 'local' ? 'dev' : environment.name;
const cognitoDomain = SSOinfo.CognitoHostedUiBaseUrl.replace('ENV', envName).replace('REGION', environment.region);
const authorizedScopes = SSOinfo.authorizedScopes.split(' ');

awsmobile.oauth = {
  domain: cognitoDomain,
  scope: authorizedScopes,
  redirectSignIn: origin,
  redirectSignOut: origin,
  responseType: 'code',
};

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (awsmobile.oauth.redirectSignIn.includes(',')) {
  const filterHost = url => new URL(url).host === host;
  awsmobile.oauth.redirectSignIn = awsmobile.oauth.redirectSignIn.split(',').filter(filterHost).shift();
  awsmobile.oauth.redirectSignOut = awsmobile.oauth.redirectSignOut.split(',').filter(filterHost).shift();
}

Amplify.configure(awsmobile);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
