import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RiskImpactEnum } from 'app/API.service';
import { ImpactTextEnum } from 'app/shared/enums/impact.enum';
import { ImpactTextEnum as ImpactValueEnum } from 'app/shared/enums/board.enum';
import { EnbdVendorConstant } from '../add-vendor/add-new-vendor-modal/enbd-vendor.constant';

@Component({
  selector: 'cygov-enbd-domain-popup',
  templateUrl: './enbd-domain-popup.component.html',
  styleUrls: ['./enbd-domain-popup.component.scss'],
})
export class EnbdDomainPopupComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() domain = new EventEmitter<any>();
  @Input() updatedDomainList = [];
  @Input() domainList = [];
  @Input() selectedDomains = [];

  domainOptions: any[];
  selectedSurvey: RiskImpactEnum;
  surveys;
  constructor() {}

  ngOnInit(): void {
    this.surveys = Object.keys(ImpactTextEnum).map(key => {
      return ImpactTextEnum[key] === ImpactTextEnum.CRITICAL
        ? ImpactValueEnum.CRITICAL.toLowerCase()
        : ImpactTextEnum[key];
    });
    this.domainOptions = this.domainList;
    this.selectedDomains = this.selectedDomains?.map(domain => {
      return domain?.toLowerCase();
    });

    // converting default domains to LowerCase
    const enbdDefaultDomains = EnbdVendorConstant.categories[0].defaultDomains.map(domain => domain.toLowerCase());
    this.domainOptions = this.domainOptions?.map((str, index) => {
      if (typeof str === 'string') {
        return {
          name: str,
          id: index + 1,
          isQualify: this.selectedDomains.includes(str?.toLowerCase()),
          defaultSelected: enbdDefaultDomains.includes(str?.toLowerCase()),
        };
      } else {
        str.isQualify = true;
        return str;
      }
    });

    this.changeStatus();

    this.selectedSurvey = RiskImpactEnum.CRITICAL;
  }
  changeStatus(): void {
    if (this.updatedDomainList?.length) {
      this.updatedDomainList.forEach(updatedOpt => {
        this.domainOptions.forEach(impactOpt => {
          if (updatedOpt.name?.toLowerCase() === impactOpt?.name?.toLowerCase()) {
            impactOpt.isQualify = updatedOpt.isQualify;
          }
        });
      });
    }
  }

  closePopUp(): void {
    this.closeModal.emit(true);
  }

  getImpactText(survey: string): string {
    return survey.toLowerCase().split('_').join('-');
  }

  saveImpact(): void {
    this.domain.emit(this.domainOptions);
    this.closeModal.emit(true);
  }
}
