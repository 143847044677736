import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent implements OnInit {
  @Input() titleName: string = 'Add New';
  @Input() textSize: string = '12';
  @Input() textWeight: string = 'n';
  @Input() textColor: string = '#00000';
  @Input() buttonWidth: string = '4.87vw';
  @Input() circleSize: string = '0.9vw';

  constructor() {}

  ngOnInit(): void {}
}
