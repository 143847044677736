import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cygov-button',
  templateUrl: './cygov-button.component.html',
  styleUrls: ['./cygov-button.component.scss'],
})
/**
 * This is the Centraleyes Button Component
 * A default component to show a button with the default given
 * size which is mostly used almost everywhere in the app
 * by default the Minimum parameter you need to pass is the
 * @param {string} buttonText parameter. All the rest of the
 * parameters are optional.
 */
export class CygovButtonComponent implements OnInit {
  @Input() buttonWidth = 'fit-content'; // by default it is fit-content,
  // meaning the size of the Text will be the width of the button,else pass '12vw' as size.
  @Input() buttonHeight = '2.963vh'; // this is the default height of the button,
  // you can also add a custom height '4vh' like so
  @Input() textSize = '14'; // the text size, do note pass the number which we usually use in lato-14-n-vw Font-Style class
  @Input() buttonText = 'BUTTON'; // the text that shows on the button
  @Input() buttonColor = ''; // the default background color is Cerulean, but other colors in Hex Format can be passed
  @Input() textColor = '#ffffff'; // the default text color which is white
  @Input() boldType = 'n'; // the weight of the text( b for Bold | n for Normal | l for Light )
  @Input() addIcon = ''; // pass the name of the svg icon and it will be displayed on the right side of the text
  @Input() disableClick = false; // disable the click function
  @Input() fullyDisabled = false; // fully disable the button
  @Input() buttonBorder = ''; // add border color to button
  @Input() borderRadius = ''; // give button borders radius
  @Input() hoverColor = ''; // the hover color of button if needed
  @Input() hoverText = ''; // the hover text of button if needed
  @Input() onHoverTextColor = '';
  @Input() textMargin = '0 0.7vw';
  defaultTextColor = '#ffffff';

  @Output() buttonPressed = new EventEmitter<boolean>();

  actualColor = '';
  actualText: string = '';

  constructor() {}

  ngOnInit(): void {
    this.defaultTextColor = this.textColor;
    if (this.buttonColor) {
      this.actualColor = this.buttonColor;
    }
    if (this.hoverText) {
      this.actualText = this.buttonText;
    }
  }

  buttonClicked(): void {
    if (!this.disableClick) {
      this.buttonPressed.emit(true);
    }
  }

  hoverColorChange(state): void {
    if (this.actualColor && this.hoverColor) {
      this.buttonColor = state ? this.hoverColor : this.actualColor;
    }
    // Changing text on hovering
    if (this.buttonText && this.hoverText) {
      this.buttonText = state ? this.hoverText : this.actualText;
    }

    this.textColor = state && this.onHoverTextColor ? this.onHoverTextColor : this.defaultTextColor;
  }
}
