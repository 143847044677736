import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomDropDownComponent } from './custom-drop-down.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { CheckboxModule } from '../../shared/checkbox/checkbox.module';

@NgModule({
  declarations: [CustomDropDownComponent],
  imports: [CommonModule, SvgIconModule, PipesModule, NgSelectModule, FormsModule, CheckboxModule],
  exports: [CustomDropDownComponent],
})
export class CustomDropDownModule {}
