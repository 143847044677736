import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'cygov-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Output() changed = new EventEmitter<boolean>();
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() id: string;
  @Input() isToggleMultiEntity: boolean = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.toggleStateChanged$.subscribe(status => {
      this.isChecked = status;
    });
  }
}
