import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainFrameworksListingsComponent } from './domain-frameworks-listings.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovButtonModule } from '../cygov-button/cygov-button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule } from '@angular/forms';
import { ToggleButtonModule } from '../toggle-button/toggle-button.module';
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';

@NgModule({
  imports: [
    CommonModule,
    CygovButtonModule,
    SvgIconModule,
    NgbModule,
    SvgIconModule,
    FormsModule,
    CheckboxModule,
    ToggleButtonModule,
    NgbTooltipModule,
    CygovLoaderModule,
  ],

  declarations: [DomainFrameworksListingsComponent],
  exports: [DomainFrameworksListingsComponent],
  entryComponents: [DomainFrameworksListingsComponent],
})
export class DomainFrameworksListingsModule {}
