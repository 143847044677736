<div class="modal-body exception-modal">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <div class="user-icon" cygovSvgIcon [svgName]="'trash-icon-2'">
      </div>&nbsp;
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <div class="lato-20-n-vw message">
    <div>Please note you are about to remove <span class="task-num">{{users.length > 1 ?
        users.length + ' selected admins' : users[0]['user'].name}}</span>
      from users settings section</div>
  </div>
  <div class="lato-18-n-vw delete-confirm">Type the word confirm to delete
    <input type="text" #confirmation class="delete-input"
      (keyup)="onValueChanged(confirmation.value)" />
  </div>
  <div class="btns-group">
    <button class="btn btn-confirm" [disabled]="confirmationString !== 'confirm'"
      (click)="confirm()">Confirm</button>
    <button class="btn btn-cancel" (click)="activeModal.close()">Cancel</button>
  </div>
</div>