import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { LargeNumberInputComponent } from './large-number-input.component';
import { SelectModule } from 'app/shared/select/select.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, SvgIconModule, SelectModule, PipesModule, ReactiveFormsModule],
  declarations: [LargeNumberInputComponent],
  exports: [LargeNumberInputComponent],
  entryComponents: [LargeNumberInputComponent],
})
export class LargeNumberInputModule {}
