<div class="upper-container">
    <div class="background">
        <div cygovSvgIcon [svgName]="'centraleyes'" class="logo"></div>
        <div class="container">
            <h6 class="heading lato-22-n-vw">Welcome to Step 2 of 2 of your cyber insurance
                application
                process</h6>
            <br />
            <p class="content lato-18-n-vw">Please fill out the following carrier application so our
                team can
                work on finding you the optimal insurance coverage and rates.
            </p>
            <p class="content lato-18-n-vw">We’ve prepared a short video walk-through of how to work
                with our
                digital application. Please take a few minutes and watch this, for the optimal
                experience.</p>
            <div class="video-section">
                <div *ngIf="!isVideoPlayed" class="mid-screen-control">
                    <div class="play-btn" (click)="onPlay()">
                        <div ngbTooltip="Play" cla ss="play-icon"
                            [ngClass]="{'disabled': !videoTitle.length}" cygovSvgIcon
                            [svgName]="'play-button'" placement="left" (click)="onPlay()"></div>
                    </div>
                </div>
                <video #videoPlayer controls [poster]="videoPoster" (play)="onPlay()"
                    (pause)="onPause()">
                    <source *ngIf="videoTitle.length" [src]="videoPath" type="video/mp4" />
                </video>
            </div>
            <br />
            <cygov-button [buttonText]="'GET STARTED >'" [buttonHeight]="'2.7vh'"
                [buttonColor]="'#29a9eb'" (buttonPressed)="nextButtonHandler()"></cygov-button>

        </div>
    </div>

</div>