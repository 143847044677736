import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';

export class UsersSettingConstant {
  public static headerData: any[] = [
    {
      value: 'name',
      class: 'name',
      name: 'Name',
      isSelectable: true,
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'role',
      class: 'role_header',
      name: 'Role',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'permission',
      class: 'permission_header',
      name: 'Permission',
      isSortApplicable: false,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'access',
      class: 'access_header',
      name: 'Access',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'framework',
      class: 'framework',
      name: 'Assigned',
      isSortApplicable: false,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'invitationDate',
      class: 'invitation-date',
      name: 'Invitation Date',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'lastLogin',
      class: 'last-login',
      name: 'Last Login',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'email',
      class: 'email',
      name: 'Email',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
    {
      value: 'phone',
      class: 'phone',
      name: 'Phone',
      isSortApplicable: false,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.NONE,
    },
  ];
}
