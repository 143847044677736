<div class="modal-body exception-modal">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <div class="user-icon" cygovSvgIcon [svgName]="'resend-invitation'">
      </div>
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <div class="lato-20-n-vw message">
    <div>Invitation was sent successfully to <span class="task-num">{{users.length > 1 ?
        users.length + " selected users" : users[0].user.name}}</span></div>
  </div>
</div>