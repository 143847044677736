const API = 'api';

const LOGGEDIN = API.concat('/loggedIn');

const STATE_LEADER = LOGGEDIN.concat('/state_leader');
const AGENCY_LEADER = LOGGEDIN.concat('/agency_leader');
const JIRA = LOGGEDIN.concat('/jira');

// binary-edge services
const BINARY_EDGE = LOGGEDIN.concat('/binary-edge');
export const BINARY_EDGE_DATALEAKS = BINARY_EDGE.concat('/dataleaks');
export const BINARY_EDGE_DATALEAKS_INFO = (leakId: string) => BINARY_EDGE.concat(`/dataleaks/${leakId}`);
export const BINARY_EDGE_SCORE = BINARY_EDGE.concat('/score');
export const BINARY_EDGE_CVE = BINARY_EDGE.concat('/cve');
export const BINARY_EDGE_DOMAINS = BINARY_EDGE.concat('/domains');
export const BINARY_EDGE_HOST = BINARY_EDGE.concat('/host');
export const BINARY_EDGE_SENSORS = BINARY_EDGE.concat('/sensors');
export const BINARY_EDGE_JOBS = BINARY_EDGE.concat('/jobs');
export const BINARY_EDGE_JOB_REPLAY = (jobId: string) => BINARY_EDGE.concat(`/jobs/${jobId}/replay`);
export const BINARY_EDGE_JOB_REVOKE = (jobId: string) => BINARY_EDGE.concat(`/jobs/${jobId}/revoke`);

// tenable services
const TENABLE = LOGGEDIN.concat('/tenable');
export const TENABLE_ANALYSIS = TENABLE.concat('/analysis');
export const TENABLE_SCAN_RESULT = TENABLE.concat('/scanResult');

// Rapid7 services
const RAPID7 = LOGGEDIN.concat('/rapid7');
export const RAPID7_ANALYSIS = RAPID7.concat('/analysis');
export const RAPID7_SITES = RAPID7.concat('/sites');
export const RAPID7_SCAN_RESULT = RAPID7.concat('/scan-result');
export const RAPID7_SCAN_RESULT_DETAIL = RAPID7.concat('/scan-result/detail');

// jira
export const JIRA_IS_AUTH_VALID = JIRA.concat('/isAuthValid');
export const JIRA_SAVE_DETAILS = JIRA.concat('/saveJiraDetails');
export const JIRA_GET_TASK = JIRA.concat('/getTask');
export const JIRA_UPDATE_TASK = JIRA.concat('/updateTask');
export const JIRA_EXPORT = JIRA.concat('/export');
export const JIRA_WEBHOOK = JIRA.concat('/webhooks');
export const JIRA_GET_BOARD = JIRA.concat('/getBoard');

// Report Services
const REPORT = LOGGEDIN.concat('/report');
export const DOWNLOAD_BOARD_REPORT = REPORT.concat('/board/download');
export const DOWNLOAD_OVERALL_REPORT = STATE_LEADER.concat('/report/overall/download');
export const DOWNLOAD_CRB_REPORT = AGENCY_LEADER.concat('/report/crb/download');
export const DOWNLOAD_CSV_REPORT = AGENCY_LEADER.concat('/report/crb/csv');
export const DOWNLOAD_MPL_REPORT = STATE_LEADER.concat('/report/crb/mpl');

// **********************  Paths for S3   ********************************
// --> Folders names in S3
const GLOBAL_OBJECTS = 'GLOBAL_OBJECTS/';
const FIRST_PARTY = 'first_party_enums.json';
const THIRD_PARTY = 'third_party_enums.json';

export const FRAMEWORK_RANGES_PATH = 'FRAMEWORK_RANGES/';
export const frameworkRangeMapPath = GLOBAL_OBJECTS + 'default_ranges.json';
// --> Complete Paths to export
export const FIRST_PARTY_ENUMS_PATH = GLOBAL_OBJECTS + FIRST_PARTY;
export const THIRD_PARTY_ENUMS_PATH = GLOBAL_OBJECTS + THIRD_PARTY;
