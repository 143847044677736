import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { LargeNumberInputModule } from 'app/shared/large-number-input/large-number-input.module';
import { CircularProgressComponent } from './circular-progress.component';
import { ScoreColorModule } from '../score-color/score-color.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CircularProgressComponent],
  imports: [CommonModule, ScoreColorModule, NgbModule, SvgIconModule, LargeNumberInputModule, FormsModule],
  exports: [CircularProgressComponent],
})
export class CircularProgressModule {}
