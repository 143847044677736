import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadStateEnum, UploadStateStyleEnum } from './upload-file.enum';
import { UploadFileService } from './upload-file.service';

@Component({
  selector: 'cygov-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
  @Output() closeModalClicked: EventEmitter<any> = new EventEmitter();
  @Output() totalUploadedFiles: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() uploadPath;
  @Input() isHiTrust = false;
  @Input() isFakeUpload = false;
  @Input() requiredInfo: any;
  @Input() uploadType: string = '';
  @Input() displayMessage: string = 'Uploading File/Files';
  @Input() restrictFileDrop: string = '';
  @Input() loaderType: UploadStateStyleEnum;
  @Input() invalidFile: boolean = false;
  @Input() multiSelect = true;
  @Input() isVendorBulkUpload = false;
  subscription: Subscription;
  uploadProcess: string;
  isClosed: boolean;

  constructor(private uploadService: UploadFileService) {
    this.uploadProcess = '';
    this.subscription = new Subscription();
  }
  ngOnInit(): void {
    this.subscription.add(
      this.uploadService.getUploadState().subscribe(state => {
        if (!this.invalidFile) {
          this.uploadProcess = state;
        }
      })
    );
    this.uploadService.setCurrentUser();
    this.uploadService.setUploadType(this.isFakeUpload);
  }

  get connectionResult(): typeof UploadStateEnum {
    return UploadStateEnum;
  }

  closeIt(event): void {
    this.closeModalClicked.emit(event);
  }

  ngOnDestroy(): void {
    this.uploadService.setUploadState(UploadStateEnum.UPLOAD_NOT_STARTED);
    this.subscription.unsubscribe();
  }

  closeModal(): void {
    this.closeModalClicked.emit(true);
  }
}
