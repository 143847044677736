export enum VendorNotifyTypeEnum {
  NOTIFY_MANAGER_NEW_VENDOR_USER = 'NOTIFY_MANAGER_NEW_VENDOR_USER',
  REMIND_VENDOR_TO_COMPLETE_ASSESSMENT = 'REMIND_VENDOR_TO_COMPLETE_ASSESSMENT',
  REMIND_VENDOR_ASSESSMENT_OVERDUE = 'REMIND_VENDOR_ASSESSMENT_OVERDUE',
  NOTIFY_MANAGER_VENDOR_NEW_FINDING = 'NOTIFY_MANAGER_VENDOR_NEW_FINDING',
  NOTIFY_MANAGER_ASSESSMENT_COMPLETED = 'NOTIFY_MANAGER_ASSESSMENT_COMPLETED',
  NOTIFY_MANAGER_VENDOR_RISK_DROPPED = 'NOTIFY_MANAGER_VENDOR_RISK_DROPPED',
  NOTIFY_VENDOR_NEW_REQUIREMENT = 'NOTIFY_VENDOR_NEW_REQUIREMENT',
  NOTIFY_MANAGER_VENDOR_REASSESSMENT = 'NOTIFY_MANAGER_VENDOR_REASSESSMENT',
}

export enum EntityNotifyTypeEnum {
  NOTIFY_USER_NEW_TASK_ASSIGN = 'NOTIFY_USER_NEW_TASK_ASSIGN',
  NOTIFY_USER_NEW_QUESTION_ASSIGN = 'NOTIFY_USER_NEW_QUESTION_ASSIGN',
  REMIND_USER_TO_COMPLETE_ASSESSMENT = 'REMIND_USER_TO_COMPLETE_ASSESSMENT',
  REMIND_USER_TO_COMPLETE_TASK = 'REMIND_USER_TO_COMPLETE_TASK',
  NOTIFY_USER_REASSESSMENT_STARTED = 'NOTIFY_USER_REASSESSMENT_STARTED',
  NOTIFY_REVIEWER_NEW_USER_ADDED = 'NOTIFY_REVIEWER_NEW_USER_ADDED',
  NOTIFY_MANAGER_CRITICAL_ALERT = 'NOTIFY_MANAGER_CRITICAL_ALERT',
  NOTIFY_CENTRALEYES_NEW_FRAMEWORK_REQUEST = 'NOTIFY_CENTRALEYES_NEW_FRAMEWORK_REQUEST',
  NOTIFY_CENTRALEYES_EXTERNAL_SCAN_REQUEST = 'NOTIFY_CENTRALEYES_EXTERNAL_SCAN_REQUEST',
}

export enum ReviewerApprovalEnum {
  NOTIFY_USER_AFTER_REVIEW = 'NOTIFY_USER_AFTER_REVIEW',
  NOTIFY_REVIEWER_AFTER_USER_ACTION = 'NOTIFY_REVIEWER_AFTER_USER_ACTION',
}

export enum MidMarketTypeEnum {
  BLOCKER_USER_EMAIL_NOTIFICATION = 'BLOCKER_USER_EMAIL_NOTIFICATION',
}

export enum AssessmentTypeEnum {
  ASSESSMENT_UNLOCKED = 'ASSESSMENT_UNLOCKED',
  ASSESSMENT_FINALIZED = 'ASSESSMENT_FINALIZED',
}
