import { FileTypeEnum, RoleEnum } from 'app/API.service';
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'app/shared/file.service';
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute } from '@angular/router';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';
import { DocumentType } from 'app/API.service';
import { Animations } from '../entity-wizard-pop-up/animation';
import { UtilsService } from '../utils.service';
import { FRAMEWORK_TYPES } from 'app/shared/enums/shared.enum';

export interface CustomFrameworkTypes {
  SCANS: [];
  DOCUMENTS: [];
  OTHERS: [];
  CERTIFICATIONS: [];
}

@Component({
  selector: 'cygov-add-new-custom-framework',
  templateUrl: './add-new-custom-framework.component.html',
  styleUrls: ['./add-new-custom-framework.component.scss'],
  animations: [
    Animations.fadeInOutAnimation,
    Animations.fadeInOutAnimationDelayed,
    Animations.domainHeightAnimation,
    Animations.fadeInOnly,
  ],
})
export class AddNewCustomFrameworkComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();
  @Input() isDefaultSetting: boolean = false;
  @Input() isForVendor: boolean = false; // is true if from the artifact tab
  @Input() type: string = '';
  @Input() label: string = '';
  @Input() vendorId: string = '';
  @Input() isCategoryDropdownDisabled = false;
  @Input() customDefaultTypes: CustomFrameworkTypes;
  @Input() customVendorReqs: CustomFrameworkTypes;
  @Input() isManuallyUploaded: boolean = false;
  DocumentType = DocumentType;
  DEFAULT = 'default';
  showCategoryTooltip: boolean = false;
  isInformatica: boolean = UtilsService.isInformatica;
  categoryTypesList = [
    { name: 'Others', value: DocumentType.OTHERS, selected: true }, // default
    { name: 'Scans', value: DocumentType.SCANS, selected: false },
    { name: 'Documents', value: DocumentType.DOCUMENTS, selected: false },
  ];
  typesList = {
    SCANS: [
      { name: 'Penetration Testing', value: 'penetration_testing', selected: false, isDefault: true },
      { name: 'Vulnerabilities', value: 'vulnerabilities', selected: false, isDefault: true },
    ],
    DOCUMENTS: [
      { name: 'Information Security', value: 'information_security_policy', selected: false, isDefault: true },
      { name: 'Cyber Insurance', value: 'Cyber Insurance', selected: false, isDefault: true },
      { name: 'Non-Disclosure Agreement', value: 'non-disclosure_agreement', selected: false, isDefault: true },
    ],
    OTHERS: [],
    CERTIFICATIONS: [],
    Artifact: [],
  };

  displayedTypesList = [];
  frameworkTypes = FRAMEWORK_TYPES;

  selectedTypeOthers = null; // no need to show in "others" case
  customFrameworkName = '';
  selectedFileTemplate = '';
  uploader: FileUploader;
  uploadPath = '';
  selectedFileName = '';
  isNewClicked: boolean = false;
  newlyAddedItem: string = null;
  isCategoryExpanded = false;
  isTypeExpanded = false;
  rootEntityId = '';
  newAddedItems = [];
  fileType: string = 'CUSTOM_FRAMEWORK';

  ngbModalOptionsTasks: NgbModalOptions = {
    size: 'sm',
    windowClass: 'modal-template-cont new-custom-framework-confirmation',
    backdropClass: 'new-custom-framework-confirmation-backdrop md-modal-template-backdrop',
    backdrop: 'static',
  };
  selectedType = null;

  saveOptions = [
    {
      name: 'This Vendor',
      value: 'vendor',
    },
    {
      name: 'Default Settings',
      value: 'default',
    },
  ];

  selectedTemplateType = this.saveOptions[0].value;
  closeModalInstance = null;
  showTextTooltip: boolean = false;
  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private fileService: FileService,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit(): Promise<void> {
    this.rootEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.newAddedItems = [];
    // this.typesList.Artifact = this.typesList.OTHERS = [...this.typesList.SCANS, ...this.typesList.DOCUMENTS];
    this.typesList.OTHERS = [...this.typesList.SCANS, ...this.typesList.DOCUMENTS];
    switch (this.type) {
      case this.frameworkTypes.document:
        this.selectedType = DocumentType.DOCUMENTS;
        break;
      case this.frameworkTypes.scan:
        this.selectedType = DocumentType.SCANS;
        break;
      case this.frameworkTypes.requirement:
        this.selectedType = DocumentType.OTHERS;
        break;
      case this.frameworkTypes.other:
        this.selectedType = DocumentType.OTHERS;
        break;
      default:
        this.selectedType = DocumentType.CERTIFICATIONS;
        break;
    }
    if (!this.isManuallyUploaded) {
      if (!this.checkIfArrayObjectIsLengthy(this.customVendorReqs)) {
        const vendorPath =
          this.vendorId && !this.isDefaultSetting ? `DOCUMENT_TYPES/${this.vendorId}/${this.vendorId}.json` : '';
        const res = vendorPath ? await this.checkIfCustomFrameworksExist(vendorPath) : null;
      } else {
        this.addCustomTypes(this.customVendorReqs);
      }
      this.addCustomTypes(this.customDefaultTypes);
    }
    this.resetTypeList(this.selectedType);
    this.uploader = new FileUploader({ url: this.uploadPath });
  }

  /**
   * check if new added reqs object is lengthy
   */
  checkIfArrayObjectIsLengthy(obj: CustomFrameworkTypes): boolean {
    let isLengthy = false;
    Object.keys(obj).map(key => {
      if (obj[key].length) {
        isLengthy = true;
        return isLengthy;
      }
      return isLengthy;
    });
    return isLengthy;
  }

  /**
   * check if custom framework files exist in s3, if exists get them
   */
  async checkIfCustomFrameworksExist(path: string): Promise<void> {
    const itemsList = await this.fileService.checkIfPathExistsS3(path);
    if (itemsList && itemsList.length) {
      const typesList = await this.fileService.getFileByPathFromS3(path);
      Object.keys(typesList).map(type => {
        const items = typesList[type];
        const res = items.map(item => {
          return {
            name: item,
            value: item,
            selected: false,
          };
        });
        if (res.length) {
          this.customVendorReqs[type] = res;
        }
        this.updateTypesList(type, res);
      });
    }
  }
  /**
   * Helper function to validate the data.
   */
  validateData(): boolean {
    if (this.customFrameworkName && this.selectedType) {
      return true;
    }
    if (!this.selectedType) {
      this.toastrService.error('Select one framework type');
    }
    if (!this.customFrameworkName) {
      this.toastrService.error('Name cannot be empty');
    }
    return false;
  }

  /**
   * Opening the modal for confirmation.
   */
  openConfirmationModal(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    if (this.validateData()) {
      // will open modal to select upload template if default setting is false and is not from the artifact tab
      if (!this.isDefaultSetting && !this.isForVendor) {
        const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsTasks);
        const compInstance = modalRef.componentInstance;
        compInstance.customizeHeader = true;
        compInstance.contentRef = content;
        compInstance.footerContentRef = footer;
        this.closeModalInstance = modalRef;
        this.closeModalInstance.componentInstance.modalResult.subscribe(() => {
          this.closeSharedModal();
        });
      } else {
        this.saveRequirement();
      }
    }
  }

  /**
   * uploading custom types to S3
   * */
  async saveCustomTypesOnS3(path: string, list: any): Promise<any[]> {
    const config = {
      contentType: 'application/json',
      download: true,
      level: 'public',
    };
    await this.fileService.uploadToS3CustomPath(path, list, config);
    return [];
  }

  /**
   * adding the additional data in list
   */

  addCustomTypes(obj: CustomFrameworkTypes): void {
    if (obj && Object.keys(obj).length) {
      Object.keys(obj).map(key => {
        this.updateTypesList(key, obj[key]);
      });
    }
  }

  updateTypesList(type: string, list: []): void {
    switch (type) {
      case DocumentType.OTHERS:
        this.typesList.OTHERS = [...this.typesList.OTHERS, ...list];
        break;
      case DocumentType.SCANS:
        this.typesList.SCANS = [...this.typesList.SCANS, ...list];
        break;
      case DocumentType.DOCUMENTS:
        this.typesList.DOCUMENTS = [...this.typesList.DOCUMENTS, ...list];
        break;
      case DocumentType.CERTIFICATIONS:
        this.typesList.CERTIFICATIONS = [...this.typesList.CERTIFICATIONS, ...list];
        break;
      default:
        this.typesList.CERTIFICATIONS = [...this.typesList.CERTIFICATIONS, ...list];
        break;
    }
    this.resetTypeList(this.selectedType?.toUpperCase());
  }

  /**
   * Saving the new requirement.
   */
  async saveRequirement(): Promise<void> {
    if (!this.isDefaultSetting && !this.isForVendor) {
      this.closeModalInstance.close();
    }
    let newlyAddedOrRemovedTypes;
    let uploadObj;
    // check if selected upload template is default or its for default settings, the types will be both newly added
    // or the already added custom types
    if (this.selectedType?.toLowerCase() !== this.frameworkTypes?.manual_artifact?.toLowerCase()) {
      if (this.isDefaultSetting || this.selectedTemplateType === this.DEFAULT?.toLowerCase()) {
        newlyAddedOrRemovedTypes = this.displayedTypesList.filter(
          item =>
            !item.isDefault &&
            (this.newAddedItems.includes(item.value) ||
              !this.customVendorReqs[this.selectedType.toUpperCase()].includes(item))
        );
        this.customDefaultTypes[this.selectedType.toUpperCase()] = newlyAddedOrRemovedTypes;
        uploadObj = this.getNames(this.customDefaultTypes);
      } else {
        // check if selected upload template is for vendor only the types will be only new added and the customs ones
        newlyAddedOrRemovedTypes = this.displayedTypesList.filter(
          item =>
            !item.isDefault &&
            (this.customVendorReqs[this.selectedType.toUpperCase()].includes(item) ||
              this.newAddedItems.includes(item.value))
        );
        this.customVendorReqs[this.selectedType.toUpperCase()] = newlyAddedOrRemovedTypes;
        uploadObj = this.getNames(this.customVendorReqs);
      }
      if (newlyAddedOrRemovedTypes?.length) {
        const path =
          this.vendorId && !this.isDefaultSetting && this.selectedTemplateType === RoleEnum?.VENDOR?.toLowerCase()
            ? `DOCUMENT_TYPES/${this.vendorId}/${this.vendorId}.json`
            : `DOCUMENT_TYPES/${this.rootEntityId}/${this.rootEntityId}.json`;
        await this.saveCustomTypesOnS3(path, uploadObj);
      }
    }
    this.closeModal.emit({
      name: this.customFrameworkName,
      type: this.selectedType, // category
      subType: this.selectedTypeOthers, // type
      templateURL: this.selectedFileTemplate,
      selectedTemplateType: this.isDefaultSetting
        ? this.saveOptions.find(option => option.value === this.DEFAULT?.toLowerCase()).value
        : this.selectedTemplateType,
    });
  }

  /**
   * get only names of types
   */
  getNames(listObj: CustomFrameworkTypes): CustomFrameworkTypes {
    const uploadObj: CustomFrameworkTypes = {
      SCANS: [],
      DOCUMENTS: [],
      OTHERS: [],
      CERTIFICATIONS: [],
    };
    Object.keys(listObj).map(key => {
      const values = listObj[key];
      const obj = values.map(item => {
        return item.name;
      });
      uploadObj[key] = obj;
    });
    return uploadObj;
  }

  resetTypeList(selectedType: string): void {
    if (this.typesList[selectedType] && this.typesList[selectedType].length) {
      this.selectedTypeOthers = this.typesList[selectedType][0].value;
      this.typesList[selectedType][0].selected = true;
      this.displayedTypesList = this.typesList[selectedType];
    }
  }

  categoryValueChange(value: any): void {
    if (value) {
      this.selectedType = value;
      this.resetTypeList(this.selectedType);
    } else {
      this.selectedType = null;
    }
    this.isCategoryExpanded = false;
  }

  typeValueChange(value: any): void {
    if (value) {
      this.selectedTypeOthers = value;
    } else {
      this.selectedTypeOthers = null;
    }
    this.isTypeExpanded = false;
  }

  /**
   * This function will be triggered if the user uploads a template for Artifacts
   * @param {any} eventFile this list will contain the file to upload
   */
  async uploadTemplateArtifacts(eventFile: any): Promise<void> {
    try {
      const eventFileCopy = eventFile?.length ? [...eventFile] : [];
      await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE);

      // HOTFIX: verifyUploadFile function was making eventFile variable null. So, doing deep copy. Need to find the root cause of it.
      eventFile = eventFileCopy;

      if (eventFile && eventFile[0]?.type === 'application/pdf') {
        if (await this.fileService.scanPdfFile(eventFile[0])) {
          this.toastrService.warning('Your File Contain Malicious Content');
          eventFile = null;
          return;
        }
      }

      if (eventFile && eventFile.length > 0) {
        this.selectedFileName = eventFile[0].name;
        this.selectedFileTemplate = eventFile[0];
        this.toastrService.success('File uploaded !');
      }
    } catch (error) {
      this.toastrService.warning(error);
      eventFile = null;
    }
  }

  closePopUp(): void {
    this.closeModal.emit(null);
    this.activeModal.close();
  }

  removeFile(): void {
    this.selectedFileTemplate = null;
    this.selectedFileName = '';
  }

  onSelectType(value): void {
    this.selectedTemplateType = this.saveOptions.find(option => option.value === value).value;
  }

  closeSharedModal(): void {
    if (this.closeModalInstance) {
      this.closeModalInstance.close();
      this.closeModalInstance = null;
    }
  }

  expandCategoryLowerPortion(): void {
    this.isCategoryExpanded = !this.isCategoryExpanded;
    this.isTypeExpanded = false;
  }

  selectedTypeLabel(selectedType: string): string {
    this.selectedType = selectedType
      ? UtilsService.capitalizeFirstLetter(selectedType.split('_').join(' ').toLowerCase())
      : 'Choose';
    return this.selectedType;
  }

  expandTypeLowerPortion(): void {
    this.isTypeExpanded = !this.isTypeExpanded;
    this.isCategoryExpanded = false;
  }

  selectedTypeOthersLabel(selectedTypeOthers: string): string {
    return selectedTypeOthers
      ? UtilsService.capitalizeFirstLetter(selectedTypeOthers.split('_').join(' ').toLowerCase())
      : 'Choose Type';
  }

  addNewLine(event?: any): void {
    console.log(event);
    // console.clear();
    this.isNewClicked = false;

    // Populating the original array

    // this.typesList[this.selectedType] = this.typesList[this.selectedType].map(type => {
    //   type.selected = false;
    //   return type;
    // });

    // Populating the displayed list
    this.displayedTypesList = this.displayedTypesList.map(type => {
      type.selected = false;
      return type;
    });
    this.newAddedItems.push(this.newlyAddedItem);
    this.selectedTypeOthers = this.newlyAddedItem;
    this.displayedTypesList.unshift({
      name: this.newlyAddedItem,
      value: this.newlyAddedItem,
      selected: true,
    });

    // this.typesList[this.selectedType].unshift({
    //   name: this.newlyAddedItem,
    //   value: this.newlyAddedItem,
    //   selected: true,
    // });

    this.newlyAddedItem = null;
    this.isTypeExpanded = false;
  }

  checkIfEllipsis(element): void {
    this.showTextTooltip = element.scrollWidth > element.clientWidth;
  }
  removeItem(i: number): void {
    this.displayedTypesList.splice(i, 1);
    this.selectedTypeOthers = this.displayedTypesList[0].value;
  }

  checkIfHeightEllipsis(element): void {
    this.showTextTooltip = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
}
