import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AppLevelRoleEnum } from 'app/shared/enums/appLevelRoles.enum';
import { UserService } from '../user.service';

@Directive({
  selector: '[cygovUserPermission]',
})
export class UserPermissionDirective implements OnInit {
  @Input() hiddenMode: number = 0;
  @Input() customPermission: boolean = false;
  @Input() customPermissionIgnoreViewOnly: boolean = false;

  viewOnlyPermission: boolean = false;
  constructor(private element: ElementRef, private userService: UserService) {}

  async ngOnInit() {
    const user = await this.userService.getCurrentUser();
    const userRole = this.userService.getCurrentRole();
    if (user) {
      this.viewOnlyPermission = user.isViewOnly && userRole.name !== AppLevelRoleEnum.PARTICIPANT.toLowerCase();
    }
    if ((this.viewOnlyPermission && !this.customPermission) || this.customPermissionIgnoreViewOnly) {
      switch (this.hiddenMode) {
        case 0:
          // hiding the element/removing from dom
          this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
          break;
        case 1:
          // cancelling all clickable actions
          this.element.nativeElement.disabled = true;
          break;
        case 2:
          // cancelling clickable actions on div,span etc
          this.element.nativeElement.style.pointerEvents = 'none';
          break;
        default:
          break;
      }
    }
  }
}
