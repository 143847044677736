<div class="modal-body modal-template-inner-body" [ngClass]="editModeBorder ? 'dashed-border' : ''">
  <div class="modal-header d-flex justify-content-center align-items-center">
    <div class="header-title-cont" *ngIf="!customizeHeader">
      <span class="lato-20-n-vw header-title text-capitalize">{{ title }}</span>
    </div>
    <div class="header-title-cont customize-header" *ngIf="customizeHeader">
      <ng-container *ngTemplateOutlet="headerContentRef;"></ng-container>
    </div>
    <div class="cross-btn-cont" (click)="closeModalTemplate()" *ngIf="crossBtnVisibility">
      <div cygovSvgIcon [svgName]="'x-icon-type2'" class='cross-btn'></div>
    </div>
  </div>
  <div class='modal-content-body'>
    <ng-container *ngTemplateOutlet="contentRef;"></ng-container>
  </div>

  <div class="modal-content-footer">
    <ng-container *ngTemplateOutlet="footerContentRef;"></ng-container>
  </div>
</div>