import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { WelcomeVideoComponent } from './welcome-video/welcome-video.component';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingRoutes } from './onboarding.routes';
import { CygovButtonModule } from 'app/shared/cygov-button/cygov-button.module';
import { MidmarketWizardPopUpModule } from 'app/shared/midmarket-wizard-pop-up/midmarket-wizard-pop-up.module';
import { ProgressBarModule } from 'app/shared/progress-bar/progress-bar.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { ThankYouStageOneComponent } from './thank-you-stage-one/thank-you-stage-one.component';

@NgModule({
  declarations: [
    OnboardingComponent,
    WelcomeMessageComponent,
    ClientFormComponent,
    WelcomeVideoComponent,
    ThankYouStageOneComponent,
  ],
  imports: [
    CommonModule,
    OnboardingRoutes,
    CygovButtonModule,
    MidmarketWizardPopUpModule,
    ProgressBarModule,
    SvgIconModule,
  ],
})
export class OnboardingModule {
  constructor() {}
}
