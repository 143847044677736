import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FileIconComponent } from 'app/shared/file-icon/file-icon.component';

@NgModule({
  declarations: [FileIconComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [FileIconComponent],
})
export class FileIconModule {}
