import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cygov-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit {
  @Output() nextButtonClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  nextButtonHandler(): void {
    this.nextButtonClick.emit('client-form');
  }
}
