<div class="modal-body exception-modal" [ngClass]="{'entity-deletion':deleteEntityModal}">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <div *ngIf="confirmationLevel === 2 && deleteEntityModal"
        class="back-btn txt-white lato-16-n-vw" (click)="returnToLevel1()">
        <span class="arrow-label">
        </span>
        < Back </div>
          <div *ngIf="svgName !== '' && !isFromRiskAiConfonfirmation" class="user-icon" cygovSvgIcon
            [svgName]="svgName">
          </div>

          <img class="user-robot-icon" *ngIf="isFromRiskAiConfonfirmation"
            src="assets/svg/robots-type1.svg" alt="Robot Icon">

          &nbsp;
          <span class="lato-24-n-vw message">
            {{ titleText }}
          </span>
      </div>
      <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="hideModal()">
      </div>
    </div>
    <div class='modal-content-body'
      [ngClass]="{'deny-modal-height' : svgName?.toLowerCase() === 'deny'}">
      <!-- Level 1 Box -->
      <div class='confirmation-level-{{ confirmationLevel }}'
        *ngIf="confirmationLevel === 1; else level2Box;">
        <div class="lato-18-n-vw message" *ngIf="!isCustomContent && !isFromRiskAiConfonfirmation">
          Please note you are about to {{removeOrCloseText}} <br *ngIf="showLineBreak">
          <span *ngIf="!deleteEntityModal" class="remove-label-text">{{ removeLabelText }}</span>
          <span *ngIf="!isConfirm2Required || removeFromText.length" class="from-text">
            {{removeFromText}}</span>
          <span *ngIf="deleteEntityModal"><span class='txt-blue'>{{ removeLabelText }}</span>
            {{entityType}}</span><br>
          <span *ngIf="deleteEntityModal && showPermanentText">{{show2ndConfirmText ?
            show2ndConfirmText:'All of its data will be erased permanently'}}</span>
        </div>
        <div class="lato-18-n-vw message risk-ai-message" *ngIf="isFromRiskAiConfonfirmation">
          <div class="p1">The Centraleyes AI module leverages a third-party generative AI engine to
            automate
            risk creation and mapping, efficiently identifying and evaluating potential risks
            through advanced algorithms. Beyond the recognition of the scenario, it analyzes
            contextual information for a nuanced understanding of each risk and adapts dynamically.

          </div>
          <div class="p2">
            The only data that is shared with the third-party engine is the risk name, industry, and
            description entered by the user
          </div>
          <div class="p3">
            By clicking on confirm below, you approve that the AI
            module will be activated and available to all relevant users.
          </div>
        </div>
        <div class="lato-16-n-vw message" *ngIf="isCustomContent && !deleteFrameworkText">
          <span>{{svgNameDescription[svgName].join('')}}</span><br>
          <span *ngIf="artifactsNames?.length > 0" class="text-blue"
            [ngbTooltip]="artifactsNames[0]" [disableTooltip]="!showColumnTooltip"
            (mouseenter)="showColumnTooltip=true"
            (mouseleave)="showColumnTooltip=false">{{artifactsNames[0]}}</span>
        </div>
        <div class="lato-16-n-vw message" *ngIf="isCustomContent && deleteFrameworkText">
          <span>{{deleteFrameworkText}}</span><br>
          <span *ngIf="artifactsNames?.length > 0" class="text-blue">"{{artifactsNames}}"</span><br>
        </div>
        <div class="lato-16-n-vw delete-confirm"
          *ngIf="svgName !== 'deny' && svgName !== 'approve' && svgName !== 'gap'"
          [ngClass]="{'delete-confirm-ai' : isFromRiskAiConfonfirmation}">
          Type the word <span class="confirm-txt"
            [ngClass]="{'confirm-text-ai' : isFromRiskAiConfonfirmation}">&nbsp;&quot;confirm&quot;&nbsp;</span>
          to &nbsp;

          <span *ngIf="!isFromRiskAiConfonfirmation">delete</span>
          <span *ngIf="isFromRiskAiConfonfirmation">activate</span>
          <input [(ngModel)]="confirmationString" type="text" #confirmation="ngModel"
            class="delete-input" placeholder="confirm"
            (keyup)="onValueChanged(confirmation?.value)" />
        </div>
        <div class="lato-16-n-vw comment-textbox" *ngIf="svgName === 'deny' || svgName === 'gap'">
          <span class="comment-heading">{{commentHeading}}</span>
          <textarea class="explain-answer lato-14-n-vw" [(ngModel)]="comment"></textarea>
        </div>
      </div>
      <!-- Level 2 Box -->
      <ng-template #level2Box>
        <div class="confirmation-level-{{ confirmationLevel }} lato-18-n-vw">
          {{confirmationLevel2Text}}
        </div>
        <div *ngIf="show2ndConfirmText" class="second-message txt-white lato-18-n-vw">
          {{show2ndConfirmText}}
        </div>
      </ng-template>
    </div>
    <div class="modal-content-footer"
      [ngClass]="{'gap-footer': svgName === 'deny' || svgName === 'gap'}">
      <div class="d-flex">
        <cygov-button class="button-margin" [buttonText]="'CONFIRM'" [buttonWidth]="'5vw'"
          [buttonColor]="'#29a9eb'" (click)="confirmLevel()" [hoverColor]="'#0F92E3'">
        </cygov-button>

        <!-- <button class="standard-btn" [disabled]="confirmationString !== 'confirm'"
        (click)="confirmLevel1()" *ngIf='confirmationLevel === 1'>
        Confirm
      </button> -->
        <!-- <button class="standard-btn" (click)="confirmLevel2()" *ngIf='confirmationLevel === 2'>
        Confirm
      </button> -->

        <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
          [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5vw'" [textColor]="'#C5C9D1'"
          (click)="hideModal()">
        </cygov-button>
        <!-- <button class="standard-btn-transparent" (click)="activeModal.close()">
        Cancel
      </button> -->
      </div>
    </div>
  </div>