import { Pipe, PipeTransform } from '@angular/core';
import { LargeNumberEnum } from 'app/shared/enums/largeNumber.enum';
/*
 * Shows a large number in its short form
 * Takes a number as a value, shows it in Millions or Thousands depending on value.
 * Optional to append a character on Right and Left of the value.
 * Optional to restrict the number of decimals points using the 4th option
 * Keep note the decimals will not be rounded off
 * Usage:
 *  value | large-number-short : (Left-Optional) String : (Right-Optional) String : (Optional-Restriction-Float) Number
 */
@Pipe({
  name: 'largeNumberShort',
})
export class LargeNumberShort implements PipeTransform {
  transform(value: any, onLeft = '', onRight = '', restrictFloat: number = 1): string {
    const checkType = value;
    // Check if string is passed
    if (typeof value !== 'number') {
      if (checkType && checkType.trim().length) {
        const regex = new RegExp(/[+]?([0-9]*[.])?[0-9]+/);
        if (!regex.test(checkType)) {
          value = 0;
        }
        if (checkType.trim() === '0.0') {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    const isNeg = value < 0 ? '-' : '';
    value = Math.abs(value);
    if (value < LargeNumberEnum.THOUSAND) {
      return isNeg + onLeft + this.appendDecimals(value, restrictFloat) + '' + onRight;
    } else if (value >= LargeNumberEnum.THOUSAND && value < LargeNumberEnum.MILLION) {
      return isNeg + onLeft + this.appendDecimals(value / LargeNumberEnum.THOUSAND, restrictFloat) + 'K' + onRight;
    } else if (value >= LargeNumberEnum.MILLION && value < LargeNumberEnum.BILLION) {
      return isNeg + onLeft + this.appendDecimals(value / LargeNumberEnum.MILLION, restrictFloat) + 'M' + onRight;
    } else if (value >= LargeNumberEnum.BILLION) {
      return isNeg + onLeft + this.appendDecimals(value / LargeNumberEnum.BILLION, restrictFloat) + 'B' + onRight;
    }
  }

  appendDecimals(value: number, restrictFloat: number = 0): string {
    if (value % 1 && !!restrictFloat) {
      const stringVal = value.toString().split('.');
      let decimals = '';
      for (let i = 0; i < restrictFloat; i++) {
        if (i < stringVal[1].length) {
          decimals = decimals + stringVal[1][i];
        }
      }
      return decimals === '0' || decimals === '' ? stringVal[0] : stringVal[0] + '.' + decimals;
    } else {
      return value + '';
    }
  }
}
