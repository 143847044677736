export class FormulaConstants {
  public static MEDICAL_FRAUD_MONITORING = 0;
  public static NUM_CONSTANT = 4;
  public static INITIAL_IMPACT = 0.3;
  public static DELTA = 0.5;
  public static LAST_DAY = 60;
  public static RISK_SCENARIO_TITLES: any = {
    'Forensic Investigation': 'FOR_INV',
    'Security Remediation': 'SEC_REM',
    'Data Breach Coach': 'DAT_BRE_COA',
    'Notification Letters': 'NOT_LET',
    'Call Center': 'CAL_CEN',
    'Credit Monitoring': 'CRE_MON',
    'Public Relations Service': 'PUB_REL_SER',
    'FTC Fines': 'FTC_FIN',
    'HHS Fines': 'HHS_FIN',
    'State AG Fines': 'STA_AG_FIN',
    'PCI Fines': 'PCI_FIN',
    'Legal Defense & Damages': 'LEG_DEF_DAM',
    'Bank Card Reissuance': 'BAN_CAR_RES',
    'Income Loss': 'INC_LOS',
    'System Reconstruction': 'SYS_REC',
    'Extra Expense': 'EXT_EXP',
    Ransomware: 'RNSM',
  };
}
