<ng-container [ngSwitch]="loaderType">
    <!-- ========= Style 1 =========== -->
    <div class="abs-centre centraleyes-progress-cont" *ngSwitchCase="styleType.STYLE1">
        <div cygovSvgIcon [svgName]="'centraleyes-logo1'" class="centraleyes-logo-cont"></div>
        <div class="centraleyes-progress-bar">
            <cygov-progress-bar [assessmentLevel]="progress" *ngIf="progress < 115"
                [postFill]="['#29A9EB', '#0B517C']" [height]="10">
            </cygov-progress-bar>
        </div>
        <div *ngIf="isVendorBulkUpload" class="lato-24-n-vw progress-bar-text">
            {{displayMessage}}
        </div>
    </div>

    <!-- ========= Default Style =========== -->
    <div class="positioning-center-inprocess" [ngClass]="{'success-pop-up':true}" *ngSwitchDefault>
        <div class="pop-up-uploads">
            <div class="cross-icon" (click)="closeModal()" cygovSvgIcon [svgName]="'x-icon-type2'">
            </div>
            <div class="body-uploads">
                <div class="file-details">
                    <div class="lato-14-n-vw text">
                        {{displayMessage}}
                    </div>
                    <cygov-progress-bar class="progress-bar" *ngIf="progress < 115"
                        [assessmentLevel]="progress" [postFill]="['#29A9EB', '#0B517C']"
                        [height]="10">
                    </cygov-progress-bar>
                </div>
            </div>
        </div>
    </div>
</ng-container>