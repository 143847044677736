export const getDomainMenu = payload => {
  const { key, board, entityId, clientsPermission, screenPermissions } = payload;
  // if user isn't admin or mssp then disable client/management tabs using clientPermission
  const menus = {
    netskope: [
      {
        name: 'Management',
        routerLink: ['clients'],
        svgName: 'clients-icon',
        disabled: !clientsPermission,
      },
      {
        name: '1st Party',
        disabled: !entityId || !screenPermissions?.['first-party'],
      },
      {
        name: '3rd Party',
        disabled: !entityId || !screenPermissions?.['third-party'],
      },
      {
        name: 'Boardview',
        routerLink: [`${board}/${entityId}`],
        disabled: !entityId || !screenPermissions?.['board-netskope'],
        subMenu: [
          {
            name: 'Overview',
            domain: 'rehan',
            svgName: 'netskope-overview-icon',
            disabled: !screenPermissions?.['board-netskope']?.includes('overview-netskope'),
            routerLink: [`/${board}/${entityId}/overview-netskope`],
          },
          {
            name: 'Executive Summary',
            domain: 'rehan',
            svgName: 'executive-summary-icon',
            disabled: !screenPermissions?.['board-netskope']?.includes('exectuive-summary'),
            routerLink: [`/${board}/${entityId}/executive-summary`],
          },
          {
            name: 'Security',
            domain: 'rehan',
            svgName: 'security-icon',
            disabled: !screenPermissions?.['board-netskope']?.includes('security'),
            routerLink: [`/${board}/${entityId}/security`],
            subItems: [
              {
                name: 'Computer Tickets',
                routerLink: [`/${board}/${entityId}/security/computer-tickets`],
              },
              {
                name: 'Vendor risk',
                routerLink: [`/${board}/${entityId}/security/vendor-risk`],
              },
            ],
          },
          {
            name: 'Project',
            domain: 'rehan',
            svgName: 'project-icon',
            disabled: !screenPermissions?.['board-netskope']?.includes('projects'),
            routerLink: [`/${board}/${entityId}/projects`],
          },
          {
            name: 'Maturity',
            domain: 'rehan',
            svgName: 'maturity-icon',
            disabled: !screenPermissions?.['board-netskope']?.includes('maturity'),
            routerLink: [`/${board}/${entityId}/maturity`],
            subItems: [
              {
                name: 'Capabilities',
                routerLink: [`/${board}/${entityId}/maturity/capabilities`],
              },
              {
                name: 'Tools',
                routerLink: [`/${board}/${entityId}/maturity/tools`],
              },
            ],
          },
        ],
      },
      {
        name: 'Training Center',
        disabled: !clientsPermission,
      },
    ],
    another: [
      {
        name: '1st Party',
        disabled: false,
      },
      {
        name: 'Board',
        disabled: false,
        subMenu: [
          {
            name: 'Overview',
            domain: 'netskope',
            svgName: 'reports-archive-icon',
            disabled: true,
            routerLink: [`/${board}/${entityId}/reports-archive`],
          },
          {
            name: 'Executive Summary',
            domain: 'netskope',
            svgName: 'reports-archive-icon',
            disabled: true,
            routerLink: [`/${board}/${entityId}/reports-archive`],
          },
        ],
      },
      {
        name: 'Training Center',
        disabled: true,
      },
    ],
  };
  return menus[key] ? menus[key] : [];
};
