import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter of the string
 * Takes a string as a value.
 * Usage:
 *  value | replace
 */
@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, regexValue: string, replaceValue: string): any {
    if (value && value !== undefined) {
      const regex = new RegExp(regexValue, 'g');
      const newValue = value.replaceAll(regex, replaceValue);
      return newValue;
    }
  }
}
