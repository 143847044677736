import { animate, state, style, transition, trigger } from '@angular/animations';

export const Animations = {
  colorChanger: trigger('colorOpener', [
    state(
      'charade',
      style({
        'background-color': '{{closed}}',
      }),
      { params: { closed: '' } }
    ),
    state(
      'ebony',
      style({
        'background-color': '{{opened}}',
      }),
      { params: { opened: '' } }
    ),
    transition('charade => ebony', animate('400ms ease-out')),
    transition('ebony => charade', animate('400ms ease-in')),
  ]),
  heightChanger: trigger('heightUpdate', [
    state(
      'void',
      style({
        'background-color': '#21242F',
        height: 0,
      })
    ),
    state(
      'appear',
      style({
        'background-color': '#0A0F1A',
        height: '*',
      })
    ),
    transition('void => appear', [animate('400ms ease-in')]),
    transition('appear => void', [animate('400ms ease-out')]),
  ]),
};
