/* eslint-disable no-useless-escape */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RoleEnum, GetEntityQuery, CreateUserInput, GetUserQuery, APIService, GetRoleQuery } from 'app/API.service';
import { EntityService } from '../entity.service';
import { FrameworkGroup } from 'app/questionnaire-management/questionnaire-management.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../utils.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../user.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from '../enums/appLevelRoles.enum';
import { CUSTOMAPIService } from 'app/custom-api.service';

interface DropDown {
  id: string;
  name: string;
}

@Component({
  selector: 'cygov-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss'],
})
export class AddParticipantModalComponent implements OnInit {
  @Input() collectionMode = false;
  @Input() isFromBulkVendor = false;
  @Input() framework: FrameworkGroup;
  @Input() currentSubId: string;
  @Input() rolesList: GetRoleQuery[];
  @Input() subEntitiesList: GetEntityQuery[];
  @Output() modalResult = new EventEmitter<{
    newUser: CreateUserInput;
    frameworkId: string;
    assessmentId: string;
  }>();
  @Output() modalResultCollection = new EventEmitter<CreateUserInput>();
  entity: GetEntityQuery;
  filteredSubEntityList: DropDown[] = [];
  newUser: CreateUserInput;
  selectedEntity: string;
  currentUser: GetUserQuery;
  roleOptions: string[] = [];
  leaderTypes = ['SubEntityLeader'];
  subEntity: GetEntityQuery;
  subEntityList: GetEntityQuery[];
  leaderType: string;
  roleEnum = RoleEnum;
  notAllowedRoles: string[] = [AppLevelRoleEnum.PARTICIPANT];
  currentUserRole: GetRoleQuery; // Adding currentUserRole which will be removed and replaced with user.role after making connection.
  isBnb = UtilsService.isBnB;
  divID = '';
  regID = '';

  constructor(
    private entityService: EntityService,
    private api: APIService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger,
    private userService: UserService,
    private customApi: CUSTOMAPIService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser();
    this.currentUserRole = this.userService.getCurrentRole();
    this.newUser = this.initUser();
    this.populateRoleOptions();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entity = entityId ? await this.customApi.GetEntity(entityId) : null;
    try {
      if (this.entity) {
        if (this.entityService.isRootEntity(this.entity)) {
          this.subEntityList = await this.entityService.listChildEntitysByParentId(this.getDisplayEntityId());
          this.leaderTypes = [...this.leaderTypes, 'EntityLeader'];
        } else {
          this.toastr.error('Unauthorized');
        }
      }
    } catch (e) {
      this.logger.error('Add Participant Modal Component - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
    if (this.isBnb && this.entity) {
      this.divID = await this.entityService.getDivisionRegionId(true, this.entity?.id);
      this.regID = await this.entityService.getDivisionRegionId(false, this.entity?.id);
    }
  }

  getDisplayEntityId(): string {
    return this.entityService.isChildEntity(this.entity) ? this.entity.parentId : this.entity.id;
  }

  isValid(user: CreateUserInput): boolean {
    let valid = true;
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }
    if (!user.roleId && !this.notAllowedRoles.includes(this.currentUserRole.name.toLowerCase())) {
      this.toastr.error('User role is required');
      valid = false;
    } else if (
      user.role === RoleEnum.LEADER &&
      !this.notAllowedRoles.includes(this.currentUserRole.name.toLowerCase())
    ) {
      this.toastr.error('Leader Type is required');
      valid = false;
    }
    return valid;
  }

  emitUser(newUser: CreateUserInput): void {
    newUser.email = newUser.email.toLowerCase();
    const tmpCurrentRole = this.rolesList.find(role => role.id === newUser.roleId)?.name;
    let subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    if (!UtilsService.isDefined(subEntityId) && UtilsService.isDefined(this.currentSubId)) {
      subEntityId = this.currentSubId;
    }
    switch (tmpCurrentRole) {
      case AppLevelRoleEnum.SUBENTITY_LEADER:
      case AppLevelRoleEnum.PARTICIPANT:
      case AppLevelRoleEnum.VENDOR: {
        if (subEntityId) {
          newUser.entityIds = [subEntityId];
        }
        break;
      }
      case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN:
      case AppLevelRoleEnum.ENTITY_LEADER: {
        newUser.entityIds = [this.entity.id];
        break;
      }
      case AppLevelRoleEnumBnB.DIVISION_LEADER: {
        newUser.entityLayerIds = [this.divID];
        break;
      }
      case AppLevelRoleEnumBnB.REGION_LEADER: {
        newUser.entityLayerIds = [this.regID];
        break;
      }
      default:
        break;
    }

    if (this.isValid(newUser)) {
      if (this.collectionMode) {
        this.modalResultCollection.emit(newUser);
      } else {
        const selectedSubEntity = this.subEntitiesList.find(subEntity => subEntity.id === this.selectedEntity);
        this.modalResult.emit({
          newUser,
          frameworkId: this.framework.name,
          assessmentId: selectedSubEntity.activeAssessmentId,
        });
      }
    }
  }
  initUser(): any {
    return {
      name: '',
      email: '',
      roleId:
        this.rolesList && this.rolesList.length
          ? this.currentUserRole.id
          : this.rolesList.find(role => role.name === AppLevelRoleEnum.PARTICIPANT)?.id, // this.getDefault(),
      isCognitoUser: false,
      questionMap: JSON.stringify({}),
    };
  }

  // Function not in user anymore..
  getDefault(): AppLevelRoleEnum {
    switch (this.currentUserRole.name.toLowerCase()) {
      case AppLevelRoleEnum.ADMIN:
        return AppLevelRoleEnum.ADMIN;
        break;
      case AppLevelRoleEnum.MSSP:
        return AppLevelRoleEnum.MSSP;
        break;
      case AppLevelRoleEnum.ENTITY_LEADER:
        return AppLevelRoleEnum.ENTITY_LEADER;
        break;
      case AppLevelRoleEnum.SUBENTITY_LEADER:
        return AppLevelRoleEnum.SUBENTITY_LEADER;
        break;
      case AppLevelRoleEnum.PARTICIPANT:
        return AppLevelRoleEnum.PARTICIPANT;
        break;
    }
    return AppLevelRoleEnum.PARTICIPANT;
  }
  populateRoleOptions(): void {
    switch (this.currentUserRole.name.toLowerCase()) {
      case AppLevelRoleEnum.ADMIN:
        break;
      case AppLevelRoleEnum.MSSP:
        this.rolesList = this.rolesList.filter(role => role.name.toLowerCase() !== AppLevelRoleEnum.ADMIN);
        break;

      case AppLevelRoleEnum.ENTITY_LEADER:
        this.rolesList = this.rolesList.filter(
          role =>
            role.name.toLowerCase() !== AppLevelRoleEnum.ADMIN && role.name.toLowerCase() !== AppLevelRoleEnum.MSSP
        );
        break;

      case AppLevelRoleEnum.SUBENTITY_LEADER: {
        this.rolesList = this.rolesList.filter(
          role =>
            role.name.toLowerCase() !== AppLevelRoleEnum.ADMIN &&
            role.name.toLowerCase() !== AppLevelRoleEnum.MSSP &&
            role.name.toLowerCase() !== AppLevelRoleEnum.ENTITY_LEADER
        );
        break;
      }

      default: {
        this.rolesList = this.rolesList.filter(
          role =>
            role.name.toLowerCase() !== AppLevelRoleEnum.ADMIN && role.name.toLowerCase() !== AppLevelRoleEnum.MSSP
        );
        // We will check if the current custom role has the root entity access or not.
        if (
          this.currentUserRole &&
          this.currentUserRole.screenPermissions &&
          this.currentUserRole.screenPermissions['first-party'] &&
          !this.currentUserRole.screenPermissions['first-party'].includes('upperdeck')
        ) {
          const ind = this.rolesList.findIndex(role => role.name === AppLevelRoleEnum.ENTITY_LEADER);
          this.rolesList.splice(ind, 1);
        }
      }
    }
  }
}
