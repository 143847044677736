import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
})
export class FileIconComponent implements OnInit {
  @Input() totalFiles: number;
  @Input() circleBgColor: string = '#29a9eb';
  constructor() {}

  ngOnInit() {}
}
