import { arc, easeCubicOut, hierarchy, partition, select, selectAll } from 'd3';
import { SunburstConstant } from './sunburst.constant';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'app/shared/utils.service';
interface Point {
  x: number;
  y: number;
}

// Sunburst Tutorial (d3 v4) - https://bl.ocks.org/denjn5/e1cdbbe586ac31747b4a304f8f86efa5

@Injectable()
export class SunburstService implements OnDestroy {
  skipOneLayerOnUsingFiters: boolean = false;
  private colors: any = SunburstConstant.COLORS;
  private labels: any = SunburstConstant.LABELS;
  private isHebrew = false;
  private radToDeg = 57.2958;
  // d3 stuff
  private _partition = null;
  private _rootData = null;
  private _leftRightArrowData = null;
  private _arc = null;
  private _arrowArc = null;
  private _svg = null;
  private _nodes = null;
  private _defs = null;
  private _rootNode = null;
  private _arrowsData = [];
  // plugin admin
  private data = null;
  private element = null;
  private selected = null;
  private dimensions = null;
  private frameInnerRadius = [];
  private frameOuterRadius = [];
  private reset = true;
  private selectedParent = [];
  private rotatingAngles = [];
  private currentRotatedAngles = [0, 0, 0, 0];
  private maxChildren = 6;
  inTransition = false;
  notDollarkeepLastClick: any = null;
  securityControlClicked: EventEmitter<any> = new EventEmitter();
  answered: any = null;
  totalScore = UtilsService.isBnB ? 5 : 10;
  onClickDepth = 1;

  constructor(private logger: NGXLogger) {}

  private showNodes(nodes, show, cb) {
    let shows = {};
    const yOffset = 10; // Amount to lift nodes by
    nodes
      .style('pointer-events', d => {
        let tmpShow = show;
        if (typeof show === 'function') {
          tmpShow = show(d);
        }
        shows[d.depth] = shows[d.depth] || tmpShow;
        d.pointerEvents = tmpShow;
        return tmpShow ? 'all' : 'none';
      })
      .transition()
      // duration is reduced so that shadow cannot come before transition
      .duration(1)
      .attr('transform', function (d) {
        setTimeout(() => {
          return `translate(${d + yOffset})`;
        }, 0);
      }) // Lift nodes by yOffset
      .style('opacity', show)
      .on('end', () => {
        shows = null;
        return typeof cb === 'function' ? cb() : null;
      })
      .on('start', d => {
        if (shows[d.depth] === 0) {
          select('.label.lvl' + d.depth + ', .labelText.lvl' + d.depth)
            .transition()
            .duration(500)
            .style('opacity', this.isRoot(d.depth) ? 1 : 0);

          shows[d.depth] = undefined;
        }
      });
  }

  private setWidthAndPosition(d, radius, padding = 5) {
    // get the correct angle
    const alpha = d.startAngle - d.endAngle;
    const angle = d.startAngle - alpha / 2; // middle angle in radians
    const x1 = radius * d.depth * Math.sin(angle);
    const y1 = radius * d.depth * Math.cos(angle); // Maybe we should invert because minus means higher?
    const x2 = radius * (d.depth + 1) * Math.sin(angle);
    const y2 = radius * (d.depth + 1) * Math.cos(angle); // Maybe we should invert because minus means higher?

    let width =
      d.depth > 0 ? 2 * ((radius * d.depth) / 2 - padding) * Math.abs(alpha) * Math.abs(Math.cos(angle)) : 100;

    if (width < 100) {
      width = 100;
    }
    // exclude limit occution
    if (d.parent && d.parent.children.length >= 5 && d.depth === 1) {
      width = 70;
    }

    const direction = Number(Math.abs(d.startAngle) > Math.abs(d.endAngle)) - 1;
    const middle = {
      x: x2 - (x2 - x1) / 2,
      y: y2 - (y2 - y1) / 2,
    };

    d.textDims = {
      middle,
      direction,
      p1: {
        x: x1,
        y: y1,
      },
      p2: {
        x: x2,
        y: y2,
      },
      width,
    };
  }

  // Sets the width and positions of the Labels that appear on the left of each arc.
  private setTextWidthAndPosition(d, radius, padding = 5) {
    // get the correct angle
    console.log(padding);
    // console.clear();
    const angle = d.startAngle; // middle angle in radians
    const x1 = radius * d.depth * Math.sin(angle);
    const y1 = radius * d.depth * Math.cos(angle); // Maybe we should invert because minus means higher?
    const x2 = radius * (d.depth + 1) * Math.sin(angle);
    const y2 = radius * (d.depth + 1) * Math.cos(angle); // Maybe we should invert because minus means higher?

    // Previously used formula which was not working correclty
    // let width =
    //   d.depth > 0
    //     ? 2 * ((radius * d.depth) / 2 - padding) * Math.abs(alpha) * Math.abs(Math.cos(angle))
    //     : 100;

    let width = Math.abs(Math.abs(x1) - Math.abs(x2));

    // exclude limit occution
    if (d.parent && d.parent.children.length >= 5 && d.depth === 1) {
      width = 70;
    }

    const direction = Number(Math.abs(d.startAngle) > Math.abs(d.endAngle)) - 1;
    const middle = {
      x: x2 - (x2 - x1) / 2,
      y: y2 - (y2 - y1) / 2,
    };

    d.textDims = {
      middle,
      direction,
      p1: {
        x: x1,
        y: y1,
      },
      p2: {
        x: x2,
        y: y2,
      },
      width,
    };
  }

  private countNonQuestionChildren(d) {
    d.nonQuestionChildren = 0;

    if (d.children) {
      d.children.forEach(childData => {
        if (!childData.question && !childData.data.question) {
          d.nonQuestionChildren++;
        }
      });
    }

    return d.nonQuestionChildren;
  }

  private getBox(d, self, isBox) {
    let bbox;
    // fireFox treat's self/this differently then chrome. therefor it's needed to get the element from the dom
    // and not use self.previousSibling.
    // i could have unify the code, but i didn't want to mass up things in chrome.
    if (navigator.userAgent.toLowerCase().includes('firefox')) {
      const parent = d && d.data ? document.getElementById(`node${d.data.id}`) : null;
      if (parent) {
        if (parent.children[0]) {
          const element = parent.children[0];
          bbox = isBox ? (element as any).getBBox() : element;
          if (!isBox && !bbox.getAttribute('d')) {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      bbox = isBox ? self.previousSibling.getBBox() : self.previousSibling;
    }
    return bbox;
  }

  private getDimensions(element) {
    return {
      maxLabelLevels: 0,
      width: element.clientWidth,
      height: element.clientHeight,
      radius: 770,
      angles: {
        mainAngle: 90,
        arrowMainAngle: 90,
        frameAngle: 90,
        get frameCloseAngle() {
          return (this.frameAngle * Math.PI) / 180;
        },
        get frameBeginAngle() {
          return (-this.frameAngle * Math.PI) / 180;
        },
        get closeAngle() {
          return (this.mainAngle * Math.PI) / 180;
        },
        get beginAngle() {
          return (-this.mainAngle * Math.PI) / 180;
        },
        get arrowBeginAngle() {
          return (-this.arrowMainAngle * Math.PI) / 180;
        },
        get totalRange() {
          return this.closeAngle - this.beginAngle;
        },
      },
      mishbezet: {
        radius: element.clientHeight / 7,
        arc: 200,
        size: 30000,
        textPadding: 40, // px
      },
      meshulashZela: 40,
      borderSlope: 0,
    };
  }

  private isRoot(depth) {
    return `frame_${depth}` === 'frame_0';
  }

  private isLeaf(depth) {
    return `frame_${depth}` === 'frame_6';
  }

  // Create the data required for showing the arrows on the arcs.
  createArrowsData() {
    this._arrowsData = Array(6)
      .fill(0)
      .map((x, i) => [
        {
          id: 'left-arrow-' + (i + 1),
          name: 'left-arrow',
          children: [],
          depth: i + 1,
        },
        {
          id: 'right-arrow-' + (i + 1),
          name: 'right-arrow',
          children: [],
          depth: i + 1,
        },
      ]);
  }

  initSunburst(elementId: string, data) {
    const subBustElement: HTMLElement = document.querySelector(elementId);

    if (data && subBustElement) {
      this.createArrowsData();
      this.data = data;
      this.element = subBustElement;
      this.isHebrew = this.data.name.match(/[\u0590-\u05FF]+/g);
      this.labels = this.skipOneLayerOnUsingFiters ? SunburstConstant.SKIP_ONE_LAYER : SunburstConstant.LABELS;
      this.dimensions = this.getDimensions(this.element);
      this.reset = true;
      this.clearSVG(); // clear the SVG

      // this._rootData = this.initRootData();
      // this._partition = this.initPartition();
      // this._arc = this.initArc();
      // this._svg = this.initSVG();
      // this._nodes = this.svg.selectAll('.node');
      // this._rootNode = this.svg.select('.node.lvl0');

      this.visualizeData();
      this.reset = false;
    }
  }

  ngOnDestroy(): void {
    this.selected = null;
    if (this.element && this.svg) {
      this.svg
        .select(function () {
          // couldn't be arrow fn
          return this.parentNode;
        })
        .remove();

      this._svg = null;
    }
  }

  initRootData() {
    // set the d3 hierarchy from the data
    if (this._rootData) {
      createNames(this._rootData, this._rootData.data.name[0].toLocaleUpperCase());
    }
    if (!this.data) {
      this.logger.error('data/field is required');
      return;
    }
    return (this._rootData = hierarchy(this.data, d => {
      // if data has children, add to each children its index in the parent
      if (d.children) {
        let iter = 0;
        d.children.map(node => {
          if (!d.question) {
            iter++;
            return (node.notDollarindex = iter);
          }
        });
        return d.children;
      } else {
        return d;
      }
    })
      // sum up the node and its children score
      .sum(d => (d.score ? d.score : 0)));

    function createNames(root, letter) {
      root.data.hierarchyName = letter;
      (function recurse(node) {
        if (node.children) {
          node.children.map((subNode, index) => {
            subNode.data.hierarchyName = node.data.hierarchyName + '.' + (index + 1);
            subNode.data.presentName = subNode.data.hierarchyName + '.' + ' ' + subNode.data.name;
            recurse(subNode);
          });
        } else {
          return;
        }
      })(root);
      return root;
    }
  }

  initPartition() {
    // set the partition layout
    return (this._partition = partition().size([this.dimensions.angles.totalRange, this.dimensions.radius]).padding(0));
  }

  initArc() {
    return (this._arc = arc()
      .startAngle((d: any) => (d.startAngle = d.parent ? this.arcAngle(d, -1) : this.dimensions.angles.beginAngle))
      .endAngle((d: any) => (d.endAngle = d.parent ? this.arcAngle(d, 0) : this.dimensions.angles.closeAngle))
      .innerRadius((d: any) => (this.frameInnerRadius[d.depth] = d.depth * this.dimensions.mishbezet.radius))
      .outerRadius((d: any) => (this.frameOuterRadius[d.depth] = (1 + d.depth) * this.dimensions.mishbezet.radius)));
  }

  initSVG() {
    return (this._svg = select(this.element)
      .append('svg')
      .attr('class', 'sunburst-svg')
      .attr('direction', this.isHebrew ? 'rtl' : 'ltr')
      .attr(
        'viewBox',
        `-${this.dimensions.width / 2} -${this.dimensions.height} ${this.dimensions.width} ${this.dimensions.height}`
      ));
  }

  get rootData() {
    if (!this.reset && this._rootData) {
      return this._rootData;
    }
    // set the d3 hierarchy from the data
    if (this._rootData) {
      createNames(this._rootData, this._rootData.data.name[0].toLocaleUpperCase());
    }
    if (!this.data) {
      this.logger.error('data/field is required');
      return;
    }
    return (this._rootData = hierarchy(this.data, d => {
      // if data has children, add to each children its index in the parent
      if (d.children) {
        let iter = 0;
        d.children.map(node => {
          if (!d.question) {
            iter++;
            return (node.notDollarindex = iter);
          }
        });
        return d.children;
      } else {
        return d;
      }
    })
      // sum up the node and its children score
      .sum(d => (d.score ? d.score : 0)));

    function createNames(root, letter) {
      root.data.hierarchyName = letter;
      (function recurse(node) {
        if (node.children) {
          node.children.map((subNode, index) => {
            subNode.data.hierarchyName = node.data.hierarchyName + '.' + (index + 1);
            subNode.data.presentName = subNode.data.hierarchyName + '.' + ' ' + subNode.data.name;
            recurse(subNode);
          });
        } else {
          return;
        }
      })(root);
      return root;
    }
  }

  get arrowsData() {
    if (!this.data) {
      this.logger.error('data/field is required');
      return;
    }
    return (this._leftRightArrowData = hierarchy(this._arrowsData));
  }

  get partition() {
    if (!this.reset && this._partition) {
      return this._partition;
    }
    // set the partition layout
    return (this._partition = partition().size([this.dimensions.angles.totalRange, this.dimensions.radius]).padding(0));
  }

  get arc() {
    if (!this.reset && this._arc) {
      return this._arc;
    }
    return (this._arc = arc()
      .startAngle((d: any) => (d.startAngle = d.parent ? this.arcAngle(d, -1) : this.dimensions.angles.beginAngle))
      .endAngle((d: any) => (d.endAngle = d.parent ? this.arcAngle(d, 0) : this.dimensions.angles.closeAngle))
      .innerRadius((d: any) => (this.frameInnerRadius[d.depth] = d.depth * this.dimensions.mishbezet.radius))
      .outerRadius((d: any) => (this.frameOuterRadius[d.depth] = (1 + d.depth) * this.dimensions.mishbezet.radius)));
  }

  get arrowArc() {
    if (!this.reset && this._arrowArc) {
      return this._arrowArc;
    }
    return (this._arrowArc = arc()
      .startAngle((d: any) => (d.startAngle = this.arrowArcAngle(d, -1)))
      .endAngle((d: any) => (d.endAngle = this.arrowArcAngle(d, 0)))
      .innerRadius((d: any) => (this.frameInnerRadius[d.depth] = d.depth * this.dimensions.mishbezet.radius))
      .outerRadius((d: any) => (this.frameOuterRadius[d.depth] = (1 + d.depth) * this.dimensions.mishbezet.radius)));
  }

  get svg() {
    // if the SVG already exists, return it
    if (this._svg) {
      return this._svg;
    }
    return (this._svg = select(this.element)
      .append('svg')
      .attr('class', 'sunburst-svg')
      .attr('direction', this.isHebrew ? 'rtl' : 'ltr')
      .attr(
        'viewBox',
        `-${this.dimensions.width / 2} -${this.dimensions.height} ${this.dimensions.width} ${this.dimensions.height}`
      ));
  }

  get nodes() {
    if (!this.reset && this._nodes) {
      return this._nodes;
    }
    return this.svg.selectAll('.node');
  }

  get arrows() {
    return this.svg.selectAll('.arrow');
  }

  get rootNode() {
    if (!this.reset && this._rootNode) {
      return this._rootNode;
    }
    return (this._rootNode = this.svg.select('.node.lvl0'));
  }

  private setGradients() {
    if (this._defs) {
      this._defs.remove();
    }

    this._defs = this.svg.append('defs');

    const filter = this._defs.append('filter').attr('id', 'glow');

    // blur
    filter.append('feGaussianBlur').attr('class', 'blur').attr('stdDeviation', '12').attr('result', 'coloredBlur');

    const feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'coloredBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    // define vertical linear gradient
    const strokeGradient = this._defs
      .append('linearGradient')
      .attr('id', 'lvl-label-stroke-gradient')
      .attr('x1', '0')
      .attr('x2', '0')
      .attr('y1', '0')
      .attr('y2', '1');

    strokeGradient
      .append('stop-icon.svg')
      .attr('offset', '0%')
      .attr('style', 'stop-color: rgb(0, 0, 0); stop-opacity: 1'); // Archery color : $black

    strokeGradient
      .append('stop-icon.svg')
      .attr('offset', '100%')
      .attr('style', 'stop-color: transparent; stop-opacity: 0');

    const fillGradient = this._defs
      .append('linearGradient')
      .attr('id', 'lvl-label-fill-gradient')
      .attr('x1', '0')
      .attr('x2', '0')
      .attr('y1', '0')
      .attr('y2', '1');

    fillGradient.append('stop-icon.svg').attr('offset', '0%').attr('style', 'stop-color: transparent; stop-opacity: 1');

    fillGradient
      .append('stop-icon.svg')
      .attr('offset', '100%')
      .attr('style', 'stop-color: transparent; stop-opacity: 0');
  }

  private setRootImage() {
    const rootLocation = this.rootNode.node().getBBox();
    const score = this.rootNode?.datum()?.data?.score; // Get the score value
    // Calculate the color based on the score value
    const color = this.getRootColor(score);
    // set the label as the clicked item
    this.svg
      .select('.label.lvl0')
      .attr('cursor', 'pointer')
      .on('click', async () => {
        await this.nodeClick(this.rootNode.datum());
      });

    const rootText = this.svg
      .append('foreignObject')
      .style('width', rootLocation.width + 7)
      .style('height', rootLocation.height * 2 + 7)
      .style('background-image', `radial-gradient(circle, ${color} 65%, rgba(255, 255, 255, 0) 70.5%)`)
      .style('padding', '9px')
      .attr('class', 'root-object')
      .attr('x', rootLocation.x - 5)
      .attr('y', rootLocation.y - 5)
      .on('click', async () => await this.nodeClick(this.rootNode.datum()))
      .append('xhtml:div')
      .attr('class', 'root-text')
      .append('xhtml:div')
      .attr('class', 'root-score')
      .text(this.rootNode.datum().data.name.toUpperCase());
    rootText
      .append('xhtml:div')
      .attr('class', 'score')
      .text(UtilsService.totalScoreOf5(this.rootNode.datum().data?.score))
      .style('color', `${color}`)
      .append('xhtml:span')
      .attr('class', 'from-score')
      .text('/' + this.totalScore)
      .style('color', `${color}`)
      .attr('fill', 'url(#gradient)');
  }

  // * function returns color based on flag and score
  private getRootColor(score: number) {
    let color;
    // * If CyberArk flag is true
    if (UtilsService.isCyberArk) {
      const cyberArkColors = SunburstConstant.COLORS.cyberArkRootColors;
      if (score >= 0 && score <= 5) {
        color = cyberArkColors.cyberArkLow;
      } else if (score > 5 && score <= 9) {
        color = cyberArkColors.cyberArkMedium;
      } else if (score > 9 && score <= 10) {
        color = cyberArkColors.cyberArkHigh;
      }
    } else if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
      // * Mid Market Colors
      const bnbColors = SunburstConstant.COLORS.lines;
      if (score < 7) {
        color = bnbColors.bad;
      } else if (score < 8) {
        color = bnbColors.medium;
      } else if (score >= 8) {
        color = bnbColors.best;
      }
    } else {
      if (score >= 0 && score < 3.33) {
        color = '#f75f57';
      } else if (score >= 3.33 && score < 6.66) {
        color = '#f7d857';
      } else if (score >= 6.66 && score <= 10) {
        color = '#1bd964';
      }
    }
    return color;
  }

  private clearSVG() {
    // if SVG is defined and exists, clear all of its children
    // and no need to recreate it...
    if (this._svg) {
      this._svg.selectAll('*').remove();
    }
  }

  private async nodeClick(d) {
    this.onClickDepth = d.depth;

    if (this.inTransition) {
      this.notDollarkeepLastClick = d;
      return Promise.resolve();
    }
    this.fixEdgeTextRendering();
    // if the same was clicked, we do nothing
    if (this.selected && this.selected.data.id === d.data.id) {
      return Promise.resolve();
    }

    if (!d.notDollarancestorsList) {
      d.notDollarancestorsList = d.ancestors().map(i => i.data.id);
    }

    // hide the frame if needed - in case we need to make something disappear
    if (this.selected && (this.selected.depth > d.depth || !d.nonQuestionChildren)) {
      await this.hideLevelsFrames(d.depth); // should hide all the levels from depth and up
    }
    this.selected = d;
    this.selectedParent[d.depth] = d;
    this.currentRotatedAngles.forEach((x, i) => {
      if (i >= d.depth) {
        this.currentRotatedAngles[i] = 0;
      }
    });

    // if deepest layer - trigger security control details event
    if (d.nonQuestionChildren === 0) {
      this.securityControlClicked.emit(d.data);
      d.children = [];
    } else {
      this.securityControlClicked.emit(null);
    }

    this.inTransition = true;
    // hide all of the nodes above it
    await Promise.resolve('x');
    const showNodeFn: any = data => {
      // we are in the right depth And our parent is in the ancestors list
      return Number(
        // root
        !data.data.question &&
          data.parent &&
          (data.depth <= d.depth || d.depth === 0) &&
          d.notDollarancestorsList.indexOf(data.parent.data.id) > -1
      );
    };
    await this.toggleNodes(showNodeFn);
    this.fixEdgeTextRendering();

    // make the selection (blur out the others and show its path)
    this.highlightPath(d);
    this.getTriangleArc(d);

    this.mouseOver(d, true);

    // show the level's frame animation first - then show the layer itself
    if (d.nonQuestionChildren === 0) {
      this.inTransition = false;
      return null;
    }
    await this.enterLevelFrame(d.depth + 1, d.data.id, d.children.length);

    if (this.notDollarkeepLastClick) {
      await this.nodeClick(this.notDollarkeepLastClick);
      this.notDollarkeepLastClick = null;
    }
  }

  private arrowsClicked(d, arrowType: string): void {
    this.svg
      .selectAll('.node.lvl' + d.depth + '.parent' + this.selectedParent[d.depth - 1].data.id)
      .transition()
      .duration(2000)
      .ease(easeCubicOut)
      .attr(
        'transform',
        'scale(1)rotate(' +
          (this.currentRotatedAngles[d.depth - 1] =
            this.currentRotatedAngles[d.depth - 1] +
            (arrowType === 'left-arrow' ? -this.rotatingAngles[d.depth - 1] : this.rotatingAngles[d.depth - 1])) +
          ')translate(0 0)'
      );
    if (this.currentRotatedAngles[2] > 29) {
      const ids = d.notDollarancestorsList;
      const childrenIds = d.children.map(child => child.data.id);

      this.svg.selectAll('.node').style('fill', data => {
        // if it is a child or a parent in the path
        data.notDollarselected = ids?.indexOf(data.data.id) > -1 || -1 < childrenIds?.indexOf(data.data.id);
        return data.notDollarselected ? this.colors.selected : this.colors.notSelected;
      });
    }
  }

  private hideLevelsFrames(lvl) {
    return new Promise(res => {
      let selector = '';
      for (let i = lvl + 1; i <= this.dimensions.maxLabelLevels; i++) {
        selector += '.label.lvl' + i + ', .arrow.lvl' + i + ', .labelText.lvl' + i + ', ';
      }

      if (selector.length === 0) {
        res(false);
      }

      selector = selector.substring(0, selector.length - 2); // remove the last char
      const selectedNodes = this.svg.selectAll(selector);
      if (selectedNodes.nodes().length === 0) {
        res(false);
      } else {
        selectedNodes.transition().duration(750).style('opacity', 0).on('end', res);
      }
    });
  }

  private enterLevelFrame(lvl, parentId, childCount) {
    this.svg
      .selectAll('.label.lvl' + lvl + ', .labelText.lvl' + lvl + ', .parent' + parentId + ', .arrow.lvl' + lvl)
      .style('pointer-events', 'all')
      .attr(
        'transform',
        `scale(.25)rotate(90)translate(${this.dimensions.mishbezet.radius / 2}, ${
          this.dimensions.mishbezet.radius / 2
        })`
      )
      .style('display', childCount > this.maxChildren ? 'block' : 'none')
      .transition()
      .style('opacity', childCount > this.maxChildren ? '1' : '0')
      .duration(2000)
      .ease(easeCubicOut)
      .attr('transform', 'scale(1)rotate(0)translate(0 0)');
    return new Promise(res => {
      this.svg
        .selectAll('.label.lvl' + lvl + ', .labelText.lvl' + lvl + ', .parent' + parentId)
        .style('pointer-events', 'all')
        .attr(
          'transform',
          `scale(.25)rotate(90)translate(${this.dimensions.mishbezet.radius / 2}, ${
            this.dimensions.mishbezet.radius / 2
          })`
        )
        .style('display', 'block')
        .transition()
        .style('opacity', '1')
        .duration(2000)
        .ease(easeCubicOut)
        .attr('transform', 'scale(1)rotate(0)translate(0 0)')
        .on('end', res);
    }).then(() => (this.inTransition = false));
  }

  private highlightPath(d) {
    const ids = d.notDollarancestorsList;
    const childrenIds = d.children.map(child => child.data.id);

    this.svg.selectAll('.node').style('fill', data => {
      // if it is a child or a parent in the path
      data.notDollarselected = ids.indexOf(data.data.id) > -1 || -1 < childrenIds.indexOf(data.data.id);
      return data.notDollarselected ? this.colors.selected : this.colors.notSelected;
    });

    this.svg.selectAll('.middleSquareText').attr('opacity', data => {
      data.notDollarselected = ids.indexOf(data.data.id) > -1 || -1 < childrenIds.indexOf(data.data.id);
      return data.notDollarselected ? 1 : 0.3;
    });
    this.borderAndStrokeFill();
    this.svg.selectAll('.borderStand').attr('stroke', '#58666d');
  }

  borderAndStrokeFill() {
    const ids = this.selected.notDollarancestorsList;
    const childrenIds = this.selected.children.map(child => child.data.id);
    this.svg
      .selectAll('.border')
      .attr('fill', data => {
        data.notDollarselected = ids.indexOf(data.data.id) > -1 || -1 < childrenIds.indexOf(data.data.id);
        return data.notDollarselected ? this.getScoreColor(data, true) : this.getScoreColor(data, false);
      })
      .attr('stroke', data => {
        data.notDollarselected = ids.indexOf(data.data.id) > -1 || -1 < childrenIds.indexOf(data.data.id);
        return data.notDollarselected ? this.getScoreColor(data, true) : this.getScoreColor(data, false);
      });
    this.getTriangleArc();
  }
  // To update selected control every time user remediate
  updateSelected(selectedControl: any) {
    this.answered = selectedControl;
  }
  // on remediating the question, the control border will be updated
  borderUpdate() {
    // Added answer check as an extra check. It has been handled on onSave function in question-card-ui component.
    if (this.selected && this.selected.data && this.answered && this.answered.length) {
      if (this.answered[0].answers[0].answer === 0) {
        this.selected.data.score = 10;
      } else {
        this.selected.data.score = this.answered[0].answers[0].answer;
      }
      this.colorParentBorder(this.selected);
      this.securityControlClicked.emit(this.selected.data);
      // this.borderAndStrokeFill();
    }
  }
  colorParentBorder(node) {
    let data = node;
    for (let i = 0; i < 3; i++) {
      let score = 0;
      data.parent?.children?.forEach(element => {
        score = element.data.score + score;
      });
      score = score / data?.parent?.children.length;
      data.parent.data.score = score;
      if (data.parent) {
        data = data?.parent;
      }
    }
    this.borderAndStrokeFill();
  }

  private mouseOver(d, isEnter) {
    if (!this.selected.notDollarancestorsList) {
      this.selected.notDollarancestorsList = this.selected.ancestors().map(i => i.data.id);
    }
    const ids = this.selected.notDollarancestorsList;
    const childrenIds = this.selected.children.map(child => child.data.id);
    const isLight = d.data && d.data.id ? ids.indexOf(d.data.id) > -1 || -1 < childrenIds.indexOf(d.data.id) : false;

    const element = select('#' + 'node' + d.data.id)
      .style('fill', isEnter ? this.colors.hover : d.notDollarselected ? this.colors.selected : this.colors.notSelected)
      .attr('cursor', 'pointer');

    element.selectAll('.middleSquareText').attr('opacity', () => (isEnter || isLight ? 1 : 0.3));

    element
      .selectAll('.border')
      .attr('fill', () => (isEnter || isLight ? this.getScoreColor(d, true) : this.getScoreColor(d, false)));
  }

  private toggleNodes(show: any = 0, lvl?, parent?) {
    return new Promise(resolve => {
      let nodes = this.nodes;
      if (lvl) {
        if (parent) {
          nodes = this.svg.selectAll('.lvl' + lvl + '.parent' + parent.data.id);
        } else {
          nodes = this.svg.selectAll('.lvl' + lvl);
        }
      }

      this.showNodes(nodes, show, resolve);
    });
  }

  private getDPathParams(d, self) {
    // /////////////////////// get params /////////////////////////////////////////////
    let M;

    // set the D according to the parent's d values
    const box = this.getBox(d, self, false);
    if (!box) {
      return;
    }

    const pathD = box.getAttribute('d');

    // you can extract the starting point here (the M attribute)
    let parsedD = pathD.split(/(?=[LMA])/);

    // turn parseD array into object
    parsedD = parsedD.reduce((acc, cur) => {
      if (!acc[cur[0]]) {
        acc[cur[0]] = cur.replace(cur[0], '');
      } else {
        acc[cur[0] + '1'] = cur.replace(cur[0], '');
      }
      return acc;
    }, {});
    // TODO::parsedD.M is the starting point where you should put the grade
    // the last two numbers of A1 are the end points (left bottom corner)
    if (parsedD.A1) {
      // for firefox & explorer
      if (parsedD.A1.indexOf(',') < 0) {
        M = parsedD.A1.replace('Z', '').slice(1, -1).split(' ');
      } else {
        M = parsedD.A1.replace('Z', '').split(',');
      }
    } else {
      d.frameCoord = null;
      //  return null;
    }
    // for firefox & explorer
    parsedD.M = parsedD.M.charAt(0) === ' ' ? parsedD.M.slice(1, -1).replace(/ /g, ',') : parsedD.M;
    parsedD.A = parsedD.A.charAt(0) === ' ' ? parsedD.A.slice(1, -1).replace(/ /g, ',') : parsedD.A;

    parsedD.A = parsedD.A.replace('z', '');
    if (!M || !parsedD) {
      return null;
    }
    // /////////////////////// work with params ////////////////////////////////
    const radius = (d.depth + 1) * this.dimensions.mishbezet.radius;
    const arrOfA = parsedD.A.split(',');

    // // frame points //////
    const bottomLeftPoint: Point = {
      x: parseFloat(M[M.length - 2]),
      y: parseFloat(M[M.length - 1]),
    };

    const paraseM = parsedD.M.split(',');
    const upperLeftPoint: Point = {
      x: parseFloat(paraseM[0]),
      y: parseFloat(paraseM[1]),
    };

    const paraseL = parsedD.L.split(',');
    const bottomRightPoint: Point = {
      x: parseFloat(paraseL[0]),
      y: parseFloat(paraseL[1]),
    };

    const paraseA = parsedD.A.split(',');
    const upperRightPoint: Point = {
      x: parseFloat(paraseA[paraseA.length - 2]),
      y: parseFloat(paraseA[paraseA.length - 2]),
    };

    const rightLine = { slope: null, constant: null };
    rightLine.slope = (upperRightPoint.y - bottomRightPoint.y) / (upperRightPoint.x - bottomRightPoint.x);
    rightLine.constant = upperRightPoint.y - rightLine.slope * upperRightPoint.x;

    const leftSide = upperLeftPoint.x < 0 ? 1 : -1;

    // left line equation
    const leftLine = { slope: null, constant: null };
    leftLine.slope = (upperLeftPoint.y - bottomLeftPoint.y) / (upperLeftPoint.x - bottomLeftPoint.x);
    leftLine.slope = [-Infinity, Infinity].includes(leftLine.slope) ? 0 : leftLine.slope;
    leftLine.constant = upperLeftPoint.y - leftLine.slope * upperLeftPoint.x;

    const framePoints = {
      leftLine,
      rightLine,
      upperPoint: upperLeftPoint,
      bottomPoint: bottomLeftPoint,
    };

    // /////////////////////

    // some figure needed for calc
    const leftSlopeCalc = Math.sqrt(Math.pow(leftLine.slope, 2) + 1);

    // ///// border points //////////

    const borderRightAngle = d.startAngle + (d.endAngle - d.startAngle);
    const borderRightPoint = {
      x: Math.sin(borderRightAngle) * radius,
      y: -Math.cos(borderRightAngle) * radius,
    };
    arrOfA[arrOfA.length - 2] = borderRightPoint.x;
    arrOfA[arrOfA.length - 1] = borderRightPoint.y;
    const arcEndPoint = arrOfA.toString();
    const borderLeftPoint = { x: null, y: null };
    borderLeftPoint.x = (upperLeftPoint.x * leftSlopeCalc + leftSide * this.dimensions.borderSlope) / leftSlopeCalc;
    borderLeftPoint.y = leftLine.slope * borderLeftPoint.x + leftLine.constant;
    arrOfA[arrOfA.length - 2] = borderLeftPoint.x;
    arrOfA[arrOfA.length - 1] = borderLeftPoint.y;
    arrOfA[arrOfA.length - 3] = 0;
    const arcStartPoint = arrOfA.toString();

    const borderPoints = {
      arcEndPoint,
      arcStartPoint,
    };

    d.frameCoord = {
      framePoints,
      borderPoints,
    };
  }

  private getScoreColor(d, opacity) {
    let colorToReturn = null;
    if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
      colorToReturn = this.getBeecherScoreColor(d, opacity);
    } else if (UtilsService.isBnB) {
      colorToReturn = this.getBNBScoreColor(d, opacity);
    } else if (UtilsService.isCyberArk) {
      colorToReturn = this.getCyberArkScoreColor(d, opacity);
    } else {
      switch (true) {
        case d.data && d.data.score < 3.33:
          colorToReturn = opacity ? this.colors.lines.bad : this.colors.lines.fade;
          break;
        case d.data && d.data.score < 6.66:
          colorToReturn = opacity ? this.colors.lines.medium : this.colors.lines.fade;
          break;
        case d.data && d.data.score >= 6.66:
          colorToReturn = opacity ? this.colors.lines.best : this.colors.lines.fade;
          break;
        default:
          colorToReturn = this.colors.lines.fade;
      }
    }
    return colorToReturn;
  }

  private getBeecherScoreColor(d, opacity): string {
    let colorToReturn = '';
    switch (true) {
      case d.data && d.data.score < 7:
        colorToReturn = opacity ? this.colors.lines.bad : this.colors.lines.fade;
        break;
      case d.data && d.data.score < 8:
        colorToReturn = opacity ? this.colors.lines.medium : this.colors.lines.fade;
        break;
      case d.data && d.data.score >= 8:
        colorToReturn = opacity ? this.colors.lines.best : this.colors.lines.fade;
        break;
      default:
        colorToReturn = this.colors.lines.fade;
    }
    return colorToReturn;
  }

  private getBNBScoreColor(d, opacity): string {
    let colorToReturn = '';
    switch (true) {
      case d.data && d.data.score <= 4:
        colorToReturn = opacity ? this.colors.lines.bnbLowest : this.colors.lines.fade;
        break;
      case d.data && d.data.score <= 6:
        colorToReturn = opacity ? this.colors.lines.bnbLow : this.colors.lines.fade;
        break;
      case d.data && d.data.score <= 8:
        colorToReturn = opacity ? this.colors.lines.bnbMedium : this.colors.lines.fade;
        break;
      case d.data && d.data.score < 10:
        colorToReturn = opacity ? this.colors.lines.bnbHigh : this.colors.lines.fade;
        break;
      case d.data && d.data.score === 10:
        colorToReturn = opacity ? this.colors.lines.bnbHighest : this.colors.lines.fade;
        break;
      default:
        colorToReturn = this.colors.lines.fade;
    }
    return colorToReturn;
  }

  // * sets colors for CyberArk
  private getCyberArkScoreColor(d, opacity): string {
    let colorToReturn = '';
    switch (true) {
      case d.data && d.data.score <= 5:
        colorToReturn = opacity ? this.colors.lines.cyberArkLow : this.colors.lines.fade;
        break;
      case d.data && d.data.score <= 9:
        colorToReturn = opacity ? this.colors.lines.cyberArkMedium : this.colors.lines.fade;
        break;
      case d.data && d.data.score > 9:
        colorToReturn = opacity ? this.colors.lines.cyberArkHigh : this.colors.lines.fade;
        break;
      default:
        colorToReturn = this.colors.lines.fade;
    }
    return colorToReturn;
  }

  private fixEdgeTextRendering() {
    if (navigator.userAgent.includes('Edge')) {
      selectAll('.nodeName').classed('fix-edge-text', true);
      setTimeout(() => selectAll('.nodeName').classed('fix-edge-text', false), 100);
    }
  }

  private mouseOverArrow(d, isEnter) {
    const mouseEnterfill = '#29a9eb';
    const mouseOutfill = '#29a9eb';
    const element = select(`#arrow${d.id}`)
      .select('foreignObject')
      .attr('height', isEnter ? 40 : 30);
    element
      .select('div')
      .select('svg')
      .attr('style', () => `width: 113%;height: 113%;fill: ${isEnter ? mouseEnterfill : mouseOutfill};`);
  }
  private getArcOpacity(d) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    let returnValue;

    if (d.endAngle || d.endAngle === 0) {
      that.getDPathParams(d, this);
      const params = d.frameCoord;

      if (!d.frameCoord) {
        returnValue = null;
      } else {
        returnValue = params.framePoints.upperPoint.x === 248.92 || params.framePoints.upperPoint.x === 287.429 ? 0 : 1;
      }
    }
    // that.setWidthAndPosition(d, that.dimensions.mishbezet.radius, that.dimensions.mishbezet.textPadding);
    return returnValue;
  }

  private visualizeData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this; // need for getDPathParams()
    // add the data
    // add the surrounding frame and put the groups inside it

    // Add labels that will appear on the left with each arc. i.e: function, category, sub-category
    this.drawLevelsLabels();

    // Add the left and right arrows if the children of clicked section is more than 6
    this.svg
      .selectAll('g.arrow')
      .data(this.arrowsData.descendants()[0].data.flat())
      .enter()
      .insert('g', ':first-child')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .attr('placement', 'top')
      .attr('fill', 'none')
      .attr('cursor', 'pointer')
      .attr('class', d => `arrow lvl${d.depth}`)
      .attr('id', d => {
        return `arrow${d.id}`;
      })
      .on('click', (_, d) => {
        if (d.id.includes('left-arrow')) {
          return this.arrowsClicked(d, 'left-arrow');
        } else if (d.id.includes('right-arrow')) {
          return this.arrowsClicked(d, 'right-arrow');
        }
      })
      .on('contextmenu', d => {
        event.preventDefault();
        const menuElement = document.getElementsByClassName('context-menu')[0];
        if (menuElement) {
          menuElement.setAttribute(
            'style',
            `display: block; top:${(event as any).clientY - 100}px; left:${(event as any).clientX}px`
          );
          menuElement.setAttribute('id', `${d.data.id}`);
        }
        const listener = (clickEvent: any) => {
          const clickZone = clickEvent.path.find(element => element.className === 'context-menu');
          if (!clickZone) {
            document.removeEventListener('click', listener);
          }
          menuElement.setAttribute('style', 'display: none');
        };
        document.addEventListener('click', listener);
        // react on right-clicking
      });

    // Add the noes for each children of the selected section.
    this.svg
      // do something on selection
      .selectAll('g.node')
      // enter the data
      .data(this.rootData.descendants())
      .enter()
      // set a group for each node
      .insert('g', ':first-child')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .attr('ngbTooltip', d => d.data.name)
      .attr('placement', 'top')
      .attr('fill', this.colors.notSelected)
      .attr('class', d => `node lvl${d.depth} ${d.parent ? 'parent' + d.parent.data.id : ''}`)
      .attr('id', d => {
        this.countNonQuestionChildren(d);
        return `node${d.data.id}`;
      })
      // underscore _ represents that event is not of our use
      .on('click', async (_, d) => {
        return await this.nodeClick(d);
      })
      .on('contextmenu', d => {
        event.preventDefault();
        const menuElement = document.getElementsByClassName('context-menu')[0];
        if (menuElement) {
          menuElement.setAttribute(
            'style',
            `display: block; top:${(event as any).clientY - 100}px; left:${(event as any).clientX}px`
          );
          menuElement.setAttribute('id', `${d.data.id}`);
        }
        const listener = (clickEvent: any) => {
          const clickZone = clickEvent.path.find(element => element.className === 'context-menu');
          if (!clickZone) {
            document.removeEventListener('click', listener);
          }
          menuElement.setAttribute('style', 'display: none');
        };
        document.addEventListener('click', listener);
        // react on right-clicking
      })
      // underscore _ represents that event is not of our use
      .on('mouseenter', (_, d) => this.mouseOver(d, true))
      .on('mouseleave', (_, d) => this.mouseOver(d, false));

    // set gradient
    this.setGradients();

    // add the paths and labels
    this.nodes
      .append('path')
      .attr('d', this.arc)
      .style('stroke', () => '0')
      .style('opacity', d => this.getArcOpacity(d));
    this.arrows
      .append('path')
      .attr('d', this.arrowArc)
      .style('stroke', () => 'none');

    // add the path's border -> arc that gets smaller
    this.nodes
      .append('path')
      .attr('class', 'border borderArc')
      .attr('stroke-width', '3')
      .attr('d', function (d, i) {
        // couldn't be arrow fn
        let returnValue;
        if (d.endAngle || d.endAngle === 0) {
          that.getDPathParams(d, this);
          const params = d.frameCoord;

          if (!d.frameCoord) {
            returnValue = null;
          } else {
            // i should be above -1 then add proper path. otherwise 0 index element dont have colored arc border
            if (i > -1) {
              returnValue = `M${params.framePoints.upperPoint.x},
                             ${params.framePoints.upperPoint.y}
                             L${params.framePoints.upperPoint.x},
                             ${params.framePoints.upperPoint.y}
                             A${params.borderPoints.arcEndPoint}
                             A${params.borderPoints.arcStartPoint}`;
            } else {
              returnValue = 'M1,2';
            }
          }
        }
        that.setWidthAndPosition(d, that.dimensions.mishbezet.radius, that.dimensions.mishbezet.textPadding);
        return returnValue;
      })
      .style('fill', d => this.getScoreColor(d, true));

    this.arrows
      .append('path')
      .attr('d', function (d, i) {
        // couldn't be arrow fn
        let returnValue;
        if (d.endAngle || d.endAngle === 0) {
          that.getDPathParams(d, this);
          if (!d.frameCoord) {
            returnValue = null;
          } else {
            const params = d.frameCoord;
            if (i) {
              returnValue = `M${params.framePoints.upperPoint.x},
                              ${params.framePoints.upperPoint.y}
                              L${params.framePoints.upperPoint.x},
                              ${params.framePoints.upperPoint.y}
                              A${params.borderPoints.arcEndPoint}
                              A${params.borderPoints.arcStartPoint}`;
            } else {
              returnValue = 'M1,2';
            }
          }
        }
        that.setWidthAndPosition(d, that.dimensions.mishbezet.radius, that.dimensions.mishbezet.textPadding);
        return returnValue;
      })
      .style('fill', d => this.getScoreColor(d, true));

    this.drawLevelsFrames();
    // add the path's border -> left side
    this.nodes
      .append('path')
      .attr('class', 'border borderStand')
      .attr('stroke-width', '1')
      .attr('stroke', '#000000')
      .attr('d', d => {
        if (!d.frameCoord) {
          return null;
        }
        const params = d.frameCoord;
        return `M${params.framePoints.upperPoint.x},
                 ${params.framePoints.upperPoint.y}
                L${params.framePoints.bottomPoint.x},
                 ${params.framePoints.bottomPoint.y}`;
      });

    this.nodes
      .append('foreignObject')
      .attr('x', d => d.textDims.middle.x - d.textDims.width / 2)
      .attr('y', d => -d.textDims.middle.y - 30)
      .attr('class', 'middleSquareText')
      .attr('transform', d => {
        // calculating average of start and end angle
        const angle = (d.startAngle * this.radToDeg + d.endAngle * this.radToDeg) / 2;
        return `rotate(${angle}, ${d.textDims.middle.x}, ${d.textDims.middle.y * -1})`;
      })
      .attr('height', 57)
      .attr('width', d => d.textDims.width)

      .append('xhtml:div')
      .attr('class', 'nodeNameContainer')

      .append('xhtml:div')
      .attr('title', d => d.data.name)
      .attr('class', 'nodeName line-clamp line-clamp-3 lato-14-n-vw')
      .text(d => {
        let nodeName = d.data.children ? d.data.name : d.data.controlName;
        // only in the last level we add the label to control name
        if (this.isLeaf(d.depth)) {
          const nodeLabel = d.data.label
            ? d.data.label
            : d.data.children && d.data.children.length
            ? d.data.children[0].label
            : null;
          nodeName = nodeLabel ? `${nodeLabel} - ${nodeName}` : nodeName;
        }
        return nodeName;
      });

    // Display the left and the right arrows on the arcs.
    this.arrows
      .append('foreignObject')
      .attr('x', function (d) {
        if (d.depth === 2 && d.id.includes('left-arrow')) {
          return getScreenSize() === 1920 ? '-272.204' : '-196.204';
        } else if (d.depth === 4 && d.id.includes('left-arrow')) {
          return getScreenSize() === 1920 ? '-485.204' : '-340.1';
        } else if (d.depth === 1 && d.id.includes('left-arrow')) {
          return getScreenSize() === 1920 ? '-170.204' : '-130.82';
        } else if (d.depth === 3 && d.id.includes('left-arrow')) {
          return getScreenSize() === 1920 ? '-379.686' : '-285.1';
        } else if (d.depth === 2 && d.id.includes('right-arrow')) {
          return getScreenSize() === 1920 ? '256.796' : '180.796';
        } else if (d.depth === 1 && d.id.includes('right-arrow')) {
          return getScreenSize() === 1920 ? '147.796' : '107.8196';
        } else if (d.depth === 4 && d.id.includes('right-arrow')) {
          return getScreenSize() === 1920 ? '466' : '320.1';
        } else if (d.depth === 3 && d.id.includes('right-arrow')) {
          return getScreenSize() === 1920 ? '360.686' : '269.1';
        } else {
          return d.textDims.middle.x - d.textDims.width / 2 + 10;
        }
      })
      .attr('y', function (d) {
        if (d.depth === 2 && (d.id.includes('left-arrow') || d.id.includes('right-arrow'))) {
          return '-26.4307';
        } else {
          return '-26.4307';
        }
      })
      .attr('class', 'middleSquareArrow')
      .attr('height', 30)
      .attr('width', d => d.textDims.width)
      .attr('class', function (d) {
        if (d.depth === 2) {
          return 'wheel-circle';
        } else {
          return 'wheel-circle';
        }
      })
      .append('xhtml:div')
      .attr(
        'style',
        d =>
          `width: 80%;height: 106%;padding: 0px 4px 1px 1px; transform:rotate(${
            d.id.includes('left-arrow') ? -70 : -110
          }deg)`
      )
      // underscore _ represents that event is not of our use
      .on('mouseenter', (_, d) => this.mouseOverArrow(d, true))
      .on('mouseleave', (_, d) => this.mouseOverArrow(d, false))
      .append('svg')
      .attr('style', 'width: 113%;height: 113%; fill: #29a9eb')
      .attr('viewBox', '0 0 477.175 477.175')
      .append('g')
      .append('path')
      .attr(
        'd',
        // eslint-disable-next-line max-len
        'M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225 c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z'
      );

    // add the root image
    this.setRootImage();
  }

  private arcAngle(d, angleKey = 0) {
    // if our parent have no visible children - do nothing
    if (!d.parent.nonQuestionChildren) {
      return null;
    }
    // do we need current index (for start angle) or later index (for end)
    const iterLevel = d.data.notDollarindex + angleKey; // how much to multiply the angle

    let marginAngle = 0; // margin from horizontal radius to start angle
    let slice;

    // get the needed angle - the angle that is needed to make the node the constant node size
    const requiredAngle =
      (2 * this.dimensions.mishbezet.size) / (Math.pow(this.dimensions.mishbezet.radius, 2) * (2 * d.depth + 1));
    // if the angle times nodes fits in than the total range
    if (
      requiredAngle * d.parent.nonQuestionChildren < this.dimensions.angles.totalRange &&
      d.parent.nonQuestionChildren <= this.maxChildren
    ) {
      marginAngle = (this.dimensions.angles.totalRange - requiredAngle * d.parent.nonQuestionChildren) / 2;
      slice = requiredAngle;
    } else {
      // doesn't fit - fitting in according to number of children
      const divisor = d.parent.nonQuestionChildren < this.maxChildren ? d.parent.nonQuestionChildren : this.maxChildren;
      slice = this.dimensions.angles.totalRange / divisor;
      d.sliced = d.depth / divisor;
    }
    return this.dimensions.angles.beginAngle + marginAngle + slice * iterLevel;
  }
  private getTriangleArc(data?: any) {
    console.log(data);
    // console.clear();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const ids = that.selected.notDollarancestorsList;
    const childrenIds = this.selected.children.map(child => child.data.id);

    // add the path's border -> arc that gets smaller
    that.nodes
      .append('path')
      .attr('class', 'border borderStand triangle')
      .attr('stroke-width', '3')
      .attr('d', function (d, i) {
        let returnValue = '';

        if (d.endAngle || d.endAngle === 0) {
          that.getDPathParams(d, that);
          const params1 = d.frameCoord;
          // const triangleSize = 3; // Adjust the size of the triangle
          // const longerSide = 8;
          // const triangleOffset = 3; // Offset from the border
          d.notDollarselected = ids.indexOf(d.data.id) > -1 || -1 < childrenIds.indexOf(d.data.id);
          if (!d.frameCoord) {
            returnValue = null;
          } else {
            if (i > -1) {
              returnValue += `M${params1?.framePoints.upperPoint.x + 2.5},
                ${params1?.framePoints.upperPoint.y}
                L${params1?.framePoints.upperPoint.x + 6},
                ${params1?.framePoints.upperPoint.y + 1.5}
                
                            L${params1?.framePoints.upperPoint.x - params1?.framePoints.bottomPoint.x / 9},
                            ${params1?.framePoints.upperPoint.y - params1?.framePoints.bottomPoint.y / 9}
                            Z`;
            }
          }
        }
        that.setWidthAndPosition(d, that.dimensions.mishbezet.radius, that.dimensions.mishbezet.textPadding);
        return returnValue;
      })
      // .style('opacity', d => {
      //   d.notDollarselected = ids.indexOf(d.data.id) > -1 || -1 < childrenIds.indexOf(d.data.id);
      //   return d.notDollarselected ? 1 : 0;
      // })
      .style('fill', d => {
        d.notDollarselected = ids.indexOf(d.data.id) > -1 || -1 < childrenIds.indexOf(d.data.id);
        return d.notDollarselected ? this.getScoreColor(d, true) : '#121521';
      })
      .style('stroke', d => {
        d.notDollarselected = ids.indexOf(d.data.id) > -1 || -1 < childrenIds.indexOf(d.data.id);
        return d.notDollarselected ? this.getScoreColor(d, true) : '#121521';
      });
  }

  // Calculate the angles for the left and right arrows to be displayed
  private arrowArcAngle(d, angleKey = 0) {
    let iterLevel;
    if (d.id.includes('left')) {
      iterLevel = 1 + angleKey;
    }
    if (d.id.includes('right')) {
      iterLevel = 14 + angleKey;
    }
    let marginAngle = 0; // margin from horizontal radius to start angle
    let slice;
    const nonQuestionChildren = 14;
    // get the needed angle - the angle that is needed to make the node the constant node size
    const requiredAngle =
      (2 * this.dimensions.mishbezet.size) / (Math.pow(this.dimensions.mishbezet.radius, 2) * (2 * d.depth + 1));
    // if the angle times nodes fits in than the total range
    if (requiredAngle * nonQuestionChildren < this.dimensions.angles.totalRange) {
      marginAngle = (this.dimensions.angles.totalRange - requiredAngle * nonQuestionChildren) / 2;
      slice = requiredAngle;
    } else {
      // doesn't fit - fitting in according to number of children
      slice = this.dimensions.angles.totalRange / nonQuestionChildren;
      d.sliced = d.depth / nonQuestionChildren;
      this.rotatingAngles[d.depth - 1] = (this.dimensions.angles.totalRange / this.maxChildren) * (180 / Math.PI);
    }
    return this.dimensions.angles.arrowBeginAngle + marginAngle + slice * iterLevel;
  }

  private drawLevelsFrames() {
    const labelsPartition = partition().size([this.dimensions.angles.totalRange, this.dimensions.radius]).padding(0);

    const labelsData = hierarchy(this.labels, d => {
      if (d.children) {
        let iter = 0;
        for (const child of d.children) {
          if (!d.question) {
            iter++;
            child.notDollarindex = iter;
          }
        }
        return d.children;
      } else {
        return d;
      }
    }).sum(d => (d.children ? 1 : 0));
    labelsPartition(labelsData);

    this.svg
      .selectAll('svg')
      .data(labelsData.descendants())
      .enter()
      .insert('g', d => {
        this.dimensions.maxLabelLevels = Math.max(this.dimensions.maxLabelLevels, d.depth);
        const elements = document.getElementsByClassName(`node lvl${d.depth}`);
        return elements[0];
      })
      .attr('class', d => `label lvl${d.depth}`)
      .attr('id', d => `frame_${d.depth}`)
      .style('display', d => (this.isRoot(d.depth) || !d.parent ? null : 'none'))
      .style('opacity', d => (this.isRoot(d.depth) || !d.parent ? 0 : 0));

    const arcFrame = arc()
      .startAngle((d: any) => (d.startAngle = !d.parent ? -180 : this.dimensions.angles.frameBeginAngle))
      .endAngle((d: any) => (d.endAngle = !d.parent ? 180 : this.dimensions.angles.frameCloseAngle))
      .innerRadius((d: any) => this.frameInnerRadius[d.depth])
      .outerRadius((d: any) => this.frameOuterRadius[d.depth]);

    this.svg
      .selectAll('.label')
      .append('path')
      .attr('d', arcFrame)
      .attr('class', 'pathLabel')
      .attr('stroke', '#000000')
      .attr('fill', 'none')
      .style('opacity, 0.4');

    this.svg.select('.label.lvl0').select('path').attr('class', 'rootPathLabel');
  }

  private drawLevelsLabels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const labelsPartition = partition().size([this.dimensions.angles.totalRange, this.dimensions.radius]).padding(0);

    const labelsData = hierarchy(this.labels, d => {
      if (d.children) {
        let iter = 0;
        for (const child of d.children) {
          if (!d.question) {
            iter++;
            child.notDollarindex = iter;
          }
        }
        return d.children;
      } else {
        return d;
      }
    }).sum(d => (d.children ? 1 : 0));
    labelsPartition(labelsData);

    this.svg
      .selectAll('svg')
      .data(labelsData.descendants())
      .enter()
      .insert('g', d => {
        this.dimensions.maxLabelLevels = Math.max(this.dimensions.maxLabelLevels, d.depth);
        const elements = document.getElementsByClassName(`node lvl${d.depth}`);
        return elements[0];
      })
      .attr('class', d => `labelText lvl${d.depth}`)
      .attr('id', d => `frameText_${d.depth}`)
      .style('display', d => (this.isRoot(d.depth) || !d.parent ? null : 'none'))
      .style('opacity', d => (this.isRoot(d.depth) || !d.parent ? 1 : 0));

    const arcFrame = arc()
      .startAngle((d: any) => (d.startAngle = !d.parent ? -180 : this.dimensions.angles.frameBeginAngle))
      .endAngle((d: any) => (d.endAngle = !d.parent ? 180 : this.dimensions.angles.frameCloseAngle))
      .innerRadius((d: any) => this.frameInnerRadius[d.depth])
      .outerRadius((d: any) => this.frameOuterRadius[d.depth]);

    this.svg.selectAll('.labelText').append('path').attr('d', arcFrame).attr('style', 'display: none;');

    this.svg
      .selectAll('.labelText')
      .append('foreignObject')
      .attr('width', function (d) {
        if (d.depth === 0) {
          return 475;
        } else if (d.depth === 1) {
          return 105.143;
        } else if (d.depth === 2) {
          return 126.143;
        } else if (d.depth === 3) {
          return getScreenSize() === 1920 ? 130.143 : 115.714;
        } else if (d.depth === 4) {
          return getScreenSize() === 1920 ? 112.143 : 102.7143;
        } else {
          // couldn't be arrow fn
          that.setTextWidthAndPosition(d, that.dimensions.mishbezet.radius, that.dimensions.mishbezet.textPadding);
          return d.textDims.width;
        }
      })
      .attr('height', function (d) {
        if (d.depth === 1) {
          return getScreenSize() === 1920 ? ' 48' : '35';
        } else if (d.depth === 2) {
          return '43';
        } else if (d.depth === 3) {
          return '44';
        } else if (d.depth === 4) {
          return '42';
        } else {
          return '100';
        }
      })
      .attr('x', function (d) {
        if (d.depth === 0) {
          return 58.2552;
        } else if (d.depth === 1) {
          return getScreenSize() === 1920 ? -210.286 : -162.286;
        } else if (d.depth === 2) {
          return getScreenSize() === 1920 ? -324.429 : -271.429;
        } else if (d.depth === 3) {
          return getScreenSize() === 1920 ? -431.571 : -336.571;
        } else if (d.depth === 4) {
          return getScreenSize() === 1920 ? -528.714 : -417.571;
        } else {
          return d?.textDims?.p2?.x;
        }
      })
      .attr('y', function (d) {
        if (d.depth === 0) {
          return 1.3006;
        } else if (d.depth === 1) {
          return getScreenSize() === 1920 ? -2.6994 : -0.6994;
          // return -56.6994;
        } else if (d.depth === 2) {
          return -0.6994;
        } else if (d.depth === 3) {
          return getScreenSize() === 1920 ? -2.6994 : -1.6994;
        } else if (d.depth === 4) {
          return -0.6994;
        } else {
          return 1.3006;
        }
      })
      .style('background', function (d) {
        if (d.depth === 0 || d.depth === 2 || d.depth === 4 || d.depth === 3 || d.depth === 1) {
          return '#21242F';
        } else {
          return '';
        }
      }) // setting y of text 2.4 % above
      .append('xhtml:div')
      .text(d => d.data.name)
      .attr('class', function (d) {
        if (d.depth === 1) {
          return getScreenSize() === 1920 ? 'frame-text-hide' : 'frame-text-hide-for-small-screens-level1';
        }
        if (d.depth === 2) {
          return getScreenSize() === 1920 ? 'frame-text-hide' : 'frame-text-hide-for-small-screens-level2';
        }
        if (d.depth === 3) {
          return getScreenSize() === 1920 ? 'frame-text-hide' : 'frame-text-hide-for-small-screens-level3';
        }
        if (d.depth === 4) {
          return getScreenSize() === 1920 ? 'frame-text-hide' : 'frame-text-hide-for-small-screens-all-level';
        } else {
          return 'frame-text';
        }
      });
  }

  public updateScoreOfRootImage(updatedScore): void {
    // set the label as the clicked item
    this.svg
      .select('.score')
      .text('')
      .text(updatedScore.toFixed(1))
      .append('xhtml:span')
      .attr('class', 'from-score')
      .text('/10');
  }
}
function getScreenSize() {
  return document.documentElement.clientWidth || document.body.clientWidth;
}
