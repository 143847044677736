import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class CygovErrorHandler implements ErrorHandler {
  constructor(private logger: NGXLogger) {}

  handleError(error) {
    error = error ? error : 'error is undefined';
    const message = error.message ? error.message : error.toString();
    console.log(error);
    // we can log in external logger. ex: loggly
    this.logger.log(`CygovErrorHandler Warning: ${message}`);
    // commenting this out because this throw crashes handler and some errors skipped to log
    // throw error;
  }
}
