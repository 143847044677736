export class SunburstConstant {
  public static COLORS = {
    selected: '#000000',
    notSelected: '#121521',
    hover: '#0A0D19',
    lines: {
      bad: '#F75F57',
      medium: '#F7D857',
      best: '#1BD964',
      // fade: '#58666d'
      bnbLowest: '#FA0000',
      bnbLow: '#F98400',
      bnbMedium: '#F4D100',
      bnbHigh: '#57E558',
      bnbHighest: '#0073FD',
      // CyberArk Colors
      cyberArkLow: '#e6251b',
      cyberArkMedium: '#f7d857',
      cyberArkHigh: '#1bd964',
    },
    // Root colors for cyberArk
    cyberArkRootColors: {
      cyberArkLow: '#e6251b',
      cyberArkMedium: '#f7d857',
      cyberArkHigh: '#1bd964',
    },
  };

  public static LABELS = {
    name: 'Function',
    id: 'a',
    children: [
      {
        name: 'Function',
        id: 'b',
        children: [
          {
            name: 'Category',
            id: 'c',
            children: [
              {
                name: 'Sub-category',
                id: 'd',
                children: [
                  {
                    name: 'Control',
                    id: 'e',
                    children: [
                      {
                        name: 'Control',
                        id: 'f',
                        children: [
                          {
                            name: 'Control',
                            id: 'g',
                            children: [
                              {
                                name: 'Control',
                                id: 'h',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  public static SKIP_ONE_LAYER = {
    name: 'Function',
    id: 'b',
    children: [
      {
        name: 'Category',
        id: 'c',
        children: [
          {
            name: 'Sub-category',
            id: 'd',
            children: [
              {
                name: 'Control',
                id: 'e',
                children: [
                  {
                    name: 'Control',
                    id: 'f',
                    children: [
                      {
                        name: 'Control',
                        id: 'g',
                        children: [
                          {
                            name: 'Control',
                            id: 'h',
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}
