/**
 * Contains all the enums required for the active directory authentication.
 */
export class AWSEnum {
  static GRANT_TYPE = 'authorization_code';
  static CLIENT_ID = '4f75ql1f91l7i0il89o2b5b4lf';
  static REDIRECT_URI = 'http://localhost:4200/';
  static CONTENT_TYPE_HEADER = 'application/x-www-form-urlencoded';
  static CLIENT_SECRET = '';
  static AUTHORIZATION_TYPE = 'Basic';
  static BASE_DOMAIN_LINK = 'https://cygov-aib.auth.us-east-1.amazoncognito.com';
  static TOKEN_VERIFICATION_ENDPOINT = `${AWSEnum.BASE_DOMAIN_LINK}/oauth2/token`;
  static AD_IDP_NAME = 'okta';
}
