<div class="upper-container">
    <div class="background">
        <div class="container">
            <br />
            <span class="welcome-header lato-22-n-vw">Thank you for completing Stage 1</span>
            <br /><br />
            <p class="lato-18-n-vw welcome-message">Please wait up to 90 seconds for Stage 2 to
                launch. </p>
            <p class="lato-18-n-vw">(Please do not close or refresh the browser).</p>
            <br /><br />
        </div>
    </div>
</div>