<div class="delete-user-modal">
  <div class="modal-overlay">
    <div class="modal-body">
      <div class="modal-header">
        <div (click)="activeModal.close()" class="close-icon" cygovSvgIcon
          [svgName]="'x-icon-type2'">
        </div>
      </div>
      <div class="modal-delete-content">
        <div class="icon" cygovSvgIcon [svgName]="'trash-icon-3'"></div>
        <span class="title-text" [innerHTML]="deleteModalMessage">
        </span>

        <div class="button-row">
          <button type="button" class="btn btn-primary uppercase add-entity-btn btnVendor"
            (click)="modalResult.emit(true); activeModal.close();">
            Confirm
          </button>
          <button type="button"
            class="btn btn-primary uppercase add-entity-btn btnVendor transparent-background"
            (click)="activeModal.close()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>