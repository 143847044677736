<div *ngIf="enableLabel" class="pointer-class" [@fadeInOutAnimation]>
  <div *ngFor="let progress of progressCheckPoints" class=" lato-12-b-vw text"
    [ngStyle]="{'margin-left': progress.textPoint+'vw'}">
    {{progress.name + ' | '}}
    {{progress.percent + '%'}}</div>
</div>
<div *ngIf="enableCheckPoint" class="pointer-class" [@fadeInOutAnimation]>
  <div *ngFor="let progress of progressCheckPoints" class="pointer"
    [ngStyle]="{'margin-left': progress.point+'vw'}">
    <div class="inner-dot"></div>
  </div>
</div>
<div class="cygov-progress-bar" #cygovProgressBar [ngStyle]="{'background-color': preFill}">
  <div class="progress-bar"
    [ngStyle]="{ 'height': this.height + 'px', 'width': assessmentLevel + '%','background': 'linear-gradient(to left, ' + postFillStr + ')'}">
  </div>
</div>