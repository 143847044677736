export class BoardConstant {
  public static attackIcons: string[] = [
    'phishing-icon',
    'insider-icon',
    'ddos-icon',
    'pentest-icon',
    'phishing-test',
    'vulnerabilities-icon',
  ];
  public static booleanKeyword = 'boolean';

  public static SORT_TYPE: any = {
    ASC: 'asc',
    DSC: 'dsc',
    NA: 'na',
  };
}
