import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';
import { QuestionsModule } from '../questions/questions.module';
import { UsernameIconModule } from '../username-icon/username-icon.module';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { CygovLoaderModule } from '../cygov-loader/cygov-loader.module';
import { InvitationSentModalComponent } from './invitation-sent-modal/invitation-sent-modal.component';

@NgModule({
  declarations: [DeleteUserModalComponent, InvitationSentModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgIconModule,
    NgbModule,
    PipesModule,
    QuestionsModule,
    UsernameIconModule,
    CygovLoaderModule,
  ],
  exports: [DeleteUserModalComponent, InvitationSentModalComponent],
  entryComponents: [DeleteUserModalComponent, InvitationSentModalComponent],
})
export class UserActionsModule {}
