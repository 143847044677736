import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetAssignmentQuery } from 'app/API.service';
@Component({
  selector: 'cygov-collection-delete-user-modal',
  templateUrl: './collection-delete-user-modal.component.html',
  styleUrls: ['./collection-delete-user-modal.component.scss'],
})
export class CollectionDeleteUserModalComponent implements OnInit {
  @Input() userAssignment: GetAssignmentQuery;

  @Output() modalResult = new EventEmitter<boolean>();

  deleteModalMessage = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    // this.taskCount = this.taskCount || 0;
    this.deleteModalMessage =
      'Please note, you are about to remove the user <span class="username">' +
      this.userAssignment.user.name +
      '</span> from this question along with all data provided by this user';
  }

  closeUserDeleteModal(): void {
    this.modalResult.emit(true);
  }
}
