<div class="wizard-main-body" [ngStyle]="{'height': isClient ?  '96.3vh' : '94.3vh'}">
    <div *ngIf="isLoading" class="loader-screen" [@fadeInOutAnimation]
        [@.disabled]="disableLoaderFader">
        <div *cygovLoader="isLoading"></div>
    </div>
    <div class="header-wiz">
        <div class="title-cross">
            <div *ngIf="!isClient" class="title-wiz lato-18-n-vw txt-blue">NEW ENTITY</div>
            <div *ngIf="isUpperdeck || !isClient"
                [ngStyle]="{ width: isClient && isUpperdeck ? '98%': '50%' }" class="cross-icon">
                <div class="cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'"
                    (click)="closeModal()"></div>
            </div>
        </div>
        <div class="progress-status"
            [ngStyle]="{'margin-bottom':isClient?'-4vh':'0vh','margin-top':isClient?'-3vh':'0vh'}">
            <div class="steps-toggle-icons-cont">
                <ng-container *ngFor="let step of steps; let i = index">
                    <div [ngClass]="['bulk-risk-step', 'bulk-' + step.num.toLowerCase() + '-step']">
                        <div class="round-checkbox">
                            <input [id]="'label-step-icon' + i" type="radio"
                                [name]="'step-icon' + i" disable
                                [checked]="step.active || step.completed" />
                            <label [for]="'label-step-icon' + i"
                                class="lato-14-n-vw capitalize label standard">
                            </label>

                            <div cygovSvgIcon [svgName]="'right-tick'" class="completed-right-tick"
                                [ngStyle]="{ opacity: step.completed ? 1 : 0 }"></div>
                        </div>
                        <div class="round-checkbox-label">
                            <span class="lato-14-n-vw" [ngClass]="{
                    'disabled-color': !step.active && !step.completed,
                    'txt-blue': step.active && !step.completed
                  }">{{ step.title }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="action-btns-wiz"
            [ngClass]="{'client-text-section client-action-btn': isClient,'client-action-btn-step-1':isClient&& stepNumber===1,
            'client-action-btn-step':isClient &&stepNumber!==1, 'admin-header-width-1':!isClient&&stepNumber===1,'admin-header-width-3':!isClient&&stepNumber===3,'admin-header-width-2':!isClient&&stepNumber===2}">
            <div *ngIf="isClient" class="client-text">
                <p class="lato-18-n-vw heading welcome-heading">Welcome to the Brown and Brown's
                    Cyber App
                </p>
                <p class="lato-16-n-vw" [ngStyle]="{'margin-bottom':'3vh' }">Please fill in the
                    following details to get started:</p>
            </div>
            <div *ngIf="!isClient" class="delete-button">
                <div *ngIf="wizardData && !isUpperdeck" cygovSvgIcon [svgName]="'trash-icon-2'"
                    [ngbTooltip]="'Delete Draft'" tooltipClass="delete-tooltip-wiz-layer"
                    (click)="openDeletePopUp(HeaderDelete, BodyDelete, FooterDelete)"></div>
            </div>

            <cygov-button *ngIf="!fromUpperDeck" [buttonText]="'SAVE DRAFT'" [buttonHeight]="'3vh'"
                [hoverColor]="'#1450C3'" [buttonColor]="'#1362F8'"
                (buttonPressed)="saveWizardDraft(true , {} , false)">
            </cygov-button>
        </div>
    </div>
    <div *ngIf="!takingScreenshot" class="body-wiz"
        [ngStyle]="{'margin-top':isClient? '2vh' : '0vh' }">
        <div class="left-wiz">
            <!-------------------------------ALL STEP BODY SECTIONS-------------------------------->
            <ng-container [ngSwitch]="stepsStringEnum[stepsNumEnum[stepNumber]]">
                <ng-container *ngSwitchCase="stepsStringEnum.ORGANIZATIONAL">
                    <ng-container
                        *ngTemplateOutlet="organizationalArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.FINANCIAL">
                    <ng-container
                        *ngTemplateOutlet="financialArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.FRAMEWORKS">
                    <ng-container
                        *ngTemplateOutlet="frameworksArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.DATA_SCOPE">
                    <ng-container
                        *ngTemplateOutlet="dataScopeArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.TECHNICAL">
                    <ng-container
                        *ngTemplateOutlet="technicalArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="stepsStringEnum.RISK_SCENARIOS">
                    <ng-container
                        *ngTemplateOutlet="riskScenariosArea; context: { stepNum: stepNumber, details:detailOpened }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="stepsStringEnum.ACCOUNT_DETAILS">
                    <ng-container
                        *ngTemplateOutlet="accountDetailsArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="stepsStringEnum.CURRENT_YEAR">
                    <ng-container
                        *ngTemplateOutlet="currentYearArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.APPLICANT_INFO">
                    <ng-container
                        *ngTemplateOutlet="applicantInfoArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.REVENUE_DETAILS">
                    <ng-container
                        *ngTemplateOutlet="revenueDetailsArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="stepsStringEnum.DATA_INVENTORY">
                    <ng-container
                        *ngTemplateOutlet="dataInventoryArea; context: { stepNum: stepNumber }">
                    </ng-container>
                </ng-container>


            </ng-container>
        </div>

    </div>
    <ng-container *ngTemplateOutlet="screenShotAllSteps;"> </ng-container>
    <div class="footer-wizard wizard-width-{{stepNumber}}"
        [ngClass]="isClient ? 'client-wizard-width-'+stepNumber:'admin-wizard-width-'+stepNumber">
        <div [ngClass]="{'hide-label' : !isClient && stepNumber === 2}" class="mandatory-label">
            <span class="lato-20-n-vw" [ngStyle]="{'color':'red','margin-top':'-0.1vh'}">*</span>
            <span class="lato-12-n-vw" [ngStyle]="{'margin-left':'0.3vw'}">Mandatory</span>
        </div>
        <div class="action-btns">
            <div *ngIf="stepNumber > 1" class="previous-button" (click)="previousClicked()"
                [ngClass]="{'prev-step':isClient&&(stepNumber===2||stepNumber===4 || stepNumber===3)}">
                <span class="icon arrow-icon " cygovSvgIcon [svgName]="'arrow-right-type2'"></span>
                Previous Step
            </div>
            <cygov-button *ngIf="!(isClient || isUpperdeck)" class="button-margin client-button"
                [buttonText]="'SEND TO CLIENT'" [buttonWidth]="'7.5vw'" [buttonHeight]="'3vh'"
                (buttonPressed)="sendConfirmationModal(sendConfirmHeader, sendConfirmBody, sendConfirmFooter)"
                [buttonColor]="colorScheme.brightPurple" [hoverColor]="'#810DD0'"
                [addIcon]="'arrow-right-type2'" [disableClick]="enableClientBtn">
            </cygov-button>
            <cygov-button *ngIf="isClient || isUpperdeck || stepNumber !== totalSteps"
                class="button-margin"
                [buttonText]="stepNumber !== totalSteps ? nextButton : !!wizardData?.rootEntity ? 'UPDATE' : 'COMPLETE'"
                [buttonWidth]="'5.6vw'" [buttonHeight]="'3vh'"
                [buttonColor]="colorScheme.buttonBlue" [hoverColor]="colorScheme.buttonHover"
                [addIcon]="stepNumber !== totalSteps ? 'arrow-right-type2' : ''"
                (buttonPressed)="nextClicked()">
            </cygov-button>
        </div>
    </div>


    <!-------------------------ORGANIZATIONAL---------------------------->
    <ng-template #organizationalArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-18-n-vw step-' + stepNum" [@fadeInOnly]
            [ngClass]="{'screenshot-step-1' : takingScreenshot}" [@.disabled]="!!disableAnimation">
            <div class="upload-container">
                <div class="upload">
                    <ng-container *ngTemplateOutlet="uploadSection">
                    </ng-container>
                </div>
            </div>
            <div class="left-section">
                <div class="upper-strings">
                    <div *ngFor="let str of organizational[0]; let idx = index" class="string-input"
                        [ngClass]="{'upperdeck-disabled':isUpperdeck && str.title==='Entity Name'}">
                        <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: str }">
                        </ng-container>
                        <div class="input-area-string">
                            <input [name]="'organStr' + idx" class="string-input-style"
                                [ngClass]="{'reduce-opacity': isUpperdeck ||wizardData?.rootEntity||wizardData?.userId && (idx === 1|| idx === 2)}"
                                (keyup)="enableClientButton()" [(ngModel)]="str.value" />
                        </div>
                    </div>



                    <div class="lower-logo industry-title">
                        <div class="lower-title lato-16-n-vw txt-white">
                            Industry <div *ngIf="!isUpperdeck " class="asterisk lato-16-n-vw">*
                            </div>
                        </div>
                        <div class="logo-body">
                            <div class="industry-selection"
                                [ngClass]="{'upperdeck-disabled':isUpperdeck}">
                                <ng-container *ngIf="!isBeecher; else industryDropDown">
                                    <cygov-select [items]="industryOpt"
                                        [(ngModelValue)]="organizational[2].industry"
                                        (ngModelValueChange)="organizational[2].industry = $event"
                                        [customArrow]="true" [clearable]="false"
                                        [searchable]="false" placeholder="Choose"
                                        [disabled]='isUpperdeck'>
                                    </cygov-select>
                                </ng-container>
                                <ng-template #industryDropDown>
                                    <div class="drop-down-area">
                                        <div class="drop-down-label lato-16-n-vw"
                                            (click)="openBeecherIndustry()">
                                            <div class="label-value"
                                                [disableTooltip]="!showTextTooltip"
                                                (mouseenter)="checkWidthEllipsis($event.target)"
                                                [ngbTooltip]="organizational[2].industry"
                                                tooltipClass="industry-tooltip">
                                                {{organizational[2].industry ?
                                                organizational[2].industry
                                                :'Choose'}}</div>
                                            <div class="arrow-icon-right" cygovSvgIcon
                                                [svgName]="'arrow-right-type3'"></div>
                                        </div>
                                        <ng-container *ngIf="showIndustryDropDown">
                                            <div [@fadeInOnly] class="expanded-list">
                                                <div class="expanded-list-arrow"
                                                    (click)="openBeecherIndustry()">X
                                                </div>
                                                <div class="selected-item lato-20-n-vw"
                                                    [disableTooltip]="!showTextTooltip"
                                                    (mouseenter)="checkWidthEllipsis($event.target)"
                                                    [ngbTooltip]="organizational[2].industry"
                                                    tooltipClass="industry-tooltip">
                                                    {{organizational[2].industry}}</div>
                                                <div class="industry-list">
                                                    <div *ngFor="let item of industryOptBeecher; let i = index;"
                                                        class="industry-list-item">
                                                        <span class="lato-12-n-vw"
                                                            (click)="selectBeecherIndustry(i)">{{item}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>


                </div>




                <div class="lower-domains">
                    <div class="header">
                        <div class="toggle-btn">
                            <cygov-toggle-button [isChecked]="organizational[3].isActive"
                                (changed)="organizational[3].isActive = activeScan = $event"
                                [id]="'stand-wizard'">
                            </cygov-toggle-button>
                            <div class="active-scan">Active Scan</div>
                        </div>
                        <div class="lower-title lato-16-n-vw txt-white">
                            <cygov-add-button [titleName]="'Add Domain'" [textSize]="'14'"
                                [textColor]="'#fffff'" [buttonWidth]="'5.3vw'"
                                (click)="newActiveDomains.unshift({ value: '' })"
                                class="domain-btn"></cygov-add-button>

                        </div>
                    </div>
                    <div
                        [ngClass]="takingScreenshot ? 'screenshot-temp-glitch-fix' : 'temp-glitch-fix' ">
                        <div [ngClass]="{'reduce-opacity' : !activeScan}" class="domain-listings">
                            <ng-container *ngFor="let domain of newActiveDomains; let idx = index">
                                <div class="overflow-container" [@domainHeightAnimation]
                                    [@.disabled]="newActiveDomains.length === 1">
                                    <div class="single-domain-input">
                                        <input class="input-domain-styling" type="text"
                                            [(ngModel)]="domain.value" />
                                        <div *ngIf="newActiveDomains.length > 1"
                                            class="cancel-domain"
                                            (click)="newActiveDomains.splice(idx, 1)"
                                            [@fadeInOutAnimation]>
                                            x
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>

            </div>
            <div class="right-section">
                <div class="upper-numbers">
                    <div *ngFor="let dataObj of organizational[1]; let idx = index"
                        class="number-input" [ngClass]="{'show-title': idx === 0}">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: dataObj }">
                        </ng-container>
                        <div class="input-area-numbers">
                            <span *ngIf="dataObj.showDollar" class="dollar-sign-style"
                                [ngClass]="{'white-dollar-sign' : !dataObj.value}">$</span>
                            <ng-container
                                *ngTemplateOutlet="inputNumBox; context: {$implicit: dataObj, idx: idx, stepName: 'organizational'}">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="lower-domains">
                    <div class="framework-section">
                        <ng-container>
                            <div class="frameworks">
                                <div class="framework-header-section lato-14-n-vw">
                                    <div class="header-name">
                                        <span class="label-name framework-title">Frameworks</span>
                                        <span class="asterisk lato-16-n-vw">*</span>
                                    </div>
                                </div>
                                <div [ngClass]="{'reduce-opacity': isUpperdeck}"
                                    class="framework-options ">
                                    <div class="round-checkbox"
                                        *ngFor="let opt of frameworkList.slice(0,4)">
                                        <input [id]="'frameworkLabel-' + opt.name" type="radio"
                                            (click)="selectedRiskFramework = opt.key"
                                            [name]="'frameworkLabel'" [value]="opt.name"
                                            #identification
                                            [checked]="opt.key === selectedRiskFramework "
                                            class="jarl-radio" />
                                        <label [for]="'frameworkLabel-' + opt.name"
                                            [ngClass]="{'change-color': opt.key === selectedRiskFramework}"
                                            class="lato-14-n-vw capitalize label target">{{opt.name}}</label>
                                    </div>
                                </div>
                            </div>


                        </ng-container>
                    </div>









                </div>

                <ng-container *ngIf="isDropDownOpened">
                    <div class="drop-full-background" *ngIf="isDropDownOpened">
                    </div>
                    <div class="container-icon" (click)="expandLowerPortion()">
                        <div class="x-icon" cygovSvgIcon [svgName]="'x-icon-type2'">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>

    <!-------------------------FINANCIAL---------------------------->
    <ng-template #financialArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-16-n-vw step-' + stepNum" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section">
                <div *ngFor="let numbObj of financial[0]; let idx = index" class="number-input">
                    <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <span class="dollar-sign-style"
                            [ngClass]="{'white-dollar-sign' : !numbObj.value}">$</span>
                        <ng-container
                            *ngTemplateOutlet="inputNumBox; context: {$implicit: numbObj, idx: idx, stepName: 'financial'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="right-section">
                <div *ngFor="let numbObj of financial[1]; let idx = index" class="number-input">
                    <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <span *ngIf="numbObj.showDollar" class="dollar-sign-style"
                            [ngClass]="{'white-dollar-sign' : !numbObj.value}">$</span>
                        <ng-container
                            *ngTemplateOutlet="inputNumBox; context: {$implicit: numbObj, idx: idx , stepName: 'financial'}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-------------------------FRAMEWORKS---------------------------->
    <ng-template #frameworksArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-18-n-vw step-' + stepNum" [@fadeInOnly]
            [ngClass]="{'beecher-changes': isBeecher}" [@.disabled]="!!disableAnimation">
            <div class="upper-section">
                <div class="title-framework lato-16-n-vw txt-white">Risk Assessment
                    <div class="asterisk lato-16-n-vw">*</div>
                </div>
                <div class="risk-framework lato-14-n-vw" *ngIf="!isBeecher">
                    <div class="circle-toggle">
                        <div class="inner-circle"></div>
                    </div>
                    BnB CSF
                </div>
                <div class='risk-wrapper lato-14-n-vw' *ngIf="isBeecher"
                    [ngClass]="{'isEditMode': wizardData?.rootEntity}">
                    <div class='risk-name round-checkbox'
                        *ngFor="let item of riskFrameWorkOpt; let i=index;">
                        <input type="radio" [name]="'firstTab-item'" [value]="item.key"
                            [id]="'risk-label-' + i" class="risk-item-checkbox"
                            [checked]="item.key === selectedRiskFramework"
                            (click)="selectedRiskFramework = item.key"
                            [disabled]="wizardData?.rootEntity" />
                        <label [for]="'risk-label-' + i" class='label netskope-overview'>
                            <div class="frame-ellipse" [ngbTooltip]="item?.name" container="body"
                                tooltipClass="framework-wizard-name-tooltip"
                                [disableTooltip]="!showTextTooltip"
                                (mouseenter)="checkWidthEllipsis($event.target)">{{ item?.name }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="lower-section">
                <div class="title-framework lato-16-n-vw txt-white">Regulatory Requirements</div>
                <div class="filtering-btns lato-16-n-vw">
                    Filter By
                    <cygov-select [items]="thirdStepDrop" [(ngModelValue)]="frameworkFilterBy"
                        (ngModelValueChange)="frameworkFilterChange()" [customArrow]="true"
                        [clearable]="false" [searchable]="false">
                    </cygov-select>

                    <div class="search-wrapper">
                        <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
                        <input type="text" class="form-control search" aria-label="Search"
                            placeholder="Search" [(ngModel)]="searchKeyword"
                            (keyup)="frameworkFilterChange()" name="search" spellcheck="true" />
                    </div>

                    <cygov-add-button [titleName]="'Add New'" [textSize]="'14'"
                        [textColor]="'#fffff'" (click)="addNewCustomFramework()"
                        [circleSize]="'1.1vw'" [buttonWidth]="'5vw'" [textSize]="'16'"
                        [textColor]="'#fffff'"></cygov-add-button>
                </div>
                <div class="compliance-list">
                    <ng-container *ngFor="let newFrame of editingFrames;let idx2=index">
                        <div class="single-standard">
                            <div class="toggle-btn-container">
                                <cygov-toggle-button [isChecked]="newFrame.isToggled"
                                    (changed)="onSavingCustomFrame(newFrame.value , idx2)"
                                    [id]="idx2 + 'editing-stand-wizard'"
                                    [isDisabled]="!(newFrame.value && newFrame.value.trim())">
                                </cygov-toggle-button>
                            </div>
                            <div class="custom-input-framework">
                                <input type="text" class="comp-editing-name lato-16-n-vw txt-white"
                                    placeholder="Enter Name" [ngbTooltip]="newFrame.value"
                                    (mouseenter)="checkWidthEllipsis($event.target)"
                                    (focusout)="onSavingCustomFrame(newFrame.value , idx2)"
                                    [disableTooltip]="!showTextTooltip" container="body"
                                    [(ngModel)]="newFrame.value"
                                    (keyup)="checkEnteredClicked(newFrame.value , idx2 , $event)">
                                <div class="cross-icon-frame">
                                    <div class='cancel-cross'
                                        (click)="removeEditableFramework(idx2)">x
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let comp of complianceList; let idx = index">
                        <div class="single-standard">
                            <div class="toggle-btn-container">
                                <cygov-toggle-button [isChecked]="frameworks.includes(comp)"
                                    (changed)="updateSelectedFramework(comp)"
                                    [id]="idx + 'stand-wizard'"
                                    [isDisabled]="!!(editingFrames && editingFrames.length)">
                                </cygov-toggle-button>
                            </div>
                            <div class="comp-name lato-16-n-vw"
                                [ngClass]="frameworks.includes(comp) ? 'txt-white' : 'standard-inactive'"
                                [ngbTooltip]="comp" (mouseenter)="checkWidthEllipsis($event.target)"
                                [disableTooltip]="!showTextTooltip" container="body">
                                {{ comp }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #uploadSection>
        <div class="upload-section" [ngClass]="{'uploading-state' : uploadingStart}">
            <div class="upload-border">
                <div class="img-logo-show" *ngIf="newLogoUploaded && !uploadingStart">
                    <div class="logo">
                        <div class="image-container">
                            <img class="vendor-logo" *ngIf="uploadedLogo" [src]="uploadedLogo"
                                alt="logo" />
                        </div>
                    </div>
                </div>
                <div class="cygov-loader" *ngIf="uploadingStart">
                    <div class="spinner"></div>
                </div>
                <div class="upload-logo-btn" [ngClass]="newLogoUploaded ? 'mr-top' : ''">
                    <div *ngIf="uploadedLogo" (click)="uploadLogo.click()"
                        [ngClass]="!isClient?'pencil-icon':'pencil-icon-client'" cygovSvgIcon
                        [svgName]="'edit-icon'">
                    </div>
                    <button *ngIf="!uploadedLogo && !uploadingStart"
                        class="btn-type-link capitalize logo-btn" (click)="uploadLogo.click()">
                        <span class="camera-icon" cygovSvgIcon [svgName]="'camera-icon'"></span>
                        <span class="lato-16-n-vw btn-text">Upload
                            Logo</span>
                    </button>
                    <input #uploadLogo class="file-input" type="file" name="logo"
                        accept=".gif, .jpg, .png" (change)="imgHandler($event)" />
                </div>
            </div>
        </div>
    </ng-template>

    <!-------------------------DATA SCOPE---------------------------->
    <ng-template #dataScopeArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [ngStyle]="{height: isClient ? '65vh' : '59vh' , width: isClient ? '59.7vw' : '54.4vw'}"
            [class]="'outer-border lato-16-n-vw step-4'" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section"
                [ngClass]="{'border-none' :(isClient && stepNumber === 1) || (stepNumber === 3 && !isClient)}">
                <div *ngFor="let numbObj of newObj.step_3[0].slice(0,2) ; let idx = index"
                    class="number-input">
                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: false }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <ng-container
                            *ngTemplateOutlet="inputNumCheckBox; context: {$implicit: numbObj, idx: idx, stepName: 'data_scope',isRevenue:true}">
                        </ng-container>
                    </div>
                </div>
                <!-- <ng-container *ngTemplateOutlet="uploadSection">
                </ng-container> -->
            </div>
            <div class="right-section">
                <div *ngFor="let numbObj of newObj.step_3[0].slice(2,4); let idx = index"
                    class="number-input">
                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: false }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <ng-container
                            *ngTemplateOutlet="inputNumCheckBox; context: {$implicit: numbObj, idx: idx, stepName: 'data_scope',isRevenue:false}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <!-------------------------APPLICANT INFO AREA------------------->


    <ng-template #applicantInfoArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-16-n-vw step-9'" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section">
                <div class="upper-section">

                    <div *ngFor="let str of applicantInfo[0]; let idx = index" class="string-input">
                        <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: str }">
                        </ng-container>
                        <div class="string-input-2">
                            <ng-container
                                *ngTemplateOutlet="stringInput; context: {$implicit: str,  stepName: 'organizational'}">
                            </ng-container>
                        </div>
                    </div>
                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: applicantInfo[1][0][0] }">
                    </ng-container>
                    <div class="parent-number-input">
                        <div *ngFor="let numbObj of applicantInfo[1][0][0].optionList; let idx = index"
                            class="number-input"
                            [ngClass]="{'number-input2':numbObj.inputField,'individual-width':idx===0}">
                            <ng-container
                                *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: true }">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="lower-section">
                    <div class="calendar"
                        [ngClass]="applicantInfo[1][1][0].isDropDownOpened ? 'add-margin' : 'no-margin'">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: applicantInfo[1][1][0], isCheckbox: false }">
                        </ng-container>
                        <div *ngIf="applicantInfo[1][1][0].isCalendar" class="calendar-input">
                            <ng-container
                                *ngTemplateOutlet="calendarInput; context: {$implicit: applicantInfo[1][1][0], idx: 0, stepName: 'data_scope'}">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-section">
                <div class="upper-section">

                    <div class="number-input">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: applicantInfo[1][2][0], isCheckbox: false }">
                        </ng-container>
                        <div class="input-area-numbers">
                            <ng-container
                                *ngTemplateOutlet="inputNumBox; context: {$implicit: applicantInfo[1][2][0], stepName: 'organizational'}">
                            </ng-container>
                        </div>
                    </div>

                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: applicantInfo[2][0][0] }">
                    </ng-container>

                    <div class="framework-options ">
                        <div class="round-checkbox"
                            *ngFor="let opt of applicantInfo[2][0][0].optionList ; let idx = index">
                            <input [id]="'answerLabell-' + opt.title" type="radio"
                                (click)="updateOptions(idx)" [name]="'answerLabell'"
                                [value]="opt.title" #identification [checked]="opt.isChecked"
                                class="jarl-radio" />
                            <label [for]="'answerLabell-' + opt.title"
                                class="lato-14-n-vw capitalize label target">{{opt.title}}</label>
                        </div>
                    </div>

                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: applicantInfo[2][0][1] , reduceOpacity : !applicantInfo[2][0][0].optionList[0].isChecked }">
                    </ng-container>

                    <div class="string-input-2">
                        <textarea class="text-area"
                            [ngClass]="{'reduce-opacity':!applicantInfo[2][0][0].optionList[0].isChecked }"
                            [(ngModel)]="applicantInfo[2][0][1].value"
                            [disabled]="!applicantInfo[2][0][0].optionList[0].isChecked">{{applicantInfo[2][0][1].value}}</textarea>
                    </div>
                </div>
                <div class="lower-section">
                    <div *ngFor="let str of applicantInfo[3]; let idx = index" class="string-input">
                        <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: str }">
                        </ng-container>
                        <div class="string-input-2" *ngIf="str.type">
                            <ng-container
                                *ngTemplateOutlet="stringInput; context: {$implicit: str,  stepName: 'organizational'}">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!------------------------REVENUE DETAILS AREA------------------->


    <ng-template #revenueDetailsArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-16-n-vw step-10'" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section">
                <div class="upper-section">


                    <div class="lower-logo industry-title">
                        <div class="lower-title lato-16-n-vw txt-white">
                            Nature of Business Operations of the Applicant:
                            <div *ngIf="!isUpperdeck " class="asterisk lato-16-n-vw">*
                            </div>
                        </div>
                        <div class="logo-body">
                            <div class="industry-selection"
                                [ngClass]="{'upperdeck-disabled':isUpperdeck}">
                                <ng-container *ngIf="!isBeecher; else industryDropDown">
                                    <cygov-select [items]="industryOpt"
                                        [(ngModelValue)]="revenueDetails[0].value"
                                        (ngModelValueChange)="revenueDetails[0].value = $event"
                                        [customArrow]="true" [clearable]="false"
                                        [searchable]="false" placeholder="Choose"
                                        [disabled]='isUpperdeck'>
                                    </cygov-select>
                                </ng-container>
                                <ng-template #industryDropDown>
                                    <div class="drop-down-area">
                                        <div class="drop-down-label lato-16-n-vw"
                                            (click)="openBeecherIndustry()">
                                            <div class="label-value"
                                                [disableTooltip]="!showTextTooltip"
                                                (mouseenter)="checkWidthEllipsis($event.target)"
                                                [ngbTooltip]="revenueDetails[0].value"
                                                tooltipClass="industry-tooltip">
                                                {{revenueDetails[0].value ?
                                                revenueDetails[0].value
                                                :'Choose'}}</div>
                                            <div class="arrow-icon-right" cygovSvgIcon
                                                [svgName]="'arrow-right-type3'"></div>
                                        </div>
                                        <ng-container *ngIf="showIndustryDropDown">
                                            <div [@fadeInOnly] class="expanded-list">
                                                <div class="expanded-list-arrow"
                                                    (click)="openBeecherIndustry()">X
                                                </div>
                                                <div class="selected-item lato-20-n-vw"
                                                    [disableTooltip]="!showTextTooltip"
                                                    (mouseenter)="checkWidthEllipsis($event.target)"
                                                    [ngbTooltip]="revenueDetails[0].value"
                                                    tooltipClass="industry-tooltip">
                                                    {{revenueDetails[5].industry}}</div>
                                                <div class="industry-list">
                                                    <div *ngFor="let item of industryOptBeecher; let i = index;"
                                                        class="industry-list-item">
                                                        <span
                                                            (click)="selectRevenueIndustry(i)">{{item}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>


                    <!-- Past 12 months revenue -->

                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: revenueDetails[1] }">
                    </ng-container>
                    <div class="past-12-months">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: revenueDetails[1].pastMonths[0]  , reduceOpacity:true }">
                        </ng-container>
                        <div *ngFor="let str of revenueDetails[1].pastMonths[0].revenue; let idx = index"
                            class="string-input">
                            <ng-container
                                *ngTemplateOutlet="titleHeader; context: { titleObj: str }">
                            </ng-container>
                            <div class="string-input-2">
                                <ng-container
                                    *ngTemplateOutlet="inputNumBox; context: {$implicit: str,  stepName: 'organizational',isRevenue:true}">
                                </ng-container>
                            </div>
                        </div>
                    </div>


                    <!-- Projected 12 months revenue -->


                    <div class="projected-12-months">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: revenueDetails[1].projectedMonths[0] , reduceOpacity:true }">
                        </ng-container>
                        <div *ngFor="let str of revenueDetails[1].projectedMonths[0].revenue; let idx = index"
                            class="string-input">
                            <ng-container
                                *ngTemplateOutlet="titleHeader; context: { titleObj: str }">
                            </ng-container>
                            <div class="string-input-2">
                                <ng-container
                                    *ngTemplateOutlet="inputNumBox; context: {$implicit: str,  stepName: 'organizational',isRevenue:true}">
                                </ng-container>
                            </div>
                        </div>
                    </div>



                </div>
                <div class="lower-section">


                </div>
            </div>
            <div class="right-section">
                <div class="upper-container">
                    <div class="upper-section">

                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: revenueDetails[2] }">
                        </ng-container>

                        <div class="framework-options ">
                            <div class="round-checkbox"
                                *ngFor="let opt of revenueDetails[2].optionList  ;let idx = index">
                                <input [id]="'answerLabel-' + opt.title" type="radio"
                                    (click)="updateOptions(idx,'revenueDetails')"
                                    [name]="'answerLabel'" [value]="opt.title" #identification
                                    [checked]="opt.isChecked" class="jarl-radio" />
                                <label [for]="'answerLabel-' + opt.title"
                                    class="lato-14-n-vw capitalize label target">{{opt.title}}</label>
                            </div>
                        </div>

                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: revenueDetails[3] ,reduceOpacity: !revenueDetails[2].optionList[0].isChecked }">
                        </ng-container>

                        <textarea class="text-area" [(ngModel)]="revenueDetails[4].value"
                            [ngClass]="{'reduce-opacity':!revenueDetails[2].optionList[0].isChecked }"></textarea>

                    </div>

                </div>
                <div class="lower-container">
                    <div class="lower-section">

                        <div class="header-parent">
                            <ng-container
                                *ngFor="let item of revenueDetails[5].tableHeader; let idx = index">
                                <div class="row-with-red-background"
                                    [ngClass]="{'upload-file-section':idx===1}">
                                    <span [ngClass]="idx ? 'reduce-width' : 'increase-width'"
                                        class="text-with-white-color lato-16-n-vw">{{item}}
                                        <span *ngIf="!idx" class="asterisk lato-16-n-vw"
                                            [ngStyle]="{'margin-left':'0.2vw'}">*</span>
                                    </span>

                                </div>
                            </ng-container>
                        </div>

                        <div class="body">
                            <div *ngFor="let item of revenueDetails[5].bodyItems; let idx = index"
                                class="body-row">
                                <!-- <div class="check-box"
                                    (click)="item.isChecked = item.files.length>0?item.isChecked:!item.isChecked"
                                    [ngClass]="{'checked': item.isChecked || item.files.length>0}">
                                    <div *ngIf="item.isChecked" class="selected-tick" cygovSvgIcon
                                        [svgName]="'right-tick'">
                                    </div>
                                </div> -->

                                <div class="body-item ">{{item.title}}</div>
                                <div class="file-upload-wrapper">
                                    <span class="svg" cygovSvgIcon [svgName]="'upload_collection'"
                                        (click)="uploadFiles(idx)">
                                    </span>
                                    <div *ngIf="item.files.length>0" class="files-count-circle">
                                        <span class="files-count">{{item.files.length}}</span>
                                    </div>
                                </div>




                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </ng-template>

    <!------------------------DATA INVENTORY AREA-------------------->
    <ng-template #dataInventoryArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-16-n-vw step-11'" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section">
                <div class="upper-section">

                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: dataInventory[0][0] }">
                    </ng-container>

                    <div *ngFor="let str of dataInventory[0][0].optionList; let idx = index"
                        class="string-input">
                        <ng-container
                            *ngTemplateOutlet="titleHeader; context: { titleObj: str, index : idx}">
                        </ng-container>
                        <div class="string-input-2">
                            <ng-container
                                *ngTemplateOutlet="stringInput; context: {$implicit: str,  stepName: 'organizational'}">
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>
            <div class="right-section">
                <div class="upper-section">
                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: dataInventory[1][0][0] }">
                    </ng-container>
                    <div class="records-options">
                        <div *ngFor="let numbObj of dataInventory[1][0][0].optionList; let idx = index"
                            class="account-details-left ">
                            <ng-container
                                *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: true }">
                            </ng-container>
                        </div>
                    </div>

                </div>
                <div class="lower-section">
                    <ng-container
                        *ngTemplateOutlet="titleHeader; context: { titleObj: dataInventory[1][0][1],reducedOpacity:!dataInventory[1][0][0].optionList[2].isChecked }">
                    </ng-container>
                    <div>
                        <textarea class="text-area"
                            [(ngModel)]="dataInventory[1][0][1].value">{{dataInventory[1][0][1].value}}</textarea>
                        <!-- <ng-container
                            *ngTemplateOutlet="stringInput; context: {$implicit:  dataInventory[1][0][1]}">
                        </ng-container> -->
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <!-------------------------TECHNICAL---------------------------->
    <ng-template #technicalArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-16-n-vw step-' + stepNum" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="left-section">
                <div *ngFor="let numbObj of technical[0]; let idx = index" class="number-input">
                    <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <span *ngIf="numbObj.showDollar" class="dollar-sign-style"
                            [ngClass]="{'white-dollar-sign' : !numbObj.value}">$</span>
                        <ng-container
                            *ngTemplateOutlet="inputNumBox; context: {$implicit: numbObj, idx: idx, stepName: 'technical'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="right-section">
                <div *ngFor="let numbObj of technical[1]; let idx = index" class="number-input">
                    <ng-container *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj }">
                    </ng-container>
                    <div class="input-area-numbers">
                        <span class="dollar-sign-style" *ngIf="!numbObj.showPercent"
                            [ngClass]="{' white-dollar-sign' : !numbObj.value}">$</span>
                        <span class="percent-sign-style" *ngIf="numbObj.showPercent"
                            [ngClass]="{' white-percent-sign' : !numbObj.value}">%</span>

                        <ng-container
                            *ngTemplateOutlet="inputNumBox; context: {$implicit: numbObj, idx: idx, stepName: 'technical'}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <!-------------------------RISK SCENARIOS---------------------------->
    <ng-template #riskScenariosArea let-stepNum="stepNum" let-detailRow="details"
        let-disableAnimation="disableAnim">
        <div [class]="'lato-18-n-vw step-' + stepNum" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div class="table-area">
                <div class="table-section">
                    <div class="header">
                        <div class="name-col">Name</div>
                        <div class="inherent-col">Inherent</div>
                    </div>
                    <div *ngIf="detailRow" class="more-detail-section" [@fadeInOutAnimation]>
                        <ng-container
                            *ngTemplateOutlet="riskMoreDetails; context: { moreObj: riskScenarios[detailRow - 1] }">
                        </ng-container>
                    </div>
                    <ng-container *ngFor="let section of riskScenarios; let idx = index">
                        <div *ngIf="idx < 3" class="table-row" [ngClass]="{
                'enable-hovering': !detailRow,
                'enable-box': detailRow === idx + 1,
                'disable-box': detailRow && detailRow !== idx + 1
              }">
                            <div class="left-col">
                                <div class="more-button lato-28-b-vw"
                                    (click)="detailOpened = idx + 1">
                                    ...</div>
                                <div class="section-name lato-16-n-vw">
                                    {{ section.title }}
                                </div>
                            </div>
                            <div class="right-col lato-16-b-vw txt-blue">
                                <span container="body"
                                    [ngbTooltip]="'$' + parsedInherentValues[idx]"
                                    tooltipClass="industry-tooltip"> $&nbsp;{{ section.inherent |
                                    largeNumberShort }}
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="footer-button">
                <div class="button-area" (click)="showTotalRisk = true" container="body"
                    [disableTooltip]="!showTotalRisk" [ngbTooltip]="'$' + parsedInherentValues[3]"
                    tooltipClass="industry-tooltip">
                    <div *ngIf="showTotalRisk; else noTotal" [@fadeInOutAnimationDelayed]>
                        TOTAL INHERENT RISK:<br />{{ this.projectedInherent| largeNumberShort: '$'
                        }}
                    </div>
                    <ng-template #noTotal>
                        <div>PROJECT INHERENT RISK</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>

    <!-------------------------ACCOUNT DETAILS---------------------------->
    <ng-template #accountDetailsArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [ngClass]="takingScreenshot ? 'adjust-step2-width' : 'step2-parent'">
            <div class="class step2-left-section">
                <div [class]="'outer-border lato-16-n-vw step-7'" [@fadeInOnly]
                    [@.disabled]="!!disableAnimation">
                    <div class="left-section">
                        <ng-container *ngIf="leftDropDown">
                            <div class="drop-down-backdrop" [@fadeInOnly]
                                [@.disabled]="!!disableAnimation">
                                <span class="cross-icon lato-16-n-vw"
                                    (click)="closeAccountDetailDropdown()">x</span>
                            </div>
                        </ng-container>
                        <div *ngFor="let numbObj of accountDetails[0]; let idx = index"
                            class="account-details-left"
                            [ngClass]="numbObj.isDropDownOpened ? 'add-margin' : 'no-margin'">
                            <ng-container
                                *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: false }">
                            </ng-container>
                            <div *ngIf="numbObj.isCalendar" class="calendar-input">
                                <ng-container
                                    *ngTemplateOutlet="calendarInput; context: {$implicit: numbObj, idx: idx, stepName: 'data_scope'}">
                                </ng-container>
                            </div>
                            <div *ngIf="!numbObj.isCalendar && idx === 0;" class="drop-down-input">
                                <ng-container
                                    *ngTemplateOutlet="dropDownInput; context: {$implicit: numbObj, idx: idx, stepName: 'data_scope'}">
                                </ng-container>
                            </div>
                            <div *ngIf="!numbObj.isCalendar && idx !== 0;" class="string-input-2">
                                <ng-container
                                    *ngTemplateOutlet="stringInput; context: {$implicit: numbObj, idx: idx, stepName: 'accountDetailsLeft'}">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="right-section">
                        <ng-container *ngIf="rightDropDown">
                            <div class="drop-down-backdrop" [@fadeInOnly]>
                                <span class="cross-icon lato-16-n-vw"
                                    (click)="closeAccountDetailDropdown(true)">x</span>
                            </div>
                        </ng-container>

                        <ng-container>
                            <cygov-user-listing [showToolTip]="true" [users]="brokers"
                                [multiple]="true" [showUserRole]="false"
                                (selectedUser)="addSelectedBroker($event)"
                                [listLabel]="'Assign Broker'"
                                [midMarketSelectedBrokers]="selectedBrokers" [showInitials]="false">
                            </cygov-user-listing>
                        </ng-container>
                        <div class="section-divider-broker"></div>
                        <div class="account-detail-container">
                            <div *ngFor="let numbObj of accountDetails[1].slice(-2); let idx = index"
                                class="account-details-right"
                                [ngClass]="numbObj.isDropDownOpened ? 'add-margin' : 'no-margin'">
                                <ng-container
                                    *ngTemplateOutlet="titleHeader; context: { titleObj: numbObj, isCheckbox: false , notes: numbObj.isCalendar }">
                                </ng-container>
                                <div *ngIf="!numbObj.isCalendar"
                                    class="string-input-2 bg-assign-booker border-assign">
                                    <ng-container
                                        *ngTemplateOutlet="stringInput; context: {$implicit: numbObj, idx: idx, stepName: 'accountDetails'}">
                                    </ng-container>
                                </div>
                                <div *ngIf="numbObj.isCalendar"
                                    class="textarea-input bg-assign-booker">
                                    <textarea class="lato-14-n-vw" [rows]="7" [cols]="48"
                                        [(ngModel)]="numbObj.value"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="class step2-right-section">
                <div [class]="'outer-border lato-18-n-vw step-8'" [@fadeInOnly]
                    [@.disabled]="!!disableAnimation">
                    <div *ngIf="!showCurrentYearPopup" class="step-8-table">
                        <div class="header">
                            <div class="lato-16-n-vw current-title title">Current
                                <div class="copy-icon" cygovSvgIcon
                                    [svgName]="'reports-archive-icon'"
                                    [ngbTooltip]="'Copy current to previous'"
                                    (click)="copyCurrentValues()"></div>
                            </div>
                            <div class="lato-16-n-vw title title-previous">Previous</div>
                        </div>
                        <div class="current-year-body">
                            <div *ngFor="let obj of currentYear[0]; let idx = index"
                                class="current-year-row">
                                <div class="column column-title">{{ obj.title }}</div>
                                <div class="column">
                                    <div *ngIf="obj.type === 'number'" class="column-value">
                                        <ng-container
                                            *ngTemplateOutlet="inputNumBox; context: {$implicit: obj.current, idx: idx, stepName: 'current_year'}">
                                        </ng-container>
                                    </div>
                                    <div *ngIf="obj.type === 'string'" class="column-value">
                                        <ng-container
                                            *ngTemplateOutlet="stringInput; context: {$implicit: obj.current, idx: idx, stepName: 'current_year'}">
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="column">
                                    <div *ngIf="obj.type === 'number'" class="column-value">
                                        <ng-container
                                            *ngTemplateOutlet="inputNumBox; context: {$implicit: obj.previous, idx: idx, stepName: 'previous_year'}">
                                        </ng-container>
                                    </div>
                                    <div *ngIf="obj.type === 'string'" class="column-value">
                                        <ng-container
                                            *ngTemplateOutlet="stringInput; context: {$implicit: obj.previous, idx: idx, stepName: 'previous_year'}">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="calculate-btn" (click)="toggleCurrentYearPopup()">Calculate
                        </div>
                    </div>
                    <ng-container *ngIf="showCurrentYearPopup">
                        <div class="popup">
                            <div class="cross-btn" (click)="toggleCurrentYearPopup()">X</div>
                            <div class="current-section">
                                <div class="heading">Current</div>
                                <div *ngFor="let item of currentYear[1]; let i=index"
                                    class="current-year-item lato-16-n-vw">
                                    <div class="circle"></div>
                                    {{item.title}}:&nbsp;
                                    <span *ngIf="item.type === '$'">{{item.current |
                                        largeNumberShort:
                                        '$'
                                        }}</span>
                                    <span *ngIf="item.type === '%'">{{item.current |
                                        largeNumberShort:
                                        '' : '%'
                                        : 2
                                        }}</span>
                                </div>
                            </div>
                            <div class="previous-section">
                                <div class="heading previous">Previous</div>
                                <div *ngFor="let item of currentYear[1]; let i=index"
                                    class="current-year-item lato-16-n-vw">
                                    <div class="circle"></div>
                                    {{item.title}}:&nbsp;

                                    <span *ngIf="item.type === '$'">{{item.previous |
                                        largeNumberShort:
                                        '$'
                                        }}</span>
                                    <span *ngIf="item.type === '%'">{{item.previous |
                                        largeNumberShort:
                                        '' : '%'
                                        : 2
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>


            </div>
        </div>
    </ng-template>

    <!-------------------------CURRENT/PREVIOUS YEAR---------------------------->
    <ng-template #currentYearArea let-stepNum="stepNum" let-disableAnimation="disableAnim">
        <div [class]="'outer-border lato-18-n-vw step-' + stepNum" [@fadeInOnly]
            [@.disabled]="!!disableAnimation">
            <div *ngIf="!showCurrentYearPopup" class="step-8-table">
                <div class="header">
                    <div class="lato-18-n-vw title">Current
                        <div class="copy-icon" cygovSvgIcon [svgName]="'reports-archive-icon'"
                            (click)="copyCurrentValues()"></div>
                    </div>
                    <div class="lato-16-n-vw title title-previous">Previous</div>
                </div>
                <div class="current-year-body">
                    <div *ngFor="let obj of currentYear[0]; let idx = index"
                        class="current-year-row">
                        <div class="column column-title">{{ obj.title }}</div>
                        <div class="column">
                            <div *ngIf="obj.type === 'number'" class="column-value">
                                <ng-container
                                    *ngTemplateOutlet="inputNumBox; context: {$implicit: obj.current, idx: idx, stepName: 'current_year'}">
                                </ng-container>
                            </div>
                            <div *ngIf="obj.type === 'string'" class="column-value">
                                <ng-container
                                    *ngTemplateOutlet="stringInput; context: {$implicit: obj.current, idx: idx, stepName: 'current_year'}">
                                </ng-container>
                            </div>
                        </div>
                        <div class="column">
                            <div *ngIf="obj.type === 'number'" class="column-value">
                                <ng-container
                                    *ngTemplateOutlet="inputNumBox; context: {$implicit: obj.previous, idx: idx, stepName: 'previous_year'}">
                                </ng-container>
                            </div>
                            <div *ngIf="obj.type === 'string'" class="column-value">
                                <ng-container
                                    *ngTemplateOutlet="stringInput; context: {$implicit: obj.previous, idx: idx, stepName: 'previous_year'}">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calculate-btn" (click)="toggleCurrentYearPopup()">Calculate</div>
            </div>
            <ng-container *ngIf="showCurrentYearPopup">
                <div class="popup">
                    <div class="cross-btn" (click)="toggleCurrentYearPopup()">X</div>
                    <div class="current-section">
                        <div class="heading">Current</div>
                        <div *ngFor="let item of currentYear[1]; let i=index"
                            class="current-year-item lato-16-n-vw">
                            <div class="circle"></div>
                            {{item.title}}:&nbsp;
                            <span *ngIf="item.type === '$'">{{item.current | largeNumberShort: '$'
                                }}</span>
                            <span *ngIf="item.type === '%'">{{item.current | largeNumberShort: '' :
                                '%'
                                : 2
                                }}</span>
                        </div>
                    </div>
                    <div class="previous-section">
                        <div class="heading previous">Previous</div>
                        <div *ngFor="let item of currentYear[1]; let i=index"
                            class="current-year-item lato-16-n-vw">
                            <div class="circle"></div>
                            {{item.title}}:&nbsp;

                            <span *ngIf="item.type === '$'">{{item.previous | largeNumberShort: '$'
                                }}</span>
                            <span *ngIf="item.type === '%'">{{item.previous | largeNumberShort: '' :
                                '%'
                                : 2
                                }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>

    <!------------------------TITLE STRING AREA------------------------->
    <ng-template #titleHeader let-titleObj="titleObj" let-isCheck="isCheckbox" let-notes="notes"
        let-reduceOpacity="reduceOpacity" let-index="index">
        <div class="title-area" [ngClass]="{'account-details-title' : titleObj.isDropDownOpened, 
      'title-area-step7-notes' : notes}">
            <div *ngIf="isCheck" class="check-box" [ngClass]="{'checked': titleObj.isChecked }"
                (click)="updateCheckBox(titleObj)">
                <div *ngIf="titleObj.isChecked" class="selected-tick" cygovSvgIcon
                    [svgName]="'right-tick'">
                </div>
            </div>
            <div
                [ngClass]="{'reduce-opacity' : reduceOpacity , 'title-name': !titleObj.inputField , 'adjust-width': titleObj.inputField,'display-class':titleObj.isTooltip}">
                {{ titleObj.title }}
                <span *ngIf="titleObj.asterisk && !reduceOpacity"
                    class="asterisk lato-16-n-vw">*</span>
                <div *ngIf="titleObj.isTooltip" class="info-tooltip-icon info-icon"
                    [ngbTooltip]="titleObj.toolTipContent"
                    [tooltipClass]="'data-inventory-tooltip' + ' ' + 'width-' + index"
                    [disableTooltip]="false" placement="right">
                    <div cygovSvgIcon [svgName]="'info-board-icon'"
                        class="data-inventory-info-icon"></div>
                </div>
            </div>

            <div class="string-input-2" *ngIf="titleObj.inputField">
                <ng-container
                    *ngTemplateOutlet="stringInput; context: {$implicit: titleObj,  stepName: 'organizational',placeholder: 'Other' }">
                </ng-container>
            </div>

        </div>
    </ng-template>
    <!------------------------ STRING INPUT Area------------------------->
    <ng-template #stringInput let-obj let-idx="idx" let-stepName="stepName"
        let-placeholder="placeholder" let-reduceOpacity="reduceOpacity">
        <input type="text" [name]="stepName+idx" [(ngModel)]="obj.value"
            [ngClass]="{'reduce-opacity':reduceOpacity}"
            [placeholder]="placeholder ? placeholder : ''">
    </ng-template>
    <!------------------------Drop Down Area------------------------->
    <ng-template #dropDownInput let-obj let-idx="idx" let-stepName="stepName">
        <div class="custom-drop-down">
            <div class="drop-down-label lato-16-n-vw" (click)="openAccountDetailDropdown(obj, idx)"
                [ngClass]="{'drop-down-opened' : obj.isDropDownOpened,'placeholder-color': !obj.value}">
                {{obj.value ? obj.value:'Expense'}}
                <div class="arrow-icon-right" cygovSvgIcon [svgName]="'arrow-right-type3'"></div>
            </div>
            <ng-container *ngIf="obj.isDropDownOpened">
                <div class="expanded-list">
                    <div *ngFor="let item of obj.list; let indx=index" class="expanded-list-item">
                        <span class="item lato-16-n-vw" (click)="saveDropdownItem(obj, indx)"
                            [ngClass]="{'blue-text': obj.value === item}">{{item}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
    <!------------------------Calendar Input Area------------------------->
    <ng-template #calendarInput let-obj let-idx="idx" let-stepName="stepName">
        <input [disabled]="true" type="text" class="lato-16-n-vw input-date" [ngModel]="obj.value">
        <div class="calendar-icon-button" cygovSvgIcon [svgName]="'bnb-current-previous-year'"
            (click)="openCalendar(obj)"></div>
        <div *ngIf="obj.isCalendarOpen" obj.isCalendarOpen class="calendar-area" [@fadeInOnly]>
            <div class="cross-icon-area">
                <div class="cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'"
                    (click)="obj.isCalendarOpen = false">
                </div>
            </div>
            <cygov-ngb-calendar [dateType]="'Calendar-'+idx"
                (selectedDateChange)="saveDate($event, obj); obj.isCalendarOpen = false"
                [openDefault]="true" (calendarRef)="calRefUpdate($event,obj)"
                [inputMinDate]="minDate" [firstDayOfWeek]="1" [showCustomNavigation]="true"
                [navigation]="'select'">
            </cygov-ngb-calendar>
        </div>
    </ng-template>
    <!------------------------Number Input AREA------------------------->
    <ng-template #inputNumBox let-obj let-idx="idx" let-stepName="stepName"
        let-isRevenue="isRevenue">
        <input type="text" min="1" [name]="stepName+idx" #inputElement
            class="number-input-style account-input"
            [ngModel]="obj.displayValue ? obj.displayValue : obj.value"
            (ngModelChange)="parseInput($event, obj, inputElement, stepName,isRevenue)"
            autocomplete="off" (keydown)="numberCheck($event)" />
    </ng-template>
    <ng-template #inputNumCheckBox let-obj let-idx="idx" let-stepName="stepName"
        let-isRevenue="isRevenue">
        <input type="text" min="1" [name]="stepName+idx" #inputElement class="number-input-style"
            [ngClass]="{'disabled-color-text':!obj.isChecked}"
            [ngModel]="isRevenue? addDollarSign(obj.displayValue ? obj.displayValue:obj.value): obj.displayValue ? obj.displayValue:obj.value"
            (ngModelChange)="parseInput($event, obj, inputElement, stepName,isRevenue)"
            autocomplete="off" (keydown)="numberCheck($event)" [disabled]="!obj.isChecked" />
    </ng-template>

    <!------------------------Risk Scenarios Details--------------------->
    <ng-template #riskMoreDetails let-moreObj="moreObj">
        <div class="header-dots lato-28-b-vw" (click)="detailOpened = 0">...</div>
        <div class="title-name">
            {{ moreObj?.title }}
        </div>
        <div class="body-area">
            <ng-container *ngFor="let detail of moreObj?.moreChildren">
                <div class="single-detail lato-14-n-vw"
                    [ngClass]="{'single-detail-hovering':!!detail.additionalChild}"
                    (click)="addCurrentDisplayAdd(!!detail.additionalChild , detail)">
                    <span class="ellipsis-wizard" [ngbTooltip]="detail?.name" container="body"
                        tooltipClass="framework-wizard-name-tooltip"
                        [disableTooltip]="!showTextTooltip"
                        (mouseenter)="checkWidthEllipsis($event.target)">{{ detail.name
                        }}:&nbsp;&nbsp;</span><span class="amount-numb"
                        [ngbTooltip]="detail?.value | number"
                        [disableTooltip]="!!detail?.additionalChild" container="body"
                        tooltipClass="industry-tooltip">
                        {{ detail.value | largeNumberShort:'$' }}</span>
                </div>
            </ng-container>
        </div>
        <div *ngIf="showAdditionalDetails" class="additional-details" [@fadeInOutAnimation]>
            <div class="cross-area-btn">
                <div class="cross-svg" cygovSvgIcon [svgName]="'x-icon-type2'"
                    (click)="addCurrentDisplayAdd(false , null)"></div>
            </div>
            <ng-container
                *ngIf="displayAdd.name === 'Security Remediation' && isBeecher ; else showNormalDetails">
                <!------------------------------------ DISCLAIMER WARNING MESSAGE AREA -------------------------------------->
                <div class="detail-add-name lato-14-b-vw">
                    Disclaimer
                </div>
                <div class="body-add-area lato-12-l-vw">
                    Security remediation costs are not included in these calculations, but are
                    typically
                    not
                    covered by cyber policies.
                </div>
            </ng-container>
            <ng-template #showNormalDetails>
                <!------------------------------------------ NORMAL FLOW AREA ------------------------------------------->
                <div class="detail-add-name lato-12-n-vw">
                    {{ displayAdd?.name }}:&nbsp;&nbsp;<span
                        class="amount-numb">$&nbsp;{{displayAdd?.value |
                        number}}</span>
                </div>
                <div class="body-add-area">
                    <ng-container *ngFor="let strName of dataScopeNames ; let idx =index">
                        <div *ngIf="displayAdd?.additionalChild[strName]>=0 || (displayAdd?.additionalChild[strName] && strName === 'IDT')"
                            class="item-add-detail lato-12-l-vw">
                            <span class="txt-area">
                                <ng-container *ngIf="idx<4 ; else otherNames">{{strName}}&nbsp;{{
                                    displayAdd?.name
                                    }}:&nbsp;&nbsp;</ng-container>
                                <ng-template
                                    #otherNames>{{otherDataScopeNames[strName]}}:&nbsp;&nbsp;</ng-template>
                                <span class="txt-white lato-12-n-vw">
                                    <ng-container *ngIf="strName==='IDT';else numbCase">
                                        &nbsp;{{displayAdd?.additionalChild[strName]}}
                                    </ng-container>
                                    <ng-template #numbCase>
                                        $&nbsp;{{displayAdd?.additionalChild[strName] | number }}
                                    </ng-template>
                                </span>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </div>
    </ng-template>

    <!-----------------------Delete Pop-Up Section--------------------------->
    <ng-template #HeaderDelete>
        <div class="delete-header"></div>
    </ng-template>

    <!----------------------------- edit BNB entity  pop up body --------------------------------->
    <ng-template #BodyDelete>
        <div class="delete-pop-body">
            <div class="delete-icon">
                <div cygovSvgIcon [svgName]="'trash-icon-2'"></div>
            </div>
            <div class="delete-message lato-20-n-vw">
                Please note you are starting a new wizard that will delete all the information in
                the
                draft
            </div>
        </div>
    </ng-template>

    <!-- BNB pop up Footer -->
    <ng-template #FooterDelete>
        <div class="delete-pop-footer">
            <cygov-button class="button-margin" [buttonText]="'CONFIRM'" [buttonWidth]="'5.25vw'"
                [buttonHeight]="'3vh'" [buttonColor]="colorScheme.buttonBlue"
                [hoverColor]="colorScheme.buttonHover"
                (buttonPressed)="showDeleteConfirmationPop()">
            </cygov-button>
            <cygov-button [buttonText]="'CANCEL'" [buttonColor]="'transparent'"
                [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.25vw'" [textColor]="'#C5C9D1'"
                (click)="closeDeleteModal()">
            </cygov-button>
        </div>
    </ng-template>

    <!---------------------------SCREEN SHOTS ALL STEP SCREENS ---------------------------------->
    <!---------Rendering all steps in a single step to save time when taking screen shot--------->
    <ng-template #screenShotAllSteps>
        <div *ngIf="takingScreenshot" class="outer-container-wiz" id="all-steps-div" #allStepsDiv>
            <div class="screenshot-wiz">
                <div class="left-wiz">
                    <ng-container *ngIf="organizationalScreen">
                        <ng-container>
                            <ng-container
                                *ngTemplateOutlet="organizationalArea; context: { stepNum: 1 , disableAnim:true}">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="accountsScreen">
                        <ng-container>
                            <ng-container
                                *ngTemplateOutlet="accountDetailsArea; context: { stepNum: 2 , disableAnim:true}">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="dataScopeScreen">
                        <ng-container
                            *ngTemplateOutlet="dataScopeArea; context: { stepNum: 3, disableAnim:true }">
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="applicantInfoScreen">
                        <ng-container
                            *ngTemplateOutlet="applicantInfoArea; context: { stepNum: 4 , disableAnim:true}">
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="revenueDetailScreen">
                        <ng-container
                            *ngTemplateOutlet="revenueDetailsArea; context: { stepNum: 5 , disableAnim:true}">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="dataInventoryScreen">
                        <ng-container
                            *ngTemplateOutlet="dataInventoryArea; context: { stepNum: 6, disableAnim:true }">
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #sendConfirmHeader>
        <div class="send-header"></div>
    </ng-template>
    <ng-template #sendConfirmBody>
        <div class="send-body">
            <p class="lato-20-n-vw main-heading">You are about to send the client the onboarding
                wizard</p>
            <p class="lato-14-n-vw dim">(The client will receive an invitation by email)</p>
        </div>
    </ng-template>
    <ng-template #sendConfirmFooter>
        <div class="send-footer">
            <cygov-button class="margin-right" [buttonText]="'CONFIRM'" [buttonWidth]="'5.1vw'"
                [buttonColor]="colorScheme.buttonBlue" [hoverColor]="colorScheme.buttonHover"
                (click)="sendClientEmail()">
            </cygov-button>
            <cygov-button class="border" [buttonText]="'CANCEL'" [buttonWidth]="'5.1vw'"
                [buttonColor]="'#030715'" [hoverColor]="'#030710'" (click)="closeDeleteModal()">
            </cygov-button>
        </div>
    </ng-template>
    <ng-template #dataInventoryToolTipContent>
        <div class="tooltip-content-wrapper lato-12-n-vw">
            <p><b>Inherent:&nbsp;</b>Probable Maximum Loss</p>
            <p [ngStyle]="{'margin-top': '2px'}"><b>Residual:&nbsp;</b>Current exposure following
                reduction of
                risk through control implementation</p>
        </div>
    </ng-template>