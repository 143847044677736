import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetUserQuery } from '../../../API.service';

@Component({
  selector: 'cygov-delete-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss'],
})
export class DeleteUserModalComponent implements OnInit {
  @Input() titleText = 'DELETE';
  @Input() users: GetUserQuery[];
  @Output() modalResult = new EventEmitter<any>();
  confirmationString = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.users = this.users || [];
  }

  onValueChanged(value): void {
    this.confirmationString = value?.toLowerCase();
  }

  confirm(): void {
    const isConfirmed = true;
    this.modalResult.emit({ isConfirmed });
  }
}
