// eslint-disable-next-line no-shadow
export enum FrequencySelectboxEnum {
  ADVANCE = 'Advance',
  BI_ANNUALLY = 'Bi-Annually',
  QUARTERLY = 'Quarterly',
  ANNUALLY = 'Annually',
}

export enum FrequencyDuration {
  QUARTERLY = 3,
  ANNUALLY = 12,
  ANNUAL = 12,
  BI_ANNUALLY = 6,
}
