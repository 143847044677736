import { animate, state, style, transition, trigger } from '@angular/animations';

export const Animations = {
  toogleDropdown: trigger('togglePanel', [
    state(
      'void',
      style({
        height: 0,
      })
    ),
    state(
      'appear',
      style({
        height: '*',
      })
    ),
    transition('void => appear', [animate('1000ms ease-out')]),
    transition('appear => void', [animate('1000ms ease-in')]),
  ]),
};
