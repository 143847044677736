import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'cygov-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOutAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(1000)]),
      transition(':leave', [style({ opacity: 1 }), animate(0, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ProgressBarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() assessmentLevel: number;
  @Input() preFill: string;
  @Input() postFill: string[];
  @Input() height: number;
  @Input() width: number;
  @Input() enableCheckPoint = false;
  @Input() enableLabel = false;
  @Input() progressCheckPoints = [];
  postFillStr: string;
  @ViewChild('cygovProgressBar') progressBar: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes): void {
    // if change detected in any value then progress bar will be re-rendered
    if (changes && (changes.assessmentLevel || changes.progressCheckPoints)) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit(): void {
    if (this.progressBar) {
      const width = this.progressBar.nativeElement.clientWidth;
      const ratio = (this.height / width) * 100;

      // return ratio if assessmentLevel is null or undefined
      // eslint-disable-next-line radix
      this.assessmentLevel =
        // if undefined set to 0
        !UtilsService.isDefined(this.assessmentLevel)
          ? 0
          : this.assessmentLevel >= 0
          ? !width || this.assessmentLevel >= ratio
            ? this.assessmentLevel
            : ratio
          : ratio;
      // 21% of progress bar width (in pixels) are added to maintain proper distances between checkpoints.
      const additionalPixels = 21 * (width / 100);
      const maxWidth = (additionalPixels + width) / 19.2;
      const pointsPos = this.progressCheckPoints.map(point => maxWidth * point.percent);
      let prevTextPoint;
      this.progressCheckPoints.forEach((data, index) => {
        data.point = index ? (pointsPos[index] - pointsPos[index - 1]) / 100 : pointsPos[index] / 100;
        if (index) {
          // 4.5vw is the width of label container
          data.textPoint = data.point > prevTextPoint + 4.6 ? data.point - prevTextPoint - 4.5 : 0.1;
        } else {
          data.textPoint = (data.point / maxWidth) * (maxWidth - 4.6);
        }
        prevTextPoint = data.textPoint;
      });
      this.assessmentLevel = this.assessmentLevel > 100 ? 100 : this.assessmentLevel;
    }
    this.preFill = this.preFill || '#2E3240'; // '#979797';
    this.postFillStr = this.postFill && this.postFill.length > 1 ? this.postFill.join(',') : '#2684D6, #0c3152';
    this.height = this.height || 10;
    this.cdRef.detectChanges();
  }

  barStyle() {
    return {
      height: `${this.height}px`,
      width: `${this.assessmentLevel}%`,
      background: `linear-gradient(to left, ${this.postFillStr})`,
    };
  }
}
