export class RiskRegisterConstants {
  // impact + ' ' + probability
  public static lowLabelRange: string[] = ['low low', 'low medium', 'low high', 'medium low'];

  public static mediumLabelRange: string[] = ['low critical', 'medium medium', 'medium high', 'high low'];

  public static highLabelRange: string[] = ['medium critical', 'high medium', 'critical low', 'critical medium'];

  public static criticalLabelRange: string[] = ['high critical', 'high high', 'critical high', 'critical critical'];

  public static popUpsStrings = {
    DISABLE: 'disable',
    DELETE: 'delete',
    ARCHIVE: 'archive',
    ESCALATE: 'escalate',
    DE_ESCALATE: 'deescalate',
    RE_ACTIVATE: 'reactivate',
    SAVE_FILTER: 'filter',
  };

  public static filterNameToObject = {
    status: 'riskStatus',
    impact: 'riskImpact',
    probability: 'riskProbability',
    inherentImpact: 'riskImpact',
    inherentProbability: 'riskProbability',
    residualProbability: 'residualProbability',
    residualImpact: 'residualImpact',
    show: 'disable',
    priority: 'riskPriority',
    source: 'type',
    residual: 'residualRisk',
    entities: 'subEntityId',
    tags: 'riskTags',
    financial: 'financialToggle',
    tasks: 'riskTasks',
    escalated: 'isEscalated',
    riskOwner: 'riskOwnerIds',
  };
  public static financialToggle = {
    With: true,
    Without: false,
  };
}

export class RiskTableConstant {
  public static SORT_TYPE: any = {
    ASC: 'asc',
    DSC: 'dsc',
    NA: 'na',
  };
  public static ASSET_ITEMS: any = {
    SOFTWARE: 'Software',
    INFORMATION: 'Information',
    HARDWARE: 'Hardware',
    NETWORK: 'Network',
    PEOPLE: 'People',
    FACILITIES: 'Facilities',
  };
  public static EDITABLE_SECTIONS: any = {
    'Related Assets': true,
    Impact: true,
    Probability: true,
    Controls: false,
    'Financial Impact': true,
    'Cost To Remediate': true,
    Description: true,
    Notes: true,
    ID: false,
    Name: true,
    Entity: false,
    Priority: true,
    Inherent: false,
    Residual: false,
    Status: true,
    Tagging: true,
    Tasks: false,
    'Open Remediation Tasks': true,
  };
  public static INFO_DATA: any = {
    ID: 'idTitle',
    Name: 'riskTitle',
    Entity: 'subEntity',
    Inherent: 'inherentRisk',
    Residual: 'residualRisk',
    Financial: 'primaryLoss',
    Status: 'riskStatus',
  };
  public static INFO_DATA_DETAILS: any = {
    relatedAssets: 'relatedAssets',
    riskImpact: 'riskImpact',
    riskProbability: 'riskProbability',
    residualImpact: 'residualImpact',
    residualProbability: 'residualProbability',
    Control_List: 'controlIds',
    'Financial Impact': 'financialImpact',
    'Cost To Remediate': 'costToRemediate',
    'Risk Owner': 'riskOwner',
    Sensitivity: 'sensitivity',
    'Control Effectiveness': 'controlEffectiveness',
    Description: 'riskDescription',
    Notes: 'notes',
    riskTags: 'riskTags',
    Tasks: 'riskTasks',
    'Financial Exposure': 'FinancialExposure',
  };
  public static RISK_TYPE_SVG: any = {
    AUTOMATIC: 'risk-auto-icon',
    MANUAL: 'risk-semi-auto-icon',
    HARD_MANUAL: 'risk-manual-icon',
    AI: 'risk-ai-icon',
  };
  public static RISK_TYPE_TOOLTIP: any = {
    AUTOMATIC: 'Source : Autonomous',
    MANUAL: 'Source : Automated',
    HARD_MANUAL: 'Source : Manual',
    AI: 'Source : AI',
  };
}

export class RiskFinancialConstant {
  public static MODE: any = {
    PML: 'pml',
    BASIC: 'basic',
    ADVANCED: 'advanced',
    ALL: 'all',
  };
}

//* EXPORT ENUMS
export enum RISK_EXPORT_OPTIONS {
  EXPORT_XLS = 'General Report (XLS)',
  EXPORT_CSV = 'General Report (CSV)',
  EXPORT_CURRENT_XLS = 'Current View (XLS)',
  EXPORT_CURRENT_CSV = 'Current View (CSV)',
}
