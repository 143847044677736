<div class="upper-container">
    <div class="background">
        <div class="container">
            <span class="welcome-header lato-18-n-vw">Welcome to the Cyber In-Site Platform powered
                by
                Centraleyes</span>
            <br />
            <p class="lato-16-n-vw welcome-message">You will now begin step 1 of 2 in your cyber
                insurance
                application process. Please fill out the following information to help our risk
                engine begin the analysis. We look forward to sharing with you the automated outputs
                of your cyber insurance application once completed.</p>
            <br />
            <br />

            <cygov-button [buttonText]="'GET STARTED >'" [buttonHeight]="'2.7vh'"
                [buttonColor]="'#29a9eb'" (buttonPressed)="nextButtonHandler()"></cygov-button>
        </div>
    </div>

</div>