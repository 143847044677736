import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  APIService,
  BnbLayerEnum,
  CreateUserInput,
  EntityTypeEnum,
  GetEntityQuery,
  GetRoleQuery,
  GetUserQuery,
  UpdateUserInput,
} from 'app/API.service';
import { EntityService } from '../entity.service';
import { FrameworkGroup } from 'app/questionnaire-management/questionnaire-management.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../utils.service';
import { NGXLogger } from 'ngx-logger';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from '../enums/appLevelRoles.enum';
import { UserService } from '../user.service';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { CUSTOMAPIService } from 'app/custom-api.service';

interface DropDown {
  id: string;
  name: string;
}

const ENTITY_LEADER = 'EntityLeader';
const SUB_ENTITY_LEADER = 'SubEntityLeader';

@Component({
  selector: 'cygov-add-user-settings-modal',
  templateUrl: './add-user-settings-modal.component.html',
  styleUrls: ['./add-user-settings-modal.component.scss'],
})
export class AddUserSettingsModalComponent implements OnInit {
  @Input() collectionMode = false;
  @Input() framework: FrameworkGroup;
  @Input() user: GetUserQuery;
  @Input() subEntitiesList: GetEntityQuery[];
  @Input() isUpdating = false;
  @Input() roles: GetRoleQuery[];
  @Input() isUserModal: boolean = false;
  @Input() currentEntityId: string = null;
  @Output() modalResult = new EventEmitter<CreateUserInput | UpdateUserInput>();
  entity: GetEntityQuery;
  filteredSubEntityList: DropDown[] = [];
  newUser: CreateUserInput;
  selectedEntity: string;
  currentUser: GetUserQuery;
  roleOptions: string[] = [];
  leaderTypes = [SUB_ENTITY_LEADER];
  entityId: string = null;
  subEntityId: string = null;
  selectedSubEntityId: any = null; // this variable can contains multiple ids. So, it should be 'selectedSubEntityIds'.
  subEntityList: GetEntityQuery[];
  leaderType: string = SUB_ENTITY_LEADER;
  appLevelRoleEnum = AppLevelRoleEnum;
  appLevelRoleEnumBnB = AppLevelRoleEnumBnB;
  notAllowedRoles: string[] = [AppLevelRoleEnum.PARTICIPANT];
  previousEntityId: string = null;
  showEntitySelection: boolean = true;
  disableInput = true;
  sendEnrollment = false;
  permissionEdit = false;

  currentUserRole: GetRoleQuery; // Adding currentUserRole which will be removed and replaced with user.role after making connection.
  isGlobalUsersSetting: boolean = false;
  isGlobalParticipant: boolean = false; // Boolean for BNB instance where a participant can be added as globally
  secondSelectBoxTitle: string;
  rootEntities: any[];
  listOfAllLayersAndEntities: GetEntityQuery[];
  childEntitiesOfSelectedRootEntity: any;
  divisions: any;
  regions: any;
  isBnB: boolean = UtilsService.isBnB;
  assignmentCheck: boolean = true;
  divisionIds: string[] = [];

  secondSelectionList = [
    AppLevelRoleEnum.SUBENTITY_LEADER,
    AppLevelRoleEnum.PARTICIPANT,
    AppLevelRoleEnumBnB.DIVISION_LEADER,
    AppLevelRoleEnumBnB.REGION_LEADER,
    AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN,
    AppLevelRoleEnum.ENTITY_LEADER.toLowerCase(),
  ] as any[];
  isRoleLeader: boolean;
  preSelectedTitle: string;
  preSelectedName: string;
  divRegName: any;
  userModalRoles: string[];
  isThirdParty: boolean = false;
  buttonText: string = 'SEND INVITATION';
  buttonColor: string = '#0f92e3';

  constructor(
    private entityService: EntityService,
    private authService: AuthService,
    private userSettingService: UsersSettingsService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger,
    private userService: UserService,
    private customApi: CUSTOMAPIService
  ) {}

  async ngOnInit(): Promise<void> {
    this.buttonText = this.isUserModal ? 'ADD' : 'SEND INVITATION';
    this.buttonColor = this.isUserModal ? '#29a9eb' : '#0f92e3';
    this.userModalRoles = [AppLevelRoleEnum.VENDOR, AppLevelRoleEnum.PARTICIPANT];
    this.currentUser = await this.userService.getCurrentUser();
    this.currentUserRole = this.userService.getCurrentRole();
    this.isGlobalUsersSetting = this.userSettingService.isGlobalUserSettings();
    this.newUser = this.initUser();
    this.roleOptions = this.roles.map(role => role.name);
    if (this.isGlobalUsersSetting) {
      this.rootEntities = this.userSettingService.getGlobalRootEntities();
      this.rootEntities.sort((a, b) => {
        const textA = a.name?.toUpperCase();
        const textB = b.name?.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      // removing the vendor role.
    }
    const vendorRoleIndex = this.roleOptions.findIndex(role => role === AppLevelRoleEnum.VENDOR);
    if (vendorRoleIndex > -1) {
      if (!this.isUserModal) {
        this.roleOptions.splice(vendorRoleIndex, 1);
      }
    }
    if (!this.isUserModal) {
      this.removeSpecificRole(AppLevelRoleEnum.VENDOR);
    }
    if (!this.isGlobalUsersSetting) {
      // removing the vendor role from the roles list
      // Will be required in the future after adding the connection
      // this.roleOptions = this.user ? this.upgradeRoleOptions() : this.populateRoleOptions();
      // this.userSettingService.currEntRoles.subscribe(roles => {
      //   this.roleOptions = roles.map(role => role.name);
      //   this.roles = roles;
      // });
      if (this.user && this.user.entityId) {
        this.previousEntityId = this.user.entityId;
      }

      this.isThirdParty = this.router.url.includes('third-party');
      this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      this.leaderTypes = this.populateLeaderOptions();
      this.entity = this.entityId ? await this.entityService.getEntity(this.entityId) : null;
      this.subEntityList = await this.entityService.listChildEntitysByParentId(
        this.entity ? this.getDisplayEntityId() : this.entityId
      );
      this.divRegName = await this.entityService.getDivisionRegionName(this.entityId);
    } else if (!this.isBnB && !UtilsService.isBnBCyberSite) {
      /**
       * For Global User Settings OUTSIDE the clients menu.
       * Fixed: SubentityId list loaded for Participant
       */
      this.isGlobalUsersSetting = false;
      if (this.user && this.user.entityId) {
        this.previousEntityId = this.user.entityId;
      }

      this.entityId = this.currentEntityId;
      this.entity = this.entityId ? await this.entityService.getEntity(this.entityId) : null;
      this.subEntityList = await this.entityService.listChildEntitysByParentId(
        this.entity ? this.getDisplayEntityId() : this.entityId
      );
    }

    // This code will be removed later after adding the connection. For testing purpose in future.
    // try {
    //   if (await this.authService.hasPermission(RoleEnum.LEADER, this.entityId)) {
    //     this.entity = this.entityId ? await this.customApi.GetEntity(this.entityId) : null;
    //     this.subEntityList = await this.entityService.listChildEntitysByParentId(this.getDisplayEntityId());
    //   } else {
    //     this.subEntityList = [await this.customApi.GetEntity(this.subEntityId)];
    //     this.selectedSubEntityId = this.subEntityList[0].id;
    //   }
    // } catch (e) {
    //   this.logger.error('Add Participant Modal Component - Error: ', e);
    //   const message = UtilsService.msgFromError(e);
    //   this.toastr.error(message);
    // }
    if (this.isBnB) {
      const domain = DomainFrameworkService.getDomain();
      const enterprise = await this.entityService.getEntityLayerChildren('rootlayer', domain);
      if (enterprise && enterprise.length) {
        this.divisions = await this.entityService.getEntityLayerChildren(enterprise[0].id, domain);
        this.divisions.forEach(div => {
          this.divisionIds.push(div.id);
        });
        const allRegions = await this.entityService.getEntityLayersByType(BnbLayerEnum.REGION);
        this.regions = allRegions.filter(region => this.divisionIds.includes(region.parentId));
      }
    }
    if (this.isUserModal) {
      this.roles = this.roles.filter(role => {
        return role.name.toLowerCase() === AppLevelRoleEnum.VENDOR;
      });
    }
  }

  /**
   * helper method to remove the role from the roleslist
   */
  removeSpecificRole(roleName: any): void {
    const roleIndex = this.roles.findIndex(role => role.name.toLowerCase() === roleName.toLowerCase());
    if (roleIndex > -1) {
      this.roles.splice(roleIndex, 1);
    }
  }

  get prompt(): string {
    let sub;
    let param;
    if (this.newUser?.role) {
      switch (this.newUser.role.toLowerCase()) {
        case AppLevelRoleEnum.MSSP:
          return 'The new user will receive an enrolment invitation by email,\
                    following which they will be able to access the platform with full MSSP and\
                    management rights.';
        case AppLevelRoleEnum.ENTITY_LEADER:
          return 'The new user will receive an enrolment invitation by email,\
                    following which they will be able to access the platform with full access\
                    to the entire organization including all entities dashboards, and 3rd party\
                    solution dashboards. The user will be able to manage all other users in the\
                    organization.';
        case AppLevelRoleEnum.PARTICIPANT:
          return 'The new user will receive an enrollment invitation by email,\
								  following which they will be able to access their questionnaire\
								  and remediation tasks, which would be assigned to them.';
        case AppLevelRoleEnum.SUBENTITY_LEADER:
          if (!this.selectedSubEntityId) {
            return null;
          }
          sub = this.subEntityList
            ? this.subEntityList.filter(
                subEntity => this.selectedSubEntityId && this.selectedSubEntityId.includes(subEntity.id)
              )
            : null;
          param = sub && sub.length > 0 ? sub[0].name : null;

          return `The new user will receive an enrolment invitation by email,\
                  following which they will be able to access the platform with full access\
                  to the ${param} dashboards`;
        case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN:
          // return this.isGlobalUsersSetting ? null : 'Current Selected Root Entity will be asign to this User';
          break;
        case AppLevelRoleEnumBnB.DIVISION_LEADER:
          // return this.isGlobalUsersSetting ? null : 'Current Selected Root Entity will be asign to this User';
          break;
        case this.appLevelRoleEnumBnB.REGION_LEADER:
          // return this.isGlobalUsersSetting ? null : 'Current Selected Root Entity will be asign to this User';
          break;
        case this.appLevelRoleEnumBnB.USER_ADMINISTRATOR:
          return 'The new user will receive an enrolment invitation by email,\
           following which they will be able to access the User Settings and manage all users.';
          break;
        default:
          return null;
      }
    }
  }

  getDisplayEntityId(): string {
    return this.entityService.isChildEntity(this.entity) ? this.entity.parentId : this.entity.id;
  }

  isValid(user: CreateUserInput | UpdateUserInput): boolean {
    let valid = true;
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const linkPattern = /(?:https?:\/\/|www\.)\S+/gi;
    const htmlPattern = /<[^>]*>/gi;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }
    if (linkPattern.test(user.name) || htmlPattern.test(user.name)) {
      this.toastr.error('Name is invalid');
      valid = false;
    }
    if (!user.role && !this.notAllowedRoles.includes(this.currentUserRole.name)) {
      this.toastr.error('User role is required');
      valid = false;
    } else if (
      !this.leaderType &&
      (user.role.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER ||
        user.role.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER) &&
      !this.notAllowedRoles.includes(this.currentUserRole.name.toLowerCase())
    ) {
      this.toastr.error('Leader Type is required');
      valid = false;
    } else if (
      this.isSubEntityLeaderOrParticipant(user) &&
      (!UtilsService.isDefined(this.selectedSubEntityId) || this.selectedSubEntityId.length === 0) &&
      !this.isUpdating &&
      !this.isUserModal &&
      !this.isGlobalParticipant
    ) {
      this.toastr.error('SubEntity is required');
      valid = false;
    }
    return valid;
  }

  /**
   *  emit new User to back to main user-settings component
   * @param newUser is type of User Object
   */
  async emitUser(newUser: CreateUserInput | UpdateUserInput | any): Promise<any> {
    newUser.email = newUser.email.toLowerCase();
    if (!this.isValid(newUser)) {
      console.log('Not valid', newUser);
      return;
    }
    this.userSettingService.setIsAddNewUSerModalOpened(false);
    if (this.isSubEntityLeaderOrParticipant(newUser) && !this.isUserModal) {
      // Temporary Entity ID -- will be removed soon.
      // newUser.entityId =
      //   this.newUser.role === RoleEnum.PARTICIPANT ? this.selectedSubEntityId : this.selectedSubEntityId[0];
      if (newUser.role.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER) {
        // Temporarily promote to Entity Leader
        newUser.entityIds = this.selectedSubEntityId && this.selectedSubEntityId.length ? this.selectedSubEntityId : [];
        newUser.temporaryHigherRank = true;
      } else {
        // A Global participant will not be assigned to any sub entity initially. Later admin/mssp can assign it
        // to any number of sub entities from different root entities as it will be displayed in collection
        newUser.entityIds = this.selectedSubEntityId && this.selectedSubEntityId.length ? this.selectedSubEntityId : [];
        newUser.isGlobalParticipant = this.isGlobalParticipant;
        // If the participant is global then dont assign any entityId to it. Admin later can assign this user to any entity
        if (this.isGlobalParticipant && this.isBnB) {
          // Can set empty string so making it global
          newUser.entityId = 'global';
        }
      }
    } else if (
      this.isEntityLeader(newUser) ||
      newUser.role?.toLowerCase() === this.appLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN
    ) {
      newUser.entityIds = [this.entityId];
    } else if (newUser.role?.toLowerCase() === this.appLevelRoleEnumBnB.DIVISION_LEADER) {
      if (this.isGlobalUsersSetting) {
        newUser.entityLayerIds = [this.entityId];
      } else {
        const divisionId = await this.entityService.getDivisionRegionId(true, this.entityId);
        newUser.entityLayerIds = [divisionId];
      }
    } else if (newUser.role?.toLowerCase() === this.appLevelRoleEnumBnB.REGION_LEADER) {
      if (this.isGlobalUsersSetting) {
        newUser.entityLayerIds = [this.entityId];
      } else {
        const regionId = await this.entityService.getDivisionRegionId(false, this.entityId);
        newUser.entityLayerIds = [regionId];
      }
    }
    newUser.roleId = this.roles.find(role => role.name.toLowerCase() === newUser.role.toLowerCase())?.id;
    if (this.isUserModal) {
      newUser.assignmentCheck =
        newUser.role?.toLowerCase() === this.appLevelRoleEnumBnB.PARTICIPANT ? false : this.assignmentCheck;
    }

    // console.log('emit user', newUser);
    this.modalResult.emit(newUser);
  }

  initUser(): any {
    if (this.isGlobalUsersSetting) {
      this.secondSelectBoxTitle = 'Root Entities';
      this.listOfAllLayersAndEntities = this.rootEntities;
    }
    return (
      this.user || {
        name: '',
        email: '',
        role: UtilsService.isBnB
          ? AppLevelRoleEnumBnB.ENTERPRISE
          : this.isUserModal
          ? AppLevelRoleEnum?.VENDOR
          : AppLevelRoleEnum.PARTICIPANT, // this.getDefault(),
        isCognitoUser: false,
        questionMap: JSON.stringify({}),
        isViewOnly: false,
        clickThrough: false,
        clickThroughDate: null,
      }
    );
  }
  // TODO: delete if no need
  getDefault(): AppLevelRoleEnum {
    switch (this.currentUserRole.name.toLowerCase()) {
      case AppLevelRoleEnum.ADMIN:
        return AppLevelRoleEnum.ADMIN;
        break;
      case AppLevelRoleEnum.MSSP:
        return AppLevelRoleEnum.MSSP;
        break;
      case AppLevelRoleEnum.ENTITY_LEADER:
        return AppLevelRoleEnum.ENTITY_LEADER;
        break;
      case AppLevelRoleEnum.SUBENTITY_LEADER:
        return AppLevelRoleEnum.SUBENTITY_LEADER;
        break;
      case AppLevelRoleEnum.PARTICIPANT:
        return AppLevelRoleEnum.PARTICIPANT;
        break;
    }
    return AppLevelRoleEnum.PARTICIPANT;
  }
  populateRoleOptions(): string[] {
    const options: string[] = [];

    switch (this.currentUserRole.name.toLowerCase()) {
      case AppLevelRoleEnum.ADMIN:
        options.push(AppLevelRoleEnum.ADMIN);
        options.push(AppLevelRoleEnum.MSSP);
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        options.push(AppLevelRoleEnum.PARTICIPANT);
        break;
      case AppLevelRoleEnum.MSSP:
        options.push(AppLevelRoleEnum.MSSP);
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        options.push(AppLevelRoleEnum.PARTICIPANT);
        break;
      case AppLevelRoleEnum.ENTITY_LEADER:
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        options.push(AppLevelRoleEnum.PARTICIPANT);
        break;
      case AppLevelRoleEnum.SUBENTITY_LEADER:
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        options.push(AppLevelRoleEnum.PARTICIPANT);
        break;
    }
    return options;
  }

  upgradeRoleOptions(): string[] {
    const options: string[] = [];

    switch (this.user.role.toLowerCase()) {
      case AppLevelRoleEnum.PARTICIPANT:
        options.push(AppLevelRoleEnum.ADMIN);
        options.push(AppLevelRoleEnum.MSSP);
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        options.push(AppLevelRoleEnum.PARTICIPANT);
        break;
      case AppLevelRoleEnum.MSSP:
        options.push(AppLevelRoleEnum.ADMIN);
        options.push(AppLevelRoleEnum.MSSP);
        break;
      case AppLevelRoleEnum.ENTITY_LEADER:
        options.push(AppLevelRoleEnum.ADMIN);
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        break;
      case AppLevelRoleEnum.SUBENTITY_LEADER:
        options.push(AppLevelRoleEnum.ADMIN);
        options.push(AppLevelRoleEnum.ENTITY_LEADER);
        options.push(AppLevelRoleEnum.SUBENTITY_LEADER);
        break;
      case AppLevelRoleEnum.ADMIN:
        options.push(AppLevelRoleEnum.ADMIN);
        break;
    }
    return options;
  }

  populateLeaderOptions(): string[] {
    const options: string[] = [SUB_ENTITY_LEADER];
    options.push(ENTITY_LEADER);
    this.leaderType = ENTITY_LEADER;
    return options;
  }

  isSubEntityLeaderOrParticipant(user): boolean {
    return (
      user.role.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER ||
      user.role.toLowerCase() === AppLevelRoleEnum.PARTICIPANT
    );
  }

  isEntityLeader(user): boolean {
    return user.role.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER;
  }

  async listValueSelected(event, listNum): Promise<void> {
    // resetting the 3rd drop down value when 2nd drop down selection is changed
    this.selectedSubEntityId = null;
    if (this.isGlobalUsersSetting) {
      if (listNum === 1) {
        this.childEntitiesOfSelectedRootEntity = await this.userSettingService.getRootAndChildEntities(
          event,
          EntityTypeEnum.ROOT_ENTITY
        );
        this.childEntitiesOfSelectedRootEntity = this.childEntitiesOfSelectedRootEntity?.childEntities;
      }
    }
  }

  upgradeRole(role: any): void {
    this.isRoleLeader =
      role.toLowerCase() === AppLevelRoleEnumBnB.DIVISION_LEADER ||
      role.toLowerCase() === AppLevelRoleEnumBnB.REGION_LEADER ||
      role.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN;
    if (this.isGlobalUsersSetting) {
      // resetting the 2nd drop down value when 1st drop down selection is changed
      // only for global user settings case , not for First Party User Settings
      this.entityId = null;
      switch (role.toLowerCase()) {
        case AppLevelRoleEnumBnB.DIVISION_LEADER:
          this.secondSelectBoxTitle = 'Divisions';
          this.listOfAllLayersAndEntities = this.divisions;
          break;
        case AppLevelRoleEnumBnB.REGION_LEADER:
          this.secondSelectBoxTitle = 'Regions';
          this.listOfAllLayersAndEntities = this.regions;
          break;
        case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN:
          this.secondSelectBoxTitle = 'Profit Center';
          this.listOfAllLayersAndEntities = this.rootEntities;
          break;
        case AppLevelRoleEnumBnB.SUBENTITY_LEADER:
          this.secondSelectBoxTitle = 'Root Entities';
          this.listOfAllLayersAndEntities = this.rootEntities;
          break;
        case AppLevelRoleEnum.ENTITY_LEADER.toLowerCase():
          this.secondSelectBoxTitle = 'Root Entities';
          this.listOfAllLayersAndEntities = this.rootEntities;
          break;
        case AppLevelRoleEnum.PARTICIPANT:
          this.secondSelectBoxTitle = 'Root Entities';
          this.listOfAllLayersAndEntities = this.rootEntities;
          break;
        default:
          break;
      }
    } else {
      switch (role.toLowerCase()) {
        case AppLevelRoleEnumBnB.DIVISION_LEADER:
          this.preSelectedTitle = 'Selected Division ';
          this.preSelectedName = this.divRegName?.div;
          break;
        case AppLevelRoleEnumBnB.REGION_LEADER:
          this.preSelectedTitle = 'Selected Region ';
          this.preSelectedName = this.divRegName?.reg;
          break;
        case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN:
          this.preSelectedTitle = 'Selected Entity ';
          this.preSelectedName = this.entity.name;
          break;
        default:
          this.preSelectedTitle = '';
          this.preSelectedName = '';
          break;
      }
    }
    if (this.newUser.role.toLowerCase() === AppLevelRoleEnum.PARTICIPANT) {
      this.newUser.entityId = this.previousEntityId;
      this.disableInput = true;
      this.selectedSubEntityId = null;
      this.assignmentCheck = false;
    } else {
      this.disableInput = false;
      this.assignmentCheck = true;
    }
  }

  showSelect(): void {
    this.showEntitySelection =
      this.newUser?.role.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER ||
      this.newUser?.role.toLowerCase() === AppLevelRoleEnum.PARTICIPANT ||
      false;
  }

  setPermission() {
    this.newUser.isViewOnly = !this.newUser?.isViewOnly;
  }

  closeModal() {
    this.activeModal.close();
    this.userSettingService.setIsAddNewUSerModalOpened(false);
  }

  isCurrentUserAdmin() {
    console.log('isCurrentUserAdmin: ', this.currentUserRole);
    return true;
  }

  /**
   * Function to get Sub Entities from Dropdown and add it into SelectedSubEntityId.
   * @param event - Output from Custom Dropdown which will contain array of sub entity ids
   */
  selectedSubEntities(event: any): void {
    this.selectedSubEntityId = event.map(entity => entity.id);
  }
}
