<div class="download-modal-screen">
    <div class="modal-overlay">
        <div class="modal-body">
            <div class="modal-header">
                <div class="close-icon" (click)="closeDeleteModal()" cygovSvgIcon
                    [svgName]="'x-icon-type2'">
                </div>
            </div>

            <div *ngIf="showConfirmDialogBox && allFiles.length!=0 && totalCounter>0" class="modal-download-content">
                <div cygovSvgIcon [svgName]="'download-files-circular'"></div>

                <span>
                    Please note you are about to download <span class='txt-blue'>{{totalCounter}}</span>
                    file<ng-container *ngIf="totalCounter>1">s</ng-container>
                </span>

                <div class="button-row">
                    <button type="button" (click)="downloadFiles()"
                        class="btn btn-primary uppercase add-entity-btn btnVendor lato-14-n-vw">
                        Confirm
                    </button>
                    <button type="button" (click)="closeDeleteModal()"
                        class="btn btn-primary uppercase add-entity-btn btnVendor transparent-background lato-14-n-vw">
                        Cancel
                    </button>
                </div>
            </div>

            <div *ngIf="(!confirmDownloadFlag && !showSuccessMessage && showConfirmDialogBox && allFiles.length===0) || totalCounter<1"
                class="no-files">
                <span class="lato-20-b-vw">No Files to download</span>
            </div>


            <div *ngIf="confirmDownloadFlag">
                <div class="confirm-download-container">
                    <div *ngFor="let file of allFileNames">
                        <div class="file-type-container">
                            <cygov-username-icon [document]="true" [docFormat]="file"
                                [height]="'4.7'">
                            </cygov-username-icon>
                            <div class="lato-14-n-vw file-name"><span
                                    class="aligner">{{file}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="progress-bar-container">
                    <cygov-progress-bar class="progress-bar-height"
                        [assessmentLevel]="assessmentValue" [postFill]="['#F53D7D', '#290411']">
                    </cygov-progress-bar>
                </div>
            </div>
            <div *ngIf="showSuccessMessage">
                <div class="success-content-container">
                    <div cygovSvgIcon [svgName]="'download-complete-tick'"></div>

                    <div class="button-row">
                        <span>Your Files Download Successfully !
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>