<div class="positioning-center upload-file-cont" ng2FileDrop [uploader]="uploader"
    (onFileDrop)="fileHandler($event)">

    <div class="body">
        <!-- <div class="upload-icon-positioning">
            <div class="icon-upload-cloud" cygovSvgIcon [svgName]="'upload_collection'"></div>
        </div> -->
        <div class="upload-icon-positioning">
            <div class="icon-upload-cloud" cygovSvgIcon [svgName]="'upload_collection'"></div>
            <img *ngIf="!isInformatica" class="upload-cloud-img" src="assets/images/upload-cloud.png" alt="Cloud Upload" />
            <img *ngIf="isInformatica" class="upload-cloud-img" src="assets/images/cloud-new.png" alt="Cloud Upload" />

        </div>
        <div id="caller" class="lato-30-n-vw center-align-text upload-text-cont">
            Drag files here or
            <label class="browse-text" for="file">browse</label>
            <input class="hide-input-file" type="file" [accept]="uploadType" id="file" ng2FileSelect
                [uploader]="uploader" (onFileSelected)="fileHandler($event)"
                [multiple]="multiSelect" />
        </div>
    </div>
</div>