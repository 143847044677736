import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {
  public message = 'You are not Authorized!';
  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  async navigateHome() {
    await this.userService.navigateByRole();
  }
}
